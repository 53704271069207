import { GlobalBaseEntity } from "../../models/base.models";
import { RtOption, RtNone } from "../../utils/option-helper";

export const NIGHT_MODE_THEME = 'BLACK-THEME';

export type Language = 'en' | 'sk' | 'de' | 'fr' | 'es' | 'pt-br' | 'he' | 'ar';

export enum AppSettingsEnum {
  LANGUAGE = "language",
  // DEFAULT_LANGUAGE = "default_language",
  THEME = "theme",
  AUTO_NIGHT_MODE = "auto_night_mode",
  NIGHT_THEME = "night_theme",
  // PAGE_ANIMATIONS = "page_animations",
  // ELEMENTS_ANIMATION = "elements_animation",
  UOM_SYSTEMS = "uom_system",
  UOM_MEASURES = "uom_system",
  TIME_ZONE = "time_zone",
  DATE_FORMAT = "date_format"
}

export type AppSetting = { value: any, canUserChange: boolean }

export type AppSettingsContainer = {
  language: AppSetting,
  defaultLanguage: AppSetting,
  theme: AppSetting,
  appTZ: AppSetting,
  autoNightMode: boolean,
  nightTheme: AppSetting,
  dateFormat: AppSetting,
  uomSystems: string[],
  uomMeasures: string[],
  canGuessTZ: boolean,
  webDefaultPage: AppSetting,
  mobileDefaultPage: AppSetting,
}

//user specific: set by app
export class UserAppSettings {
  constructor(public userName: string,
    public appCode: string,
    public settings: Map<string, any>) { }
}

export type ResolvedAppSettings = {
  language: string,
  defaultLanguage: string,
  theme: string,
  appTZ: string,
  autoNightMode: boolean,
  nightTheme: string,
  dateFormat: string,
  uomSystems: string[],
  uomMeasures: string[],
  canGuessTZ: boolean,
  webDefaultPage: string,
  mobileDefaultPage: string,
}


export namespace AppSettingsEnum {
  export function getKeys(): string[] {
    return [AppSettingsEnum.LANGUAGE,
    AppSettingsEnum.THEME,
    AppSettingsEnum.AUTO_NIGHT_MODE,
      // AppSettingsEnum.PAGE_ANIMATIONS,
      // AppSettingsEnum.ELEMENTS_ANIMATION
    ]
  }
}

//app specific: set by app developer
export class AppSettings extends GlobalBaseEntity {
  constructor(public appCode: string,
    public settings: Map<string, any>) {
    super();
  }
}





export class UserAppSettingsService {
  //use this
  getAppSettings(appCode: string): AppSettings {
    throw new Error("call from ")
  }

  saveAppSettings(appCode: string, settings: Map<string, any>): UserAppSettings {
    throw new Error("call from ")
  }

  getUserAppSettings(appCode: string) {
    const userAppSettingsOpt: RtOption<UserAppSettings> = RtNone() // resolve using BE
    const appSettings: AppSettings = this.getAppSettings(appCode)
    if (userAppSettingsOpt.isDefined) {
      const userAppSettings = userAppSettingsOpt.get
      const keys = AppSettingsEnum.getKeys()
      const resolvedSettings = keys.map(key => {
        const setting = userAppSettings.settings.get(key)
        if (setting) {
          return [key, setting];
        } else {
          //if user specific setting not found, then take from appsettings
          return [key, appSettings.settings.get(key)];
        }
      })
      const settingsMap = new Map(resolvedSettings.map(([v, k]) => [k, v]));
      new AppSettings(appCode, new Map(settingsMap))
    }

  }

  saveUserAppSettings(appCode: string, settings: Map<string, string>) {
    throw new Error("save UserAppSettings to DB ")
  }
}


