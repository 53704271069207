import { Injectable } from "@angular/core";
import { UOMReferenceService } from "../../../../uom/uom-reference-service";
import { DsResult, DsResultArray, DsResultValue, IDsResult } from "../../../data-source/data-source-result-entities";
import { ControlPropertyDefinitionValue } from "../control-instance";
import { ControlInstanceWrapper } from "../control-instance-drafts-models";
import { RtSome } from "../../../../utils/option-helper";
import { UomBinding } from "../../../../uom/uom-binding";
import { AllMeasuresUnits } from "convert-units/definitions/all";

@Injectable()
export class ControlLocalizationAndPersonalizationService {

  constructor(private uomService: UOMReferenceService) {

  }
  // convert(value:any):any
  convert(data: IDsResult, controlInstanceWrapper: ControlInstanceWrapper): IDsResult {
    const controlPropertyDefinitionValues: ControlPropertyDefinitionValue[] = controlInstanceWrapper.propertyDefinitions;
    const hasAnyUomBindingsDefined = controlPropertyDefinitionValues.some(controlProperty => RtSome(controlProperty.uomBinding).isDefined);
    if (hasAnyUomBindingsDefined) {
      const controlInstancePropertiesWithUomBindings = controlPropertyDefinitionValues.filter(property => RtSome(property.uomBinding).isDefined);
      const uomConvertedData = this.applyUomConversions(data, controlInstancePropertiesWithUomBindings);
      return uomConvertedData;
    } else {
      return data;
    }
  }

  private applyUomConversions(data: IDsResult, controlPropertyDefinitionValues: ControlPropertyDefinitionValue[]): IDsResult {


    if (data instanceof DsResultArray) {

      const updatedDsResults: DsResult[] = data.results.map((dsResult) => {
        return this.getUpdatedDsResult(dsResult, controlPropertyDefinitionValues);
      })
      const updatedDsResultArray = new DsResultArray(data.dsName, updatedDsResults, data.totalResults, data.fks, data.isWsResult);
      return updatedDsResultArray;
    } else if (data instanceof DsResult) {
      const updatedDsResult = this.getUpdatedDsResult(data, controlPropertyDefinitionValues);
      return updatedDsResult;
    }
    return data;
  }

  private getUpdatedDsResult(dsResult: DsResult, controlPropertyDefinitionValues: ControlPropertyDefinitionValue[]) {
    const dsResultValues = dsResult.data.map((dsResultValue) => {
      if (dsResultValue.hasValue) {
        const controlPropertyDefinitionValue = controlPropertyDefinitionValues.find(p => p.dsPropertyName === dsResultValue.fieldName);
        if (controlPropertyDefinitionValue && !Array.isArray(controlPropertyDefinitionValue.dsPropertyName)) {
          const sourceUom = dsResultValue.uom;
          if (sourceUom.isDefined) {
            const updatedDsResultValue = this.convertValueToDifferentUom(dsResultValue, sourceUom.get as AllMeasuresUnits, controlPropertyDefinitionValue.uomBinding);
            return updatedDsResultValue;
          }
        }
      }
      return dsResultValue;
    });
    const updatedDsResult = new DsResult(dsResult.id, dsResult.dsName, dsResultValues, dsResult.fks, dsResult.isWsResult, dsResult.isDirectValue(), dsResult.dataStateType);
    return updatedDsResult;
  }

  private convertValueToDifferentUom(dsResultValue: DsResultValue, sourceUom: AllMeasuresUnits, uomBinding: UomBinding) {
    if (uomBinding.direct) {
      const convertedValue = this.uomService.convertDirect(dsResultValue.originalValue, sourceUom, uomBinding.direct);
      return new DsResultValue(dsResultValue.fieldName, dsResultValue.originalValue, convertedValue, RtSome(uomBinding.direct), dsResultValue.referenceData)
    } else if (uomBinding.uomOptions) {
      const bestOpt = this.uomService.convertToBest(dsResultValue.value, sourceUom, uomBinding.uomOptions);
      if (bestOpt.isDefined) {
        const best = bestOpt.get;
        return new DsResultValue(dsResultValue.fieldName, dsResultValue.originalValue, best.val, RtSome(best.unit), dsResultValue.referenceData);
      }
    }
    return new DsResultValue(dsResultValue.fieldName, dsResultValue.originalValue, dsResultValue.value, RtSome(sourceUom), dsResultValue.referenceData);
  }
}

export class DateTimeConversionService {
}
