import { ChangeDetectorRef, Injector, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ToastServiceProvider } from '../../../providers/abstract/toast-service-provider';
import { AnimationService } from '../../../providers/animations/animation-service';
import { EventService } from '../../../providers/event-service/event.service';
import { RtNone, RtOption, RtSome } from '../../../utils/option-helper';
import { AnimationsContainer } from '../../attributes/animation/animation-attribute';
import { AttributeThemeType, AttributeType } from '../../attributes/core/attribute';
import { AttributeInstance, AttributeInstanceHolder, UsableAttributeValue } from '../../attributes/core/attribute-instance';
import { ModelAndUsableValueContainer } from '../../attributes/core/model-and-usable-value-container';
import { EventDataBinder } from '../../attributes/event-data-binder/event-data-binder-attribute';
import { EventDataMapper } from '../../attributes/event-data-mapper/event-data-mapper-attribute';
import { AttributeRegistryConstant } from '../../constants/attribute-registry-constants';
import { PBEventConfiguration } from '../../constants/common-constants';
import { PubSubTopic } from '../../constants/pub-sub-topic-constants';
import { DsResult, IDsResult } from '../../data-source/data-source-result-entities';
import { ICommonControlRenderService } from '../../ide/services/common-control-render-service';
import { IChildControlInfo, IControlInfo } from '../../ide/services/parent-control';
import { ControlCondition, ControlConditionGroup, DsResultArray, FilterConfig } from '../../public_api';
import { ControlRenderProgressIndicatorService } from '../../services/control-render-progress-indicator/control-render-progress-indicator.service';
import { PageService } from '../../services/page-service';
import { ControlStateManager } from '../../services/page/control-state-manager';
import { AttributeHydrationService } from './attribute-hydrators/attribute-hydration-service';
import { AttributeInstanceResolver, AttributeResolverType } from './attribute-instance-resolver';
import { AddChildBaseCommand, AddChildWithDsResultCommand, AddStaticChildCommand, ChildCommand, ChildCommandResolver, ChildCommandType, ChildControlDeletedCommand, DataStateType, DeleteChildByDsResultIdCommand, UpdateChildChildWithDsResultCommand } from './child-rendered-event';
import { ConditionBehaviourService } from './condition-builder/condition-behavior-service';
import { DsResultsConvertersService } from './condition-builder/converters/ds-results-converters.service';
import { BehaviourData, ControlConditionGroupEvaluationResult } from './condition-builder/models/control-conditions';
import { ControlConditionEvaluatorService } from './condition-builder/services/control-condition-evaluator.service';
import { ControlCompanionEventsTopics } from './control-companion/control-companion-constants';
import { CompanionRequestCommand, DestroyCompanionCommand } from './control-companion/control-companion-models';
import { ControlDescriptor } from './control-descriptor';
import { ControlHelper } from './control-helper';
import { ControlPropertyDefinitionValue } from './control-instance';
import { ControlInstanceDraftHelper, ControlInstanceWrapper } from './control-instance-drafts-models';
import { ControlLocalizationAndPersonalizationService } from './control-localization-and-personalization/control-localization-and-personlization-service';
import { ControlAccessability, ControlVisibility, IControl, RenderedControlInstance } from './control-model';
import { ControlReadinessCheckService } from './control-readiness-check.service';
import { DataSourceExecutorService } from './data-source-executors/data-source-executor.service';
import { AbstractDSStateManager } from './data-source-state-manager/abstract-ds-state-manager';
import { DsStateManagerResolver } from './data-source-state-manager/ds-state-manager-resolver';
import { AppEventTopics, DataSourceParamEvent } from './event/app-event-topics';
import { CtrlEventRaised } from './event/control-event';
import { ControlBindingsAction } from './event/event-actions/control-bindings/control-bindings-action-models';
import { AppEvent } from './event/event-models';
import { ProducerType } from './event/event-schema-provider';
import { BehaviorData, ControlBindingPayload, ControlBindingPayloadType, EventData } from './event/event-store';
import { EventStoreReaderService } from './event/event-store-reader.service';
import { CommonEventType, DesktopEvents, StandardEvents } from './event/event-types';
import { GlobalEventOrchestrator } from './event/global-event-orchestrator';

export abstract class Control<D extends IDsResult,
  PI extends IControlInfo,
  R extends ICommonControlRenderService<PI>> implements IControl<D> {

  resultClass: { new(...args: any[]): D; };
  eventStroeSubscription: Subscription;

  get data(): D {
    //read from dataSource manager
    return this.dataSourceStateManager.data;
  }
  get resultClassName(): string {
    return this.resultClass.name;
  }

  get id(): string {
    return this.parentCmd.id(this.controlInstance.instanceId);
  }

  /**
   * set any control special props to be used for both control rendering or DS execution
  *  e.g. tab index, pagination, filters, etc
 */
  public setControlProps(key: string, value: any) {
    this.dataSourceStateManager.setControlProps(key, value)
  }

  get controlProps() {
    return this.dataSourceStateManager.controlProps;
  }
  onControlInfoUpdateSubject = new Subject();
  protected subs = new SubSink();
  private dsControlBindingSubscriptions = new SubSink();
  public children: RenderedControlInstance[] = [];
  public controlInstance: ControlInstanceWrapper;
  public descriptor: ControlDescriptor;
  public parentCmd: AddChildBaseCommand;

  public parentDsResult: RtOption<DsResult> = RtNone();

  public helper = ControlInstanceDraftHelper;

  public eventService: EventService;
  public viewContainerRef: ViewContainerRef;
  protected pageService: PageService;
  protected attributeHydrationService: AttributeHydrationService;

  private dsStateManagerResolver: DsStateManagerResolver;
  private dataSourceStateManager: AbstractDSStateManager<D>;
  private dataSourceExecutor: DataSourceExecutorService;
  private controlReadinessCheckService: ControlReadinessCheckService<D>;
  public changeDetectorRef: ChangeDetectorRef;
  public controlConditionEvaluatorService: ControlConditionEvaluatorService;
  public conditionBehaviourService: ConditionBehaviourService;
  public controlLocalizationAndPersonalizationService: ControlLocalizationAndPersonalizationService;
  public eventStoreReaderService: EventStoreReaderService;
  public dsResultsConvertersService: DsResultsConvertersService;
  private controlRenderProgressIndicatorService: ControlRenderProgressIndicatorService;
  //private controlInfo: CI;
  private resetDataForAllDataSourcesSubscription = new Subscription();
  public toastServiceProvider: ToastServiceProvider;
  controlInfo: PI;
  canRenderChildren = true;
  //abstract functions
  abstract controlRenderService: R;
  animationService: AnimationService;

  abstract onDatasourceResolved(data: RtOption<D>): Observable<PI>;
  abstract onControlEventReceivedFn(event: CtrlEventRaised): void;
  abstract applyPropertyDefinitions(propertyDefinitions: ControlPropertyDefinitionValue[]): void;
  abstract applyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void;
  abstract reApplyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void;
  abstract applyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]): void;
  abstract reApplyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]): void;
  abstract applyVisibility(visibility: ControlVisibility): void;
  abstract applyAccessability(accessability: ControlAccessability): void;

  // tslint:disable-next-line: callable-types
  constructor(private injector: Injector, resultClass: { new(...args: any[]): D; }) {
    this.resultClass = resultClass;
    this.eventService = this.injector.get(EventService);
    this.attributeHydrationService = this.injector.get(AttributeHydrationService);
    this.pageService = this.injector.get(PageService);
    this.dsStateManagerResolver = this.injector.get(DsStateManagerResolver);
    this.controlConditionEvaluatorService = this.injector.get(ControlConditionEvaluatorService);
    this.conditionBehaviourService = this.injector.get(ConditionBehaviourService);
    this.dataSourceStateManager = this.dsStateManagerResolver.resolveDsStateManager(this.resultClass.name);
    this.controlReadinessCheckService = new ControlReadinessCheckService(this.pageService, this.dataSourceStateManager);
    this.animationService = this.injector.get(AnimationService);
    this.dataSourceExecutor = this.injector.get(DataSourceExecutorService);
    this.controlLocalizationAndPersonalizationService = this.injector.get(ControlLocalizationAndPersonalizationService);
    this.eventStoreReaderService = this.injector.get(EventStoreReaderService);
    this.dsResultsConvertersService = this.injector.get(DsResultsConvertersService);
    this.controlRenderProgressIndicatorService = this.injector.get(ControlRenderProgressIndicatorService);
    this.toastServiceProvider = this.injector.get(ToastServiceProvider);
  }

  //inits
  initControl(controlInstance: ControlInstanceWrapper,
    descriptor: ControlDescriptor,
    cmd: AddChildBaseCommand) {
    this.controlInstance = controlInstance;
    this.descriptor = descriptor;
    this.parentCmd = cmd;
    this.applyParentData(cmd);
    this.verifyAndApplyDatasource(controlInstance, cmd);
    this.applyPropertyDefinitionsIfAny(controlInstance);
    this.prepareAndApplyProperties(controlInstance).then(() => {
      this.controlReadinessCheckService.setAttributeInitialized();
      this.tryAdaptDataAndApplyChildren();
      this.subscribeForControlBindings();
      this.subscribeForControlBindingsForUniqueControl();
    });
  }

  enableRenderChildren() {
    this.canRenderChildren = true;
  }

  disableRenderChildren() {
    this.canRenderChildren = false;
  }


  private subscribeForResetData() {
    const topic = PubSubTopic.buildResetPreviousDataTopic(this.controlInstance.instanceId);
    this.resetDataForAllDataSourcesSubscription = this.eventService.subscribe(topic, () => {
      this.dataSourceStateManager.data = null;
      this.dataSourceStateManager.resetDataForAllDataSources();
      this.destroyAllChildren();
    }, true);
  }
  private async prepareAndApplyProperties(controlInstance: ControlInstanceWrapper) {
    const attributes = await this.descriptor.getAttributes();
    const allAttributeInstanceHolder = AttributeInstanceResolver.convertAttributeInstanceToAttributeInstanceHolder(controlInstance.controlName,
      controlInstance.allAttributeValues, attributes);
    await this.applyProperties(allAttributeInstanceHolder, AttributeResolverType.ON_INIT);
  }

  private async prepareAndReApplyProperties(controlInstance: ControlInstanceWrapper) {
    const attributes = await this.descriptor.getAttributes();
    const allAttributeInstanceHolder = AttributeInstanceResolver.convertAttributeInstanceToAttributeInstanceHolder(controlInstance.controlName,
      controlInstance.allAttributeValues, attributes);
    const hydratedInstances: ModelAndUsableValueContainer<unknown, unknown>[] = await this.getHydratedInstances(allAttributeInstanceHolder, AttributeResolverType.ON_INIT);
    const usableValues = hydratedInstances.map(m => m.usableValues).flat()
    const cssAttributeValues = usableValues.filter(c => c.attributeType === AttributeType.CSS);
    const configurationAttributeValues = usableValues.filter(c => c.attributeType === AttributeType.CONFIGURATION);

    if (cssAttributeValues.length > 0) {
      this.reApplyCSSAttributes(cssAttributeValues);
    }

    if (configurationAttributeValues.length > 0) {
      this.reApplyConfigurationAttributes(configurationAttributeValues);
    }
  }

  async onThemeApplied() {
    const attributes = await this.descriptor.getAttributes();
    const allAttributeInstanceHolder = AttributeInstanceResolver.convertAttributeInstanceToAttributeInstanceHolder(this.controlInstance.controlName,
      this.controlInstance.allAttributeValues, attributes);
    const themeTypeAttributes = allAttributeInstanceHolder.filter(_ => _.attributeThemeType === AttributeThemeType.THEMEABLE_ATTRIBUTE);
    this.applyProperties(themeTypeAttributes, AttributeResolverType.ON_THEME_CHANGE);
    this.children.forEach(_ => _.childControlRef.control.onThemeApplied());
  }

  childrenTyped<C extends IChildControlInfo>() {
    return this.children.map(c => c.toTyped<C>());
  }

  reInitialiseDataSourceExecutor(): void {
    this.destroyAllChildren();
    this.destroySubSinkSubscriptions();
    this.dataSourceStateManager?.onDestroy();
    this.dataSourceStateManager = this.dsStateManagerResolver.resolveDsStateManager(this.resultClass.name);
    this.controlReadinessCheckService.reSetStateManager(this.dataSourceStateManager);
    this.verifyAndApplyDatasource(this.controlInstance, this.parentCmd);
  }

  renderChild(childControlInstance: ControlInstanceWrapper, cmd: AddStaticChildCommand): Observable<RenderedControlInstance> {
    return new Observable((observer) => {
      this.subs.add(this.controlRenderService.renderComponent(childControlInstance, cmd, this.controlInfo)
        .subscribe({
          next: (childControls: RenderedControlInstance) => {
            this.children.push(childControls);
            observer.next(childControls);
          },
          error: error => {
            this.toastServiceProvider.showError(error);
            observer.error(error);
            this.controlRenderProgressIndicatorService.hideProgressIndicator();
          }
        }));
    });
  }

  reloadChildren(controlInstance?: ControlInstanceWrapper): void {
    if (controlInstance) {
      this.controlInstance = controlInstance
    }

    this.destroyAllChildren();
    if (this.controlReadinessCheckService.hasNoDataSourceConfigured(this.controlInstance)) {
      //No datasource configured, so no need to reload children
      this.tryAdaptDataAndApplyChildren();
    } else {
      //Re build commands using dataSourceStateManager and render children
      const commands = this.dataSourceStateManager.rebuildCommands(this);
      this.addChildren(commands);
    }
  }

  destroyAllChildren() {
    //const childControlIdsToDelete = this.children.filter(child => child.dsResultId.isDefined).map((existing) => {
    const childControlIdsToDelete = this.children.map((existing) => {
      existing.childControlRef.destroy();
      return existing.childControlRef;
    });
    this.children = [];
    this.controlRenderService.destroyChildren(childControlIdsToDelete, this.controlInfo, this.controlInstance);
    this.markAndUnMarkOnControlsAddedOrRemoved();
  }


  updateData(data: D): void {
    // this.dataSourceStateManager.applyData(this.parentCmd, data, this, RtNone());
    this.getFilterConfigAndApplyData(data)
    //todo: revisit and remove duplicated state of DsResult
    this.parentDsResult = RtSome(data as unknown as DsResult);
    this.tryAdaptDataAndApplyChildren();
  }

  destroy(): void {
    this.onDestroy();
  }

  onDestroy() {
    // this.destroyAllChildren();

    this.removeCompanion();
    this.destroySubSinkSubscriptions();
    this.dataSourceExecutor?.destroyParamsSubscriptionsByControlInstanceId(this.controlInstance.instanceId);
    this.dataSourceStateManager?.onDestroy();
    this.controlInfo = null;
    this.dsControlBindingSubscriptions.unsubscribe();
    // this.unsubscribeEventServiceSubscriptions()
    /**
     * TODO: Need review, FIX: Duplicate control renderings. E.g: Table actions are displaying bottom of the page
     * Event service has also got some changes regarding this ("@reactore/fe-core": "0.0.28-rc-2",@reactore/ng-core: "1.1.16-beta-13").
     *
     * */

    this.unsubscribeAppEventFromEventService();
    // all the subscriptions are unsubscribed. For example not displaying the drillholes for toggle on state because all the event subscriptions are unsubscribed for respective control.
    // Ref=> https://app.clickup.com/t/85ztwg2vf

    // this.unsubscribeEventServiceSubscriptions();
    this.unsubscribeEventServiceSubsForUniqueControl();
  }
  private unsubscribeEventServiceSubsForUniqueControl() {
    if (this.parentCmd.commandType === ChildCommandType.NEW_WITH_DS_RESULT
      && this.resultClassName === DsResult.name) {
      const addChildWithDsResultCommand = this.parentCmd as unknown as AddChildWithDsResultCommand;
      const id = addChildWithDsResultCommand.dsResult.id;
      const controlBindings: ControlBindingsAction[] = this.eventStoreReaderService.getControlBindings(this.controlInstance.instanceId);
      controlBindings?.forEach((controlBindingsAction: ControlBindingsAction) => {
        const topic = AppEventTopics.buildControlBindingTopicForUniqueControl(controlBindingsAction.id, this.controlInstance.instanceId, id);
        this.unsubscribeEventServiceSubs(topic);
      });
    }
  }

  /*
  -  StandardEvents.ON_APP_LOAD eventType condition is added to fix the form event data mapper binding issue
     for app load event in the form
  -  Ex:- App Load control Bindings will not work if we open the pop up which is having form for second time.
  */
  public unsubscribeEventServiceSubscriptions() {
    const controlBindings: ControlBindingsAction[] = this.eventStoreReaderService.getControlBindings(this.controlInstance.instanceId);
    controlBindings?.forEach((controlBindingsAction: ControlBindingsAction) => {
      if (controlBindingsAction.eventType != StandardEvents.ON_APP_LOAD) {
        const topic = AppEventTopics.buildControlBindingTopic(controlBindingsAction.id, this.controlInstance.instanceId);
        this.unsubscribeEventServiceSubs(topic);
      }
    });
  }
  private unsubscribeEventServiceSubs(topic: string) {
    let subscriptions = this.eventService.getSubscriptions().filter(s => s.topic === topic);
    subscriptions?.forEach(s => this.eventService.unsubscribe(s.topic, RtSome(this.controlInstance.instanceId)));
  }

  private destroySubSinkSubscriptions() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
  applyParentData(cmd: ChildCommand) {
    this.parentDsResult = ChildCommandResolver.getDsResult(cmd);
  }
  private getFilterConfigAndApplyData(data: D) {
    if (this.controlReadinessCheckService.hasNoDataSourceConfigured(this.controlInstance)) {
      this.dataSourceStateManager.applyData(this.parentCmd, data, this, RtNone());

    } else {
      const dsInstances = this.pageService.getControlInstanceDataSourceServiceInstances(this.controlInstance.instanceId);
      const mainDs = dsInstances.length > 1 ? dsInstances.find(ds => ds.isMaster) : dsInstances[0];
      if (mainDs.method.isOnRequestMview) {
        this.dataSourceStateManager.applyData(this.parentCmd, data, this, RtNone());
      } else {
        const recievedParamBuffer = this.dataSourceExecutor.getParamBuffer(this.controlInstance.instanceId);
        const filterConfig: FilterConfig = { params: [], paramBuffer: recievedParamBuffer, filterProperties: null }
        this.dataSourceStateManager.applyData(this.parentCmd, data, this, RtSome(filterConfig));
      }
    }
  }

  public verifyAndApplyDatasource(controlInstance: ControlInstanceWrapper, parentCmd: AddChildBaseCommand) {
    //The control does not have data source configured, however received data from parent which needs to be applied to the control
    if (this.controlReadinessCheckService.hasNoDataSourceConfigured(controlInstance)) {
      if (this.hasGlobalEventsBinded(controlInstance)) {
        this.subscribeForGlobalEventsFromConditions(controlInstance.controlConditionGroup, false);
        // skip the initializing Ds executor
      }
      if (this.hasParentData(parentCmd)) {
        this.getFilterConfigAndApplyData(this.parentDsResult.get as unknown as D);
      }
      this.tryAdaptDataAndApplyChildren();
    }

    else if (this.hasGlobalEventsBinded(controlInstance)) {
      this.dataSourceExecutor.initDSDataRegistry(controlInstance.instanceId, false);
      this.subscribeForGlobalEventsFromConditions(controlInstance.controlConditionGroup, true);
      // skip the initializing Ds executor
    } else {

      this.initControlDataSourceExecutor(controlInstance);
    }
  }
  private subscribeForGlobalEventsFromConditions(controlConditionGroup: ControlConditionGroup[], hasDataSourceConfigured: boolean) {
    const conditionDataArray: { paramName: string, controlCondition: ControlCondition, eventValue: any }[] = [];
    controlConditionGroup.forEach(group => {
      group.controlConditions.forEach(condition => {
        const dsName = `GlobalEvent_${this.controlInstance.instanceId}-${condition.uniqueId}`;
        if (condition.isGlobalEvent) {
          conditionDataArray.push({ paramName: condition.producerParamName, controlCondition: condition, eventValue: null });
          const topic = AppEventTopics.buildDataSourceParamTopic(dsName, 'globalParam');
          this.subs.add(this.eventService.subscribe(topic, (data: DataSourceParamEvent) => {
            const dsResultValue = data.value.find(dsrv => dsrv.fieldName === data.binding.fieldName);
            const index = conditionDataArray.findIndex(conditionData => conditionData.paramName === condition.producerParamName);
            conditionDataArray[index].eventValue = dsResultValue.value;
            if (conditionDataArray.every(condtionData => condtionData.eventValue && condtionData.eventValue === condtionData.controlCondition.comparingValue)) {
              if (hasDataSourceConfigured && !this.dataSourceExecutor.isInitialized(this.controlInstance.instanceId)) {
                this.initControlDataSourceExecutor(this.controlInstance)
                this.evaluateGlobalEventConditionsAndApplyBehaviours(dsName, data);
              } else if (!hasDataSourceConfigured) {
                this.evaluateGlobalEventConditionsAndApplyBehaviours(dsName, data);
              }
            }
          }, true))
        }
      })
    })
  }

  private evaluateGlobalEventConditionsAndApplyBehaviours(dsName: string, data: DataSourceParamEvent) {
    const dsResult = new DsResult('', dsName, data.value, [], false, false, DataStateType.NEW);
    const conditionGroups = this.controlInstance.controlConditionGroup.filter(group => group.controlConditions.some(condition => condition.isGlobalEvent));
    const controlConditionEvaluationResults: ControlConditionGroupEvaluationResult[] = this.controlConditionEvaluatorService.evaluateConditions(dsResult, conditionGroups, this.controlInstance.instanceId);
    setTimeout(() => {
      this.conditionBehaviourService.applyVisibilityAndAccessability(this, controlConditionEvaluationResults);
      const behavioursToApply = this.resolveBehavioursToApply(controlConditionEvaluationResults);
      if (behavioursToApply.length > 0) {
        this.conditionBehaviourService.applyBehaviours(this, dsResult, this.controlInstance, behavioursToApply);
      }
    });
  }

  private hasGlobalEventsBinded(controlInstance: ControlInstanceWrapper) {
    const groups = controlInstance.controlConditionGroup.filter(group => group.controlConditions.some(condition => condition.isGlobalEvent));
    return groups.length > 0 ? true : false;
  }

  /* 
  This subscription is to send the value through the event data binder/mapper using the trigger data source or 
  similar event on demand - https://app.clickup.com/t/86cvhxr9t
  */
  private handleTriggerDataSourceEventControlBindings() {
    const controlBindings: ControlBindingsAction[] = this.eventStoreReaderService.getControlBindings(this.controlInstance.instanceId);
    const triggerDsBindings = controlBindings?.filter(binding => binding.eventType == StandardEvents.TRIGGER_DATASOURCE);
    const nonTriggerDsBindings = controlBindings?.filter(binding => binding.eventType != StandardEvents.TRIGGER_DATASOURCE);
    this.subscribeForTriggerDataSourceEventBindings(triggerDsBindings, nonTriggerDsBindings);
  }

  private subscribeForTriggerDataSourceEventBindings(triggerDsBindings: ControlBindingsAction[], nonTriggerDsBindings: ControlBindingsAction[]) {
    triggerDsBindings?.forEach((controlBindingsAction: ControlBindingsAction) => {
      const triggerDstopic = AppEventTopics.buildControlBindingTopic(controlBindingsAction.id, this.controlInstance.instanceId);
      this.dsControlBindingSubscriptions.add(this.eventService.subscribe(triggerDstopic, (_) => {
        nonTriggerDsBindings?.forEach((controlBindingsAction: ControlBindingsAction) => {
          const topic = AppEventTopics.buildControlBindingTopic(controlBindingsAction.id, this.controlInstance.instanceId);
          const sub = this.eventService.subscribe(topic, (controlBindingPayload: ControlBindingPayload) => {
            if ((controlBindingPayload as EventData).eventType === StandardEvents.CLOSE || (controlBindingPayload as EventData).eventType === StandardEvents.TRIGGER_DATASOURCE) {
              return;
            }
            this.handleEventDataAndBehaviours(controlBindingPayload, controlBindingsAction);
          }, true);
          //Unsubscribing the topic because, here we should subscribe the topic only once to support the trigger datasource.
          sub.unsubscribe();
        });
      }, true));
    });
  }

  private handleEventDataAndBehaviours(controlBindingPayload: ControlBindingPayload, controlBindingsAction: ControlBindingsAction) {
    if (controlBindingPayload.type === ControlBindingPayloadType.BEHAVIOR_DATA) {
      this.applyBehaviorData(controlBindingPayload as BehaviorData);
    }
    else if (controlBindingPayload.type === ControlBindingPayloadType.EVENT_DATA) {
      this.applyEventData(controlBindingPayload as EventData);
      this.applyEventDataMapper(controlBindingPayload as EventData, controlBindingsAction);
      this.handleEmptinessAndDestroyChildren(controlBindingPayload as EventData);
    }
  }

  private subscribeForControlBindings() {
    if (this.hasTriggerDataSourceEvent(this.controlInstance.instanceId)) {
      this.handleTriggerDataSourceEventControlBindings();
      return;
    }
    const controlBindings: ControlBindingsAction[] = this.eventStoreReaderService.getControlBindings(this.controlInstance.instanceId);
    controlBindings?.forEach((controlBindingsAction: ControlBindingsAction) => {
      const topic = AppEventTopics.buildControlBindingTopic(controlBindingsAction.id, this.controlInstance.instanceId);
      this.dsControlBindingSubscriptions.add(this.eventService.subscribe(topic, (controlBindingPayload: ControlBindingPayload) => {
        if ((controlBindingPayload as EventData).eventType === StandardEvents.CLOSE || (controlBindingPayload as EventData).eventType === StandardEvents.TRIGGER_DATASOURCE) {
          return
        }
        this.handleEventDataAndBehaviours(controlBindingPayload, controlBindingsAction);
      }, true))
      this.subscribeForControlBindingCloseEvent(topic, controlBindingsAction);
    })
  }

  private subscribeForControlBindingCloseEvent(topic: string, controlBindingsAction: ControlBindingsAction) {
    this.dsControlBindingSubscriptions.add(this.eventService.subscribe(topic, (controlBindingPayload: ControlBindingPayload) => {
      if (controlBindingPayload.type === ControlBindingPayloadType.EVENT_DATA) {
        if ((controlBindingPayload as EventData).eventType === StandardEvents.CLOSE) {
          this.applyEventDataMapper(controlBindingPayload as EventData, controlBindingsAction);
        }
      }
    }))
  }

  private subscribeForControlBindingsForUniqueControl() {
    if (this.parentCmd.commandType === ChildCommandType.NEW_WITH_DS_RESULT
      && this.resultClassName === DsResult.name) {
      const addChildWithDsResultCommand = this.parentCmd as unknown as AddChildWithDsResultCommand;
      const controlBindings: ControlBindingsAction[] = this.eventStoreReaderService.getControlBindings(this.controlInstance.instanceId);
      controlBindings?.forEach((controlBindingsAction: ControlBindingsAction) => {
        const slectedControlInstanceContainer = controlBindingsAction.containers.find(e => e.controlInstanceId === this.controlInstance.instanceId);
        let topic, dsResultvalue = addChildWithDsResultCommand.dsResult.data.find(e => e.fieldName === slectedControlInstanceContainer?.idPropertyName);
        // NOTE :  Id will be defined only for self datasource control bindings so added the below condition.
        if (dsResultvalue) {
          topic = AppEventTopics.buildControlBindingTopicForUniqueControl(controlBindingsAction.id, this.controlInstance.instanceId, dsResultvalue.value);
          this.subscribeForControlBindingUnApplyTopicForUniqueControl(controlBindingsAction.id, this.controlInstance.instanceId, dsResultvalue.value);
        } else {
          topic = AppEventTopics.buildControlBindingTopicForUniqueControl(controlBindingsAction.id, this.controlInstance.instanceId, addChildWithDsResultCommand.dsResult.id);
          this.subscribeForControlBindingUnApplyTopicForUniqueControl(controlBindingsAction.id, this.controlInstance.instanceId, addChildWithDsResultCommand.dsResult.id);
        }
        this.dsControlBindingSubscriptions.add(this.eventService.subscribe(topic, (controlBindingPayload: ControlBindingPayload) => {
          this.handleEventDataAndBehaviours(controlBindingPayload, controlBindingsAction);
        }, true))
      })
    }
  }

  private handleEmptinessAndDestroyChildren(eventData: EventData) {
    if (eventData.eventType === StandardEvents.EMPTINESS) {
      // TO show no records found message on emptiness
      let result;
      if (this.resultClassName === DsResult.name) {
        result = new DsResult(null, "", null, [], false, true, DataStateType.NEW);
      } else {
        result = new DsResultArray("", [], 0, []);
      }
      this.onDatasourceResolved(RtSome(result) as unknown as RtOption<D>)
      this.destroyAllChildren();
    }
  }

  private subscribeForControlBindingUnApplyTopicForUniqueControl(actionId: string, controlInstanceId: string, fieldValue: string) {
    const unApplyBehaviorsTopic = AppEventTopics.buildControlBindingUnApplyTopicForUniqueControl(actionId, controlInstanceId, fieldValue);
    this.dsControlBindingSubscriptions.add(this.eventService.subscribe(unApplyBehaviorsTopic, () => {
      //ReApply styles
      this.prepareAndReApplyProperties(this.controlInstance);
    }));
  }

  private applyBehaviorData(behaviorData: BehaviorData) {
    behaviorData.behaviors.forEach(async (behavior) => {
      const behaviourAttributes: AttributeInstance[] = (behavior.attributeValues || []).filter(attr => attr.attributeType === AttributeType.CSS);
      const attributes = await this.descriptor.getAttributes();
      const allAttributeInstanceHolder = AttributeInstanceResolver.convertAttributeInstanceToAttributeInstanceHolder(this.controlInstance.controlName,
        behaviourAttributes, attributes);
      this.applyProperties(allAttributeInstanceHolder, AttributeResolverType.ON_CONFIGURATION_CHANGE);
    })
  }

  private applyEventData(eventData: EventData) {
    const eventDataBinderAttr = this.controlInstance.allAttributeValues.find(attr => attr.name === AttributeRegistryConstant.EVENT_DATA_BINDER);
    const eventDataValue = eventDataBinderAttr?.value ? JSON.parse(eventDataBinderAttr.value) : null;
    if (eventDataValue) {
      if (Array.isArray(eventDataValue)) {
        eventDataValue.forEach(ed => {
          this.extractDsResultValueAndPublish(eventData, ed);
        })
      } else {
        this.extractDsResultValueAndPublish(eventData, eventDataValue);
      }
    }
  }
  applyEventDataMapper(eventData: EventData, controlBindingsAction: ControlBindingsAction) {
    const eventDataMapperAttr = this.controlInstance.allAttributeValues.find(attr => attr.name === AttributeRegistryConstant.EVENT_DATA_MAPPER);
    const eventDataMapperValue = eventDataMapperAttr?.value ? JSON.parse(eventDataMapperAttr.value) : null;
    if (eventDataMapperValue) {
      if (Array.isArray(eventDataMapperValue)) {
        eventDataMapperValue.forEach(ed => {
          if (ed.actionId === controlBindingsAction.id) {
            this.extractDsResultValueAndPublishForMapper(eventData, ed);
          }
        })
      } else {
        if (eventDataMapperValue.actionId === controlBindingsAction.id) {
          this.extractDsResultValueAndPublishForMapper(eventData, eventDataMapperValue);
        }
      }
    }
  }

  private extractDsResultValueAndPublish(eventData: EventData, eventDataValue: EventDataBinder) {
    const dsResultValue = eventData.data?.data?.filter(d => eventDataValue.fieldMapper.some(e => e.fieldName == d.fieldName));
    // const dsResultValue = new DsResultValue(evtDataRes.fieldName, evtDataRes.value, value2, RtNone(), RtNone());
    const result = new DsResult(null, "", dsResultValue || [], [], false, true, DataStateType.NEW);
    const data = RtSome(result);
    // When value recieved through control bindings then also updating it in State managers. As control binding subscription is receiving first and view is initialising next.
    this.dataSourceStateManager.applyData(this.parentCmd, result as unknown as D, this, RtNone());
    this.onDatasourceResolved(data as unknown as RtOption<D>);
  }
  extractDsResultValueAndPublishForMapper(eventData: EventData, eventDataValue: EventDataMapper) {
    const dsResultValue = eventData.data?.data?.filter(d => eventDataValue.fieldMapper.some(e => e.fieldName == d.fieldName));
    // const dsResultValue = new DsResultValue(evtDataRes.fieldName, evtDataRes.value, value2, RtNone(), RtNone());
    const result = new DsResult(null, "", dsResultValue, [], false, true, DataStateType.NEW);
    const data = RtSome(result);

    this.onEventDataMapperResolved(eventDataValue, data);
  }
  onEventDataMapperResolved(_eventDataValue: EventDataBinder, _data: RtOption<DsResult>) {
  }
  private hasParentData(parentCmd: AddChildBaseCommand) {
    return parentCmd.commandType == ChildCommandType.NEW_WITH_DS_RESULT;
    // return parentCmd.isDefined //&& parentCmd.get.dsResult.isDefined;
  }

  //step-1: Execute data source
  private initControlDataSourceExecutor(controlInstance: ControlInstanceWrapper) {
    if (this.resetDataForAllDataSourcesSubscription) this.resetDataForAllDataSourcesSubscription.unsubscribe();
    this.subscribeForResetData();

    // this.dataSourceExecutor?.destroyParamsSubscriptionsByControlInstanceId(controlInstance.instanceId);


    this.subs.add(this.dataSourceExecutor.subscribeForDataSourceExecution<D>(controlInstance.instanceId, this.parentDsResult, this.resultClassName).subscribe((data) => {
      this.datasourceResolved(data)
    }));
    this.dataSourceExecutor.initDataSourceExecution(controlInstance.instanceId, this.parentDsResult, this.resultClassName);
  }

  private datasourceResolved(data: RtOption<D>): void {
    if (data.isDefined) {
      if (this.resultClassName === DsResult.name) {
        //If DSResult then convert it to fullyQualifiedDsResult for DSResultArray it happens inside dataSourceStateManager.applyData method
        const dsResult = data.get as unknown as DsResult;
        if (dsResult instanceof DsResult && dsResult?.dataStateType) {
          const fullyQualifiedDsResult = dsResult.asFullyQualifiedDsResult(dsResult.dsName) as unknown as D;
          this.getFilterConfigAndApplyData(fullyQualifiedDsResult);
        } else {
          this.toastServiceProvider.showInfo("List data is not supported for single type control !");
        }
      }
      else {
        const dsResultArray = data.get as unknown as DsResultArray;
        if (dsResultArray instanceof DsResultArray) {
          if (dsResultArray.results.length === 0) {
            this.onEmptiness();
          }
          this.getFilterConfigAndApplyData(data.get);
        } else {
          this.toastServiceProvider.showInfo("Single data is not supported for list type control !");
        }
      }
    } else {
      this.onEmptiness();
    }
    this.tryAdaptDataAndApplyChildren();
  }
  // end of step-1


  //step-2; Manipulate datasources
  // , attributeResolverType: AttributeResolverType, changedAttribute: AttributeInstance[]
  // const allAttrsValue = AttributeInstanceResolver.resolveAttributes(attributeResolverType, allAttributeValues, changedAttribute);

  async applyProperties(allAttributeValues: AttributeInstanceHolder[], attributeResolverType: AttributeResolverType) {

    const hydratedInstances: ModelAndUsableValueContainer<unknown, unknown>[] = await this.getHydratedInstances(allAttributeValues, attributeResolverType);
    //rest of attributes
    await this.applyUsableProperties(hydratedInstances.map(m => m.usableValues).flat());
  }

  private async getHydratedInstances(allAttributeValues: AttributeInstanceHolder[], attributeResolverType: AttributeResolverType) {
    const allHydratedInstances = await this.attributeHydrationService.hydrateInstances(allAttributeValues, attributeResolverType, this.descriptor);

    //animation
    const animationUsuableContainer = RtSome(allHydratedInstances.find(u => u.hasAnimationValue()));

    const hydratedInstances: ModelAndUsableValueContainer<unknown, unknown>[] = allHydratedInstances.filter(u => !u.hasAnimationValue());

    if (animationUsuableContainer.isDefined) {
      //animation attribute's usable value will always be only one
      const animationUsableValue = animationUsuableContainer.get.usableValues[0];
      this.applyIfAnimationConfigured(animationUsableValue, hydratedInstances);
    }
    return hydratedInstances;
  }

  private applyPropertyDefinitionsIfAny(ctrlInst: ControlInstanceWrapper) {
    if (ctrlInst.propertyDefinitions && ctrlInst.propertyDefinitions.length > 0) {
      this.applyPropertyDefinitions(ctrlInst.propertyDefinitions);
    }
  }


  applyUsableProperties(hydratedInstances: UsableAttributeValue<unknown>[]) {


    const cssAttributeValues = hydratedInstances.filter(c => c.attributeType === AttributeType.CSS);

    const configurationAttributeValues = hydratedInstances.filter(c => c.attributeType === AttributeType.CONFIGURATION);

    //  this.filterProperties = configurationAttributeValues.find(config => config.name === this.FILTER_SETTINGS)?.value;
    if (cssAttributeValues.length > 0) {
      this.applyCSSAttributes(cssAttributeValues);
    }

    // if (configurationAttributeValues.length > 0) {
    // const animationUsuableContainer = configurationAttributeValues.find(u => u.name === AnimationAttribute.getName());
    //  const remainingConfigurations = configurationAttributeValues.filter(u => u.name != AnimationAttribute.getName())
    //  this.applyIfAnimationConfigured(animationUsuableContainer);

    if (configurationAttributeValues.length > 0) {
      this.applyConfigurationAttributes(configurationAttributeValues);
    }
  }

  private applyIfAnimationConfigured(animationUsuableContainer: UsableAttributeValue<unknown>,
    hydratedInstances: ModelAndUsableValueContainer<unknown, unknown>[]) {

    if (RtSome(animationUsuableContainer).isDefined) {
      const animationContainer = animationUsuableContainer.value as unknown as AnimationsContainer;
      this.animationService.applyAnimation(animationContainer, this, hydratedInstances);
    }
  }

  //step-3: Render children

  //1) notify parent with RenderedEvent
  //2) dequeue commands from state manager and render children
  notifyAfterViewInit() {
    this.controlReadinessCheckService.setAsViewInitialized();
    this.onControlInfoUpdateSubject.pipe(first()).subscribe(() => {
      // console.info('controlInfo:' + this.controlInstance.instanceId, this.controlInstance.controlName)
      this.publishControlRendered();
    })
    this.tryAdaptDataAndApplyChildren();
    // setTimeout(()=>{
    // this.subscribeForControlBindings();
    // })
    this.controlRenderProgressIndicatorService.hideProgressIndicator();

  }

  private publishControlRendered() {
    this.eventService.publish(PubSubTopic.CONTROL_RENDERED, this.id);
  }

  /*
    tryAdaptDataAndApplyChildren method is called from three places.
    1) notifyAfterViewInit: There is a chance that data will be received before viewInit, in such case it is required to call the below method
    2) datasourceResolved: Once data is received to render children
    3) verifyAndApplyDatasource: When no data source is configured for control, however when the data is available from parent then it is required to call the below method
  */
  private tryAdaptDataAndApplyChildren() {
    //  this._dsName = dsName;
    // 1) this.applyData(data) and get control info
    // 2) applyData on datasourceManager and get ChildRenderedCommand
    // 3) for each one of them, call controlRenderService.render(cmd,controlInfo)
    if (this.canRenderChildren) {
      if (this.controlReadinessCheckService.isReadyToAdaptDataAndRenderChildren) {

        const data = RtSome(this.data);
        const dataWithAppliedConditions = this.applyControlConditions(data);
        const uomConvertedData = this.getUomConvertedData(dataWithAppliedConditions);

        //1) apply data to self
        this.subs.add(this.onDatasourceResolved(uomConvertedData).subscribe((ci: PI) => {
          this.controlInfo = ci;
          this.onControlInfoUpdateSubject.next(true);

          //2) apply children
          const isDirectValue = this.dataIsDirectValue();
          if (!isDirectValue) {
            this.applyChildren();
            this.changeDetectorRef?.detectChanges();
          }
        }));
      } else if (this.controlReadinessCheckService.isViewInitializedAndHasNoDatasource(this.controlInstance)) {
        const data = RtSome(this.data);
        const dataWithAppliedConditions = this.applyControlConditions(data);
        const uomConvertedData = this.getUomConvertedData(dataWithAppliedConditions);
        this.subs.add(this.onDatasourceResolved(uomConvertedData).subscribe((ci: PI) => {
          this.changeDetectorRef?.markForCheck();
          this.controlInfo = ci;
          this.onControlInfoUpdateSubject.next(true);


          const cmd = new AddStaticChildCommand(this.controlInstance, this.parentCmd.repeaterControlId, this.id, this.controlProps);
          this.addChildren([cmd]);
          this.changeDetectorRef?.detectChanges();
        }));
      } else if (this.controlReadinessCheckService.isViewInitializedAndHasDatasourceAndNotInitialized(this.controlInstance) && this.hasTriggerDataSourceEvent(this.controlInstance.instanceId)) {
        this.setEmptyDSValueToControlAfterInitiated();
      }
      else {
        // control (html-view) is not initialized or data is not received;
      }
    }

  }

  setEmptyDSValueToControlAfterInitiated() {
    let result;
    if (this.resultClassName === DsResult.name) {
      result = new DsResult(null, "", null, [], false, false, DataStateType.NEW);
    } else {
      result = new DsResultArray("", [], 0, []);
    }
    this.onDatasourceResolved(RtSome(result) as unknown as RtOption<D>)
  }

  private hasTriggerDataSourceEvent(controlInstanceId: string) {
    const controlBindings: ControlBindingsAction[] = this.eventStoreReaderService.getControlBindings(controlInstanceId);
    return controlBindings.some(binding => binding.eventType === StandardEvents.TRIGGER_DATASOURCE);
  }

  private getUomConvertedData(data: RtOption<D>): RtOption<D> {
    if (data.isDefined) {
      const convertedData = this.controlLocalizationAndPersonalizationService.convert(data.get, this.controlInstance);
      return RtSome(convertedData as D);
    }
    return data;
  }

  private dataIsDirectValue() {
    if (this.resultClassName === DsResult.name) {
      const dsResult = this.data as unknown as DsResult;
      return dsResult.isDirectValue();
    } else {
      return false;
    }
  }

  applyControlConditions(data: RtOption<D>): RtOption<D> {
    if (this.canApplyControlConditions(data)) {
      const controlConditionEvaluationResults: ControlConditionGroupEvaluationResult[] = this.controlConditionEvaluatorService.evaluateConditions(data.get as unknown as DsResult, this.controlInstance.controlConditionGroup, this.controlInstance.instanceId);
      this.conditionBehaviourService.applyVisibilityAndAccessability(this, controlConditionEvaluationResults);
      const behavioursToApply = this.resolveBehavioursToApply(controlConditionEvaluationResults);
      if (behavioursToApply.length > 0) {
        this.conditionBehaviourService.applyBehaviours(this, data.get as unknown as DsResult, this.controlInstance, behavioursToApply);
      }
      const convertedData = this.applyConverters(data, controlConditionEvaluationResults);
      return convertedData;
    }
    else if (data.isDefined) {
      const controlConditionEvaluationResults: ControlConditionGroupEvaluationResult[] = this.controlConditionEvaluatorService.evaluateConditions(data.get as unknown as DsResult, this.controlInstance.controlConditionGroup, this.controlInstance.instanceId);
      const convertedData = this.applyConverters(data, controlConditionEvaluationResults);
      return convertedData;
    }
    else {
      return data;
    }
  }
  private applyConverters(data: RtOption<D>, controlConditionEvaluationResults: ControlConditionGroupEvaluationResult[]) {
    return data.map(d => {
      const convertersToApply = controlConditionEvaluationResults.map(result => result.canExecuteBehaviour && result.controlConditionGroup.converters || []).flat();
      return this.dsResultsConvertersService.applyConverters(this.resultClass.name, convertersToApply, data.get);
    });
  }

  private resolveBehavioursToApply(controlConditionEvaluationResults: ControlConditionGroupEvaluationResult[]): BehaviourData[] {
    return controlConditionEvaluationResults.filter(result => result.canExecuteBehaviour).map(result => result.controlConditionGroup.behaviours || []).flat();
  }

  private canApplyControlConditions(data: RtOption<D>) {
    return data.isDefined && (this.resultClassName == DsResult.name) && this.hasControlConditions();
  }
  private hasControlConditions() {
    return this.controlInstance.controlConditionGroup?.length > 0;
  }

  private applyChildren(): void {

    const cmds = this.dataSourceStateManager.deQueueAllCmds();
    const addCmds = ChildCommandResolver.resolveCmds<AddChildWithDsResultCommand>(cmds, ChildCommandType.NEW_WITH_DS_RESULT);
    const updateCmds = ChildCommandResolver.resolveCmds<UpdateChildChildWithDsResultCommand>(cmds, ChildCommandType.UPDATED_WITH_DS_RESULT);
    const deleteCmds = ChildCommandResolver.resolveCmds<DeleteChildByDsResultIdCommand>(cmds, ChildCommandType.DELETED_DS_RESULT);

    this.addChildren(addCmds);
    this.updateChildren(updateCmds);
    this.deleteChildren(deleteCmds);

    this.onUpdated(updateCmds);
  }

  //Override from each control where it matters. Currently the use case is threejs animations
  public onUpdated(updatedCmds: UpdateChildChildWithDsResultCommand[]) {

  }

  private markAndUnMarkOnControlsAddedOrRemoved() {
    this.unMarkAllFirstChildStatus();
    this.markFirstChildStatus();
  }
  unMarkAllFirstChildStatus() {
    ControlHelper.unMarkFirstChildStatus(this.children);
  }
  markFirstChildStatus() {
    const raiseClickEventForFirstChild = this.canRaiseClickEventForFirstChild();
    ControlHelper.markFirstChildStatus(this.children, RtSome(raiseClickEventForFirstChild));
    //this.raiseClickEventIfFirstChild();

  }

  private canRaiseClickEventForFirstChild(): boolean {
    const raiseClcickEvent = this.controlInstance.allAttributeValues.find(attr => attr.name === PBEventConfiguration.CAN_RAISE_CLICK_EVENT_FOR_FIRST_CHILD)?.value;
    const parentCmd = this.parentCmd as AddChildWithDsResultCommand;
    return raiseClcickEvent || (parentCmd?.firstChild?.isDefined && parentCmd.firstChild.get.canRaiseClickEvent)
  }

  //pre-conditions:
  //1) view initialized
  //2) datsource resolved (no DS configured || state manager is ready)
  childControlRenderedSub = new BehaviorSubject(false);
  // Note: controlRenderedSub will cover only immediate children. Not the nested one.
  addChildren(childCommands: AddChildBaseCommand[]): void {
    this.subs.add(this.controlRenderService.renderChildren(childCommands, this.controlInfo, this.controlInstance).subscribe((childControls => {
      childControls.map(childCtrl => {
        this.children.push(childCtrl);
      });
      this.markAndUnMarkOnControlsAddedOrRemoved();
      this.childControlRenderedSub.next(true);
      this.raiseClickEventIfFirstChild();
    })));

  }

  //for update children does not require render service
  private updateChildren(updateCommands: UpdateChildChildWithDsResultCommand[]): void {
    updateCommands.map(cmd => {
      this.children.filter(ds => ds.dsResultId.isDefined && ds.dsResultId.get === cmd.dsResult.id).map((existing) => {
        existing.childControlRef.updateData(cmd.dsResult);
      });
    });
  }

  private deleteChildren(deleteCmds: DeleteChildByDsResultIdCommand[]): void {
    const childControlIdsToDelete = deleteCmds.map(cmd => {
      return this.children.filter(child => child.dsResultId.isDefined && child.dsResultId.get === cmd.dsResultId).map((existing) => {
        existing.childControlRef.destroy();
        return existing.childControlRef;
      });
    }).flat();

    this.children = this.children.filter(child => !deleteCmds.some(deleteCmd => (child.dsResultId.isDefined
      && deleteCmd.dsResultId == child.dsResultId.get)));

    this.controlRenderService.destroyChildren(childControlIdsToDelete, this.controlInfo, this.controlInstance);
    this.markAndUnMarkOnControlsAddedOrRemoved();
    this.handleRaiseFirstChildClickEvent();
  }

  private handleRaiseFirstChildClickEvent() {
    const parentCmd = this.parentCmd as AddChildWithDsResultCommand;
    if (parentCmd?.firstChild?.isDefined && parentCmd.firstChild.get.canRaiseClickEvent) {
      const raiseClickEventForFirstChild = this.canRaiseClickEventForFirstChild();
      ControlHelper.markFirstChildStatus(this.children, RtSome(raiseClickEventForFirstChild));
      // Added timeout as raisevent values getting as NA instead of actual values
      // setTimeout(() => {
      //   this.raiseFirstChildEventforChildren(parentCmd.dsResult);
      // }, 1000);

      //TODO: Under observation, to check if NA is shown if timeout is removed.
      this.raiseFirstChildEventforChildren(parentCmd.dsResult);
    }
  }


  private raiseFirstChildEventforChildren(dsResult: DsResult) {
    this.children.forEach((e: any) => e.childControlRef.ref?.instance.raiseClickEventIfFirstChildAndNestedChild(dsResult));
  }

  private raiseClickEventIfFirstChild() {
    // const raiseClickEventForFirstChild = this.canRaiseClickEventForFirstChild();
    // ControlHelper.markFirstChildStatus(this.children, RtSome(raiseClickEventForFirstChild));
    const parentCmd = this.parentCmd as AddChildWithDsResultCommand;
    if (parentCmd?.firstChild?.isDefined && parentCmd.firstChild.get.canRaiseClickEvent) {
      this.onClick([parentCmd.dsResult]);
    }
  }


  // When the control is deleted from designer mode control companion will publish ChildControlDeletedCommand
  deleteChildControl(deleteCmd: ChildControlDeletedCommand) {
    const renderedControlInstance = this.children.find(child => child.childControlRef.id === deleteCmd.childControlId);
    if (renderedControlInstance) {
      this.children = this.children.filter(child => child.childControlRef.id !== deleteCmd.childControlId);
      this.controlRenderService.destroyChildren([renderedControlInstance.childControlRef], this.controlInfo, this.controlInstance);
    }
  }

  addBusyLoader() {
    // TODO: Implement
    //throw new Error("unimplemented")
  }

  //RAISE EVENTS
  onClick(item: DsResult[]) {
    this.handleCtrlEvent(item, StandardEvents.CLICK);
    this.handleCloseEvent(undefined, StandardEvents.CLOSE);
    this.handleCtrlEvent(undefined, StandardEvents.TRIGGER_DATASOURCE);
    if (!item || this.isEmptyArray(item)) {
      this.onEmptiness();
    }
  }

  handleCloseEvent(data: undefined, eventType: CommonEventType) {
    this.eventStroeSubscription?.unsubscribe();
    this.eventStroeSubscription = this.eventStoreReaderService.getEventStore().subscribe(eventStore => {
      const configuredEvents = eventStore.getConfiguredEvents(this.controlInstance.instanceId);
      if (configuredEvents?.length && configuredEvents.includes(StandardEvents.CLOSE)) {
        this.publishEvent(eventType, data);
      }
    })
  }

  private publishEvent(eventType: CommonEventType, data: DsResult[]) {
    const appEvent: AppEvent = {
      eventType: eventType, eventProducerName: this.controlInstance.instanceId,
      dsResults: data, subEventType: RtNone(),
      producerType: ProducerType.DATASOURCE_SCHEMA
    };
    this.eventService.publish(GlobalEventOrchestrator.APP_EVENT, appEvent);
  }

  setSelectedData<T>(data: T) {
    // set from event. e.g., click or select
    ControlStateManager.setValue<T>(this.controlInstance.id, data)
  }

  getPreviousData() {
    return ControlStateManager.getValue(this.controlInstance.id);
  }

  private isEmptyArray(item: any): boolean {
    return Array.isArray(item) && !item.length;
  }

  onEmptiness() {
    this.handleCtrlEvent(undefined, StandardEvents.EMPTINESS);
  }

  onLoad(item: DsResult[]) {
    this.handleCtrlEvent(item, StandardEvents.LOAD);
  }

  onHover(item: DsResult) {
    this.handleCtrlEvent([item], DesktopEvents.MOUSE_OVER);
  }

  onMouseLeave(item: DsResult) {
    this.handleCtrlEvent([item], DesktopEvents.MOUSE_LEAVE);
  }

  handleCtrlEvent(item: DsResult[], eventType: CommonEventType) {
    this.publishAppEvent(eventType, item);
  }

  public publishAppEvent(eventType: CommonEventType, data: DsResult[]) {

    // TODO verify the event is published by Parent
    this.eventStroeSubscription?.unsubscribe();
    this.eventStroeSubscription = this.eventStoreReaderService.getEventStore().subscribe(eventStore => {
      const configuredEvents = eventStore.getConfiguredEvents(this.controlInstance.instanceId);
      if (configuredEvents?.length) {
        this.publishEvent(eventType, data);
      }
    })
  }

  public unsubscribeAppEventFromEventService() {
    this.eventStoreReaderService.getEventStore().subscribe(eventStore => {
      const eventActionContainersOpts = eventStore.getEventActionContainerByProducerName(this.controlInstance.instanceId);
      if (eventActionContainersOpts.isDefined) {
        eventActionContainersOpts.get.forEach(eac => {
          (eac.actions as ControlBindingsAction[]).forEach(action => {
            action.containers?.forEach(actCon => {
              if (action.eventType === StandardEvents.CLOSE) {
                const topic = AppEventTopics.buildControlBindingTopic(action.id, actCon.controlInstanceId);
                this.eventService.unsubscribe(topic);
              }
            });
          });
          eac.paramBindings.forEach(pb => {
            this.dataSourceExecutor.resetParamBufferOnControlDestroy(pb.datasourceName, pb.param);
          });
        })
      }
    })
  }


  //companions

  showCompanion(companionRequestCommand: CompanionRequestCommand<D, IControl<D>>) {
    //  const companionRequestCommand: CompanionRequestCommand = this.buildCompanionRequestCommand();
    this.eventService.publish(ControlCompanionEventsTopics.SHOW_COMPANION_REQUEST, companionRequestCommand);
  }

  removeCompanion() {
    const destroyCompanionCommand: DestroyCompanionCommand<D, IControl<D>> = { control: this };
    this.eventService.publish(ControlCompanionEventsTopics.DESTROY_COMPANION, destroyCompanionCommand);
  }
}
