import { ViewContainerRef } from "@angular/core";
import { EntitySchemaDataResponse } from "../../models/base.models";
import { EntitySchemaFieldContainer } from "../../models/schema/schema-models";
import { DenThreeJSPosition } from "../../models/threejs/den-threejs-modals";
import { RtOption } from "../../utils/option-helper";
import { AttributeInstance } from "../attributes/core/attribute-instance";
import { ControlPropertyDefinitionValue } from "../controls/core/control-instance";
import { ControlInstanceWrapper } from "../controls/core/control-instance-drafts-models";
import { ReturnMethodParamsWrapper } from "../data-source/data-source";
import { RenderOwnership } from "./enums";

export class FilterAndSortResult {
  model: EntitySchemaDataResponse;
  sortBy: ReturnMethodParamsWrapper;
}

export type ControlDropParentData = { parentInstanceId: string, parentControlName: string, parentSectionIndex: RtOption<number>, pageName: string, parentViewContainerRef?: ViewContainerRef };

export abstract class ControlDropData<U extends DropChildData> {
  get type() {
    return this.childData.type;
  };

  constructor(public childData: U, public parentData: ControlDropParentData, public isTheControlDroppedFromNewDataSourceMode: boolean = false) {

  }
};

export abstract class DropChildData {
  abstract type: ControlDropTypeEnum;
  constructor() {

  }
}

export enum ControlDropTypeEnum {
  STANDARD = 'standard',
  THREE_JS_ENTITY_ITEM = 'three_js_entity_item',
  FORM_FIELD = 'form_field',
  WIDGET = 'widget',
  CONTROL_RE_ARRANGE = 'rearrange',
  LEAFLET_ENTITY_ITEM = 'leaflet_entity_item',
}


export class StandardDropChildData extends DropChildData {
  type = ControlDropTypeEnum.STANDARD;

  constructor(public ctrlType: string, public controlName: string, public attributes: AttributeInstance[],
    public propertyDefinitions: ControlPropertyDefinitionValue[], public renderOwnership: RenderOwnership, public data?: any) {
    super();
  }

  updateControlName(name: string): void {
    this.controlName = name;
  }
}

export class StandardControlDrop extends ControlDropData<StandardDropChildData>{

  constructor(public childData: StandardDropChildData, public parentData: ControlDropParentData, public isTheControlDroppedFromNewDataSourceMode: boolean) {
    super(childData, parentData, isTheControlDroppedFromNewDataSourceMode);
  }
}

export class ThreeJsEntityChildData extends DropChildData {
  type = ControlDropTypeEnum.THREE_JS_ENTITY_ITEM;
  constructor(public ctrlType: string, public controlName: string, public data: any, public threeDFieldName: string, public position: DenThreeJSPosition) {
    super();
  }
}

export class ThreeJsEntityItemDrop extends ControlDropData<ThreeJsEntityChildData>{
  constructor(public childData: ThreeJsEntityChildData, public parentData: ControlDropParentData, public isTheControlDroppedFromNewDataSourceMode: boolean) {
    super(childData, parentData, isTheControlDroppedFromNewDataSourceMode);
  }
}

export class FormFieldChildData extends DropChildData {
  type = ControlDropTypeEnum.FORM_FIELD;
  constructor(public renderOwnership: RenderOwnership, public data?: EntitySchemaFieldContainer) {
    super();
  }
}

export class FormFieldDrop extends ControlDropData<FormFieldChildData>{
  constructor(public childData: FormFieldChildData, public parentData: ControlDropParentData,
    public attributes: AttributeInstance[], public isTheControlDroppedFromNewDataSourceMode: boolean) {
    super(childData, parentData, isTheControlDroppedFromNewDataSourceMode);
  }
}
export class WidgetDropChildData extends DropChildData {
  type = ControlDropTypeEnum.WIDGET;

  constructor(public layoutFileName: string) {
    super();
  }
}

export class WidgetControlDrop extends ControlDropData<WidgetDropChildData>{

  constructor(public childData: WidgetDropChildData, public parentData: ControlDropParentData, public isTheControlDroppedFromNewDataSourceMode: boolean) {
    super(childData, parentData, isTheControlDroppedFromNewDataSourceMode);
  }
}

export class ControlReArrangeDropChildData extends DropChildData {
  type = ControlDropTypeEnum.CONTROL_RE_ARRANGE;
  constructor(public controlInstanceId: string) {
    super();
  }
}

export class ControlReArrangeDrop extends ControlDropData<ControlReArrangeDropChildData>{

  constructor(public childData: ControlReArrangeDropChildData, public parentData: ControlDropParentData, public childControlInstanceWrapper: ControlInstanceWrapper) {
    super(childData, parentData);
  }
}

export class LeafletEntityChildData extends DropChildData {
  type = ControlDropTypeEnum.THREE_JS_ENTITY_ITEM;
  constructor(public ctrlType: string, public controlName: string, public data: any) {
    super();
  }
}

export class LeafletEntityItemDrop extends ControlDropData<LeafletEntityChildData>{
  constructor(public childData: LeafletEntityChildData, public parentData: ControlDropParentData, public isTheControlDroppedFromNewDataSourceMode: boolean) {
    super(childData, parentData, isTheControlDroppedFromNewDataSourceMode);
  }
}

