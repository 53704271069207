import { RtOption } from "../../../utils/option-helper";
import { UsableAttributeValue } from "../core/attribute-instance";

export class ModelAndOneUsableValueContainer<M, U> {
  constructor(public model: RtOption<M>, public usableValue: UsableAttributeValue<U>) { }

  withUpdatedValue(newValue: U) {
    return this.usableValue.withUpdatedValue(newValue)
  }
}
