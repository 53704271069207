import { RtOption } from '../../../../utils/option-helper';
import { CtrlEventAttribute, EventAttributeType } from './control-event-attribute';
import { CommonEventType, EventActionGroupUniqueKey } from './event-types';

// 1) register all events on control level
// e.g. CLICKED can be on Control level
//      where as ON_OFF_SWITCH can be on Toggle button control level


export abstract class EventId {

  evtType: CommonEventType;
  ctrlInstanceId: string;

  constructor(evtType: CommonEventType,
    ctrlInstanceId: string) {
    this.evtType = evtType;
    this.ctrlInstanceId = ctrlInstanceId;
  }

  getEventId() {
    return this.evtType + '_' + this.ctrlInstanceId;
  }
}

// Register: EVENT PROVIDER (CONTROL INSTANCE) => RegisteredEvents
// 2) Save CtrlEvent[] in ControlInstance for each event registered in its respective Control
// 3) update when property is mapped
//
// datasource event Register: EVENT CONSUMER (CONTROL INSTANCE) => Datasource => Subscribed events (ctrlType and instance name)
// Other event Register: EVENT CONSUMER (CONTROL INSTANCE) => Subscribed events (ctrlType and instance name)
export class CtrlEvent extends EventId {

  #isDefaultEvent = false;

  constructor(public evtType: CommonEventType,
    public ctrlInstanceId: string,
    public fieldName: RtOption<string>,
    //public pageName: RtOption<string> //TODO: Save page name as part of control events (Evens to publish)
  ) {
    super(evtType, ctrlInstanceId);
  }

  markAsDefault() {
    const ctrlEvent = new CtrlEvent(this.evtType, this.ctrlInstanceId, this.fieldName);
    ctrlEvent.#isDefaultEvent = true;
    return ctrlEvent;
  }

  get isDefault() {
    return this.#isDefaultEvent;
  }
}

// export class CtrlEventWithItsSubscribers {
//   constructor(public ctrlEvent: CtrlEvent,public subscriptions:CtrlEventSubscription[]
//   ) {
//   }
// }

export enum CtrlEventSubscriptionType {
  // To provide the datasource parameters
  AS_DATASOURCE_PARAMETER = 1,
  // direct value binding
  AS_DATASOURCE = 2,
  // interactions (popup, floating panel, )
  AS_CTRL_EVENT = 3,
  // to clear data if there is any existing data;
  // Ideally bind to emptyness (CtrlEventType.EMPTYNESS) event of list control or any event.
  AS_CTRL_EMPTY = 4,
  // to restrict api execution on user intraction
  // Control will have datasource parameters as well as datasource control events.
  AS_DATASOURCE_CTRL_EVENT = 5,

}


/**
 *
 */
export class CtrlEventSubscription extends EventId {
  constructor(public evtType: CommonEventType, // Event type e.g. Click, Load etc
    public ctrlInstanceId: string, //ControlInstanceId of the control which is publishing this event
    public dsName: RtOption<string>, // Name of the subscriber (self) data source
    public paramName: RtOption<string>, // data source param which is listening for the event
    public boundedParamName: RtOption<string>, //param name which is getting published
    public ctrlEventSubscriptionId: CtrlEventSubscriptionType, // Type of subscription
  ) {
    super(evtType, ctrlInstanceId);
  }
}

export class ParamEventSubscription {
  constructor(
    public eventProducerName: string, //ControlInstanceId of the control which is publishing this event
    public eventActionGroupUniqueKey: EventActionGroupUniqueKey,
    public dsName: string, // Name of the subscriber (self) data source
    public paramName: string, // data source param which is listening for the event
    public boundedParamName: string, //param name which is getting published
  ) {

  }
}

export class CtrlEventSubscriptionWrapper {
  public get evtType(): CommonEventType {
    return this.ctrlEventSubscription.evtType;
  }
  public get ctrlInstanceId(): string {
    return this.ctrlEventSubscription.ctrlInstanceId;
  }
  public get fieldName() {
    return this.ctrlEventSubscription.paramName;
  }
  public get ctrlEventSubscriptionId(): CtrlEventSubscriptionType {
    return this.ctrlEventSubscription.ctrlEventSubscriptionId;
  }
  constructor(public subscriberControlInstanceId: string, public ctrlEventSubscription: CtrlEventSubscription) {

  }
}

// 4) Publish when event raised
// 5) manage datsources based on event attribute type (on/off or check/uncheck)
//  5.1) if parameter is array
//      5.1.1) if the bound property is array, then do the direct binding
//      5.1.2) else
//                 collect parameters (add/remove based on the state),and invoke datasource whenever new parameter (selcted/unselected)
export class CtrlEventRaised extends EventId {

  private attributes: CtrlEventAttribute[];

  constructor(public evt: CtrlEvent, public value: RtOption<any>, attributes: CtrlEventAttribute[] = []) {
    super(evt.evtType, evt.ctrlInstanceId);
    this.attributes = attributes;
  }

  public getAttributeValue(attributeType: EventAttributeType) {
    const value = this.attributes.find(a => a.attributeType === attributeType);
    return new RtOption(value);
  }
}


// export class ControlEventsAndMappedInstancesContainer {
//   public constructor(public ControlEventsContainer: ControlEventsContainerWrapper,
//     public controlEventSubscribersByOtherInstances: CtrlEventSubscriptionWrapper[]) { }
// }
// export class ControlEventsContainerWrapper {

//   constructor(public id: string,
//     // public name: string,
//     public controlInstanceId: string,
//     public eventRegisterToPublish: CtrlEvent[] = [],
//     public eventSubscriptions: CtrlEventSubscription[] = [], public pageName: string, public createdDate: string, public createdBy: string,
//     public attributes: Object, public appCode: string, public identifier: string) {
//   }

//   public getSubscriptionsByPublishingInstanceId(publishingControlInstanceId: string): CtrlEventSubscriptionWrapper[] {
//     return this.eventSubscriptions.filter(es => es.ctrlInstanceId === publishingControlInstanceId).map(es => new CtrlEventSubscriptionWrapper(this.controlInstanceId, es));
//   }

//   public addCtrlEventToPublish(controlEventsToPublish: CtrlEvent) {
//     if (!this.eventRegisterToPublish) {
//       this.eventRegisterToPublish = [];
//     }
//     if (!this.isControlEventAlreadyExist(controlEventsToPublish)) {
//       this.eventRegisterToPublish.push(controlEventsToPublish);
//     }
//   }
//   public deleteCtrlEvent(controlEvent: CtrlEvent) {
//     if (this.eventRegisterToPublish && this.isControlEventAlreadyExist(controlEvent)) {
//       this.eventRegisterToPublish = this.eventRegisterToPublish.filter(ce => !this.controlEventEqualityValidator(ce, controlEvent));
//     }
//   }

//   public deleteAllCtrlEventSubscriptions() {
//     if (this.eventSubscriptions) {
//       this.eventSubscriptions = [];
//     }
//   }
//   public addCtrlEventSubscription(ctrlEventSubscription: CtrlEventSubscription) {
//     if (!this.eventSubscriptions) {
//       this.eventSubscriptions = [];
//     }
//     if (!this.isCtrlEventSubscriptionAlreadyExist(ctrlEventSubscription)) {
//       this.eventSubscriptions.push(ctrlEventSubscription);
//     }
//   }
//   public deleteCtrlEventSubscription(ctrlEventSubscription: CtrlEventSubscription) {
//     if (this.eventSubscriptions && this.isCtrlEventSubscriptionAlreadyExist(ctrlEventSubscription)) {
//       this.eventSubscriptions = this.eventSubscriptions.filter(ce => !this.controlEventSubscriptionEqualityValidator(ce, ctrlEventSubscription));
//     }
//   }
//   public deleteDependentCtrlEventSubscription(controlEvent: CtrlEvent) {
//     if (this.eventSubscriptions) {
//       this.eventSubscriptions = this.eventSubscriptions.filter(ce => !(
//         ce.ctrlInstanceId === controlEvent.ctrlInstanceId
//         && ce.evtType === controlEvent.evtType
//         && (ce.paramName.isDefined ? ce.paramName.get : undefined) === (controlEvent.fieldName.isDefined ? controlEvent.fieldName.get : undefined))
//       );
//     }
//   }

//   private isControlEventAlreadyExist(controlEvent: CtrlEvent): boolean {
//     const isControlEventAlreadyExist = this.eventRegisterToPublish.some(ce => this.controlEventEqualityValidator(ce, controlEvent));
//     return isControlEventAlreadyExist;
//   }
//   private controlEventEqualityValidator(ce: CtrlEvent, controlEvent: CtrlEvent): boolean {
//     return ce.ctrlInstanceId === controlEvent.ctrlInstanceId
//       && ce.evtType === controlEvent.evtType
//       && (ce.fieldName.isDefined ? ce.fieldName.get : undefined) === (controlEvent.fieldName.isDefined ? controlEvent.fieldName.get : undefined);
//   }

//   private isCtrlEventSubscriptionAlreadyExist(ctrlEventSubscription: CtrlEventSubscription): boolean {
//     const isCtrlEventSubscriptionAlreadyExist = this.eventSubscriptions.some(ce => this.controlEventSubscriptionEqualityValidator(ce, ctrlEventSubscription));
//     return isCtrlEventSubscriptionAlreadyExist;
//   }

//   private controlEventSubscriptionEqualityValidator(ce: CtrlEventSubscription, ctrlEventSubscription: CtrlEventSubscription): boolean {
//     return ce.ctrlInstanceId === ctrlEventSubscription.ctrlInstanceId
//       && ce.evtType === ctrlEventSubscription.evtType
//       && (ce.paramName.isDefined ? ce.paramName.get : undefined) === (ctrlEventSubscription.paramName.isDefined ? ctrlEventSubscription.paramName.get : undefined)
//       && ce.ctrlEventSubscriptionId === ctrlEventSubscription.ctrlEventSubscriptionId;
//   }
// }
