import { Injectable } from "@angular/core";
import { FilterProperty, PageProperties } from "../../../../../models/base.models";
import { Observable, Subject } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";

@Injectable()
export class PaginationAndFilterService {

  private subjectMap = new Map<string, Subject<PaginationAndFilterData>>();

  public subscribeForFilterAndPaginationChanges(controlInstanceId: string, dsName: string): Observable<PaginationAndFilterData> {
    let subject = this.subjectMap.get(controlInstanceId);
    if(!subject){
      subject = new Subject<PaginationAndFilterData>();
      this.subjectMap.set(controlInstanceId, subject);
    }
    return subject.pipe(debounceTime(300),filter((paginationAndFilterData) => paginationAndFilterData.dsName === dsName))
  }

  public publishPaginationAndFilterChanges(paginationAndFilterData: PaginationAndFilterData) {
    const subject = this.subjectMap.get(paginationAndFilterData.controlInstanceId);
    subject?.next(paginationAndFilterData);
  }
  public resetSubjectInstance(controlInstanceId: string) {
    let subject = this.subjectMap.get(controlInstanceId);
    subject?.complete();
    this.subjectMap.delete(controlInstanceId);
  }
}

export type PaginationAndFilterData = { controlInstanceId: string, dsName: string, pagination?: PageProperties, additionalFilters?: FilterProperty[] };
