import { Directive, Output, EventEmitter, NgModule } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
    selector: '[ngModel][rtDebounce]'
})
export class DebounceDirective {
    @Output('rtDebounce')
    public debounceClick: EventEmitter<any> = new EventEmitter<any>();
    private subscription: Subscription;
    private isFirstChange = true;

    constructor(public model: NgControl) { }

    ngOnInit() {
        this.subscription = this.model.valueChanges
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((modelValue: string) => {
                if (this.isFirstChange) {
                    this.isFirstChange = false;
                } else {
                    this.debounceClick.emit(modelValue);
                }
            });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }


}
@NgModule({
  declarations: [
    DebounceDirective
  ],
  exports: [
    DebounceDirective
  ]
})
export class DenDebounceDirectiveModule { }
