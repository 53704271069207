import { BaseDependencyProperty, BaseType, DynamicDependencyProperty, FilterProperty } from "../models/base.models";

export class ArrayHelperMethods {

    static getNameById<T extends BaseType>(array: T[], name: string, dependencyObj?: BaseDependencyProperty): string {
        if (!array || !name) {
            throw new Error('No arguments passed');
        }
        if (dependencyObj instanceof DynamicDependencyProperty) {
            const selectedItem = array.find((item) => {
                return item.name === (name);
            });
            if (selectedItem) {
                return selectedItem.label;
            } else {
                return 'NA';
            }
        } else if (this.isNumber(name)) {
            return this.getNameForIsNotDynamicData(array, name);
        }
        return 'NA';
    }

    private static getNameForIsNotDynamicData<T extends BaseType>(array: T[], name: string): string {
        if (name) {
            const selectedItem = array.find((item) => {
                return item.name === (name);
            });
            if (selectedItem) {
                return selectedItem.label;
            } else {
                return 'NA';
            }
        } else {
            throw new Error('Id does not exist');
        }
    }
    static getObjectById<T extends BaseType>(array: T[], name: string): T {
        if (!array || !name) {
            throw new Error('No arguments passed');
        }
        if (name) {
            const selectedItem = array.find((item) => {
                return item.name === (name);
            });
            if (selectedItem) {
                return selectedItem;
            } else {
                return null;
            }
        } else {
            throw new Error('Id does not exist');
        }
    }
    static includes<T>(array: Array<T>, propertyName: T): boolean {
        const isFound = false;
        if (array && array.length && array.length > 0) {
            const result = array.filter((item: any) => {
                return item === propertyName;
            });
            if (result.length > 0) {
                return true;
            } else {
                return isFound;
            }
        }
        return isFound;
    }

    // static removeById(array: Array<number | string>, id: number | string, dependencyData?: DependencyProperty): (string | number)[] {
    //     if (!array || !id || array.length === 0) {
    //         throw new Error('No arguments passed');
    //     }
    //     if (dependencyData && dependencyData.isDynamic) {
    //         if (array.includes(id)) {
    //             array = array.filter(item => item !== (id));
    //             return array;
    //         } else {
    //             throw new Error('Invalid input');
    //         }
    //     } else if (this.isArrayOfNumber(array) && this.isNumber(id)) {
    //         return this.isNotDynamicDependency(array, id);
    //     }

    // }
    // private static isNotDynamicDependency(array: number[], id: number) {//NOTE:unused
    //     array.forEach(i => {
    //         if (i < 0 || i % 1 !== 0) {
    //             throw new Error('Invalid inputs');
    //         }
    //     });
    //     if (id > 0 && id % 1 === 0 && array.includes(id)) {
    //         array = array.filter(item => item !== (id));
    //         return array;
    //     } else {
    //         throw new Error('Invalid input');
    //     }
    // }

    private static isArrayOfNumber(value: (string | number)[]): value is number[] {
        if (Array.isArray(value)) {
            return value.every(item => typeof item === 'number');
        }
        return false;
    }

    static isNumber(x: string | number): x is number {
        return typeof x === 'number';
    }

    // static getHeaderdata(): HttpHeaders {
    //     const headers = new HttpHeaders()
    //         .set(HttpHeaderConstants.MINE_ID, JSON.stringify(ArrayHelperMethods.mineId))
    //         .set(HttpHeaderConstants.API_KEY, ArrayHelperMethods.apiKey)
    //         .set(HttpHeaderConstants.IP_ADDRESS, sessionStorage.getItem(HttpHeaderConstants.IP_ADDRESS) || '')
    //         .set(HttpHeaderConstants.GEO_LOCATION, sessionStorage.getItem(HttpHeaderConstants.GEO_LOCATION || ''));
    //     return headers;
    // }
    static removeByIdForNav(array: (string)[], object: BaseType, dependencyData: BaseType[], dependencyObj?: BaseDependencyProperty): void {
        if (!array || !object || array.length === 0 || !dependencyData || dependencyData.length === 0) {
            throw new Error('No arguments passed');
        }
        if (dependencyObj instanceof DynamicDependencyProperty) {
            const selectedId: BaseType = dependencyData.find(item => {
                return item.label === object.label;
            });


            if (selectedId && typeof selectedId.name === 'string' && array.includes(selectedId.name)) {
                const index: number = array.indexOf(selectedId.name);
                array.splice(index, 1);
            } else {
                throw new Error('Invalid input');
            }
        } else if (this.isArrayOfNumber(array)) {
            this.removeIfNotDynamicDependency(array, dependencyData, object);
        }

    }

    private static removeIfNotDynamicDependency(array: string[], dependencyData: BaseType[], object: BaseType) {
        let selectedId: BaseType;
        array.forEach(i => {
            if (!i) {
                throw new Error('Invalid inputs');
            }
        });

        if (dependencyData[0].hasOwnProperty('name')) {
            selectedId = dependencyData.find(item => {
                return item.label === object.label;
            });
        } else {
            selectedId = dependencyData.find(item => {
                return item.name === object.name;
            });
        }

        if (selectedId && typeof selectedId.name === 'number' && array.includes(selectedId.name)) {
            const index: number = array.indexOf(selectedId.name);
            array.splice(index, 1);
        } else {
            throw new Error('Invalid input');
        }
    }

    static removeByIndex<T>(array: T[], value: T): void {
        if (!array || !value || array.length === 0) {
            throw new Error('No arguments passed');
        }
        const index: number = array.indexOf(value);
        if (index >= 0) {
            array.splice(index, 1);
        } else {
            throw new Error('Invalid Index');
        }

    }

    static findDuplicateValue<T extends FilterProperty>(previousObject: T, filterValue: any): any {
        if (!previousObject || !filterValue) {
            throw new Error('No arguments passed');
        }
        if (previousObject && previousObject.valueList && typeof (filterValue) === 'number' ? filterValue > 0 && filterValue % 1 === 0 : filterValue) {
            if (previousObject.valueList.includes(filterValue)) {
                return filterValue;
            } else {
                return undefined;
            }
        } else {
            throw new Error('Invalid input');
        }
    }
    // sorting order is optional, if not provided, by default its ascending order
    static sortArrayByPropertyName(key: string | number, order = 'asc') {
        const result = (a, b) => {
            if (!a.hasOwnProperty(key) ||
                !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ?
                    (comparison * -1) : comparison
            );
        };
        return result;
    }

    static groupBy<T>(array: Array<T>, propName: keyof T) {
        const groups = array.reduce((groups, item) => {
            const val: any = item[propName];
            groups[val] = groups[val] || [];
            groups[val].push(item);
            return groups;
        }, []);
        return groups;
    }
    // convert form values if exists, to the string or if not exists to the null
    static valuesToString(obj: Object) {
        const convertedObject = {};
        const keys = Object.keys(obj);
        keys.forEach(key => {
            if (typeof obj[key] === 'boolean' || obj[key]) {
                obj[key] = obj[key].toString();
                convertedObject[key] = obj[key].length ? obj[key] : null;
            } else {
                convertedObject[key] = null;
            }
        });
        return convertedObject;
    }
    static clone<T>(list: T | T[]) {
        return JSON.parse(JSON.stringify(list));
    }
}
