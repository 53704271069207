import { Directive, ElementRef, Injector, Renderer2 } from "@angular/core";
import { RtOption } from "../../../utils/option-helper";
import { DsResult } from "../../data-source/data-source-result-entities";
import { SimpleAngularControlInfo } from "../../ide/services/parent-control";
import { AngularDefaultControlRenderService } from "../../services/render/strategy/angular-default-control-render-service";
import { AngularDroppableContainerControl } from "./angular-droppable-container-control";

@Directive()
export abstract class SimpleAngularDroppableContainerControl extends AngularDroppableContainerControl<SimpleAngularControlInfo, AngularDefaultControlRenderService>  {

    controlRenderService: AngularDefaultControlRenderService;

    constructor(injector: Injector,
        public el: ElementRef,
        public renderer: Renderer2,) {
        super(injector, el, renderer);
        this.controlRenderService = injector.get(AngularDefaultControlRenderService);
    }
    protected handleNoRecordsFound(data: RtOption<DsResult>, noRecordsMessage: string) {
        if (data.isDefined && !data.get.data) {
            this.renderNoRecordsFoundElement(noRecordsMessage);
        } else {
            const id = this.getNoRecordsID();
            const noRecordsElement = document.getElementById(id);
            if (noRecordsElement) {
                noRecordsElement.remove();
            }
        }
    }
}
