export enum DataModellerSchema {
    ENTITYSCHEMAS = 'entitySchemas',
    ENTITYSCHEMAFIELDS = 'entitySchemaFields',
    ENTITYSCHEMAFIELDFILTERS = 'entitySchemaFieldFilters',
    ADVANCEDSCHEMAFIELDSSETTINGS = 'advancedSchemaFieldSettings',
    ENTITYFIELDCONDITION = 'entityFieldCondition',
    ENTITYFIELDDISPLAYCONDITION = 'entityFieldDisplayCondition',
    ADVANCEDENTITYSCHEMASETTINGS = 'advancedEntitySchemaSettings',
    DENDATATYPES = 'denDataTypes',
    ENTITYFIELDCONDITIONOPERATORS = 'entityFieldConditionOperators'
}