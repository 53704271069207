import { Attribute, AttributeThemeType, AttributeType } from "../../attributes/core/attribute";
import { AttributeInstance, AttributeInstanceHolder } from "../../attributes/core/attribute-instance";

export enum AttributeResolverType {
    ON_INIT = 'on_init',
    ON_CONFIGURATION_CHANGE = 'on_configuration_change',
    ON_THEME_CHANGE = 'on_theme_change',

    ON_DATA_TRIGGERED_BEHAVIOR_APPLIED = "on_data_triggered_behavior_applied",

}

export class AttributeInstanceResolver {


    static convertAttributeInstanceToAttributeInstanceHolder(controlName: string, allAttributeValues: AttributeInstance[], attributes: Attribute<unknown, unknown>[]): AttributeInstanceHolder[] {
        return allAttributeValues.map(attr => {
            const attribute = attributes.find(_ => _.name === attr.name);
            if (attribute) {
                return new AttributeInstanceHolder(attr.name, attr.value, attr.attributeType, attribute.attributeThemeType());
            } else {
                console.warn(`${attr.name} is not a known attribute of the ${controlName} control`);
                return new AttributeInstanceHolder(attr.name, attr.value, attr.attributeType, AttributeThemeType.NON_THEMEABLE_ATTRIBUTE);
            }
        });
    }

    static resolveAttributeOnConfigurationChange(allAttributeValues: AttributeInstanceHolder[], changedAttribute: AttributeInstanceHolder[]): AttributeInstanceHolder[] {
        const cssAttributes = changedAttribute.filter(ca => ca.attributeType === AttributeType.CSS);
        const configAttributes = changedAttribute.filter(ca => ca.attributeType === AttributeType.CONFIGURATION);

        const overriddenCSSAttributes = AttributeInstanceResolver.getOverriddenAttributes(allAttributeValues, cssAttributes);
        // const overriddenConfigAttributes = AttributeInstanceResolver.getOverriddenConfigAttributes(allAttributeValues, configAttributes);

        return [...overriddenCSSAttributes, ...configAttributes];
    }

    private static getOverriddenAttributes(allAttributeValues: AttributeInstanceHolder[], changedAttributes: AttributeInstanceHolder[]) {
        const allCssAttributes = allAttributeValues.filter(attr => attr.attributeType === AttributeType.CSS);
        const overriddenCSSAttributes = allCssAttributes.map(attr => {
            const attributeIndex = changedAttributes.findIndex(ca => ca.name === attr.name);
            if (attributeIndex >= 0) {
                return changedAttributes[attributeIndex];
            } else {
                return attr;
            }
        });
        return overriddenCSSAttributes;
    }

}
