
import { RtNone, RtOption } from "projects/den-core";
import { Attribute, AttributeInstance, AttributeType, UsableAttributeValue } from "projects/den-core/page-builder";
import { FormFieldInstanceContainer } from "../../../../models/form/form-control";
import { FormFieldInstanceEnum } from "../../../../models/form/form.enums";


export class FormFieldsAttribute extends Attribute<FormFieldInstanceContainer, FormFieldInstanceContainer> {

  constructor() {
    super(FormFieldInstanceEnum.FORM_FIELD_INSTANCE_CONTAINER, null, "string", AttributeType.CONFIGURATION, RtNone());
  }

  generateTSCode(): string {
    return "";
  }

  clone(): Attribute<unknown, unknown> {
    return new FormFieldsAttribute();
  }

  parseModel(jsonValue: string): FormFieldInstanceContainer {
    return FormFieldInstanceContainer.parse(jsonValue);
  }
  buildUsableValue(resolvedValue: RtOption<FormFieldInstanceContainer>): UsableAttributeValue<FormFieldInstanceContainer>[] {
    if (resolvedValue.isDefined) {
      const attributeInstance = new UsableAttributeValue(this.name, this.model, this.attributeType);
      return [attributeInstance];
    }
    return [];
  }

  static asFormFieldsAttribute(attributeInstances: AttributeInstance[]): FormFieldsAttribute {
    const attributeInstance = attributeInstances.find(attr => attr.name === FormFieldInstanceEnum.FORM_FIELD_INSTANCE_CONTAINER);
    const formFieldConfig = attributeInstance?.value ? JSON.parse(attributeInstance.value) : null;
    const formFieldsAttribute = new FormFieldsAttribute();
    formFieldsAttribute.applyModel(formFieldConfig);
    return formFieldsAttribute;
  }
}
