import { Injectable } from "@angular/core";
import { ModalDataContainer } from "projects/den-core/contants";
import { ConfirmationDialogComponent } from "projects/den-core/src/lib/components-library/confirmation-dialog/confirmation-dialog.component";
import { ModalService } from "projects/den-core/src/lib/modal-service/modalservice";
import { Observable, lastValueFrom } from "rxjs";
import { NavigationService } from "../../../../../../page-builder/devum-app/providers/navigation-service";
import { PageStateManager } from "../../../../../services/page/page-state-manager";
import { ApplyActionCommand, PageEventActionStrategy } from "../../event-models";
import { NavigateAction, NavigateType } from "./navigation-action-models";

@Injectable()
export class NavigationProviderStrategy implements PageEventActionStrategy<NavigateAction> {
  constructor(private navigationService: NavigationService, private modalService: ModalService) {
  }

  applyActions(cmds: ApplyActionCommand<NavigateAction>[]): Promise<void> {
    const cmd$ = new Observable<void>((observer) => {
      if (PageStateManager.isInRuntimeMode()) {
        cmds.map((cmd) => {
          this.navigateToPage(cmd);
        })
      } else {
        //DO Nothing as navigation is not allowed in design mode
      }
      observer.next();
    })

    return lastValueFrom(cmd$);
  }
  navigateToPage(cmd: ApplyActionCommand<NavigateAction>) {
    const popupAction = cmd.action.actionConfirmationDialog;
    if (popupAction?.isConfirmed) {
      const dialogCmd: ModalDataContainer = {
        message: popupAction.message, headerMessage: popupAction.header,
        confirmBtnLabel: popupAction.successButtonCaption, cancelBtnLabel: popupAction.cancelButtonCaption
      };
      this.modalService.invoke(ConfirmationDialogComponent, dialogCmd).subscribe(isExecute => {
        if (isExecute) {
          const navAction = cmd.action;
          this.executeNavigationCmd(cmd);
        }
      });
    } else {
      this.executeNavigationCmd(cmd);
    }

  }

  private executeNavigationCmd(cmd: ApplyActionCommand<NavigateAction>) {
    const navAction = cmd.action;
    if (navAction.navigateType === NavigateType.PAGE) {
      this.navigateToInternalPage(cmd);
    }
    else if (navAction.navigateType === NavigateType.EXTERNAL_LINK) {
      this.navigateToExternalPage(cmd);
    }
    else {
      throw new Error(`Navigation type not supported: ${navAction.navigateType}`);
    }
  }

  private navigateToInternalPage(cmd: ApplyActionCommand<NavigateAction>) {
    const navAction: NavigateAction = cmd.action;
    const params: Map<string, string | number> = this.constructParams(navAction, cmd);
    const navigateTo = `/app/${navAction.navigateTo}`;
    this.navigationService.navigate(navigateTo, params);
  }

  private navigateToExternalPage(cmd: ApplyActionCommand<NavigateAction>) {
    const navAction: NavigateAction = cmd.action;
    const params: Map<string, string | number> = this.constructParams(navAction, cmd);
    const urlWithParams = params.size > 0 ? navAction.navigateTo + this.getQueryParams(params) : navAction.navigateTo;
    window.open(urlWithParams, "_blank");
  }

  private constructParams(navAction: NavigateAction, cmd: ApplyActionCommand<NavigateAction>) {
    const hasQueryParams = navAction.queryParams && navAction.queryParams.size > 0;
    const params: Map<string, string | number> = new Map();
    if (hasQueryParams) {
      console.error("TODO: Vijay: NavigationProviderStrategy: Resolve param data from global producers along with control data. Currently only considering current control data");
      navAction.queryParams.forEach((value, key) => {
        const fieldValue = cmd.data.data.find(d => d.fieldName == value.fieldName);
        params.set(key, fieldValue.value);
      });
    }
    return params;
  }

  getQueryParams(params: Map<string, string | number>): string {
    return params ? '?' + [...params].map(([key, value]) => `${key}=${value}`).join('&') : '';
  }
}
