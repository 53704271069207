import { Inject, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ConfigService } from '../../config/config-service';
import { AppCacheManagerProvider, CacheVersionData, EntityDataTypeEnum } from '../../providers/abstract/app-cache-manager-provider';
import { HttpClientProvider } from '../../providers/abstract/http-client-provider';
import { LoaderServiceProvider } from '../../providers/abstract/loader-service-provider';
import { SessionStorageProvider } from '../../providers/abstract/session-storage-provider';
import { WsTopicSubscriptionHelper } from '../../web-sockets/base-web-socket.service';
import { AppGlobalWebSocketService, AppTransactionDataWebSocketService, OrgGlobalWebSocketService } from '../../web-sockets/global-web-socket.service';
import { EntityCacheManagerProvider } from '../entity-cache-manager/entity-cache-manager.provider';

@Injectable()
export class AppCacheManagerService extends AppCacheManagerProvider {


  constructor(@Inject(EntityCacheManagerProvider) public entityCacheManager: EntityCacheManagerProvider,
    @Inject(ConfigService) public configService: ConfigService,
    @Inject(LoaderServiceProvider) public loaderService: LoaderServiceProvider,
    @Inject(HttpClientProvider) public http: HttpClientProvider,
    @Inject(SessionStorageProvider) public sessionStorageProvider: SessionStorageProvider,
    private appGlobalWebSocketService: AppGlobalWebSocketService,
    private orgGlobalWebSocketService: OrgGlobalWebSocketService,
    private appTransactionWebSocketService: AppTransactionDataWebSocketService,
    ) {
    super(entityCacheManager, configService, loaderService, http, sessionStorageProvider);
  }

  setUpWSForCacheChanges() {
    this.getDataEntities()?.pipe(first())?.subscribe(() => {
      /*
      // SiteKey= ORG_{orgCode}_S_{siteId}_{topicId}, e.g., device, locations, hybridUser
      // OrgKey= ORG_{orgCode}_{topicId} e.g., UserAccount, Roles,
      // APPSiteKey= ORG_{orgCode}_APP_{appCode}_S_{siteId}_{topicId} e.g., entitySchema, schemaField, etc.,
      */
      this.subscribeOrganizationWiseCacheVersion();
      this.subscribeRunTimeSiteAndAppWiseCacheVersions();
      this.subscribeArtifactSiteAndAppWiseCacheVersions();
    });
  }

  /* DYNAMIC DATA E.g., Mongo tables - Form Data, user task data. */
  private subscribeRunTimeSiteAndAppWiseCacheVersions() {
    const topic = 'AppRuntimeData_CacheVersions';
    const topicCommand = WsTopicSubscriptionHelper.newWsTopicSubscriptionId(topic);
    // Using appTransactionWebSocketService since DYNAMIC DATA is site specific data, which requires siteCode to be appended in topic
    this.appTransactionWebSocketService.subscribeForTopic(topicCommand).subscribe((message: CacheVersionData) => {
      message.dataType = EntityDataTypeEnum.RUN_TIME;
      this.onMessageReceived(message);
    });
  }

  /* STATIC DATA E.g., PG tables (Entity Schema, Page, Fluent Service., etc.,) */
  private subscribeArtifactSiteAndAppWiseCacheVersions() {
    const topic = 'AppArtifactData_CacheVersions';
    const topicCommand = WsTopicSubscriptionHelper.newWsTopicSubscriptionId(topic);
    this.appGlobalWebSocketService.subscribeForTopic(topicCommand).subscribe((message: CacheVersionData) => {
      message.dataType = EntityDataTypeEnum.STATIC;
      this.onMessageReceived(message);
    });
  }

  /* Global Entities: App, Sites, Users, Roles, etc., */
  private subscribeOrganizationWiseCacheVersion() {
    const topic = 'AppArtifactData_CacheVersions';
    const topicCommand = WsTopicSubscriptionHelper.newWsTopicSubscriptionId(topic);
    this.orgGlobalWebSocketService.subscribeForTopic(topicCommand).subscribe((message: CacheVersionData) => {
      message.dataType = EntityDataTypeEnum.STATIC;
      this.onMessageReceived(message);
    });
  }
}
