import { RtNone, RtOption, RtSome } from "../../../utils/option-helper";
import { PageMode } from "../../constants/common-constants";

export class PageStateManager {
  //selectedModel
  private static selectedControlId: RtOption<string> = RtNone();
  // private static selectedEventPublishedControl: RtOption<string[]> = RtNone();
  private static isParamMappingMode: boolean;
  private static selectedControlName: RtOption<string> = RtNone();
  static setSelected(selectedControlId: string, controlName: string) {
    this.selectedControlId = RtSome(selectedControlId);
    this.selectedControlName = RtSome(controlName);
  }

  static getSelected() {
    return this.selectedControlId;
  }

  static getControlName() {
    return this.selectedControlName;
  }

  public static clearSelection() {
    this.selectedControlId = RtNone();
  }
  static setParamMappingMode() {
    this.isParamMappingMode = true;
  }


  static getParamMappingMode() {
    return this.isParamMappingMode;
  }

  public static clearParamMappingMode() {
    this.isParamMappingMode = false;
  }

  private static _pageMode: PageMode;
  public static get pageMode(): PageMode {
    return this._pageMode;
  }
  public static set pageMode(pageMode: PageMode) {
    this._pageMode = pageMode;
  }

  public static isInDesignMode() {
    return this.pageMode === PageMode.DESIGN;
  }
  public static isInRuntimeMode() {
    return this.pageMode === PageMode.RUNTIME;
  }
}
