import { format } from "date-fns";
import { FormFieldFileUpload } from "projects/den-core/src/lib/providers/file-manager-service/file-manager.constants";

export class UtilityHelper {
    static getDateTime(dateFormat: string) {
        return format(new Date(), dateFormat);
    }
    static getUniqueCode(numberOfCharacters: number) {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, numberOfCharacters);
    }
    static getDynamicDateTime(dateField, dateFormat: string) {
        if (dateField) {
            return format(new Date(dateField), dateFormat);
        } else {
            return format(new Date(), dateFormat);
        }
    }
    static resolveExpression(expression: string) {
        try {
            return eval(expression);
        } catch (error) {
            console.log(error?.message);
            return null
        }

    }

    static getLocation(data:{coords :any}) {
        return data?.coords?.latitude ? `_LAT_${data?.coords?.latitude}_LNG_${data?.coords?.longitude}` : '';
    }

    static getModifiedDate(dateFormat :string, data:{fileInfos :FormFieldFileUpload}) {
        if(data?.fileInfos) {
            return format(new Date(data?.fileInfos?.file.lastModified), dateFormat);
        }else{
            return format(new Date(), dateFormat);
        }
    }

    static getFunctionList() {
        return ["getUniqueCode(5)", "getDateTime(yyyy-MM-dd HH:mm:ss)", "getDynamicDateTime(date, yyyy-MM-dd HH:mm:ss)", "resolveExpression(expression)","getModifiedDate(yyyy-MM-dd HH:mm)","getLocation()"];
    }
    static GET_UNIQUE_CODE = "getUniqueCode";

}

