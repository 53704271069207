
export enum DSBindTypeEnum {
  SINGLE = 'Sinlge',
  MULTIPLE = 'Multiple',
}

export enum SingleOrArrayEnum {
  SINGLE = 1,
  ARRAY = 2,
  ARRAY_TABLE = 3,
}

export enum FormTypeEnum {
  ADD = "ADD",
  CRUD = "CRUD",
  VIEW = "VIEW",
}

export class DsEnum {

  static FORM = 'FORM';
  static KPI = 'MVIEW';
  static USERTASK = 'USERTASK';
  static PROCESS = 'PROCESS';
  static PB_BOOKMARK = 'pbBookmarkControls';
}

export enum RenderOwnership {
  DEFAULT_MECHANISM = "default_mechanism",
  PARENT_SPECIFIC_MECHANISM = "parent_specific_mechanism",
}

export enum DsMethodEnum {
  FILTER = 'filter',
  GET_LIST = 'getList',
  GET_BY_ID = 'getById',
  TASK_INFO_BY_ID = 'taskInfoById',

}

export enum SignatureEnum {
  NAME = 'name',
  FILE_URL='fileUrl'
}

export enum FilterConditionEnum {
  IN = 'in',
  NOT_IN = 'notIn',
  NULL = 'null',
  NOT_NULL = 'notNull'
}

export enum ToggleConfigEnum{
  toggleConfigOption = 'Toggle Configuration Options'
}
export enum PropertyDefinitionEnum{
  nestedProperty= 'Nested Property',
  DEFAULT_VALUE_PROPERTY = 'DefaultValue Property'
}