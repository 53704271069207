
import {TxnParams, DataTable, ProcessForeignEntityData, DataCell, DataViewItem, AdditionalEntity, AppEntity, GlobalBaseEntity} from 'projects/den-core/base-models';

export class ActionTaskQuery  implements AdditionalEntity{

    public taskId: string;
	public elementUUID: string;
	public charvakaProcessName: string;
	public version: number;
	public actionTaken: string;
	public actionTakenByName: string;
	public taskInstanceId: string;
	public tcName?: string;
	public dataTable: DataTable;
	public charvakaProcess?: CharvakaProcess;
	public actionTakenBy?: UserAccount;
	public tc?: CharvakaTestCase;

    constructor(){
    }
}
 
export class ActivateCharvakaFluentServiceCommand  implements AdditionalEntity{

    public utilizerName: string;
	public charvakaFluentServiceName: string;
	public utilizer?: CharvakaFluentServiceUtilizer;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
    }
}
 
export class ActivityHistoryResult  implements AdditionalEntity{

    public taskDetailCommand: UserTaskDetail;
	public formUpdate?: FormObjectCommand;
	public modifiedDate: string;

    constructor(){
    }
}
 
export class AddAppPermissionSetCommand  implements AdditionalEntity{

    public permissionSet: AppPermissionSet;
	public permittedOperations: Array<AppPermittedOperation>;

    constructor(){
    }
}
 
export class AddCustomAttributeCmd  implements AdditionalEntity{

    public userPoolId: string;
	public attributes: Array<UserPoolCustomAttribute>;

    constructor(){
    }
}
 
export class AddDevumHostedMicroservicesQuery  implements AdditionalEntity{

    public names: Array<string>;

    constructor(){
    }
}
 
export class AddMicroservicesQuery  implements AdditionalEntity{

    public addMsQueries: Array<AddMsQuery>;

    constructor(){
    }
}
 
export class AddMockInitDataCommand  implements AdditionalEntity{

    public charvakaTestCaseName: string;
	public charvakaEntityName: string;
	public charvakaModuleType: string;
	public data: MockData;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
    }
}
 
export class AddMsQuery  implements AdditionalEntity{

    public customerMicroserviceName: string;
	public microserviceVersion: string;
	public installationId: string;
	public customerMicroservice?: CustomerMicroservice;

    constructor(){
    }
}
 
export class AddOrUpdateDomainCmd  implements AdditionalEntity{

    public userPoolId: string;
	public domain: string;
	public certificateArn?: string;

    constructor(){
    }
}
 
export class AddUserToRoleCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;
	public roleName: string;
	public role?: Role;

    constructor(){
    }
}
 
export class AdminDashboardDetails  implements AdditionalEntity{

    public appOverview: ApplicationsOverview;
	public siteOverview: SitesOverview;
	public usersOverview: UsersOverview;
	public devumObjectsOverview: DevumObjectsOverview;

    constructor(){
    }
}
 
export class AdminSetPasswordCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;
	public newPassword: string;
	public isTemporary: boolean;

    constructor(){
    }
}
 
export class AdvancedEntitySchemaSetting extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public enableExpiry: boolean;
	public expireDuration?: number;
	public expireDurationType?: string;
	public enableCache: boolean;
	public cacheDuration?: number;
	public cacheDurationType?: string;
	public enableArchiving: boolean;
	public archiveDuration?: number;
	public archiveDurationType?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public uniqueFields?: string;
	public entitySchema?: EntitySchema;

    constructor(){
       super();
    }
}
 
export class AdvancedSchemaFieldSetting extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public entitySchemaFieldName: string;
	public referenceSchema?: string;
	public referenceField?: string;
	public regex?: string;
	public defaultValue?: string;
	public isReadOnlyOnInsert: boolean;
	public isReadOnlyOnUpdate: boolean;
	public isUnique: boolean;
	public dbIndex: boolean;
	public enableEncryption: boolean;
	public mViewReducerType?: string;
	public apiName?: string;
	public apiParamsFields: Array<string>;
	public apiSubFieldType?: string;
	public mappingFieldName?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public isArray: boolean;
	public entitySchema?: EntitySchema;
	public entitySchemaField?: EntitySchemaField;
	public mappingField?: EntitySchemaField;

    constructor(){
       super();
    }
}
 
export class AffectedSequence  implements AdditionalEntity{

    public functionCompositionId: string;
	public functionName: string;
	public newSequenceNumber: number;

    constructor(){
    }
}
 
export class AggregateEntityStatus  implements AdditionalEntity{

    public entityName: string;
	public isDraftState: boolean;
	public changesAvailable: number;

    constructor(){
    }
}
 
export class Aggregateable  implements AdditionalEntity{

    public aggregateField: string;
	public aggregateMethod: string;
	public groupableFieldName?: string;
	public groupableFieldName2?: string;
	public canAggregateTimeSeries: boolean;
	public canAggregateMultiMView: boolean;

    constructor(){
    }
}
 
export class AllFileInfoWithURL  implements AdditionalEntity{

    public files: Array<DevumFileDescription>;

    constructor(){
    }
}
 
export class AllInstanceStatusResult  implements AdditionalEntity{

    public instanceStatusList: Array<InstanceStatus>;

    constructor(){
    }
}
 
export class AllProcessInstanceByProcessName  implements AdditionalEntity{

    public processName: string;
	public processInstanceId: string;
	public version: number;
	public status: string;
	public errorMessageOpt?: string;
	public processCreateTime: string;

    constructor(){
    }
}
 
export class AnyRefValue  implements AdditionalEntity{

    public data: Object;

    constructor(){
    }
}
 
export class AnyRefValueList  implements AdditionalEntity{

    public data: Array<Object>;

    constructor(){
    }
}
 
export class AppArtifactCompared  implements AdditionalEntity{

    public id: string;
	public name: string;
	public artifactType: string;
	public statuses: Array<ArtifactStatus>;
	public comparisonResultType: string;
	public parentAppArtifactIdentifier?: string;
	public entityClazzName: string;
	public downloadedSummary?: string;
	public deployedSummary?: string;
	public overrideOnDeploymentValue?: OverrideOnDeploymentValue;

    constructor(){
    }
}
 
export class AppArtifactSummary  implements AdditionalEntity{

    public stats: AppComparerStats;
	public session: DeploymentProcessSessionImpl;
	public version: string;
	public templateAppCode: string;
	public templateOrgCode: string;
	public siteCodes: Array<string>;
	public templateName: string;
	public isWithMangoData: boolean;

    constructor(){
    }
}
 
export class AppArtifactUI  implements AdditionalEntity{

    public id: string;
	public name: string;
	public artifactType: string;
	public parentAppArtifactIdentifier?: string;

    constructor(){
    }
}
 
export class AppArtifactUpdated  implements AdditionalEntity{

    public id: string;
	public overrideOnDeploymentValue: OverrideOnDeploymentValue;

    constructor(){
    }
}
 
export class AppClientCreationResult  implements AdditionalEntity{

    public clientId: string;

    constructor(){
    }
}
 
export class AppClientSettingsConfigUiPayload  implements AdditionalEntity{

    public appUserpoolMappingId: string;
	public appClientId: string;
	public userPoolId: string;
	public identifierIds: Array<string>;
	public callbackUrl: Array<string>;
	public oAuthFlowType: Array<string>;
	public allowedOAuthScopes: Array<string>;
	public refreshTokenValidity: TokenValidity;
	public accessTokenValidity: TokenValidity;
	public idTokenValidity: TokenValidity;
	public enableTokenRevocation: boolean;
	public clientName: string;

    constructor(){
    }
}
 
export class AppClientSignInURLMapping  implements AdditionalEntity{

    public clientId: string;
	public clientName: string;
	public url: string;

    constructor(){
    }
}
 
export class AppClientSignInURLMappingContainer  implements AdditionalEntity{

    public mapping: Array<AppClientSignInURLMapping>;

    constructor(){
    }
}
 
export class AppCodeCmd  implements AdditionalEntity{

    public appCode: string;

    constructor(){
    }
}
 
export class AppCodeResult  implements AdditionalEntity{

    public appCode: string;

    constructor(){
    }
}
 
export class AppComparerStats  implements AdditionalEntity{

    public newArtifactsSize: number;
	public updatedArtifactsSize: number;
	public deletedArtifactsSize: number;
	public unchangedArtifactsSize: number;

    constructor(){
    }
}
 
export class AppDeploymentHistory extends GlobalBaseEntity {

    public id: string;
	public appCode: string;
	public version: string;
	public templateType: string;
	public status: string;
	public releaseNotes?: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
       super();
    }
}
 
export class AppDynamicServiceDetail  implements AdditionalEntity{

    public dynamicEntityIdentifier: string;
	public dynamicEntityName: string;
	public labels: Array<string>;

    constructor(){
    }
}
 
export class AppDynamicServiceDetailContainer  implements AdditionalEntity{

    public group: string;
	public services: Array<AppDynamicServiceDetail>;
	public supportedOperationTypes: Array<SupportedOperationType>;
	public appCode: string;

    constructor(){
    }
}
 
export class AppEntityCountContainer  implements AdditionalEntity{

    public appArtifactName: string;
	public totalNoOfDrafts: number;
	public aggregateAppEntityIdentifier: string;

    constructor(){
    }
}
 
export class AppEntityPublishedIdentifier  implements AdditionalEntity{

    public appArtifactType: string;
	public appArtifactName: string;
	public appEntityId: string;
	public appCode: string;

    constructor(){
    }
}
 
export class AppPermissionSet extends GlobalBaseEntity {

    public id: string;
	public group: string;
	public dynamicEntityIdentifier: string;
	public userName?: string;
	public roleName?: string;
	public appCode: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public role?: Role;

    constructor(){
       super();
    }
}
 
export class AppPermissionSetContainer  implements AdditionalEntity{

    public appPermissionSet: AppPermissionSet;
	public appPermittedOperations: Array<AppPermittedOperation>;

    constructor(){
    }
}
 
export class AppPermissionSetsByAppCodeQuery  implements AdditionalEntity{

    public appCode: string;

    constructor(){
    }
}
 
export class AppPermissionSetsByUserAccountQuery  implements AdditionalEntity{

    public userName: string;
	public appCode: string;
	public user?: UserAccount;

    constructor(){
    }
}
 
export class AppPermittedOperation extends GlobalBaseEntity {

    public id: string;
	public permissionSetIdentifier: string;
	public appPermittedOperationType: string;
	public apiName: string;
	public appCode: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
       super();
    }
}
 
export class AppPrecis  implements AdditionalEntity{

    public id: string;
	public name: string;
	public appCode: string;
	public isDefault: boolean;
	public clientLogo?: string;
	public themes?: string;

    constructor(){
    }
}
 
export class AppRegistry extends GlobalBaseEntity {

    public id: string;
	public name: string;
	public description?: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public appCode: string;
	public modifiedDate: string;
	public deployedVersion?: string;
	public clientLogo?: string;
	public partnerLogo?: string;
	public appUrl?: string;
	public deployedStatus?: string;

    constructor(){
       super();
    }
}
 
export class AppSettings extends GlobalBaseEntity {

    public id: string;
	public settings: Object;
	public appCode: string;
	public createdBy: string;
	public enabled: boolean;
	public createDate: string;
	public attributes: Object;

    constructor(){
       super();
    }
}
 
export class AppSiteCountContainer  implements AdditionalEntity{

    public appName: number;
	public sitesCount: number;
	public app?: AppRegistry;

    constructor(){
    }
}
 
export class AppSiteMapping  implements AdditionalEntity{

    public apps: Array<AppPrecis>;
	public sites: Array<SitePrecis>;

    constructor(){
    }
}
 
export class AppUserAndSiteCountUIResponse  implements AdditionalEntity{

    public userName: string;
	public emailOpt?: string;
	public siteMappingCount: number;
	public sitesInvolved: Array<UserAccountSiteMapping>;
	public rolePrecisList: Array<RolePrecis>;
	public userRoleCount: number;
	public appMappings: Array<UserAccountAppMapping>;
	public appMappingCount: number;
	public devOrAppUserType: string;
	public isEnabled: boolean;
	public createDate: string;
	public isFirstAdmin: boolean;
	public photoUrl?: string;
	public user?: UserAccount;

    constructor(){
    }
}
 
export class AppUserPoolMapping extends GlobalBaseEntity {

    public id: string;
	public appCode?: string;
	public appClientId: string;
	public identifierIds: Array<string>;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public clientType: string;
	public authDomain: string;
	public callbackUrl: Array<string>;
	public isSelected: boolean;

    constructor(){
       super();
    }
}
 
export class AppUserpoolMappingInfo  implements AdditionalEntity{

    public id: string;
	public appCode?: string;
	public userPoolId: string;
	public appClientId: string;
	public appClientSecret?: string;
	public identifierIds: Array<string>;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public clientType: string;
	public authDomain: string;
	public callbackUrl: Array<string>;

    constructor(){
    }
}
 
export class AppUserpoolMappingUIPayload  implements AdditionalEntity{

    public appName: string;
	public poolId: string;
	public identifierIds: Array<string>;
	public clientName: string;
	public refreshTokenValidity: TokenValidity;
	public accessTokenValidity: TokenValidity;
	public idTokenValidity: TokenValidity;
	public generateSecret: boolean;
	public enableTokenRevocation: boolean;
	public app?: AppRegistry;

    constructor(){
    }
}
 
export class ApplicationDetails  implements AdditionalEntity{

    public appName: string;
	public appCode: string;
	public description?: string;
	public applicationUsers: number;
	public developers: number;
	public devumObjects: number;
	public lastVersion?: string;
	public domainURL: string;
	public createdAt: string;
	public lastUpdatedAt: string;
	public activeObjects: number;
	public inActiveObjects: number;
	public appLogo?: string;
	public enabled: boolean;

    constructor(){
    }
}
 
export class ApplicationsOverview  implements AdditionalEntity{

    public createdApps: number;
	public activeApps: number;
	public inActiveApps: number;
	public maxAllowedApps: number;

    constructor(){
    }
}
 
export class ApplyAttributeMappingPayloadUI  implements AdditionalEntity{

    public userPoolId: string;
	public providerName: string;
	public attributeMappings: Object;

    constructor(){
    }
}
 
export class ApplyUICustomizationUiPayload  implements AdditionalEntity{

    public userPoolId: string;
	public clientId?: string;
	public css?: string;
	public logoPath?: string;

    constructor(){
    }
}
 
export class ArtifactStatus  implements AdditionalEntity{

    public msg: string;
	public comparerType: string;

    constructor(){
    }
}
 
export class ArtifactUsageUIContainer  implements AdditionalEntity{

    public usageEvaluation: string;
	public entityUsagesUI: Array<EntityUsageUI>;

    constructor(){
    }
}
 
export class AssetTemplateFileName  implements AdditionalEntity{

    public originalName: string;
	public uniqueName: string;

    constructor(){
    }
}
 
export class AuditDetailsFilterResult  implements AdditionalEntity{

    public totalResults: number;
	public results: any;

    constructor(){
    }
}
 
export class AuditTrail extends GlobalBaseEntity {

    public id: string;
	public entityName: string;
	public entityPKId?: string;
	public entityPKName?: string;
	public data?: string;
	public operationType: string;
	public identityType: string;
	public createdBy: string;
	public enabled: boolean;
	public siteCode?: string;
	public appCode?: string;
	public modifiedBy: string;
	public modifiedDate: string;
	public createDate: string;
	public location?: string;
	public ipAddress?: string;
	public userAgent?: string;
	public attributes: Object;

    constructor(){
       super();
    }
}
 
export class AuthenticationExceptionInfo  implements AdditionalEntity{

    public code: number;
	public message: string;

    constructor(){
    }
}
 
export class AuthenticationStatus  implements AdditionalEntity{

    public isValidUser: boolean;
	public userName: string;
	public identityType: string;
	public devOrAppUserType: string;
	public user?: UserAccount;

    constructor(){
    }
}
 
export class AuthenticationSuccessResult  implements AdditionalEntity{

    public token: string;
	public userName: string;
	public userId: string;
	public orgCode: string;
	public userSites: Array<SitePrecis>;
	public userApps: Array<AppPrecis>;
	public exceptions: Array<AuthenticationExceptionInfo>;
	public roles: Array<RolePrecis>;
	public devOrAppUserType: string;
	public user?: UserAccount;

    constructor(){
    }
}
 
export class AutoResolveField  implements AdditionalEntity{

    public fieldName: string;
	public fieldExpression: string;

    constructor(){
    }
}
 
export class AvailableUpdatesResponse  implements AdditionalEntity{

    public appCode: string;
	public currentAppVersion: string;
	public availableUpdates: Array<DeploymentThumbnailDetail>;

    constructor(){
    }
}
 
export class BackendActionFilterResult  implements AdditionalEntity{

    public totalResults: number;
	public results: Array<BackendActionFilterResultItem>;

    constructor(){
    }
}
 
export class BackendActionFilterResultItem  implements AdditionalEntity{

    public id: string;
	public record: string;

    constructor(){
    }
}
 
export class BackendActionGroup  implements AdditionalEntity{

    public typeName: string;
	public groups: Array<string>;

    constructor(){
    }
}
 
export class BackendActionGroupContainer  implements AdditionalEntity{

    public groups: Array<BackendActionGroup>;

    constructor(){
    }
}
 
export class BackendActionResult  implements AdditionalEntity{

    public typeName: string;
	public group: string;
	public name: string;
	public methods: Array<MethodResult>;
	public wsTopicResult: Array<WsTopicResult>;
	public implementationStatus?: boolean;

    constructor(){
    }
}
 
export class BackendActionServicesResult  implements AdditionalEntity{

    public groups: Array<BackendActionResult>;

    constructor(){
    }
}
 
export class BackendActionToMViewConverterCmd  implements AdditionalEntity{

    public backendActionName: string;
	public mViewName: string;
	public mViewServiceName: string;
	public label: string;
	public splitPeriod: string;
	public isArrayResponse: boolean;

    constructor(){
    }
}
 
export class Base64Images  implements AdditionalEntity{

    public image: string;

    constructor(){
    }
}
 
export class BasicFluentServiceBridge  implements AdditionalEntity{

    public utilizerName: string;
	public propertyContainer?: FluentServicePropertyContainer;
	public returnSchema: FluentServiceProperty;

    constructor(){
    }
}
 
export class BindingDetail  implements AdditionalEntity{

    public bindingType: string;
	public dependentProperties: Array<string>;

    constructor(){
    }
}
 
export class BooleanValue  implements AdditionalEntity{

    public data: boolean;

    constructor(){
    }
}
 
export class BotAbortStatementCommand  implements AdditionalEntity{

    public statement: string;
	public contentType: string;

    constructor(){
    }
}
 
export class BotCodeHookCommand  implements AdditionalEntity{

    public uri: string;
	public messageVersion: string;

    constructor(){
    }
}
 
export class BotConclusionStatementCommand  implements AdditionalEntity{

    public statement: string;
	public statementContentType: string;

    constructor(){
    }
}
 
export class BotContentTypeHolder  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class BotFulfilmentTypeHolder  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class BotLocaleHolder  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class BotProcessBehaviourHolder  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class BotSlotTypeHolder  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class BotVoiceNameHolder  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class BoundLocation  implements AdditionalEntity{

    public x: number;
	public y: number;
	public z?: number;

    constructor(){
    }
}
 
export class BoundedContext extends AppEntity {

    public id: string;
	public name: string;
	public description?: string;
	public icon?: string;
	public parentContextName?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public label: string;
	public parentContext?: BoundedContext;

    constructor(){
       super();
    }
}
 
export class BoundedContextSchemaMapping extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public boundedContextName: string;
	public positionX: number;
	public positionY: number;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public entitySchema?: EntitySchema;
	public boundedContext?: BoundedContext;

    constructor(){
       super();
    }
}
 
export class BridgeEntityCommand  implements AdditionalEntity{

    public charvakaFluentServiceName: string;
	public utilizerName?: string;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
    }
}
 
export class CFSBackendActionUtilizerBridge  implements AdditionalEntity{

    public utilizerName: string;
	public typeName: string;
	public groupName: string;
	public serviceName: string;
	public supportedType: string;
	public methodType: string;
	public methodInputParams: DataItem;
	public methodReturnResultTypes: DataItem;
	public wsTopic?: string;
	public aggregatorStrategy?: string;

    constructor(){
    }
}
 
export class CSVTemplateDownloadCommand  implements AdditionalEntity{

    public entitySchemaName: string;
	public entitySchemaFieldNames: Array<string>;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class CacheVersion extends GlobalBaseEntity {

    public id: string;
	public entityName: string;
	public version: number;
	public enabled: boolean;
	public createDate: string;
	public siteCode?: string;
	public appCode?: string;
	public isStatic: boolean;
	public createdBy: string;
	public attributes: Object;

    constructor(){
       super();
    }
}
 
export class CalendarRecurrenceType  implements AdditionalEntity{

    public months: Array<string>;
	public endDate: string;
	public days?: Array<string>;
	public dates?: Array<number>;

    constructor(){
    }
}
 
export class CancelBuildUIPayload  implements AdditionalEntity{

    public appName: string;
	public status: string;
	public versionNumber: number;
	public packageName: string;
	public authToken: string;
	public buildId: string;

    constructor(){
    }
}
 
export class CancelProcessCommand  implements AdditionalEntity{

    public processId: string;
	public versionNumber: number;
	public instanceId: string;

    constructor(){
    }
}
 
export class CesiumKeyValueContainer  implements AdditionalEntity{

    public cesiumToken: string;

    constructor(){
    }
}
 
export class ChangeConfiguredValueCommand  implements AdditionalEntity{

    public name: string;
	public fullPath: string;
	public value: string;

    constructor(){
    }
}
 
export class ChangeSettingModeCommand  implements AdditionalEntity{

    public name: string;
	public fullPath: string;
	public mode: number;

    constructor(){
    }
}
 
export class CharvakaClaimableUserAndRoleConfiguration extends AppEntity {

    public id: string;
	public charvakaProcessName: string;
	public elementUUID: string;
	public candidateUsers: Array<string>;
	public candidateRoles: Array<string>;
	public hybridUsers: Array<string>;
	public formData: string;
	public siteCode: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
       super();
    }
}
 
export class CharvakaFluentFunctionComposition extends AppEntity {

    public id: string;
	public variableName: string;
	public functionClass: string;
	public sequenceNumber: number;
	public charvakaFluentServiceName: string;
	public dependentVariableFunctionIdentifiers: Array<string>;
	public properties: string;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public sortOrder?: number;
	public parentId?: string;
	public secondaryParentId?: string;
	public className: string;
	public functionType: string;
	public microServiceName?: string;
	public microserviceVersion?: string;
	public returnType?: string;
	public isCommented: boolean;
	public debugger: boolean;
	public previousFunctionId?: string;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
       super();
    }
}
 
export class CharvakaFluentFunctionCompositionCommand  implements AdditionalEntity{

    public id: string;
	public variableName: string;
	public functionClass: string;
	public sequenceNumber: number;
	public charvakaFluentServiceName: string;
	public dependentVariableFunctionIds: Array<string>;
	public properties: Array<FluentServiceProperty>;
	public attributes: Object;
	public labels: Array<string>;
	public appCode: string;
	public parentId?: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public modifiedDate: string;
	public secondaryParentId?: string;
	public className: string;
	public functionType: string;
	public microServiceName?: string;
	public microserviceVersion?: string;
	public returnType?: DataItem;
	public isCommented: boolean;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
    }
}
 
export class CharvakaFluentFunctionUI  implements AdditionalEntity{

    public name: string;
	public iconPath?: string;
	public description?: string;
	public groupName: string;
	public properties: Array<FluentServiceProperty>;
	public returnProp: FluentServiceProperty;
	public isOptional: boolean;
	public colorCode: string;
	public canAllowChildren: boolean;
	public className: string;
	public functionType: string;
	public showReturnVariable: boolean;
	public labels: Array<string>;
	public microserviceName?: string;
	public microserviceVersion?: string;
	public functionDataAdapter?: string;

    constructor(){
    }
}
 
export class CharvakaFluentService extends AppEntity {

    public id: string;
	public name: string;
	public label: string;
	public description?: string;
	public serviceUtilizerType: string;
	public utilizerName?: string;
	public propertyContainer: string;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public sortOrder?: number;
	public isSeed: boolean;

    constructor(){
       super();
    }
}
 
export class CharvakaFluentServiceDetails  implements AdditionalEntity{

    public id: string;
	public name: string;
	public utilizerType: string;
	public utilizerName?: string;
	public isActive: boolean;

    constructor(){
    }
}
 
export class CharvakaFluentServicePrecise  implements AdditionalEntity{

    public id: string;
	public name: string;
	public serviceUtilizerType: string;
	public utilizerName?: string;

    constructor(){
    }
}
 
export class CharvakaFluentServiceUtilizer extends AppEntity {

    public id: string;
	public charvakaFluentServiceName: string;
	public utilizerType: string;
	public fsUtilizerName: string;
	public isActive: boolean;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public secondaryUtilizerId?: string;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
       super();
    }
}
 
export class CharvakaFluentServiceUtilizerContainer  implements AdditionalEntity{

    public name: string;
	public charvakaFluentServiceName: string;
	public fsUtilizerType: string;
	public fsUtilizerName: string;
	public isActive: boolean;
	public properties: string;
	public secondryUtilizerId?: string;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
    }
}
 
export class CharvakaIdentifierUI  implements AdditionalEntity{

    public charvakaModuleType: string;
	public charvakaEntityId: string;

    constructor(){
    }
}
 
export class CharvakaMonitoringMessage  implements AdditionalEntity{

    public msg: string;
	public instanceId: string;
	public elementUUID?: string;
	public ts: string;

    constructor(){
    }
}
 
export class CharvakaProcess extends AppEntity {

    public id: string;
	public name: string;
	public label: string;
	public description?: string;
	public triggerEventName: string;
	public initiatorUsers: Array<string>;
	public initiatorRoles: Array<string>;
	public icon?: string;
	public triggerOnRecordInsert: boolean;
	public retryIntervalInSeconds?: number;
	public sortOrder?: number;
	public enabled: boolean;
	public modifiedDate: string;
	public labels: Array<string>;
	public useInMemoryPlugin: boolean;
	public isSeed: boolean;

    constructor(){
       super();
    }
}
 
export class CharvakaProcessDefinition extends AppEntity {

    public id: string;
	public charvakaProcessName: string;
	public version: number;
	public json: string;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
       super();
    }
}
 
export class CharvakaProcessDetailResult  implements AdditionalEntity{

    public charvakaProcessName: string;
	public triggerEventName: string;
	public initiatorUsers: Array<string>;
	public initiatorRoles: Array<string>;
	public json: string;
	public version: number;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class CharvakaProcessDynamicUsersConfiguration extends AppEntity {

    public id: string;
	public charvakaProcessName: string;
	public elementUUID: string;
	public candidateUsers: Array<string>;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public siteCode: string;
	public hybridUserIdentifiers: Array<string>;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
       super();
    }
}
 
export class CharvakaProcessElementRef  implements AdditionalEntity{

    public sequenceNumber: number;
	public iconClass: string;
	public elementName: string;
	public elementUUID: string;
	public elementType: string;
	public parentElementUUid?: string;
	public boundLocation?: string;
	public x?: number;
	public y?: number;
	public z?: number;
	public loopingElementUUid: Array<String>;

    constructor(){
    }
}
 
export class CharvakaProcessElementRefPrecis  implements AdditionalEntity{

    public sequenceNumber: number;
	public className: string;
	public elementName: string;
	public elementUUID: string;
	public elementType: string;
	public boundLocations: Object;
	public parentElementUUid?: string;
	public group?: string;
	public iconName?: string;
	public loopingElementUUid: Array<String>;

    constructor(){
    }
}
 
export class CharvakaProcessMenuQuery  implements AdditionalEntity{

    public charvakaProcessName: string;
	public version: number;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class CharvakaProcessSpatialConfiguration extends AppEntity {

    public id: string;
	public charvakaProcessName: string;
	public type: string;
	public layerEntityName: string;
	public layerEntityType: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
       super();
    }
}
 
export class CharvakaProcessUserRoleContainer  implements AdditionalEntity{

    public id: string;
	public charvakaProcessName: string;
	public elementUUID: string;
	public siteCode: string;
	public candidateUsers: Array<string>;
	public candidateRoles: Array<string>;
	public propertyName?: string;
	public hybridUserIdentifiers: Array<string>;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class CharvakaProcessUserRolesConfiguration extends AppEntity {

    public id: string;
	public charvakaProcessName: string;
	public elementUUID: string;
	public candidateUsers: Array<string>;
	public candidateRoles: Array<string>;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public siteCode: string;
	public propertyName?: string;
	public hybridUserIdentifiers: Array<string>;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
       super();
    }
}
 
export class CharvakaTestCase extends AppEntity {

    public id: string;
	public charvakaEntityName: string;
	public name: string;
	public description: string;
	public testCaseMode: string;
	public enabled: boolean;
	public isRunning: boolean;
	public isIntegrationTest: boolean;
	public canSaveToActualDB: boolean;
	public charvakaModuleType: string;
	public label: string;

    constructor(){
       super();
    }
}
 
export class CharvakaTestCaseDetail  implements AdditionalEntity{

    public id: string;
	public appCode: string;
	public charvakaEntityName: string;
	public charvakaModuleType: string;
	public charvakaTestCaseName: string;
	public mockInitData?: MockData;
	public mockSimulatedInitDataSettings?: MockSimulatedInitDataSettings;
	public createDate: string;
	public createdBy: string;
	public enabled: boolean;
	public charvakaTestCase?: CharvakaTestCase;
	public serviceStubs?: Array<ServiceStub>;
	public asserts?: Array<VariableAssert>;

    constructor(){
    }
}
 
export class CharvakaTestCaseDetailPersistable extends AppEntity {

    public id: string;
	public charvakaEntityName: string;
	public charvakaTestCaseName: string;
	public mockInitData?: string;
	public serviceStubs?: string;
	public mockSimulatedInitDataSettings?: string;
	public asserts?: string;
	public enabled: boolean;
	public charvakaModuleType: string;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
       super();
    }
}
 
export class CharvakaTestCaseIdentifier  implements AdditionalEntity{

    public charvakaEntityId: string;
	public charvakaModuleType: string;

    constructor(){
    }
}
 
export class CharvakaUserRoleSiteCommand  implements AdditionalEntity{

    public elementUUID: string;
	public charvakProcessName: string;
	public siteCode: string;
	public appCode: string;
	public orgCode: string;

    constructor(){
    }
}
 
export class ClaimTaskQuery  implements AdditionalEntity{

    public taskId: string;
	public claimedUserName: string;
	public taskInstanceId: string;
	public tcName?: string;
	public charvakaProcessName: string;
	public version: number;
	public elementUUID: string;
	public dataTable: DataTable;
	public claimedTime: string;
	public claimedUser?: UserAccount;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ClaimedTaskNotificationResult  implements AdditionalEntity{

    public taskDetails: UserTaskDetail;
	public claimedBy: string;

    constructor(){
    }
}
 
export class ClarificationPromptCommand  implements AdditionalEntity{

    public messages: Array<ClarificationPromptMessageCommand>;
	public maxAttempts: number;

    constructor(){
    }
}
 
export class ClarificationPromptMessageCommand  implements AdditionalEntity{

    public message: string;
	public contentType: string;

    constructor(){
    }
}
 
export class CodeMagicTriggerBuildResponse  implements AdditionalEntity{

    public versionNumber?: number;
	public fileType?: string;
	public buildId: string;

    constructor(){
    }
}
 
export class CodeMagicTriggerBuildStatusUiPayload  implements AdditionalEntity{

    public packageName: string;
	public versionNumber: number;
	public authToken: string;
	public buildId: string;

    constructor(){
    }
}
 
export class CollectionUserTaskComplexProperty  implements AdditionalEntity{

    public canCreateUserTaskPerItem: boolean;
	public canCreateEntireListAsOneUserTask: boolean;
	public groupByField?: string;
	public overridingUserTaskHeader?: string;
	public headerVariableName?: string;

    constructor(){
    }
}
 
export class CommunicateWithLexBotCommand  implements AdditionalEntity{

    public botName: string;
	public botAliasId: string;
	public userId: string;
	public userInput: string;

    constructor(){
    }
}
 
export class CommunicateWithLexBotResponseCommand  implements AdditionalEntity{

    public response: string;

    constructor(){
    }
}
 
export class ComposerGroup extends AppEntity {

    public id: string;
	public name: string;
	public description?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public sortOrder?: number;
	public label: string;

    constructor(){
       super();
    }
}
 
export class ConditionBlock  implements AdditionalEntity{

    public condition: string;
	public functionCompositionName: string;
	public sequenceNumber: number;
	public secondaryParentIdentifier: string;
	public conditionBlockType: string;

    constructor(){
    }
}
 
export class ConditionBlockContainer  implements AdditionalEntity{

    public conditions: Array<ConditionBlock>;

    constructor(){
    }
}
 
export class ConditionDetail  implements AdditionalEntity{

    public dependentPropertyValues: Array<PropertyValueDetail>;

    constructor(){
    }
}
 
export class ConfirmAppCreationUsingTemplateCommand  implements AdditionalEntity{

    public deploymentSessionId: string;
	public templateAppCode: string;
	public templateType: string;
	public orgCode: string;
	public sitesForTransactionData: Array<string>;
	public solutionArtifactUpdatedList: Array<AppArtifactUpdated>;
	public version: string;
	public appRegistry: AppRegistry;
	public templateOrgCode: string;
	public s3BackupRequired: boolean;

    constructor(){
    }
}
 
export class ConfirmationPromptCommand  implements AdditionalEntity{

    public promptMessage: Array<ConfirmationPromptMessageCommand>;
	public maximumRetriesMaximumRetries: number;

    constructor(){
    }
}
 
export class ConfirmationPromptMessageCommand  implements AdditionalEntity{

    public message: string;
	public contentType: string;

    constructor(){
    }
}
 
export class Connection  implements AdditionalEntity{

    public in: Array<string>;
	public out: Array<string>;

    constructor(){
    }
}
 
export class ConsoleAdminResponse  implements AdditionalEntity{

    public response: Array<string>;

    constructor(){
    }
}
 
export class ConsumerTaskOrchestrator extends GlobalBaseEntity {

    public id: string;
	public topicId: string;
	public instanceId: string;
	public elementInstanceId: string;
	public waitingSince: string;
	public processCreateTime: string;
	public processId: string;
	public versionNo: number;
	public siteCode: string;
	public appCode: string;
	public enabled: boolean;
	public attributes: Object;
	public createDate: string;
	public createdBy: string;

    constructor(){
       super();
    }
}
 
export class ContinuousProcessDetailContainer  implements AdditionalEntity{

    public json: string;
	public version: number;
	public charvakaProcessName: string;
	public startedAt?: string;
	public stoppedAt?: string;
	public entitySchemaName?: string;
	public name: string;
	public status: number;
	public charvakaProcess?: CharvakaProcess;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class ContinuousProcessInvocationCommand  implements AdditionalEntity{

    public processId: string;
	public version: number;

    constructor(){
    }
}
 
export class ControlAttributeInstance extends AppEntity {

    public id: string;
	public name: string;
	public attributeType: string;
	public value: string;
	public controlInstanceId: string;
	public pageName: string;
	public enabled: boolean;
	public page?: Page;

    constructor(){
       super();
    }
}
 
export class ControlCondition  implements AdditionalEntity{

    public propertyName: string;
	public condition: string;
	public comparingValue: string;

    constructor(){
    }
}
 
export class ControlInstance extends AppEntity {

    public id: string;
	public instanceId: string;
	public parentInstanceId?: string;
	public ctrlType: string;
	public parentSectionIndex: number;
	public controlName: string;
	public pageName: string;
	public selectedDataSourceServiceInstanceId?: string;
	public dsDependentControlInstanceId?: string;
	public allAttributeValues: string;
	public propertyDefinitions?: string;
	public controlConditionGroup: string;
	public enabled: boolean;
	public name: string;
	public joinStrategy?: string;
	public sequenceNo: number;
	public page?: Page;

    constructor(){
       super();
    }
}
 
export class ConvertTestCaseToSimulationCommand  implements AdditionalEntity{

    public charvakaTestCaseName: string;
	public charvakaEntityName: string;
	public charvakaModuleType: string;
	public settings: MockSimulatedInitDataSettings;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
    }
}
 
export class CreateAppDeploymentCommand  implements AdditionalEntity{

    public deploymentName: string;
	public deploymentAppCode: string;
	public version: string;
	public templateType: string;
	public releaseNotes?: string;
	public orgCode: string;
	public svgIcon: string;
	public isTransactionDataRequired: boolean;
	public sitesForTransactionData: Array<string>;

    constructor(){
    }
}
 
export class CreateAppUsingTemplateCommand  implements AdditionalEntity{

    public templateAppCode: string;
	public orgCode: string;
	public solutionArtifactUpdatedList: Array<AppArtifactUpdated>;
	public version: string;
	public templateType: string;

    constructor(){
    }
}
 
export class CreateBotCommand  implements AdditionalEntity{

    public botName: string;
	public botAlias: string;
	public description: string;
	public locale: string;
	public withChildDirected: boolean;
	public withDetectSentiment: boolean;
	public voiceId?: string;
	public idleSessionTimeOutInSeconds: number;
	public abortStatements: Array<BotAbortStatementCommand>;
	public clarificationPrompt: ClarificationPromptCommand;
	public processBehavior: string;
	public tags: Object;
	public createIntentCommands: Array<CreateIntentCommand>;

    constructor(){
    }
}
 
export class CreateIntentCommand  implements AdditionalEntity{

    public intentName: string;
	public utterance: Array<string>;
	public conclusionStatement: Array<BotConclusionStatementCommand>;
	public confirmationPrompt: ConfirmationPromptCommand;
	public rejectionStatements: Array<RejectionStatementCommand>;
	public followUpPrompts: FollowUpPromptCommand;
	public fulfillmentActivityCommand: FulfillmentActivityCommand;
	public createSlotCommands: Array<CreateSlotCommand>;
	public botDialogCodeHookCommand?: BotCodeHookCommand;

    constructor(){
    }
}
 
export class CreateNewAppClientCmd  implements AdditionalEntity{

    public userPoolId: string;
	public appCode: string;

    constructor(){
    }
}
 
export class CreateSlotCommand  implements AdditionalEntity{

    public slotName: string;
	public description: string;
	public slotType: string;
	public slotTypeVersion: string;
	public slotConstraint: string;
	public slotUtterances: Array<string>;
	public slotPromptMessageCommand: Array<SlotPromptMessageCommand>;
	public maxAttempts: number;

    constructor(){
    }
}
 
export class CreateTemplateUsingAppCommand  implements AdditionalEntity{

    public templateName: string;
	public templateAppCode: string;
	public version: string;
	public templateType: string;
	public description: string;
	public templateSiteCode: string;
	public orgCode: string;
	public appName: string;
	public svgIcon?: string;
	public isTransactionRequired: boolean;
	public sitesForTransactionData: Array<string>;

    constructor(){
    }
}
 
export class CreateUserCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;
	public temporaryPassword: string;
	public isEmailVerified: boolean;
	public email?: string;
	public isPhoneNumberVerified: boolean;
	public phoneNumber?: string;
	public deliveryMedium: string;
	public photoUrl?: string;

    constructor(){
    }
}
 
export class CurrentTriggerBuildStatusUiResponse  implements AdditionalEntity{

    public devumStatus: string;
	public downloadAppUrl?: string;
	public buildId: string;

    constructor(){
    }
}
 
export class CustomPermission extends AppEntity {

    public id: string;
	public name: string;
	public description?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
       super();
    }
}
 
export class CustomerMicroservice extends GlobalBaseEntity {

    public id: string;
	public msName: string;
	public microserviceVersion: string;
	public globalVariables?: string;
	public deploymentName: string;
	public installationId: string;
	public isActive: boolean;
	public isRegistered: boolean;
	public registrationCode?: string;
	public isExternal?: boolean;
	public host: string;
	public port: number;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public microServiceType?: string;
	public msDeploymentOrgCode: string;
	public msDeploymentEnv: string;
	public shortDescription: string;
	public summary: string;
	public iconPath: string;

    constructor(){
       super();
    }
}
 
export class CustomerMicroservicePrecisUI  implements AdditionalEntity{

    public id: string;
	public name: string;
	public microserviceVersion: string;
	public installationId: string;
	public globalVariables: Array<GlobalVariableProperty>;
	public isActive: boolean;
	public isRegistered: boolean;
	public shortDescription: string;
	public summary: string;
	public iconPath: string;
	public enabled: boolean;

    constructor(){
    }
}
 
export class CustomerSubscription extends GlobalBaseEntity {

    public isExternalSubscription: boolean;
	public isInternalSubscription: boolean;
	public passwordPolicy: PasswordPolicy;
	public tokenValidityInMin: number;
	public ldapSettings?: LDAP_Settings;
	public userNameCaseSensitive: boolean;
	public id: string;
	public userPoolId: string;
	public userPoolName: string;
	public orgCode: string;
	public features: Object;
	public startDate: string;
	public endDate: string;
	public identityProvider: string;
	public planDetails: string;
	public isActive: boolean;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
       super();
    }
}
 
export class DENFilter  implements AdditionalEntity{

    public propertyName: string;
	public value1?: string;
	public value2?: string;
	public valueList: Array<string>;
	public operator: string;

    constructor(){
    }
}
 
export class DENQuery  implements AdditionalEntity{

    public filterProperties: Array<DENFilter>;
	public sortingProperties?: SortingProperties;
	public pageProperties?: DENQueryPage;
	public attributes: Array<DENFilter>;
	public condition: string;

    constructor(){
    }
}
 
export class DENQueryComplexProperty  implements AdditionalEntity{

    public denQuery: DENQuery;
	public schemaName?: string;
	public anonymousFields: Array<FluentServiceProperty>;

    constructor(){
    }
}
 
export class DENQueryPage  implements AdditionalEntity{

    public pageNumber: number;
	public pageSize: number;

    constructor(){
    }
}
 
export class DataEntity  implements AdditionalEntity{

    public name: string;
	public description?: string;
	public basePath: string;
	public isCacheable: boolean;

    constructor(){
    }
}
 
export class DataEntityByNameCmdQuery  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class DataItem  implements AdditionalEntity{

    public data: Array<DataCell>;
	public derivedFromSchemaName?: string;

    constructor(){
    }
}
 
export class DataMappingsComplexProperty  implements AdditionalEntity{

    public inSchemaName: string;
	public outSchemaName: string;
	public fields: Array<MappedField>;

    constructor(){
    }
}
 
export class DataSourceServiceInstance extends AppEntity {

    public id: string;
	public type: string;
	public group: string;
	public serviceName: string;
	public method: string;
	public dsName: string;
	public wsTopicResult: string;
	public pageName: string;
	public dataSourceOwnerId?: string;
	public isMaster: boolean;
	public enabled: boolean;
	public joinKey?: string;
	public finalResponseType?: string;
	public dataSourceOwnerType?: string;
	public shouldAutoResolveForeignKeys: boolean;
	public page?: Page;

    constructor(){
       super();
    }
}
 
export class DataViewContainer  implements AdditionalEntity{

    public className: string;
	public data: Object;
	public wsTopicDetail?: WSTopicDetail;

    constructor(){
    }
}
 
export class DataViewTimeSeriesItemContainer  implements AdditionalEntity{

    public name: string;
	public list: Array<DataViewItem>;
	public totalCount?: number;

    constructor(){
    }
}
 
export class DatasourceEvent  implements AdditionalEntity{

    public id: string;
	public payLoad: Payload;

    constructor(){
    }
}
 
export class DateRange  implements AdditionalEntity{

    public startTS: string;
	public endTS?: string;

    constructor(){
    }
}
 
export class DateTimeValue  implements AdditionalEntity{

    public data: string;

    constructor(){
    }
}
 
export class DeRegisterMicroserviceCmd  implements AdditionalEntity{

    public customerMicroserviceName: string;
	public microserviceVersion: string;
	public installationId: string;
	public customerMicroservice?: CustomerMicroservice;

    constructor(){
    }
}
 
export class DeactivateCharvakaFluentServiceCommand  implements AdditionalEntity{

    public utilizerName: string;
	public charvakaFluentServiceName: string;
	public utilizer?: CharvakaFluentServiceUtilizer;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
    }
}
 
export class DefaultCreateUserCmd  implements AdditionalEntity{

    public userName: string;
	public email: string;
	public phoneNumber?: string;
	public password?: string;

    constructor(){
    }
}
 
export class DeleteAppPermissionSetCommand  implements AdditionalEntity{

    public appPermissionSetId: string;

    constructor(){
    }
}
 
export class DeleteBotAliasCommand  implements AdditionalEntity{

    public botName: string;
	public botAlias: string;

    constructor(){
    }
}
 
export class DeleteByIdsCommand  implements AdditionalEntity{

    public ids: Array<string>;

    constructor(){
    }
}
 
export class DeleteDomainCmd  implements AdditionalEntity{

    public userPoolId: string;
	public domain: string;

    constructor(){
    }
}
 
export class DeleteExecutionResult  implements AdditionalEntity{

    public isSuccess: boolean;
	public msg: string;

    constructor(){
    }
}
 
export class DeleteIdentityProviderCmd  implements AdditionalEntity{

    public userPoolId: string;
	public providerName: string;

    constructor(){
    }
}
 
export class DeleteProcessInstanceQuery  implements AdditionalEntity{

    public charvakaProcessName: string;
	public versionNumber: number;
	public processInstanceId: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class DeleteRoleCmd  implements AdditionalEntity{

    public userPoolId: string;
	public roleName: string;
	public role?: Role;

    constructor(){
    }
}
 
export class DeleteServiceStubCommand  implements AdditionalEntity{

    public charvakaTestCaseName: string;
	public charvakaEntityName: string;
	public charvakaModuleType: string;
	public stub: ServiceStub;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
    }
}
 
export class DeleteUserCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;

    constructor(){
    }
}
 
export class DeleteUserRoleCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;
	public roleName: string;
	public role?: Role;

    constructor(){
    }
}
 
export class DenDataTypesContainer  implements AdditionalEntity{

    public id: string;
	public name: string;
	public jvmClassName: string;
	public denClassName: string;
	public supportedControlTypes: Array<string>;

    constructor(){
    }
}
 
export class DeployCommandForUpdateApp  implements AdditionalEntity{

    public deploymentSessionId: string;
	public appCode: string;
	public version: string;
	public solutionArtifactUpdatedList: Array<AppArtifactUpdated>;
	public orgCode: string;

    constructor(){
    }
}
 
export class DeploymentInfoWs  implements AdditionalEntity{

    public message: string;
	public status: string;

    constructor(){
    }
}
 
export class DeploymentProcessSessionImpl  implements AdditionalEntity{

    public sessionId: string;
	public userName: string;
	public timeStamp: string;
	public deploymentType: string;

    constructor(){
    }
}
 
export class DeploymentThumbnailDetail  implements AdditionalEntity{

    public version: string;
	public createdBy: string;
	public createDate: string;
	public releaseNotes: string;
	public templateType: string;
	public svgIcon: string;
	public deploymentAppCode: string;

    constructor(){
    }
}
 
export class DeveloperDashboardDetails  implements AdditionalEntity{

    public apps: Array<string>;
	public sites: Array<string>;

    constructor(){
    }
}
 
export class DevumAmazon  implements AdditionalEntity{

    public client_id: string;
	public client_secret: string;
	public authorize_scopes: string;

    constructor(){
    }
}
 
export class DevumApple  implements AdditionalEntity{

    public client_id: string;
	public team_id: string;
	public key_id: string;
	public private_key: string;
	public authorize_scopes: string;

    constructor(){
    }
}
 
export class DevumConsoleMicroserviceManager  implements AdditionalEntity{

    public name: string;
	public versionNo: string;
	public installationId: string;
	public deployedDate: string;
	public deploymentName: string;
	public host: string;
	public port: number;
	public microServiceType: string;
	public createdBy: string;
	public isActive: boolean;
	public createDate: string;
	public environmentName: string;

    constructor(){
    }
}
 
export class DevumCustomizeEmailAddress  implements AdditionalEntity{

    public from?: string;
	public sourceArn: string;
	public replyToEmailAddress: string;
	public emailSendingAccount: string;

    constructor(){
    }
}
 
export class DevumCustomizeInvitationMessages  implements AdditionalEntity{

    public smsMsg: string;
	public emailSubject: string;
	public emailMsg: string;

    constructor(){
    }
}
 
export class DevumEmailVerification  implements AdditionalEntity{

    public emailSubject: string;
	public emailMsg: string;

    constructor(){
    }
}
 
export class DevumEnvironmentResponse  implements AdditionalEntity{

    public runtimeMode: string;

    constructor(){
    }
}
 
export class DevumFacebook  implements AdditionalEntity{

    public client_id: string;
	public client_secret: string;
	public authorize_scopes: string;
	public api_version: string;

    constructor(){
    }
}
 
export class DevumFileDescription  implements AdditionalEntity{

    public name: string;
	public description?: string;
	public tags: Array<string>;
	public fileUrl: string;
	public thumbnailUrl: string;
	public fileGroup: string;
	public fileCategory: string;
	public fileType: string;

    constructor(){
    }
}
 
export class DevumGoogle  implements AdditionalEntity{

    public client_id: string;
	public client_secret: string;
	public authorize_scopes: string;

    constructor(){
    }
}
 
export class DevumNotification extends AppEntity {

    public name: string;
	public id: string;
	public devumNotificationTemplateName: string;
	public message: string;
	public isRead: boolean;
	public userName: string;
	public severity: string;
	public notificationType: Array<string>;
	public siteCode: string;
	public enabled: boolean;
	public devumNotificationTemplate?: DevumNotificationTemplate;

    constructor(){
       super();
    }
}
 
export class DevumNotificationMessageContainer  implements AdditionalEntity{

    public subject?: string;
	public message?: string;
	public userNames: Array<string>;

    constructor(){
    }
}
 
export class DevumNotificationTemplate extends AppEntity {

    public id: string;
	public notificationName: string;
	public messageTemplate: string;
	public subjectTemplate: string;
	public threshold: string;
	public severity: string;
	public notificationType: Array<string>;
	public userNames: Array<string>;
	public roleNames: Array<string>;
	public siteCode: string;
	public icon?: string;
	public enabled: boolean;
	public name: string;

    constructor(){
       super();
    }
}
 
export class DevumNotificationUI  implements AdditionalEntity{

    public id: string;
	public notificationTemplateName: string;
	public message: string;
	public isRead: boolean;
	public serverity: string;
	public notificationType: Array<string>;
	public appCode: string;
	public enabled: boolean;

    constructor(){
    }
}
 
export class DevumOIDC  implements AdditionalEntity{

    public providerName: string;
	public client_id: string;
	public client_secret?: string;
	public attributes_request_method: string;
	public oidc_issuer: string;
	public authorize_scopes: string;

    constructor(){
    }
}
 
export class DevumObjectsOverview  implements AdditionalEntity{

    public createdObjects: number;
	public activeObjects: number;
	public inActiveObjects: number;
	public maxAllowedObjects: number;

    constructor(){
    }
}
 
export class DevumOpenAiRequest  implements AdditionalEntity{

    public message: string;

    constructor(){
    }
}
 
export class DevumSAML  implements AdditionalEntity{

    public MetadataURL: string;
	public IDPSignout?: string;
	public providerName: string;

    constructor(){
    }
}
 
export class DevumStreamingBridge  implements AdditionalEntity{

    public utilizerName: string;

    constructor(){
    }
}
 
export class DevumSubscribedPlan  implements AdditionalEntity{

    public planName: string;
	public planFeatures: Array<DevumSubscribedPlanFeature>;

    constructor(){
    }
}
 
export class DevumSubscribedPlanFeature  implements AdditionalEntity{

    public featureName: string;
	public selectedValue?: string;

    constructor(){
    }
}
 
export class DisableUserCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;

    constructor(){
    }
}
 
export class DiscardDraftCommand  implements AdditionalEntity{

    public appArtifactType: string;
	public aggregateAppEntityName: string;

    constructor(){
    }
}
 
export class DistinctAppCodeResult  implements AdditionalEntity{

    public distinctAppCodes: Array<string>;

    constructor(){
    }
}
 
export class DistinctCollectionFieldResult  implements AdditionalEntity{

    public distinctValues: Array<string>;

    constructor(){
    }
}
 
export class DomainInfo  implements AdditionalEntity{

    public userPoolId: string;
	public domain?: string;
	public customDomain?: string;

    constructor(){
    }
}
 
export class DoubleValue  implements AdditionalEntity{

    public data: number;

    constructor(){
    }
}
 
export class DownloadedVsDeploymentCommand  implements AdditionalEntity{

    public version: string;
	public appCode: string;
	public templateType: string;
	public orgCode: string;

    constructor(){
    }
}
 
export class DraftAppEntity extends AppEntity {

    public id: string;
	public appArtifactType: string;
	public appArtifactName: string;
	public appEntityId: string;
	public aggregateAppEntityName: string;
	public draftJsonModel: string;
	public actualEntityStateType: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
       super();
    }
}
 
export class DraftAppEntityIdentifier  implements AdditionalEntity{

    public appArtifactType: string;
	public appArtifactName: string;
	public appEntityId: string;
	public appCode: string;

    constructor(){
    }
}
 
export class DraftAppEntityUI  implements AdditionalEntity{

    public id: string;
	public appEntityId: string;
	public aggregateAppEntityName: string;
	public draftJsonModel: string;
	public actualEntityStateType: string;
	public appCode: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
    }
}
 
export class DraftEntityContainer  implements AdditionalEntity{

    public appArtifactType: string;
	public appArtifactName: string;
	public drafts: Array<DraftAppEntityUI>;
	public published: Array<AppEntity>;

    constructor(){
    }
}
 
export class DraftTaskDetailCommand  implements AdditionalEntity{

    public taskId?: string;
	public elementUUID: string;
	public instanceId: string;
	public taskAssignee?: string;
	public taskStatus: string;
	public charvakaProcessName: string;
	public processVersionNumber: number;
	public taskInstanceId: string;
	public tcName?: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class DraftTaskQuery  implements AdditionalEntity{

    public taskId: string;
	public elementUUID: string;
	public instanceId: string;
	public taskAssigneeId?: string;
	public taskStatus: string;
	public charvakaProcessName: string;
	public version: number;
	public taskInstanceId: string;
	public tcName?: string;
	public dataTable: DataTable;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class DuplicateFluentServiceCommand  implements AdditionalEntity{

    public fluentServiceName: string;
	public newFluentServiceName: string;
	public newLabel: string;

    constructor(){
    }
}
 
export class DynamicFieldResult  implements AdditionalEntity{

    public collectionName: string;
	public fieldName: string;
	public controlType?: string;
	public dataSource?: string;

    constructor(){
    }
}
 
export class DynamicFieldsCollectionQuery  implements AdditionalEntity{

    public dynamicFields: Array<DynamicFieldsQuery>;

    constructor(){
    }
}
 
export class DynamicFieldsQuery  implements AdditionalEntity{

    public collectionName: string;
	public fieldNames: Array<string>;

    constructor(){
    }
}
 
export class DynamicForm extends AppEntity {

    public id: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public siteCode: string;

    constructor(){
       super();
    }
}
 
export class DynamicVariable  implements AdditionalEntity{

    public name: string;
	public value: string;

    constructor(){
    }
}
 
export class ELementActionButton  implements AdditionalEntity{

    public name: string;
	public value: string;
	public icon?: string;
	public colorCode?: string;

    constructor(){
    }
}
 
export class ElementDetail  implements AdditionalEntity{

    public className: string;
	public elementType: string;
	public groups: Array<string>;
	public iconPath: string;
	public appCode?: string;
	public siteCode: Array<string>;
	public microserviceName?: string;
	public microserviceVersion?: string;

    constructor(){
    }
}
 
export class ElementExecutionHistory  implements AdditionalEntity{

    public taskStatus: string;
	public transactionDate: string;

    constructor(){
    }
}
 
export class ElementExecutionResult  implements AdditionalEntity{

    public elementName: string;
	public elementUUID: string;
	public elementTypeName: string;
	public elementInstanceId: string;
	public taskStatus: string;
	public transactionDate: string;
	public entitySchemaName?: string;
	public icon: string;
	public assignedUser?: string;
	public hybridUser?: string;
	public performedUser?: string;
	public elementExecutionHistory: Array<ElementExecutionHistory>;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class ElementGroup  implements AdditionalEntity{

    public name: string;
	public description: string;
	public iconPath: string;
	public primaryElementClassName: string;

    constructor(){
    }
}
 
export class ElementIcon  implements AdditionalEntity{

    public name: string;
	public svgIcon: string;

    constructor(){
    }
}
 
export class ElementPropertyQuery  implements AdditionalEntity{

    public className: string;

    constructor(){
    }
}
 
export class ElementReferencePersistable  implements AdditionalEntity{

    public elementName: string;
	public className: string;
	public elementUUID: string;
	public elementType: string;
	public uiProperties: Object;
	public properties: Object;
	public connection: Connection;
	public msName?: string;
	public microserviceVersion?: string;
	public id: string;

    constructor(){
    }
}
 
export class ElementReferencePersistableEntity extends AppEntity {

    public ref: ElementReferencePersistable;
	public charvakaProcessName: string;
	public id: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
       super();
    }
}
 
export class EnableUserCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;

    constructor(){
    }
}
 
export class EntityApiResult  implements AdditionalEntity{

    public values: Array<KeyValueHolder>;

    constructor(){
    }
}
 
export class EntityColumnConfiguration extends AppEntity {

    public id: string;
	public name: string;
	public defaultSequence: number;
	public updatedSequence: number;
	public isMain: boolean;
	public isFlex: boolean;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public columnName: string;
	public isMobile: boolean;
	public sortOrder?: number;
	public entitySchemaName: string;
	public entitySchema?: EntitySchema;

    constructor(){
       super();
    }
}
 
export class EntityDetailPrecis  implements AdditionalEntity{

    public id: string;
	public name: string;
	public descriptionOpt?: string;

    constructor(){
    }
}
 
export class EntityFieldCondition extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public entityUUID?: string;
	public entitySchemaFieldName: string;
	public fieldCondition: string;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public sortOrder?: number;
	public entitySchema?: EntitySchema;
	public entitySchemaField?: EntitySchemaField;

    constructor(){
       super();
    }
}
 
export class EntityFieldConditionOperator extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public entitySchemaFieldName: string;
	public conditionalOperatorType: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public entitySchema?: EntitySchema;
	public entitySchemaField?: EntitySchemaField;

    constructor(){
       super();
    }
}
 
export class EntityFieldDisplayCondition extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public entitySchemaValueList: string;
	public entitySchemaFieldName: string;
	public dependentFields: string;
	public readOnlyOrHiddenInAdd: boolean;
	public readOnlyOrHiddenInUpdate: boolean;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public entitySchema?: EntitySchema;
	public entitySchemaField?: EntitySchemaField;

    constructor(){
       super();
    }
}
 
export class EntityPrePostProcessorCFSUtilizerBridge  implements AdditionalEntity{

    public returnType: FluentServiceProperty;
	public utilizerName: string;
	public entitySchemaName: string;
	public schema: DataTable;
	public crudOperationType: string;
	public beforeOrAfter: string;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class EntityPrePostTestCaseInitData  implements AdditionalEntity{

    public dataItem: DataItem;

    constructor(){
    }
}
 
export class EntitySchema extends AppEntity {

    public id: string;
	public name: string;
	public description?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public isSeed: boolean;
	public entityType: string;
	public behaviourType: string;
	public isPublishable: boolean;
	public auditLogLevel: string;
	public label: string;
	public isDataSystemGenerated: boolean;
	public isGlobalEntity: boolean;

    constructor(){
       super();
    }
}
 
export class EntitySchemaField extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public name: string;
	public label: string;
	public shortCode?: string;
	public description?: string;
	public controlType?: string;
	public fieldType: string;
	public sequenceNumber: number;
	public isRequired: boolean;
	public fieldCategory: string;
	public isArray: boolean;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public uom?: string;
	public subControlType?: string;
	public entitySchema?: EntitySchema;

    constructor(){
       super();
    }
}
 
export class EntitySchemaFieldFilter extends AppEntity {

    public id: string;
	public entitySchemaFieldName: string;
	public filterProperty: string;
	public dependencyProperty?: string;
	public defaultValue?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public entitySchemaName: string;
	public entitySchemaField?: EntitySchemaField;
	public entitySchema?: EntitySchema;

    constructor(){
       super();
    }
}
 
export class EntitySchemaNameQuery  implements AdditionalEntity{

    public name: string;

    constructor(){
    }
}
 
export class EntitySchemaPrecis extends AppEntity {

    public id: string;
	public entitySchemaName: string;
	public name: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public label: string;
	public entitySchema?: EntitySchema;

    constructor(){
       super();
    }
}
 
export class EntitySchemaPrecisMapping extends AppEntity {

    public id: string;
	public name: string;
	public entitySchemaFieldName: string;
	public entitySchemaPrecisName: string;
	public isReadOnly: boolean;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public entitySchemaField?: EntitySchemaField;
	public entitySchemaPrecis?: EntitySchemaPrecis;

    constructor(){
       super();
    }
}
 
export class EntitySchemaResult  implements AdditionalEntity{

    public entitySchemaId: string;
	public entitySchemaName: string;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class EntitySearchDetail  implements AdditionalEntity{

    public entityName: string;
	public precisList: Array<EntityDetailPrecis>;
	public artifactType: string;

    constructor(){
    }
}
 
export class EntityUsage  implements AdditionalEntity{

    public entity: string;
	public description: string;
	public severity: string;
	public count: number;
	public values: Array<string>;

    constructor(){
    }
}
 
export class EntityUsageUI  implements AdditionalEntity{

    public artifactGroup: string;
	public usages: Array<EntityUsage>;

    constructor(){
    }
}
 
export class EnumValue  implements AdditionalEntity{

    public value: string;
	public colorCode?: string;

    constructor(){
    }
}
 
export class ExecutableMemberDefinition  implements AdditionalEntity{

    public name: string;
	public description: string;
	public parentName: string;
	public method: Array<ExecutableMethod>;

    constructor(){
    }
}
 
export class ExecutableMethod  implements AdditionalEntity{

    public name: string;
	public params: Object;
	public returnType: number;
	public methodType: string;
	public doc: string;
	public url: string;

    constructor(){
    }
}
 
export class ExecuteBackendActionQuery  implements AdditionalEntity{

    public dsType: string;
	public group: string;
	public serviceName: string;
	public methodName: string;
	public paramsData?: Object;
	public restSuffix?: string;

    constructor(){
    }
}
 
export class ExecuteEntityApiCommand  implements AdditionalEntity{

    public dataItem: DataItem;
	public serviceName: string;
	public tcName?: string;

    constructor(){
    }
}
 
export class ExecuteTestCaseOrRunSimulationCommand  implements AdditionalEntity{

    public charvakaTestCaseName: string;
	public testCaseIdentifier: CharvakaTestCaseIdentifier;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
    }
}
 
export class ExecutionStatContainer  implements AdditionalEntity{

    public executions: number;
	public timeTaken: number;
	public exceptions: number;

    constructor(){
    }
}
 
export class FEObjectWrapper  implements AdditionalEntity{

    public result: any;

    constructor(){
    }
}
 
export class FSDurationAndDbCountContainer  implements AdditionalEntity{

    public fluentServiceName: string;
	public duration: string;
	public dbFunctionCount: number;
	public serviceUtilizerType: string;
	public status?: string;
	public siteCode?: string;

    constructor(){
    }
}
 
export class FSWithFilteredSiteCodeHolder  implements AdditionalEntity{

    public containers: Array<FSDurationAndDbCountContainer>;

    constructor(){
    }
}
 
export class FieldApiCFSUtilizerBridge  implements AdditionalEntity{

    public utilizerName: string;
	public propertyContainer?: FluentServicePropertyContainer;
	public returnSchema: FluentServiceProperty;

    constructor(){
    }
}
 
export class FileDeleteCommand  implements AdditionalEntity{

    public fileName: string;
	public category: string;
	public fileType: string;
	public fileGroup: string;

    constructor(){
    }
}
 
export class FileDownloadCommand  implements AdditionalEntity{

    public fileName: string;
	public category: string;
	public fileType: string;
	public fileGroup: string;

    constructor(){
    }
}
 
export class FileDownloadUrl  implements AdditionalEntity{

    public thumbnailUrl: string;
	public fileUrl: string;

    constructor(){
    }
}
 
export class FileInfo  implements AdditionalEntity{

    public name: string;
	public description?: string;
	public tags: Array<string>;

    constructor(){
    }
}
 
export class FileInfoWithURL  implements AdditionalEntity{

    public name: string;
	public description?: string;
	public tags: Array<string>;
	public url: string;

    constructor(){
    }
}
 
export class FileNameCommand  implements AdditionalEntity{

    public fileName: string;
	public schemaName: string;

    constructor(){
    }
}
 
export class FileNameContainer  implements AdditionalEntity{

    public fileName: string;

    constructor(){
    }
}
 
export class FileNameMapResult  implements AdditionalEntity{

    public originalName: string;
	public uniqueName: string;

    constructor(){
    }
}
 
export class FileUploadCommand  implements AdditionalEntity{

    public actualFileInfo: FileInfo;
	public thumbnailFileInfo: FileInfo;
	public category: string;
	public fileType: string;
	public fileGroup: string;

    constructor(){
    }
}
 
export class FileUploadUrl  implements AdditionalEntity{

    public thumbnailUrl: string;
	public fileUrl: string;
	public description: DevumFileDescription;

    constructor(){
    }
}
 
export class FluentCompositionCommand  implements AdditionalEntity{

    public previousCompositionId?: string;
	public functionClass: string;
	public charvakaFluentServiceName: string;
	public properties: Array<FluentServiceProperty>;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public labels: Array<string>;
	public modifiedDate: string;
	public sortOrder?: number;
	public appCode: string;
	public parentId?: string;
	public secondaryParentId?: string;
	public className: string;
	public functionType: string;
	public microServiceName?: string;
	public microserviceVersion?: string;
	public id: string;
	public returnType?: DataItem;
	public isCommented: boolean;
	public debugger: boolean;
	public charvakaFluentService?: CharvakaFluentService;
	public attributes: Object;

    constructor(){
    }
}
 
export class FluentPropertyDependsOn  implements AdditionalEntity{

    public propertyName: string;
	public propertyValue: Object;

    constructor(){
    }
}
 
export class FluentPropertyOptionsAndConditionContainer  implements AdditionalEntity{

    public propertyOptions: Array<FluentServicePropertyOption>;
	public condition: FluentServicePropertyCondition;

    constructor(){
    }
}
 
export class FluentServiceBridgeOptionsContainer  implements AdditionalEntity{

    public options: Array<FluentServiceBridgePropertyOptionContainer>;

    constructor(){
    }
}
 
export class FluentServiceBridgePropertyOptionContainer  implements AdditionalEntity{

    public fieldName: string;
	public propertyOptionsAndCondition: FluentPropertyOptionsAndConditionContainer;

    constructor(){
    }
}
 
export class FluentServiceDetail  implements AdditionalEntity{

    public id: string;
	public name: string;
	public isRunning: boolean;
	public fluentUtilizerType: string;

    constructor(){
    }
}
 
export class FluentServiceDetails  implements AdditionalEntity{

    public data: Array<FluentServiceDetail>;

    constructor(){
    }
}
 
export class FluentServiceIdContainer  implements AdditionalEntity{

    public fluentServiceId: string;

    constructor(){
    }
}
 
export class FluentServiceProperty  implements AdditionalEntity{

    public key: string;
	public value?: string;
	public denType: string;
	public dataTableSchemaOpt?: DataItem;
	public dataTableOpt?: DataTable;
	public isOptional: boolean;
	public isArray: boolean;
	public isLambda: boolean;
	public isReturnProperty: boolean;
	public description: string;
	public label: string;

    constructor(){
    }
}
 
export class FluentServicePropertyCondition  implements AdditionalEntity{

    public dependsOn: Array<FluentPropertyDependsOn>;
	public conditionType?: string;

    constructor(){
    }
}
 
export class FluentServicePropertyContainer  implements AdditionalEntity{

    public properties: Array<FluentServiceProperty>;

    constructor(){
    }
}
 
export class FluentServicePropertyOption  implements AdditionalEntity{

    public name: string;
	public value: Object;

    constructor(){
    }
}
 
export class FluentServiceRuntimeStatus  implements AdditionalEntity{

    public fluentServiceName: string;
	public status: string;

    constructor(){
    }
}
 
export class FluentServiceTaskCFSUtilizerBridge  implements AdditionalEntity{

    public returnType: FluentServiceProperty;
	public utilizerName: string;
	public charvakaProcessName: string;
	public elementUUID: string;
	public variables?: FluentServicePropertyContainer;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class FollowUpPromptCommand  implements AdditionalEntity{

    public messageCommands: Array<FollowUpPromptMessageCommand>;
	public rejectMessageCommands: Array<FollowUpPromptRejectMessageCommand>;
	public maximumRetries: number;

    constructor(){
    }
}
 
export class FollowUpPromptMessageCommand  implements AdditionalEntity{

    public message: string;
	public contentType: string;

    constructor(){
    }
}
 
export class FollowUpPromptRejectMessageCommand  implements AdditionalEntity{

    public rejectStatement: string;
	public rejectStatementContentType: string;

    constructor(){
    }
}
 
export class ForeignKey  implements AdditionalEntity{

    public tableName: string;
	public columnName: string;

    constructor(){
    }
}
 
export class FormConditionGroup  implements AdditionalEntity{

    public conditionType: string;
	public controlConditions: Array<ControlCondition>;

    constructor(){
    }
}
 
export class FormDateRange  implements AdditionalEntity{

    public dateField: string;
	public startTS: string;
	public endTS?: string;

    constructor(){
    }
}
 
export class FormFilterQuery  implements AdditionalEntity{

    public formName: string;
	public params: DENQuery;
	public aggregatable?: Aggregateable;
	public prs: TxnParams;

    constructor(){
    }
}
 
export class FormMetaData  implements AdditionalEntity{

    public entitySchemaName: string;
	public mappedSchemaFields?: MappedSchemaFields;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class FormObjectCommand  implements AdditionalEntity{

    public oldFormObject: string;
	public newFormObject: string;

    constructor(){
    }
}
 
export class FormViewQuery  implements AdditionalEntity{

    public formName: string;
	public range?: FormDateRange;
	public params: Array<DataCell>;
	public aggregatable?: Aggregateable;
	public listParams: Array<ListParams>;
	public prs: TxnParams;

    constructor(){
    }
}
 
export class FormsDeleteList  implements AdditionalEntity{

    public mongoObjectIds: Array<string>;

    constructor(){
    }
}
 
export class FormsDetailsFilterResult  implements AdditionalEntity{

    public totalResults: number;
	public results: any;

    constructor(){
    }
}
 
export class FulfillmentActivityCommand  implements AdditionalEntity{

    public botCodeHookCommand?: BotCodeHookCommand;
	public withType: string;

    constructor(){
    }
}
 
export class FunctionFilterQuery  implements AdditionalEntity{

    public groups: Array<string>;
	public dataAdapterType: Array<string>;
	public functionType: string;
	public labels: Array<string>;
	public microServiceName?: string;

    constructor(){
    }
}
 
export class FunctionsChangedWsResponse  implements AdditionalEntity{

    public compositionCommand: CharvakaFluentFunctionComposition;
	public operationType: string;
	public sequences: Array<AffectedSequence>;
	public uiProperties?: string;

    constructor(){
    }
}
 
export class GMapKeyValueContainer  implements AdditionalEntity{

    public gMapKey: string;
	public version: string;

    constructor(){
    }
}
 
export class GeneralFileName  implements AdditionalEntity{

    public originalName: string;
	public uniqueName: string;

    constructor(){
    }
}
 
export class GenerateColumn  implements AdditionalEntity{

    public name: string;
	public type: string;
	public primaryKey: boolean;
	public required: boolean;
	public unique: boolean;
	public controlType?: string;
	public foreignKey?: ForeignKey;

    constructor(){
    }
}
 
export class GenerateSchemaCommand  implements AdditionalEntity{

    public functionName: string;
	public microserviceName?: string;
	public microserviceVersion?: string;
	public debugMode: boolean;
	public fluentServicePropertyContainer: FluentServicePropertyContainer;

    constructor(){
    }
}
 
export class GeneratedContent  implements AdditionalEntity{

    public topic: string;
	public description?: string;
	public schema: Array<GeneratedTable>;

    constructor(){
    }
}
 
export class GeneratedTable  implements AdditionalEntity{

    public tableName: string;
	public displayName: string;
	public entityType: string;
	public description?: string;
	public columns: Array<GenerateColumn>;
	public shouldAutoPublish: boolean;
	public canGenerateData: boolean;
	public howManyRecords?: number;
	public id: string;

    constructor(){
    }
}
 
export class GenericFindByIdsPrecisCommand  implements AdditionalEntity{

    public ids: Array<number>;

    constructor(){
    }
}
 
export class GetAllDeploymentVersionCommand  implements AdditionalEntity{

    public deploymentAppCode: string;
	public orgCode: string;

    constructor(){
    }
}
 
export class GetAllFileInfoQuery  implements AdditionalEntity{

    public category: string;
	public fileType: string;

    constructor(){
    }
}
 
export class GetAllIdentityProvidersResponse  implements AdditionalEntity{

    public google?: DevumGoogle;
	public facebook?: DevumFacebook;
	public amazon?: DevumAmazon;
	public saml?: DevumSAML;
	public apple?: DevumApple;
	public oidc?: DevumOIDC;

    constructor(){
    }
}
 
export class GetAllIdentityProvidersUI  implements AdditionalEntity{

    public cognito: boolean;
	public google: boolean;
	public facebook: boolean;
	public amazon: boolean;
	public saml: boolean;
	public apple: boolean;
	public oidc: boolean;

    constructor(){
    }
}
 
export class GetAllTemplateCommand  implements AdditionalEntity{

    public orgCode: string;
	public onlyPrivateTemplates: boolean;

    constructor(){
    }
}
 
export class GetAppClientSettings  implements AdditionalEntity{

    public userPoolId: string;

    constructor(){
    }
}
 
export class GetBackendActionServiceQuery  implements AdditionalEntity{

    public typeNames: Array<string>;

    constructor(){
    }
}
 
export class GetBotDataCommand  implements AdditionalEntity{

    public botName: string;
	public botVersionOrAlias: string;

    constructor(){
    }
}
 
export class GetByAttributeKey  implements AdditionalEntity{

    public key: string;
	public value: string;

    constructor(){
    }
}
 
export class GetByUserPoolIdQuery  implements AdditionalEntity{

    public userPoolId: string;
	public includeDisabled: boolean;

    constructor(){
    }
}
 
export class GetCharvakaTestCasesByIdentifier  implements AdditionalEntity{

    public testCaseIdentifier: CharvakaTestCaseIdentifier;

    constructor(){
    }
}
 
export class GetExecutionResultsByTestCaseQuery  implements AdditionalEntity{

    public charvakaTestCaseName: string;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
    }
}
 
export class GetIdentityProviderCmd  implements AdditionalEntity{

    public userPoolId: string;
	public idpIdentifier: string;

    constructor(){
    }
}
 
export class GetIdentityProviderInfo  implements AdditionalEntity{

    public providerType: string;
	public providerName: string;
	public providerDetails: Object;
	public idpIdentifiers: Array<string>;
	public attributeMapping: Object;
	public creationDate: string;
	public lastModifiedDate: string;

    constructor(){
    }
}
 
export class GetIdentityProvidersCmd  implements AdditionalEntity{

    public userPoolId: string;
	public clientId: string;

    constructor(){
    }
}
 
export class GetSignInUrlsQuery  implements AdditionalEntity{

    public userPoolId: string;

    constructor(){
    }
}
 
export class GetTemplatesPerApp  implements AdditionalEntity{

    public orgCode: string;
	public appCode: string;

    constructor(){
    }
}
 
export class GetTestCaseOptionsQuery  implements AdditionalEntity{

    public charvakaEntityName: string;
	public charvakaModuleType: string;

    constructor(){
    }
}
 
export class GetUICustomizationCmd  implements AdditionalEntity{

    public userPoolId: string;
	public clientId?: string;

    constructor(){
    }
}
 
export class GetUICustomizationResponse  implements AdditionalEntity{

    public css: string;
	public imageURL: string;
	public cssVersion: string;
	public creationDate: string;
	public lastModifiedDate: string;

    constructor(){
    }
}
 
export class GlobalPrecisEntity  implements AdditionalEntity{

    public id: string;
	public name: string;
	public enabled: boolean;
	public label: string;

    constructor(){
    }
}
 
export class GlobalVariableProperty  implements AdditionalEntity{

    public key: string;
	public value?: string;
	public denType: string;
	public isOptional: boolean;
	public isArray: boolean;

    constructor(){
    }
}
 
export class GpsPoint  implements AdditionalEntity{

    public lat: number;
	public lng: number;
	public createDate: string;
	public alt?: number;

    constructor(){
    }
}
 
export class GroupedResult  implements AdditionalEntity{

    public fieldValue: Object;
	public records: DataTable;

    constructor(){
    }
}
 
export class GroupedResultDataList  implements AdditionalEntity{

    public data: Array<GroupedResult>;

    constructor(){
    }
}
 
export class HeaderMetaDataQuery  implements AdditionalEntity{

    public elementUUID: string;
	public charvakaProcessName: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class Help extends AppEntity {

    public id: string;
	public name: string;
	public content: string;
	public helpCategoryName: string;
	public labelName: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public helpCategory?: HelpCategory;
	public label?: Label;

    constructor(){
       super();
    }
}
 
export class HelpCategory extends AppEntity {

    public id: string;
	public name: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public label: string;

    constructor(){
       super();
    }
}
 
export class HistoryIncidentResult  implements AdditionalEntity{

    public incidentId: string;
	public incidentType: string;
	public processDefinitionKey: string;
	public processDefinitionId: string;
	public activityId: string;
	public exceptionMessage: string;
	public createTime: string;
	public processInstanceId: number;

    constructor(){
    }
}
 
export class ImportCSVResult  implements AdditionalEntity{

    public message: string;
	public status: boolean;

    constructor(){
    }
}
 
export class InjectableServiceHolder  implements AdditionalEntity{

    public injectableServiceName: string;
	public serviceType: string;
	public injectableServiceImpls: Array<InjectableServiceImpl>;

    constructor(){
    }
}
 
export class InjectableServiceImpl  implements AdditionalEntity{

    public injectableServiceImplName: string;
	public isMock: boolean;
	public injectableServiceImpl?: InjectableServiceImpl;

    constructor(){
    }
}
 
export class InsertGeneratedSchemaCmd  implements AdditionalEntity{

    public generatedTables: Array<GeneratedTable>;

    constructor(){
    }
}
 
export class InsertOrUpdateCharvakaFluentServiceCommand  implements AdditionalEntity{

    public id: string;
	public name: string;
	public description?: string;
	public createdBy: string;
	public createDate: string;
	public appCode: string;
	public isActive: boolean;
	public label: string;
	public fsBridge: any;

    constructor(){
    }
}
 
export class InsertOrUpdateServiceStubCommand  implements AdditionalEntity{

    public charvakaTestCaseName: string;
	public charvakaEntityName: string;
	public charvakaModuleType: string;
	public stub: ServiceStub;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
    }
}
 
export class InsertRoleCmd  implements AdditionalEntity{

    public userPoolId: string;
	public roleName: string;
	public description?: string;
	public label: string;
	public role?: Role;

    constructor(){
    }
}
 
export class InstanceInitiatorModifiedResult  implements AdditionalEntity{

    public processInstanceState: string;
	public message?: string;
	public processInstanceId: string;
	public schemaName: string;

    constructor(){
    }
}
 
export class InstanceStatus  implements AdditionalEntity{

    public statusName: string;
	public instanceTasks: Array<InstanceTask>;

    constructor(){
    }
}
 
export class InstanceTask  implements AdditionalEntity{

    public instanceId: string;
	public taskCount: number;

    constructor(){
    }
}
 
export class KeyValueHolder  implements AdditionalEntity{

    public key: string;
	public value: Object;

    constructor(){
    }
}
 
export class LDAP_Settings  implements AdditionalEntity{

    public url: string;
	public port: number;
	public baseDN: string;
	public enabled: boolean;

    constructor(){
    }
}
 
export class Label extends AppEntity {

    public id: string;
	public name: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public label: string;

    constructor(){
       super();
    }
}
 
export class LandingPageDetails  implements AdditionalEntity{

    public adminDetails?: AdminDashboardDetails;
	public developerDetails?: DeveloperDashboardDetails;
	public appDetails: Array<ApplicationDetails>;

    constructor(){
    }
}
 
export class ListComplexProperty  implements AdditionalEntity{

    public values: Array<string>;

    constructor(){
    }
}
 
export class ListParams  implements AdditionalEntity{

    public fieldName: string;
	public values: Array<string>;

    constructor(){
    }
}
 
export class LocalDateRange  implements AdditionalEntity{

    public startDate: string;
	public endDate: string;

    constructor(){
    }
}
 
export class LocalDateValue  implements AdditionalEntity{

    public data: string;

    constructor(){
    }
}
 
export class LocalTimeValue  implements AdditionalEntity{

    public data: string;

    constructor(){
    }
}
 
export class LocationImportContainer  implements AdditionalEntity{

    public geometry: string;

    constructor(){
    }
}
 
export class MSFluentFunctionMetadataPersistable extends GlobalBaseEntity {

    public id: string;
	public msInstallationId: string;
	public microserviceVersion: string;
	public name: string;
	public iconPath?: string;
	public groupName: string;
	public description?: string;
	public propertyDetails?: string;
	public isOptional: boolean;
	public supportAsProcessTask: boolean;
	public variableName: string;
	public returnClazz: string;
	public colorCode: string;
	public canAllowChildren: boolean;
	public functionType: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public functionDataAdapter?: string;
	public anonymousReturnType?: string;

    constructor(){
       super();
    }
}
 
export class MSFluentFunctionMetadataPersistableContainerUI  implements AdditionalEntity{

    public id: string;
	public msInstallationId: string;
	public microserviceVersion: string;
	public name: string;
	public iconPath?: string;
	public groupName: string;
	public description?: string;
	public propertyDetails: Array<PropertyDetail>;
	public isOptional: boolean;
	public functionType: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;

    constructor(){
    }
}
 
export class MViewDefinitionQuery  implements AdditionalEntity{

    public name: string;
	public listenToSchemaNames: Array<string>;
	public responseSchemaName?: string;
	public mViewParamSchemaName?: string;
	public splitPeriod: string;
	public mViewParamsMappingQuery: Array<MViewParamsMappingQuery>;
	public mViewTransactionDateQuery: Array<MViewTransactionDateQuery>;
	public isList: boolean;
	public isReactiveMView: boolean;
	public timeToLifeDuration?: number;
	public timeToLifeDurationType?: string;
	public triggerWaitPeriodDuration?: number;
	public triggerWaitPeriodDurationType?: string;
	public batchSize?: number;
	public description?: string;
	public label: string;
	public isPersistable: boolean;
	public params?: DataItem;
	public response?: DataItem;

    constructor(){
    }
}
 
export class MViewMetaDataResult  implements AdditionalEntity{

    public materializedViewDefinition: MaterializedViewDefinition;
	public mViewParamsMappings: Array<MViewParamsMapping>;
	public mViewTransactionDateMappings: Array<MViewTransactionDateMapping>;

    constructor(){
    }
}
 
export class MViewParams  implements AdditionalEntity{

    public params: Array<DataCell>;
	public mvName: string;
	public aggregatable?: Aggregateable;
	public listParams: Array<ListParams>;
	public sortable?: Sortable;

    constructor(){
    }
}
 
export class MViewParamsMapping extends AppEntity {

    public id: string;
	public mViewMetaDataName: string;
	public listenToSchemaName: string;
	public kpiParamSchemaName?: string;
	public paramFieldName: string;
	public listensToFieldName: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public isOptional: boolean;

    constructor(){
       super();
    }
}
 
export class MViewParamsMappingQuery  implements AdditionalEntity{

    public id: string;
	public listenToSchemaName: string;
	public paramFieldName: string;
	public listensToFieldName: string;
	public isOptional: boolean;

    constructor(){
    }
}
 
export class MViewQuery  implements AdditionalEntity{

    public range: DateRange;
	public params: MViewParams;
	public tmQuery?: TMQuery;
	public isInvokedByEvents: boolean;
	public queryUUId: string;
	public prs: TxnParams;

    constructor(){
    }
}
 
export class MViewTTL  implements AdditionalEntity{

    public canPersist: boolean;
	public duration?: Object;

    constructor(){
    }
}
 
export class MViewTestCaseInitData  implements AdditionalEntity{

    public params: DataItem;
	public response: DataItem;

    constructor(){
    }
}
 
export class MViewToBackendActionConverterCmd  implements AdditionalEntity{

    public mViewName: string;
	public name: string;
	public typeName: string;
	public groupName: string;
	public serviceName: string;
	public supportedType: string;
	public wsTopic?: string;
	public aggregatorStrategy?: string;

    constructor(){
    }
}
 
export class MViewTransactionDateMapping extends AppEntity {

    public id: string;
	public mViewDefinitionName: string;
	public entitySchemaName: string;
	public startTsFieldName: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public endTsFieldName?: string;
	public entitySchema?: EntitySchema;

    constructor(){
       super();
    }
}
 
export class MViewTransactionDateQuery  implements AdditionalEntity{

    public id: string;
	public entitySchemaName: string;
	public startTsFieldName: string;
	public endTsFieldName?: string;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class MViewWSResult  implements AdditionalEntity{

    public mvSnapshotId: string;
	public payLoad: string;
	public transactionDate: string;

    constructor(){
    }
}
 
export class ManageMobileApp extends GlobalBaseEntity {

    public id: string;
	public name: string;
	public appCode: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
       super();
    }
}
 
export class MapAllRoleUsersToAppCmd  implements AdditionalEntity{

    public appCode: string;
	public roleName: string;
	public role?: Role;

    constructor(){
    }
}
 
export class MapRoleToUsersCmd  implements AdditionalEntity{

    public userPoolId: string;
	public userNames: Array<string>;
	public roleName: string;
	public role?: Role;

    constructor(){
    }
}
 
export class MappedField  implements AdditionalEntity{

    public inFieldName?: string;
	public canResolveName: boolean;
	public alternativeExpression?: string;
	public outField: string;

    constructor(){
    }
}
 
export class MappedSchemaFields  implements AdditionalEntity{

    public readOnlyFields: Array<string>;
	public displayFields: Array<string>;

    constructor(){
    }
}
 
export class MappedSchemaFieldsQuery  implements AdditionalEntity{

    public elementUUID: string;
	public charvakaProcessName: string;
	public version: number;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class MarkAsSelectedCmd  implements AdditionalEntity{

    public appCode: string;
	public userPoolId: string;
	public appClientId: string;

    constructor(){
    }
}
 
export class MaterializedViewBuilderCFSUtilizerBridge  implements AdditionalEntity{

    public utilizerName: string;
	public materializedViewName: string;
	public params: DataItem;
	public response: DataItem;

    constructor(){
    }
}
 
export class MaterializedViewDefinition extends AppEntity {

    public id: string;
	public name: string;
	public listenToSchemaNames: Array<string>;
	public responseSchemaName?: string;
	public mViewParamSchemaName?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public splitPeriod: string;
	public isArrayResponse: boolean;
	public isReactiveMView: boolean;
	public timeToLifeDuration?: number;
	public timeToLifeDurationType?: string;
	public triggerWaitPeriodDuration?: number;
	public triggerWaitPeriodDurationType?: string;
	public batchSize?: number;
	public description?: string;
	public label: string;
	public isPersistable: boolean;
	public params?: string;
	public response?: string;
	public isSeed: boolean;

    constructor(){
       super();
    }
}
 
export class MenuItem extends AppEntity {

    public id: string;
	public name: string;
	public label: string;
	public description?: string;
	public menuIcon?: string;
	public parentMenuItemName?: string;
	public pageName?: string;
	public siteMapName: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public page?: Page;
	public siteMap?: SiteMap;

    constructor(){
       super();
    }
}
 
export class MessageCustomizationPayloadUI  implements AdditionalEntity{

    public userPoolId: string;
	public invitationMessage: DevumCustomizeInvitationMessages;
	public customizeEmail: DevumCustomizeEmailAddress;
	public emailVerification: DevumEmailVerification;

    constructor(){
    }
}
 
export class MethodParams  implements AdditionalEntity{

    public name: string;
	public type: string;
	public isOptional: boolean;
	public isArray: boolean;
	public isAttributeProperty: boolean;

    constructor(){
    }
}
 
export class MethodResult  implements AdditionalEntity{

    public methodName: string;
	public params: Array<MethodParams>;
	public supportedType: string;
	public methodType: string;
	public returningResultType: Array<ReturnMethodParams>;
	public returningSchemaName?: string;
	public wsLogTopic?: string;

    constructor(){
    }
}
 
export class MetricsEnumValues  implements AdditionalEntity{

    public id: string;
	public name: string;
	public icon: string;

    constructor(){
    }
}
 
export class MicroserviceFunctionsQuery  implements AdditionalEntity{

    public msInstallationId: string;
	public version: string;

    constructor(){
    }
}
 
export class MobileApplicationCreationAndSettingUIPayload  implements AdditionalEntity{

    public appName: string;
	public packageName: string;
	public appCode: string;
	public appIcon: string;
	public splashIcon: string;
	public versionNumber: number;
	public versionCode: string;
	public operatingSystemType: string;
	public appUUIDIdentifier: string;
	public fileType: string;

    constructor(){
    }
}
 
export class MobileSetting extends GlobalBaseEntity {

    public id: string;
	public versionNumber: number;
	public versionCode: string;
	public operatingSystemType: string;
	public manageMobileAppName: string;
	public status: string;
	public appUUIDIdentifier: string;
	public appCode: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public appName: string;
	public appIcon: string;
	public splashIcon: string;
	public downloadAppUrl?: string;
	public buildId?: string;
	public fileType?: string;
	public manageMobileApp?: ManageMobileApp;

    constructor(){
       super();
    }
}
 
export class MockData  implements AdditionalEntity{

    public data: Array<Object>;

    constructor(){
    }
}
 
export class MockRandom  implements AdditionalEntity{

    public from: number;
	public to: number;

    constructor(){
    }
}
 
export class MockSimulatedInitDataSettings  implements AdditionalEntity{

    public entitySchemaName?: string;
	public fields: Array<RandomField>;
	public randomSize: MockRandom;
	public frequency: TimeDuration;
	public hfDataItem?: DataItem;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class MockUserTaskCompletion  implements AdditionalEntity{

    public action: string;
	public data: Object;

    constructor(){
    }
}
 
export class ModelConfiguration extends AppEntity {

    public id: string;
	public name: string;
	public destinationEntityName: string;
	public sourceEntityName: string;
	public selectorEntityName?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public threeDType: string;
	public composerGroupName?: string;
	public composerGroup?: ComposerGroup;

    constructor(){
       super();
    }
}
 
export class MongoAdvancedAuditLog  implements AdditionalEntity{

    public id: string;
	public schemaName: string;
	public entityPkIds: Array<string>;
	public operationType: string;
	public dateTime: string;
	public userName: string;
	public enabled: boolean;
	public siteCode: string;
	public data?: string;
	public userAgent?: string;
	public ipAddress?: string;
	public location?: string;
	public transactionId: string;
	public schema?: EntitySchema;
	public user?: UserAccount;

    constructor(){
    }
}
 
export class MongoCustomFilterDetailResult  implements AdditionalEntity{

    public results: Array<Object>;

    constructor(){
    }
}
 
export class MongoCustomFilterQuery  implements AdditionalEntity{

    public fieldName: string;
	public fieldValue: string;

    constructor(){
    }
}
 
export class MonitoringStat  implements AdditionalEntity{

    public name: string;
	public charvakaModuleType: string;
	public charvakaEntityId: string;
	public versionNumber: number;
	public executionTime: string;
	public status: string;
	public errorMessage?: string;
	public siteCode: string;
	public appCode: string;
	public expireAt: string;
	public id?: string;

    constructor(){
    }
}
 
export class MonitoringStatUI  implements AdditionalEntity{

    public elementUUID?: string;
	public charvakaIdentifier: CharvakaIdentifierUI;
	public container: ExecutionStatContainer;

    constructor(){
    }
}
 
export class MonitoringStatsQuery  implements AdditionalEntity{

    public charvakaModuleType: string;
	public charvakaEntityId: string;

    constructor(){
    }
}
 
export class MoveFluentCompositionCommand  implements AdditionalEntity{

    public charvakaFluentFunctionCompositionId: string;
	public charvakaFluentServiceName: string;
	public previousCompositionId?: string;
	public parentId?: string;
	public secondaryParentId?: string;
	public uiProperties?: string;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
    }
}
 
export class MultiDataViewContainer  implements AdditionalEntity{

    public name: string;
	public list: Array<DataViewItem>;

    constructor(){
    }
}
 
export class MultiDataViewTimeSeriesItem  implements AdditionalEntity{

    public name: string;
	public list: Array<DataViewItem>;
	public tsDate: string;

    constructor(){
    }
}
 
export class NotificationMetadataUUIDQuery  implements AdditionalEntity{

    public uuid: string;

    constructor(){
    }
}
 
export class NumberValue  implements AdditionalEntity{

    public data: number;

    constructor(){
    }
}
 
export class OTPConfirmationCommand  implements AdditionalEntity{

    public userName: string;
	public oTP: number;

    constructor(){
    }
}
 
export class OptionDetail  implements AdditionalEntity{

    public name: string;
	public value: Object;

    constructor(){
    }
}
 
export class OverrideOnDeploymentValue  implements AdditionalEntity{

    public templateValue: string;
	public deployedValue?: string;

    constructor(){
    }
}
 
export class Page extends AppEntity {

    public id: string;
	public name: string;
	public description?: string;
	public icon?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public sortOrder?: number;
	public pageType: string;
	public isSubPage: boolean;
	public landingPage: boolean;
	public parentPageName?: string;
	public siteCodes: Array<number>;
	public masterPage?: string;
	public toPageNames: Array<string>;
	public pageParams: string;
	public thumbnailUrl?: string;
	public isSeed: boolean;

    constructor(){
       super();
    }
}
 
export class PageEventsContainer extends AppEntity {

    public id: string;
	public pageName: string;
	public eventProducerName: string;
	public eventActionContainers: string;
	public enabled: boolean;
	public page?: Page;

    constructor(){
       super();
    }
}
 
export class PageOfflineStrategy extends AppEntity {

    public id: string;
	public pageName: string;
	public variables: string;
	public offlineDataSourceConfigurations: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public page?: Page;

    constructor(){
       super();
    }
}
 
export class PageParameter extends AppEntity {

    public id: string;
	public name: string;
	public type: string;
	public pageName: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public isRequired: boolean;
	public page?: Page;

    constructor(){
       super();
    }
}
 
export class PagesByUserResult  implements AdditionalEntity{

    public totalResults: number;
	public results: Array<Page>;

    constructor(){
    }
}
 
export class PasswordPolicy  implements AdditionalEntity{

    public minLength: number;
	public passwordValidityDays: number;
	public requireNumbers: boolean;
	public requireSymbols: boolean;
	public requireUpperCaseLetters: boolean;
	public requireLowerCaseLetters: boolean;
	public allowAdminCreateUserOnly: boolean;

    constructor(){
    }
}
 
export class PasswordRecoveryByEmailIdQuery  implements AdditionalEntity{

    public email: string;

    constructor(){
    }
}
 
export class PasswordRecoveryByMobileNumberCommand  implements AdditionalEntity{

    public mobileNumber: number;

    constructor(){
    }
}
 
export class Payload  implements AdditionalEntity{

    public dsType: string;
	public groupName: string;
	public serviceName: string;
	public datasourceCrudType: string;
	public record: string;

    constructor(){
    }
}
 
export class PermittedPage  implements AdditionalEntity{

    public id: string;
	public name: string;
	public description?: string;
	public icon?: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public modifiedDate: string;
	public sortOrder?: number;
	public pageType: string;
	public isSubPage: boolean;
	public landingPage: boolean;
	public parentPageName?: string;
	public appCode: string;
	public siteCodes: Array<number>;
	public masterPage?: string;
	public toPageNames: Array<string>;
	public pageParams: string;
	public thumbnailUrl?: string;
	public isSeed: boolean;

    constructor(){
    }
}
 
export class PossibleValue  implements AdditionalEntity{

    public value: Object;
	public sequenceId: number;

    constructor(){
    }
}
 
export class PostUserActionProcessorCFSUtilizerBridge  implements AdditionalEntity{

    public returnType: FluentServiceProperty;
	public utilizerName: string;
	public userTaskSchema: DataItem;
	public taskId?: string;
	public elementUUID: string;
	public charvakaProcessName: string;
	public instanceId?: string;
	public taskInstanceId?: string;
	public action?: string;
	public actionTakenBy?: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class PreLoginInfo  implements AdditionalEntity{

    public userPoolPrecis: UserPoolPrecis;
	public appName?: string;
	public appCode?: string;
	public securityIdentifierType: string;
	public runtimeMode: string;
	public orgCode: string;
	public domain?: string;
	public orgTimeZone: string;
	public currentDateTime: string;
	public environment: string;
	public feURL: string;
	public consoleURL: string;
	public appLogoUrl?: string;
	public planDetails: DevumSubscribedPlan;
	public appTitle?: string;
	public favIcon?: string;
	public beVersion: string;
	public beDeployedAt: string;
	public ldapSettings?: LDAP_Settings;

    constructor(){
    }
}
 
export class PrecisEntityCommand  implements AdditionalEntity{

    public entitySchemaName: string;
	public entitySchemaPrecisId: string;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class ProcessActivityInfoResult  implements AdditionalEntity{

    public elementUUID: string;
	public elementType: string;
	public elementName?: string;
	public taskStatus: string;
	public serviceTaskElement?: string;
	public assignee?: number;
	public createTime: string;
	public entitySchemaName?: string;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class ProcessAndTaskViewDetailHistory  implements AdditionalEntity{

    public elementUUID: string;
	public elementName: string;
	public errorMessageOpt?: string;
	public taskStatus: string;
	public transactionDate: string;

    constructor(){
    }
}
 
export class ProcessAuditQuery  implements AdditionalEntity{

    public charvakaProcessName: string;
	public version: number;
	public processInstanceId: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessDefinitionsByUserResult  implements AdditionalEntity{

    public totalResults: number;
	public results: Array<ProcessDefinitionsResult>;

    constructor(){
    }
}
 
export class ProcessDefinitionsResult  implements AdditionalEntity{

    public charvakaProcessDefinitionDetailName: string;
	public charvakaProcessName: string;
	public triggerEventName: string;
	public canInitiate: boolean;
	public icon?: string;
	public hasAnyTaskAssigned: boolean;
	public schemaType: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessDefintionByUserQuery  implements AdditionalEntity{

    public hybridUserName?: string;
	public userAccountName: string;
	public userAccount?: UserAccount;

    constructor(){
    }
}
 
export class ProcessDeleteList  implements AdditionalEntity{

    public mongoObjectIds: Array<string>;

    constructor(){
    }
}
 
export class ProcessDeployQuery  implements AdditionalEntity{

    public charvakaProcessName: string;
	public json: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessDetailQuery  implements AdditionalEntity{

    public processId: string;
	public instanceId: string;
	public elementUUID: string;

    constructor(){
    }
}
 
export class ProcessDetailsCommand  implements AdditionalEntity{

    public initiatorName: string;
	public instanceId: string;
	public processInstanceState: string;
	public charvakaProcessName: string;
	public version: number;
	public processRemarks?: string;
	public cancelledBy?: string;
	public processCreateTime: string;
	public initiator?: UserAccount;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessDetailsFilterResult  implements AdditionalEntity{

    public totalResults: number;
	public schema: Object;
	public results: any;

    constructor(){
    }
}
 
export class ProcessIdAndElementUUIdCommand  implements AdditionalEntity{

    public elementUUID: string;
	public charvakaProcessName: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessInstanceAuditCommand  implements AdditionalEntity{

    public processAduitResult: Array<ElementExecutionResult>;

    constructor(){
    }
}
 
export class ProcessInstanceTaskStatusCount  implements AdditionalEntity{

    public instanceId: string;
	public charvakaProcessName: string;
	public schemaName: string;
	public statuses: Array<StatusBarForIndividualFormResult>;
	public message?: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessLog  implements AdditionalEntity{

    public appCode: string;
	public instanceId: string;
	public elementClass: string;
	public siteCode: string;
	public ts: string;
	public msg: string;
	public logTp: string;

    constructor(){
    }
}
 
export class ProcessLogQuery  implements AdditionalEntity{

    public processId: string;
	public logType?: string;

    constructor(){
    }
}
 
export class ProcessManagerHistoryResult  implements AdditionalEntity{

    public activitiesHistory: Array<ActivityHistoryResult>;
	public incidentCreated?: HistoryIncidentResult;

    constructor(){
    }
}
 
export class ProcessManagerWSEvent  implements AdditionalEntity{

    public id: string;
	public record: string;
	public processInstanceState: string;

    constructor(){
    }
}
 
export class ProcessParamCommand  implements AdditionalEntity{

    public paramName: string;

    constructor(){
    }
}
 
export class ProcessSchemaTaskStatusCount  implements AdditionalEntity{

    public charvakaProcessName: string;
	public schemaName: string;
	public pendingTaskCount: number;
	public overdueTaskCount: number;
	public completedTaskCount: number;
	public unclaimedTaskCount: number;
	public message?: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessStatsQuery  implements AdditionalEntity{

    public processId: string;

    constructor(){
    }
}
 
export class ProcessTaskHistoryResult  implements AdditionalEntity{

    public schemaName: string;
	public processTaskHistories: string;

    constructor(){
    }
}
 
export class ProcessTaskLogQuery  implements AdditionalEntity{

    public processId: string;
	public elementClass: string;
	public logType?: string;

    constructor(){
    }
}
 
export class ProcessUserTaskCountQuery  implements AdditionalEntity{

    public userName: string;
	public hybridUserName?: string;
	public charvakaProcessName: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessViewDetailUiPayload  implements AdditionalEntity{

    public processInstanceId: string;
	public charvakaProcessName: string;
	public version: number;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ProcessViewDetailUiResponse  implements AdditionalEntity{

    public processInstanceId: string;
	public processName: string;
	public version: number;
	public histories: Array<ProcessAndTaskViewDetailHistory>;

    constructor(){
    }
}
 
export class PropertyDetail  implements AdditionalEntity{

    public name: string;
	public group: string;
	public sequenceNumber: number;
	public description: string;
	public controlType: string;
	public defaultValue?: string;
	public editable: boolean;
	public bindingDetail: BindingDetail;
	public regexPattern: string;
	public conditionDetail: ConditionDetail;
	public required: boolean;
	public zkURL: string;
	public overrideOnDeployment: boolean;
	public denType: string;
	public isLambda: boolean;
	public isArray: boolean;
	public isReturnProperty: boolean;
	public label: string;

    constructor(){
    }
}
 
export class PropertyValueDetail  implements AdditionalEntity{

    public propertyName: string;
	public propertyValue: string;

    constructor(){
    }
}
 
export class PropertyValuesQuery  implements AdditionalEntity{

    public serviceTaskName: string;
	public propertyName: string;
	public dependentPropertyValues: any;

    constructor(){
    }
}
 
export class PublishDraftCommand  implements AdditionalEntity{

    public appArtifactType: string;
	public aggregateAppEntityName: string;

    constructor(){
    }
}
 
export class QuickStyleTemplate extends AppEntity {

    public id: string;
	public name: string;
	public pageName: string;
	public controlName: string;
	public attributeValues: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public siteCodes: Array<number>;
	public page?: Page;

    constructor(){
       super();
    }
}
 
export class R_Email  implements AdditionalEntity{

    public to: Array<string>;
	public cc: Array<string>;
	public bcc: Array<string>;
	public body: string;
	public includeAsRepoLink: boolean;
	public includeAsAttachment: boolean;
	public includeAsZIPAttachment: boolean;
	public includeHTML: boolean;
	public sendEmailIfEmpty: boolean;

    constructor(){
    }
}
 
export class R_EndDate  implements AdditionalEntity{

    public runIndefinitely: boolean;
	public runNoOfTimes?: number;
	public runUntilDate?: string;

    constructor(){
    }
}
 
export class R_FTP  implements AdditionalEntity{

    public serverURL: string;
	public port: number;
	public protocol: string;
	public directory: string;
	public userName: string;
	public password: string;
	public overwriteFiles: boolean;
	public sequentialFileNames?: string;

    constructor(){
    }
}
 
export class R_Notification  implements AdditionalEntity{

    public to: Array<string>;
	public subject: string;
	public successMsg?: string;
	public failureMsg?: string;
	public includeReportInfo: boolean;
	public includeStackTrace: boolean;

    constructor(){
    }
}
 
export class R_OutputDestination  implements AdditionalEntity{

    public email?: R_Email;
	public ftp?: R_FTP;
	public whatsApp?: R_WhatsApp;

    constructor(){
    }
}
 
export class R_RecurrenceType  implements AdditionalEntity{

    public single?: SingleRecurrenceType;
	public calendar?: CalendarRecurrenceType;

    constructor(){
    }
}
 
export class R_WhatsApp  implements AdditionalEntity{

    public countryCode: string;
	public number: number;
	public description: string;

    constructor(){
    }
}
 
export class RandomField  implements AdditionalEntity{

    public fieldName: string;
	public possibleValues: Array<PossibleValue>;
	public randomRange?: MockRandom;
	public isMasterField: boolean;
	public sequential?: RunInSequential;
	public hfType?: string;

    constructor(){
    }
}
 
export class RangeSelectorCommand  implements AdditionalEntity{

    public startDate?: string;
	public endDate?: string;

    constructor(){
    }
}
 
export class ReactiveFluentServiceBridge  implements AdditionalEntity{

    public returnType: FluentServiceProperty;
	public utilizerName: string;
	public propertyContainer?: FluentServicePropertyContainer;

    constructor(){
    }
}
 
export class RegisterMicroserviceCmd  implements AdditionalEntity{

    public customerMicroserviceName: string;
	public microserviceVersion: string;
	public installationId: string;
	public globalVariables: Array<GlobalVariableProperty>;
	public customerMicroservice?: CustomerMicroservice;

    constructor(){
    }
}
 
export class RejectionStatementCommand  implements AdditionalEntity{

    public message: string;
	public contentType: string;

    constructor(){
    }
}
 
export class Report extends AppEntity {

    public id: string;
	public name: string;
	public description?: string;
	public url: string;
	public icon: string;
	public reportTimeParamsType?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public reportGroupUUID: string;
	public reportUUID: string;
	public datePlaceholder?: string;
	public endDatePlaceholder?: string;
	public label: string;

    constructor(){
       super();
    }
}
 
export class ReportGroup extends AppEntity {

    public id: string;
	public name: string;
	public icon: string;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public reportGroupUUID: string;

    constructor(){
       super();
    }
}
 
export class ReportOutputConfig  implements AdditionalEntity{

    public fileName: string;
	public fileFormat: Array<string>;
	public outputDestination: R_OutputDestination;

    constructor(){
    }
}
 
export class ReportParameter extends AppEntity {

    public id: string;
	public reportParameterName: string;
	public reportUUID: string;
	public entityName: string;
	public defaultValue: Array<number>;
	public sequenceNo: number;
	public isRequired: boolean;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public serviceUUID?: string;
	public isDependent?: boolean;
	public payLoad?: string;
	public url: string;
	public payloadMapping?: string;
	public propertyName: string;
	public placeHolder?: string;
	public reportProperties: Array<string>;
	public reportParameter?: ReportParameter;

    constructor(){
       super();
    }
}
 
export class ReportSchedule extends AppEntity {

    public id: string;
	public name: string;
	public reportId: string;
	public paramMapping: Object;
	public startDate: string;
	public recurrenceType: R_RecurrenceType;
	public time: string;
	public reportOutputConfig: ReportOutputConfig;
	public notification: R_Notification;
	public siteCodes: Array<string>;
	public enabled: boolean;

    constructor(){
       super();
    }
}
 
export class ReportSetting extends AppEntity {

    public id: string;
	public reportSettingName: string;
	public entity: string;
	public defaultValue: Array<string>;
	public enabled: boolean;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public serviceUUID: string;
	public reportUUID: string;
	public reportProperties: Array<string>;
	public reportSetting?: ReportSetting;

    constructor(){
       super();
    }
}
 
export class RestResponderCFSBridge  implements AdditionalEntity{

    public utilizerName: string;
	public methodType: string;
	public url: string;
	public secret: string;
	public payload?: FluentServiceProperty;
	public response: FluentServiceProperty;
	public siteMappingKey?: string;
	public dependentCustomField?: string;

    constructor(){
    }
}
 
export class RestartProcessInstanceQuery  implements AdditionalEntity{

    public processInstanceId: number;
	public activityId: string;

    constructor(){
    }
}
 
export class RetryDeploymentCommand  implements AdditionalEntity{

    public appCode: string;
	public orgCode: string;
	public siteCodeOpt?: string;
	public solutionArtifactUpdatedList: Array<AppArtifactUpdated>;
	public version: string;

    constructor(){
    }
}
 
export class RetryProcessCommand  implements AdditionalEntity{

    public charvakaProcessName: string;
	public versionNumber: number;
	public instanceId: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class ReturnMethodParams  implements AdditionalEntity{

    public name: string;
	public type: string;
	public valueOpt: Array<ReturnMethodParams>;
	public props: Object;

    constructor(){
    }
}
 
export class Role extends GlobalBaseEntity {

    public id: string;
	public name: string;
	public description?: string;
	public runtimeMode: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public label: string;
	public isSeed: boolean;

    constructor(){
       super();
    }
}
 
export class RoleDetailInfo  implements AdditionalEntity{

    public id: string;
	public roleName: string;
	public description: string;
	public createDate: string;
	public lastUpdatedDate: string;
	public runTimeMode: string;
	public isSystemData: boolean;
	public enabled: boolean;
	public role?: Role;

    constructor(){
    }
}
 
export class RoleInfo  implements AdditionalEntity{

    public roleName: string;
	public description?: string;
	public createDate: string;
	public lastUpdatedDate: string;
	public enabled: boolean;
	public id: string;
	public role?: Role;

    constructor(){
    }
}
 
export class RolePrecis  implements AdditionalEntity{

    public roleId: string;
	public name: string;
	public description?: string;
	public runtimeMode: string;

    constructor(){
    }
}
 
export class RunInSequential  implements AdditionalEntity{

    public isRunInSequential: boolean;
	public walkBack: boolean;

    constructor(){
    }
}
 
export class RuntimeLatestVersions  implements AdditionalEntity{

    public fe_version: string;
	public be_version: string;

    constructor(){
    }
}
 
export class SanitizeQuery  implements AdditionalEntity{

    public date: string;
	public loggedInuserName: number;
	public requestId: string;

    constructor(){
    }
}
 
export class SaveOrUpdateIdentityProvidersPayloadUI  implements AdditionalEntity{

    public identityProviderType: string;
	public userPoolId: string;
	public providerTypeAsJson: string;

    constructor(){
    }
}
 
export class SearchByLabelQuery  implements AdditionalEntity{

    public label: string;

    constructor(){
    }
}
 
export class SearchMsCmd  implements AdditionalEntity{

    public customerMicroserviceName: string;
	public microserviceVersion: string;
	public installationId: string;
	public microserviceType: string;
	public isAdded: boolean;
	public customerMicroservice?: CustomerMicroservice;

    constructor(){
    }
}
 
export class SearchTextUiPayload  implements AdditionalEntity{

    public searchText: string;

    constructor(){
    }
}
 
export class SearchUiResponse  implements AdditionalEntity{

    public entitySearchDetails: Array<EntitySearchDetail>;

    constructor(){
    }
}
 
export class SentOTPUserAccount  implements AdditionalEntity{

    public userName: string;

    constructor(){
    }
}
 
export class ServiceStub  implements AdditionalEntity{

    public taskName: string;
	public serviceVariableName: string;
	public serviceName: string;
	public serviceType: string;
	public mockData?: MockData;
	public mockServiceName?: string;
	public useFromTaskName?: string;
	public mockUserTaskCompletion?: MockUserTaskCompletion;

    constructor(){
    }
}
 
export class ServiceStubId  implements AdditionalEntity{

    public taskName: string;
	public serviceVariableName: string;
	public serviceName: string;
	public serviceType: string;
	public mockData?: string;
	public mockServiceName?: string;
	public useFromTaskName?: string;
	public mockUserTaskCompletion?: string;

    constructor(){
    }
}
 
export class ServiceTaskDetails  implements AdditionalEntity{

    public taskClassName: string;
	public injectableServices: Array<InjectableServiceHolder>;
	public elementName: string;
	public taskClassSimpleName: string;

    constructor(){
    }
}
 
export class ServicesQuery  implements AdditionalEntity{

    public classNames: Array<string>;
	public elementType: string;

    constructor(){
    }
}
 
export class SetDefaultAppCommand  implements AdditionalEntity{

    public appRegistryName: string;
	public userName: string;
	public appRegistry?: AppRegistry;

    constructor(){
    }
}
 
export class SetDefaultSiteCommand  implements AdditionalEntity{

    public siteCode: string;
	public userName: string;

    constructor(){
    }
}
 
export class SettingPrecis  implements AdditionalEntity{

    public fullPath: string;
	public subLevels: Array<string>;
	public key: string;
	public uom: string;
	public min?: number;
	public max?: number;
	public fieldDetails: Object;
	public value: Object;
	public isLearnable: boolean;
	public learnedValue?: Object;
	public mode: number;
	public description?: string;
	public isReadOnly: boolean;

    constructor(){
    }
}
 
export class SettingsResult  implements AdditionalEntity{

    public settings: Array<SettingPrecis>;

    constructor(){
    }
}
 
export class SingleRecurrenceType  implements AdditionalEntity{

    public unitNumber: number;
	public unitName: string;
	public endDate: R_EndDate;

    constructor(){
    }
}
 
export class Site extends GlobalBaseEntity {

    public id: string;
	public name: string;
	public siteCode: string;
	public siteLocation: string;
	public enabled: boolean;
	public isFinancialYear: boolean;
	public siteTimeZone: string;
	public logo?: string;
	public mapTileLayerUrl?: string;
	public label: string;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;

    constructor(){
       super();
    }
}
 
export class SiteCustomAttribute extends GlobalBaseEntity {

    public id: string;
	public propertyName: string;
	public defaultValues: Array<string>;
	public isRequired: boolean;
	public isArray: boolean;
	public isSelfReference: boolean;
	public fieldType: string;
	public enabled: boolean;
	public label: string;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;

    constructor(){
       super();
    }
}
 
export class SiteMap extends AppEntity {

    public id: string;
	public name: string;
	public description: string;
	public pageType: string;
	public enabled: boolean;
	public label: string;

    constructor(){
       super();
    }
}
 
export class SiteMapPermissionsQuery  implements AdditionalEntity{

    public menuItemId: string;

    constructor(){
    }
}
 
export class SiteMappingCommand  implements AdditionalEntity{

    public siteCode: string;
	public appRegistryId: number;

    constructor(){
    }
}
 
export class SitePrecis  implements AdditionalEntity{

    public id: string;
	public name: string;
	public isDefault: boolean;
	public siteCode: string;

    constructor(){
    }
}
 
export class SiteResult  implements AdditionalEntity{

    public name: string;
	public siteCode?: string;

    constructor(){
    }
}
 
export class SitesOverview  implements AdditionalEntity{

    public createdSites: number;
	public activeSites: number;
	public inActiveSites: number;
	public maxAllowedSites: number;

    constructor(){
    }
}
 
export class SlotPromptMessageCommand  implements AdditionalEntity{

    public slotPromptMessage: string;
	public contentType: string;

    constructor(){
    }
}
 
export class Sortable  implements AdditionalEntity{

    public sortingField: string;
	public sortingOperator: string;
	public sortingFieldType: string;

    constructor(){
    }
}
 
export class Sorter  implements AdditionalEntity{

    public field: string;
	public priority: number;

    constructor(){
    }
}
 
export class SortingProperties  implements AdditionalEntity{

    public sortingFields: Array<Sorter>;
	public sortingOperator: string;

    constructor(){
    }
}
 
export class SpatialImportResult  implements AdditionalEntity{

    public isSuccess: boolean;
	public errorMsg?: string;

    constructor(){
    }
}
 
export class SpatialQueryParams  implements AdditionalEntity{

    public name: string;
	public dataFormatId: number;
	public fileType: string;
	public isUTM: boolean;
	public isSouth?: string;
	public zoneNumber?: string;
	public comments?: string;
	public fileName: string;
	public fileCreationDate: string;
	public fault?: string;

    constructor(){
    }
}
 
export class StartOrStopReactiveStreamingFSCommand  implements AdditionalEntity{

    public fluentServiceName: string;

    constructor(){
    }
}
 
export class StartOrStopTimerFSCommand  implements AdditionalEntity{

    public fluentServiceName: string;

    constructor(){
    }
}
 
export class StatusBarForIndividualFormQuery  implements AdditionalEntity{

    public charvakaProcessName: string;
	public collectionName: string;
	public instanceId: string;
	public userAccountName: string;
	public charvakaProcess?: CharvakaProcess;
	public userAccount?: UserAccount;

    constructor(){
    }
}
 
export class StatusBarForIndividualFormResult  implements AdditionalEntity{

    public statusName: string;
	public totalCount: number;

    constructor(){
    }
}
 
export class StatusBarQuery  implements AdditionalEntity{

    public charvakaProcessName: string;
	public userName: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class StringValue  implements AdditionalEntity{

    public data: string;

    constructor(){
    }
}
 
export class SubscribedState  implements AdditionalEntity{

    public organisationCode: string;
	public subscriptionId: string;
	public noOfUserAccounts: number;
	public noOfAppUserAccounts: number;
	public startDate: string;
	public endDate: string;
	public identityProvider: string;

    constructor(){
    }
}
 
export class SupportedOperationType  implements AdditionalEntity{

    public operationName: string;
	public operationShortCode: string;
	public apiName: string;

    constructor(){
    }
}
 
export class TMQuery  implements AdditionalEntity{

    public isDayWise: boolean;

    constructor(){
    }
}
 
export class TaskCompletedState  implements AdditionalEntity{

    public processId: string;
	public schemaName: string;
	public taskId: string;
	public schema?: EntitySchema;

    constructor(){
    }
}
 
export class TaskCompletedStateV2  implements AdditionalEntity{

    public schemaNames: Array<string>;
	public taskId: string;

    constructor(){
    }
}
 
export class TaskDeleteList  implements AdditionalEntity{

    public mongoObjectIds: Array<string>;

    constructor(){
    }
}
 
export class TaskDetailHelperCommand  implements AdditionalEntity{

    public taskDetails: UserTaskDetail;

    constructor(){
    }
}
 
export class TaskInfoQuery  implements AdditionalEntity{

    public taskId: string;
	public elementUUID: string;
	public charvakaProcessName: string;
	public taskInstanceId: string;
	public tcName?: string;
	public charvakaProcess?: CharvakaProcess;
	public tc?: CharvakaTestCase;

    constructor(){
    }
}
 
export class TaskInfoResult  implements AdditionalEntity{

    public subTaskId: string;
	public elementUUID: string;
	public instanceId: string;
	public assignee?: string;
	public assignedHybridEntityName?: string;
	public userTaskData: DataTable;
	public actions: Array<UserActionsProperty>;
	public formMetaData: FormMetaData;
	public createDate: string;
	public triggerEventName: string;
	public taskStatus: string;
	public taskPriority: string;
	public summary: Object;
	public orientationType: string;
	public taskInstanceId: string;
	public tcName?: string;
	public processId: string;
	public version: number;
	public triggerEvent?: EntitySchema;

    constructor(){
    }
}
 
export class TaskStatsQuery  implements AdditionalEntity{

    public processId: string;
	public elementClass: string;

    constructor(){
    }
}
 
export class TaskStatusCommand  implements AdditionalEntity{

    public pendingTaskCount: number;
	public overdueTaskCount: number;
	public completedTaskCount: number;
	public unclaimedTaskCount: number;

    constructor(){
    }
}
 
export class TemplateCreationStatus  implements AdditionalEntity{

    public status: string;

    constructor(){
    }
}
 
export class TemplateDownloadCmd  implements AdditionalEntity{

    public orgCode: string;
	public templateName: string;
	public templateType: string;
	public templateAppCode: string;

    constructor(){
    }
}
 
export class TemplateInfoWs  implements AdditionalEntity{

    public message: string;
	public status: string;

    constructor(){
    }
}
 
export class TemplateThumbnailDetail  implements AdditionalEntity{

    public version: string;
	public createdBy: string;
	public createDate: string;
	public description: string;
	public templateType: string;
	public svgIcon?: string;
	public templateAppCode: string;
	public environment: string;
	public templateName: string;
	public isWithMongoData: boolean;

    constructor(){
    }
}
 
export class TestCaseExecutionResult extends AppEntity {

    public id: string;
	public charvakaTestCaseName: string;
	public resultStatus: string;
	public lastRanAt: string;
	public failedAsserts: Array<string>;
	public passedAsserts: Array<string>;
	public enabled: boolean;
	public charvakaTestCase?: CharvakaTestCase;

    constructor(){
       super();
    }
}
 
export class TestCaseOptionsContainer  implements AdditionalEntity{

    public initSchemaName?: string;
	public services: Array<ServiceTaskDetails>;
	public serviceTaskCount: number;

    constructor(){
    }
}
 
export class Theme extends GlobalBaseEntity {

    public id: string;
	public name: string;
	public primaryColor: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public label: string;
	public isSeed: boolean;

    constructor(){
       super();
    }
}
 
export class TimeDuration  implements AdditionalEntity{

    public value: number;
	public unitType: string;

    constructor(){
    }
}
 
export class TimerFluentServiceBridge  implements AdditionalEntity{

    public returnType: FluentServiceProperty;
	public utilizerName: string;
	public timerDefinitionType: string;
	public timerDefinitionValue?: string;
	public durationType?: string;
	public cronExpression?: string;
	public cycleType?: string;
	public cycleStartTime?: string;
	public cycleNumber?: number;
	public initialDurationInSeconds?: number;

    constructor(){
    }
}
 
export class Token extends GlobalBaseEntity {

    public id: string;
	public userName: string;
	public tokenValue: string;
	public loggedInTime: string;
	public loggedOutTime?: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public modifiedDate: string;
	public devOrAppUserType: string;

    constructor(){
       super();
    }
}
 
export class TokenValidity  implements AdditionalEntity{

    public days: number;
	public minutes: number;

    constructor(){
    }
}
 
export class TriggerBuildUIPayload  implements AdditionalEntity{

    public packageName: string;
	public operatingSystemType: string;
	public status: string;
	public branch: string;
	public authToken: string;
	public domainURL: string;
	public fileType: string;
	public appId: string;
	public workflowId: string;

    constructor(){
    }
}
 
export class TwoDMultiDataViewContainer  implements AdditionalEntity{

    public name: string;
	public list: Array<MultiDataViewContainer>;

    constructor(){
    }
}
 
export class TwoDMultiDataViewTimeSeriesContainer  implements AdditionalEntity{

    public name: string;
	public data: Array<MultiDataViewTimeSeriesItem>;

    constructor(){
    }
}
 
export class UTMZoneSRIDContainer  implements AdditionalEntity{

    public UTMZoneSRID: number;

    constructor(){
    }
}
 
export class UndoFunctionCommand  implements AdditionalEntity{

    public charvakaFluentServiceName: string;
	public charvakaFluentFunctionCompositionId: string;
	public charvakaFluentService?: CharvakaFluentService;

    constructor(){
    }
}
 
export class UpdateGlobalVariablesQuery  implements AdditionalEntity{

    public customerMicroserviceName: string;
	public microserviceVersion: string;
	public installationId: string;
	public globalVariables: Array<GlobalVariableProperty>;
	public customerMicroservice?: CustomerMicroservice;

    constructor(){
    }
}
 
export class UpdateListQuery  implements AdditionalEntity{

    public entityList: Array<EntitySchemaField>;

    constructor(){
    }
}
 
export class UpdateMViewDefinitionQuery  implements AdditionalEntity{

    public id: string;
	public mViewDefinitionName: string;
	public listenToSchemaNames: Array<string>;
	public responseSchemaName?: string;
	public mViewParamSchemaName?: string;
	public splitPeriod: string;
	public mViewParamsMappingQuery: Array<MViewParamsMappingQuery>;
	public mViewTransactionDateQuery: Array<MViewTransactionDateQuery>;
	public isArrayResponse: boolean;
	public isReactiveMView: boolean;
	public timeToLifeDuration?: number;
	public timeToLifeDurationType?: string;
	public triggerWaitPeriodDuration?: number;
	public triggerWaitPeriodDurationType?: string;
	public batchSize?: number;
	public description?: string;
	public label: string;
	public isPersistable: boolean;
	public params?: DataItem;
	public response?: DataItem;

    constructor(){
    }
}
 
export class UpdatePasswordCmd  implements AdditionalEntity{

    public userPoolId: string;
	public previousPassword: string;
	public proposedPassword: string;
	public accessToken: string;

    constructor(){
    }
}
 
export class UpdatePasswordPolicyPayloadUI  implements AdditionalEntity{

    public userPoolId: string;
	public policy: PasswordPolicy;

    constructor(){
    }
}
 
export class UpdateProfileQuery  implements AdditionalEntity{

    public userName: string;
	public emailId?: string;
	public securityQuestionId?: number;
	public passwordAnswer?: string;
	public photoName?: string;
	public mobileNumber?: number;

    constructor(){
    }
}
 
export class UpdateRecoveryPasswordQuery  implements AdditionalEntity{

    public userName: string;
	public otp: number;
	public newPassword: string;

    constructor(){
    }
}
 
export class UpdateTagsCommand  implements AdditionalEntity{

    public fileInfo: FileInfo;
	public category: string;
	public fileType: string;
	public fileGroup: string;

    constructor(){
    }
}
 
export class UpdateUserTypeQuery  implements AdditionalEntity{

    public userName: string;
	public userType: string;

    constructor(){
    }
}
 
export class UserAccount extends GlobalBaseEntity {

    public id: string;
	public name: string;
	public password?: string;
	public email?: string;
	public userIdentifier?: string;
	public passwordAnswer?: string;
	public lastActivityDate?: string;
	public lastLoginDate?: string;
	public lastPasswordChangedDate?: string;
	public mobileNumber?: number;
	public salt?: string;
	public displayName?: string;
	public photoUrl?: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public devOrAppUserType: string;
	public status: string;
	public label: string;
	public isSuperAdmin: boolean;

    constructor(){
       super();
    }
}
 
export class UserAccountAndRoleMappingForSite  implements AdditionalEntity{

    public siteCode: string;
	public userNames: Array<string>;
	public roleNames: Array<string>;

    constructor(){
    }
}
 
export class UserAccountAndRolesBySiteQuery  implements AdditionalEntity{

    public siteCodes: Array<string>;

    constructor(){
    }
}
 
export class UserAccountAppMapping extends GlobalBaseEntity {

    public id: string;
	public appRegistryName: string;
	public userName: string;
	public isDefault: boolean;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public modifiedDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public appRegistry?: AppRegistry;

    constructor(){
       super();
    }
}
 
export class UserAccountOTP extends GlobalBaseEntity {

    public id: string;
	public userName: string;
	public mobileNumber: number;
	public oTP: number;
	public sentDateTime: string;
	public expiryDateTime: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;

    constructor(){
       super();
    }
}
 
export class UserAccountSiteMapping extends GlobalBaseEntity {

    public id: string;
	public userName: string;
	public siteCode: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public isDefault: boolean;

    constructor(){
       super();
    }
}
 
export class UserAccountView extends GlobalBaseEntity {

    public id: string;
	public name: string;
	public userName: string;
	public password?: string;
	public email?: string;
	public securityQuestionName?: string;
	public passwordAnswer?: string;
	public lastActivityDate?: string;
	public lastLoginDate?: string;
	public lastPasswordChangedDate?: string;
	public enabled: boolean;
	public photoName?: string;
	public displayName?: string;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public modifiedDate: string;
	public userIdentifier?: string;
	public mobileNumber?: number;
	public devOrAppUserType: string;
	public userPoolName?: string;

    constructor(){
       super();
    }
}
 
export class UserAction extends AppEntity {

    public id: string;
	public text: string;
	public description?: string;
	public icon?: string;
	public color?: string;
	public enabled: boolean;
	public labels: Array<string>;
	public modifiedDate: string;
	public charvakaProcessDefinitionDetailName: string;
	public name: string;

    constructor(){
       super();
    }
}
 
export class UserActionsProperty  implements AdditionalEntity{

    public name: string;
	public value: string;
	public icon?: string;
	public colorCode?: string;
	public autoResolveFields: Array<AutoResolveField>;
	public formConditionGroup?: FormConditionGroup;

    constructor(){
    }
}
 
export class UserAppSettings extends AppEntity {

    public id: string;
	public userName: string;
	public settings: Object;
	public enabled: boolean;

    constructor(){
       super();
    }
}
 
export class UserCredentialsQuery  implements AdditionalEntity{

    public userName: string;
	public password: string;
	public loginViaLDAP: boolean;

    constructor(){
    }
}
 
export class UserImportResult  implements AdditionalEntity{

    public totalCount: number;
	public insertedCount: number;
	public ignoredCount: number;
	public ignoredUserNames: Array<string>;
	public reasonForIgnoring?: string;

    constructor(){
    }
}
 
export class UserInfo  implements AdditionalEntity{

    public userName: string;
	public isEnabled: boolean;
	public status: string;
	public email?: string;
	public isEmailVerified: boolean;
	public phoneNumber?: string;
	public isPhoneNumberVerified: boolean;
	public lastUpdatedDate: string;
	public createDate: string;

    constructor(){
    }
}
 
export class UserNameAndPoolIdQuery  implements AdditionalEntity{

    public userPoolId: string;
	public userName: string;

    constructor(){
    }
}
 
export class UserPoolAppClient  implements AdditionalEntity{

    public clientId: string;
	public clientName: string;

    constructor(){
    }
}
 
export class UserPoolCustomAttribute  implements AdditionalEntity{

    public name: string;
	public isMutable: boolean;
	public attributeDataType: string;

    constructor(){
    }
}
 
export class UserPoolDetailInfo  implements AdditionalEntity{

    public userName: string;
	public isEnabled: boolean;
	public status: string;
	public email?: string;
	public isEmailVerified: boolean;
	public phoneNumber?: string;
	public isPhoneNumberVerified: boolean;
	public lastUpdatedDate: string;
	public createDate: string;
	public attributes: Object;
	public preferredMfaSetting: string;
	public mfaOptions: Object;
	public userMFASettingList: Array<string>;

    constructor(){
    }
}
 
export class UserPoolDetails  implements AdditionalEntity{

    public id: string;
	public userPoolId: string;
	public name: string;
	public noOfUsers: number;
	public noOfGroups: number;
	public appClients: Array<UserPoolAppClient>;
	public identityProviders: Array<UserPoolIdentityProvider>;
	public status: string;
	public creationDate: string;
	public lastModifiedDate: string;

    constructor(){
    }
}
 
export class UserPoolIdentityProvider  implements AdditionalEntity{

    public providerName: string;
	public providerType: string;

    constructor(){
    }
}
 
export class UserPoolPrecis  implements AdditionalEntity{

    public userPoolId: string;
	public appClientId?: string;
	public region?: string;
	public callbackUrl?: string;
	public domain?: string;

    constructor(){
    }
}
 
export class UserProfile  implements AdditionalEntity{

    public userName: string;
	public emailId?: string;
	public photoUrl?: string;
	public passwordAnswer?: string;
	public mobileNo?: number;

    constructor(){
    }
}
 
export class UserReportSetting extends AppEntity {

    public id: string;
	public reportSettingName: string;
	public defaultValue: Array<string>;
	public userName: string;
	public enabled: boolean;
	public siteCode: string;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public reportSetting?: ReportSetting;

    constructor(){
       super();
    }
}
 
export class UserRole extends GlobalBaseEntity {

    public id: string;
	public userName: string;
	public roleName: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public role?: Role;

    constructor(){
       super();
    }
}
 
export class UserSetting extends GlobalBaseEntity {

    public id: string;
	public userName: string;
	public themeName: string;
	public enabled: boolean;
	public createdBy: string;
	public createDate: string;
	public attributes: Object;
	public labels: Array<string>;
	public sortOrder?: number;
	public modifiedDate: string;
	public theme?: Theme;

    constructor(){
       super();
    }
}
 
export class UserTaskCountQuery  implements AdditionalEntity{

    public charvakaProcessName: string;
	public userName: string;
	public hybridUserName?: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class UserTaskDetail  implements AdditionalEntity{

    public subTaskId?: string;
	public elementUUID: string;
	public elementType: string;
	public processInstanceId: string;
	public assignedUser?: string;
	public assignedHybridEntity?: string;
	public taskStatus: string;
	public charvakaProcessName: string;
	public processVersionNumber: number;
	public claimableUsers: Array<string>;
	public actionTaken?: string;
	public actionTakenBy?: string;
	public header: string;
	public entitySchemaName?: string;
	public taskInstanceId: string;
	public tcName?: string;
	public charvakaProcess?: CharvakaProcess;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class UserTaskElementCountAndDetails  implements AdditionalEntity{

    public elementName: string;
	public elementUUID: string;
	public entitySchemaName: string;
	public unclaimedTaskCount: number;
	public pendingTaskCount: number;
	public overdueTaskCount: number;
	public completedTaskCount: number;
	public className: string;
	public charvakaProcessDefinitionDetailName: string;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class UserTaskElementModified  implements AdditionalEntity{

    public charvakaProcessName: string;
	public elementUUIDs: string;
	public schemaNames: string;
	public pendingTaskCount: number;
	public overdueTaskCount: number;
	public completedTaskCount: number;
	public unclaimedTaskCount: number;
	public msg?: string;
	public charvakaProcess?: CharvakaProcess;

    constructor(){
    }
}
 
export class UserTaskElementModifiedV2  implements AdditionalEntity{

    public entitySchemaName: string;
	public schemaNames: Array<string>;
	public msg?: string;
	public entitySchema?: EntitySchema;

    constructor(){
    }
}
 
export class UserTaskEntityFieldMappingComplexProperty  implements AdditionalEntity{

    public schemaName: string;
	public readOnlyFields: Array<string>;
	public displayFields: Array<string>;

    constructor(){
    }
}
 
export class UsersOverview  implements AdditionalEntity{

    public createdUsers: number;
	public activeUsers: number;
	public inActiveUsers: number;
	public maxAllowedUsers: number;

    constructor(){
    }
}
 
export class UtilizerAndTypeIdContainer  implements AdditionalEntity{

    public utilizerName: string;
	public utilizerType: string;

    constructor(){
    }
}
 
export class UtilizerAndTypeIdContainerHolder  implements AdditionalEntity{

    public containers: Array<UtilizerAndTypeIdContainer>;

    constructor(){
    }
}
 
export class VariableAssert  implements AdditionalEntity{

    public expression: string;
	public assertUUid: string;

    constructor(){
    }
}
 
export class VariablesDefinitionComplexProperty  implements AdditionalEntity{

    public variables: Array<DynamicVariable>;

    constructor(){
    }
}
 
export class VerifyTemplateUploadCmd  implements AdditionalEntity{

    public sourceOrgCode: string;
	public sourceAppCode: string;

    constructor(){
    }
}
 
export class VoidValue  implements AdditionalEntity{

    

    constructor(){
    }
}
 
export class WSTopicDetail  implements AdditionalEntity{

    public wsTopic: string;
	public strategy: string;

    constructor(){
    }
}
 
export class WsTopicResult  implements AdditionalEntity{

    public name: string;
	public template: string;

    constructor(){
    }
}
 
export class ZkSetting extends GlobalBaseEntity {

    public id: string;
	public zkSettingName: string;
	public createDate: string;
	public createdBy: string;
	public enabled: boolean;
	public attributes: Object;
	public modifiedDate: string;
	public zkSetting?: ZkSetting;

    constructor(){
       super();
    }
}
