import { Injectable } from "@angular/core";
import { FilteringManager } from "../controls/core/list-view-control/filtering-manager/filtering-manager";
import { GroupingManager } from "../controls/core/list-view-control/grouping-manager/grouping-manager";
import { PagingManager } from "../controls/core/list-view-control/paging-manager/paging-manager";
import { SortingManager } from "../controls/core/list-view-control/sorting-manager/sorting-manager";


@Injectable()
export class ListViewManagerResolver {

    constructor() {
    }

    resolveGroupingManager() {
        return new GroupingManager();
    }

    resolveFilteringManager() {
        return new FilteringManager();
    }

    resolveSortingManager() {
        return new SortingManager();
    }
    resolvePagingManager() {
        return new PagingManager();
    }

}