export class DomainModellerEventsTopics {
    static SELECTED_ENTITY_SCHEMA = "SELECTED_ENTITY_SCHEMA";
    static SELECTED_BOUNDED_CONTEXT = "SELECTED_BOUNDED_CONTEXT";
    static CREATED_BOUNDED_CONTEXT = "CREATED_BOUNDED_CONTEXT";
    static CREATED_ENTITY_SCHEMA = "CREATED_ENTITY_SCHEMA";
    static CONNECTION_CREATE = "CONNECTION_CREATE";
    static MAP_BOUNDED_CONTEXT = "MAP_BOUNDED_CONTEXT";
    static UPDATE_PUBLISH_ENTITY_SCHEMA = "UPDATE_PUBLISH_ENTITY_SCHEMA";
}

export enum DisableDates {
    DISABLE_PAST_DATES = 'disablePastDates',
    DISABLE_FUTURE_DATES = 'disableFutureDates',
    NONE = 'none'
}
export enum DateConditions {
    MIN_DATE = 'minDate',
    MAX_DATE = 'maxDate',
    NONE = 'none'
}
export enum TimeConditions {
    MIN_TIME = 'minTime',
    MAX_TIME = 'maxTime',
    NONE = 'none'
}
export enum DatePickerTypes {
    YEAR_MONTH = 'YYYY-MM',
    NONE = 'none'
}

export enum FileUploadType {
    PDF = 'pdf',
    DOCX = 'docx',
    XLSX = 'xlsx',
    PPTX = 'pptx',
    TXT = 'txt',
    JPG = 'jpg',
    JPEG = 'jpeg',
    PNG = 'png',
    GIF = 'gif',
    TIFF = 'tiff',
    TIF = 'tif',
    GLB = 'glb',
    GLTF = 'gltf',
    SVG = 'svg',
}

export enum FormFieldOption {
    COPY = 'isCopy',
    POPULATE_FIRST_RECORD = 'isPopulateFirstRecord',
    DISABLE_DATES = 'disableDates', //REVISIT
    SERACH_CHARACTER_COUNT = 'searchCharacterCount', //REVISIT
    ADD_FORM = 'addForm',
    DISPLAY_CURRENT_TIME = 'displayCurrentTime', //REVISIT
    POPULATE_ALL_RECORDS = 'populateAllRecords',
    HIDDEN_IN_ADD_FORM = 'isHiddenInAddMode',
    HIDDEN_IN_EDIT_FORM = 'isHiddenInEditMode',
    HIDDEN_IN_VIEW_FORM = 'isHiddenInViewMode',
    BUTTON_TEXT = 'buttonText',
    READONLY_IN_UPDATE = 'isReadOnlyInUpdate',
    READONLY_IN_ADD_UPDATE = 'isReadOnlyInAddUpdate',
    DATA_TYPE_NUMBER = 'isDataTypeNumber',
    RETAIN_CURRENT_VALUE_IN_SAVE_AND_CREATE_NEW_MODE = 'retainCurrentValue',
    DATE_TIME_MILLIS = 'isDateTimeMillis',
    LAZY_LOAD_ENABLED = 'isLazyLoadEnabled',
    SUB_FIELD_TYPE_ID = 'subFieldTypeId',
    PRESELECT_CURRENT_DATE = 'preSelectCurrentDate',
    PRESELECT_CURRENT_DATETIME = 'preSelectCurrentDateTime',
    DATE_DISABLE_DATES = 'date_disableDates',
    DATE_MULTI_SELECT = 'isDateMultiSelect',
    DATETIME_DISABLE_DATES = 'dateTime_disableDates',
    FILE_TYPES = 'fileTypes',
    MAX_FILE_SIZE = 'maxFileSize',
    COMPRESS_RATIO = 'compressRatio',
    UPLOAD_FROM_GALLERY = 'uploadFromGallery',
    RICH_TEXT_CONTENT = 'richTextContent',
    STATIC_OPTIONS = 'staticOptions',
    RESOURCE_FILE_TYPES = 'resourceFileType',
    MAX_CHAR_TEXT_AREA = 'maxCharTextArea',
    COLOR_PROPERTIES = 'colorProperties',
    DISABLE_COLOR_PROPERTIES = 'disableColorProperties',
    DATE_PICKER_TYPE = 'datePickerType',
    DEFAULT_VALUE = 'defaultValue',
    DATE_TIME_CONDITION = 'dateTimeCondition',
    TIME_CONDITION = 'timeCondition',
    MINMAX_CONDITION_ENTITY = 'minMaxConditionEntity',
    MINMAX_TIME_CONDITION_ENTITY = 'minMaxTimeConditionEntity',
    APPLY_SORT = 'applySort',
    IS_ARRAY = 'isArray',
    IS_MULTIPLE_DRAW = 'isMultipleDraw',
    SHOW_SITE_BOUNDARY = "showSiteBoundary",
}

export enum FileConstants {
    maxFileSize = 2000000
}

export class ConditionalOprConstants {
    static AND = 'AND';
    static OR = 'OR';
}

export enum TabType {
    BOUNDED_CONTEXT = 'bounded_context',
    ENTITY = 'entity'
}

export enum SeedFieldConstants {
    _id = "_id",
    createDate = "createdate",
    modifiedDate = "modifieddate",
    enabled = "enabled",
    createdBy = "createdby",
    id = "id",
    siteCode = "sitecode",
    modifiedBy = "modifiedby",
    rowId = "rowid",
    appCode = "appcode"
}

export enum DSFieldsConstants {
    createDate = "createDate",
    modifiedDate = "modifiedDate",
    createdBy = "createdBy",
    modifiedBy = "modifiedBy",
}

export enum OnDeleteActionsConstants {
    CLOSE_RIGHT_PANEL = 'closeRightPanel'
}

export enum MinMaxConditions {
    min = 'min',
    max = 'max'
}
export enum OperationEnumConstants {
    ReadOnly = "READONLY",
    Hidden = "HIDDEN",
    Show = "SHOW"
}