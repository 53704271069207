import { RtOption } from "../../../../utils/option-helper";
import { Attribute } from "../../../attributes/core/attribute";


export interface ICompanionSubMixin {
  icon: string;
  name: string;
  tooltip: string;
  groupName: RtOption<string>;// group the sub-mixin menu item
  keepAlive?: boolean;

  isEnabled(): boolean;
  toggle(): boolean;
  onDestroy(): void;
}

export abstract class CompanionMixinItems {
  abstract constructMenuItems(): ICompanionSubMixin[];
}


export abstract class CompanionSubMixin<M, U> extends Attribute<M, U> {
  abstract icon: string;
  abstract name: string;
  abstract tooltip: string;
  abstract groupName: RtOption<string>;// group the sub-mixin menu item

  abstract isEnabled(): boolean;
  abstract toggle(): boolean;
  abstract onDestroy(): void;
}
