//Register to keyboard events
import Keyboard from 'keyboardjs'
import { Injectable } from "@angular/core";
import { PageEventProducer } from "../../event-store";
import { StandardEvents, SubEventType } from "../../event-types";
import { ProducerProps, GlobalEventProducerInfo, GlobalEventProducer, GlobalEventData } from "../global-event-producer";
import { GlobalEventDataSchema } from '../../event-schema-provider';
import { RtSome } from '../../../../../../utils/option-helper';

//https://github.com/RobertWHurst/KeyboardJS
export class KeyBoardEventProducerProps extends ProducerProps {

  KEY_PRESS = StandardEvents.KEY_PRESS

  KEY_COMB_PARAM = "Key combination"

  KEY_PRESSED_TIME: GlobalEventDataSchema = { property: "key pressed time" }

  get props(): GlobalEventProducerInfo {
    return {
      producerName: "Keys press",
      description: "provides key combinations to configure",
      iconName: "",
      eventTypes: [this.KEY_PRESS],
      eventParams: [this.KEY_COMB_PARAM],
      produceParams: [],
    }
  }
}

@Injectable()
export class KeyBoardEventProducer extends GlobalEventProducer<KeyBoardEventProducerProps> {

  keyHandlers: {keyCombination:string, handler:(e:any) => void}[]

  get props(): KeyBoardEventProducerProps {
    return new KeyBoardEventProducerProps();
  }

  init(pageEventProducer: PageEventProducer) {
    const eventActionContainers = pageEventProducer.getDefinedActionContainers(this.props.KEY_PRESS)
    eventActionContainers.map(container => {
      const subEventType: SubEventType = container.subEventType.getOrElseThrow("subEventType need to be defined")
      const keyCombParam = subEventType.prop<string>(this.props.KEY_COMB_PARAM)
      const handler = (e) => {
        this.onKeyCombinationPressed(container.id, subEventType)
      }
      Keyboard.bind(keyCombParam, handler);
      this.keyHandlers.push({keyCombination:keyCombParam, handler:handler})
    })

  }


  onKeyCombinationPressed(id: string, subEventType: SubEventType) {
    const data: GlobalEventData = { schema: this.props.KEY_PRESSED_TIME, value: new Date() }
    this.produce(this.props.KEY_PRESS, RtSome(subEventType), [data])
    //fire page event
  }

  dispose() {
    this.keyHandlers.map(keyHandle => {
      Keyboard.unbind(keyHandle.keyCombination, keyHandle.handler);
    })

  }

}
