export enum WebStorageEnum {
  PREVIOUS_SITE_ID = 'previousSiteId',
  SITE_ID = 'siteId',
  SITE_CODE = 'siteCode',
  APP_CODE = 'appCode',
  CURRENT_PAGE = 'currentPage',
  DEFAULT_PAGE = 'defaultPage',
  PREVIOUS_MODULE_ID = 'previousModuleId',
  MODULE_ID = 'ModuleId',
  USER_PROFILE = 'userProfile',
  API_KEY = 'apiKey',
  DB_KEY = 'dbKey',
  LOGGED_IN = 'logged_in',
  ORG_SETTINGS = 'orgSettings',
  SITE_DETAILS = 'siteDetails',
  CURRENT_ORG_TIME = 'currentOrgTime',
  USER_PERMISSIONS = 'userPermissions',
  BUILD_VERSION = 'buildVersion',
  MODULE_DEPLOYMENT = 'moduleDeployment',
  ENTITY_SCHEMAS = 'entitySchemas',
  APPLICATION_CONFIG = 'applicationConfig',
  DB_KEY_PREFIX = "rt-db",
  APP_SESSION_STORAGE = 'APP_SESSION_STORAGE',
  DATA_ENTITY = 'dataEntityService/getStaticAndDynamicInfo',
  CLIENT_LOGO = 'client_logo',
  PARTNER_LOGO = 'partner_logo',
  CONFIGURATION = 'configuration',

  IDENTIFIER_TYPE = 'identifierType',
  ORG_CODE = 'orgCode',
  ORG_TIME_ZONE = 'orgTimeZone',
  SITE_TIME_ZONE = 'siteTimeZone',
  USER_ROLES = "roles",
  USER_ROLE_IDS = "roleIds",
  USER_ROLE_NAMES = "roleNames",
  USER_NAME = "userName",
  USER_ACCOUNT_ID = "userAccountId",
  PRE_LOGIN_INFO = "preLoginInfo",
  HYBRID_USER = "HYBRID_USER",
  LOCATION_URL = "LOCATION_URL",
  USER_TYPE = "USER_TYPE",
  IS_ADMIN = "IS_ADMIN",
  AUTH_INFO = "AUTH_INFO",
  SITE_NAME = 'siteName',
  APP_NAME = 'appName',
  DEFAULT_USER_POOL = 'DEFAULT_USER_POOL',

  COOKIE_NAME = "apiKey",
  SESSION_STORAGE = "SESSION_STORAGE",
  PERMISSION_SET = 'PERMISSION_SET',
  PAGE_NAME = "PAGE_NAME",
  HYBRID_USER_ID = "hybridUserId",
  LOCATION_ID = "locationId",
  SLN_CODE = "slnCode",
  RESOLVED_SETTINGS = "RESOLVED_SETTINGS",
  PREVIOUS_ROUTE = "PREVIOUS_ROUTE",
  MOBILE_IFRAME_KEY = "MOBILE_IFRAME_KEY",
  ACCESS_TOKEN = "ACCESS_TOKEN",
  UI_VERSION = "UI_VERSION",
  API_VERSION = "API_VERSION",
  API_DEPLOYED_ON = "API_DEPLOYED_ON",
  UI_DEPLOYED_ON = "UI_DEPLOYED_ON",
  OFFLINE_DATA_EXPIRY_IN_DAYS = "OfflineDataExpiryInDays"
}
