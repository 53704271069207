export class MobiCSSPropertyNameConstants {
    static BACKGROUND_IMAGE = "--background-image";
    static ZINDEX = "--z-index";
    static CURSOR = "--cursor";
    static OPACITY = "--opacity";
    static P_OPACITY = "--placeholder-opacity";
    static P_COLOR = "--placeholder-color";

    static OVERFLOW = "--overflow";
    static FLOAT = "--float";
    static BACKGROUND = "--background";
    static PROGRESS_BACKGROUND = "--progress-background";
    static BACKGROUND_ACTIVATED = "--background-activated";
    static BACKGROUND_ACTIVATED_OPACITY = "--background-activated-opacity";
    static BACKGROUND_FOCUSED = "--background-focused";
    static BORDER_RADIUS = "--border-radius";
    static BORDER_COLOR = "--border-color";
    static BORDER_STYLE = "--border-style";
    static BORDER_WIDTH = "--border-width";
    static BOX_SHADOW = "--box-shadow";
    static BACKGROUND_CHECKED = "--checkbox-background-checked";
    static CHECKMARK_COLOR = "--checkmark-color";
    static BORDER_COLOR_CHECKED = "--border-color-checked";
    static CHECK_MARK_WIDTH = "--checkmark-width";
    static CHECK_BOX_SIZE = "--size";
    static RIPPLE_COLOR = '--ripple-color';
    static COLOR_ACTIVATED = '--color-activated';
    static COLOR_FOCUSED = '--color-focused';
    static COLOR_HOVER = '--color-hover';
    static PADDING = "--padding";
    static MARGIN = "margin";
    static WIDTH = "--width";
    static HEIGHT = "--height";
    static MIN_WIDTH = "--min-width";
    static MIN_HEIGHT = "--min-height";
    static MAX_WIDTH = "--max-width";
    static MAX_HEIGHT = "--max-height";
    static HANDLE_WIDTH = "--handle-width";
    static HANDLE_HEIGHT = '--handle-height';
    static HANDLE_MAX_HEIGHT = '--handle-max-height';
    static HANDLE_SPACING = '--handle-spacing';
    static HANDLE_BACKGROUND = '--handle-background';
    static HANDLE_BACKGROUND_CHECKED = '--handle-background-checked';
    static HANDLE_BORDER_RADIUS = '--handle-border-radius';
    static HANDLE_BOX_SHADOW = '--handle-box-shadow';
    static SIZE = '--size';
    static DETAIL_ICON_COLOR = '--detail-icon-color';
    static DETAIL_ICON_FONTSIZE = '--detail-icon-font-size';
    static DETAIL_ICON_OPACITY = '--detail-icon-opacity';
    static HIGHLIGHT_COLOR_FOCUSED = '--highlight-color-focused';
    static HIGHLIGHT_COLOR_VALID = '--highlight-color-valid';
    static HIGHLIGHT_COLOR_INVALID = '--highlight-color-invalid';
    static HIGHLIGHT_HEIGHT = '--highlight-height';
    static INNER_BORDER_WIDTH = '--inner-border-width';
    static INNER_BOX_SHADOW = '--inner-box-shadow';
    static INNER_PADDING_BOTTOM = '--inner-padding-bottom';
    static INNER_PADDING_END = '--inner-padding-end';
    static INNER_PADDING_START = '--inner-padding-start';
    static INNER_PADDING_TOP = '--inner-padding-top';

    static BAR_BACKGROUND = '--bar-background';
    static BAR_BACKGROUND_ACTIVE = '--bar-background-active';
    static BAR_BORDER_RADIUS = '--bar-border-radius';
    static BAR_HEIGHT = '--bar-height';
    static KNOB_BACKGROUND = '--knob-background';
    static KNOB_BORDER_RADIUS = '--knob-border-radius';
    static KNOB_BOX_SHADOW = '--knob-box-shadow';
    static KNOB_SIZE = '--knob-size';
    static PIN_BACKGROUND = '--pin-background';
    static PIN_COLOR = '--pin-color';
}