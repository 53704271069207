import { EventActionCRUDType, EventStoreActionWrapper } from "projects/den-core/base-models";
import { IdentityGenerator } from "../../../../utils/identity-generator";
import { RtNone, RtOption, RtSome } from "../../../../utils/option-helper";
import { DsResult } from "../../../data-source/data-source-result-entities";
import { BehaviourData } from "../condition-builder/models/control-conditions";
import { AppEvent } from "./event-models";
import { ProducerType } from "./event-schema-provider"
import { CommonEventType, EventActionGroupUniqueKey, SubEventType } from "./event-types"



export class EventStore {

  constructor(public pageName: string, public pageEventProducers: PageEventProducer[]) { }


  deleteEventProducer(eventProducerName: string) {
    const remaining = this.pageEventProducers.filter(c => c.eventProducerName !== eventProducerName)
    this.pageEventProducers = [...remaining];
  }

  /**
   *
   * @param eventProducerName
   * @param newEventType e.g. KeyPressed
   * @param subEventType e.g. key combinations
   */
  addEvent(eventProducerName: string, newEventType: CommonEventType, subEventType: RtOption<SubEventType>) {
    // const newEventActionContainer = { eventType: newEventType, actions: [] }

    const eventProducerEventsContainer = this.pageEventProducers.find(c => c.eventProducerName == eventProducerName)
    if (RtSome(eventProducerEventsContainer).isDefined) {
      eventProducerEventsContainer.addEvent(newEventType, subEventType)
    } else {
      throw new Error(`Add ${eventProducerName} first, then add event`)
      // const eventProducerEventsContainer = new PageEventProducer(eventProducerName, [])
      // eventProducerEventsContainer.addEvent(eventProducerName, newEventType)
      // this.pageEventProducers.push(eventProducerEventsContainer)
    }
  }

  deleteEvent(eventProducerName: string, eventActionGroupUniqueKey: EventActionGroupUniqueKey) {
    const eventProducerEventsContainer = this.pageEventProducers.find(c => c.eventProducerName == eventProducerName)
    const updatedPageEventProducer = eventProducerEventsContainer.deleteEvent(eventActionGroupUniqueKey)
    const remaining = this.pageEventProducers.filter(c => c.eventProducerName !== eventProducerName)
    this.pageEventProducers = [...remaining, updatedPageEventProducer];
  }

  addAction(eventProducerName: string, eventType: CommonEventType, action: EventAction) {
    const eventProducer = this.pageEventProducers.find(a => a.eventProducerName == eventProducerName)
    if (RtSome(eventProducer).isDefined) {
      eventProducer.addAction(eventType, action)
    } else {
      throw new Error(`eventProducerName: ${eventProducerName} not found`)
    }
  }
  updateAction(eventProducerName: string, eventType: CommonEventType, action: EventAction) {
    const eventProducer = this.pageEventProducers.find(a => a.eventProducerName == eventProducerName)
    if (RtSome(eventProducer).isDefined) {
      eventProducer.updateAction(eventType, action)
    } else {
      throw new Error(`eventProducerName: ${eventProducerName} not found`)
    }
  }

  deleteAction(eventProducerName: string,
    eventType: CommonEventType,
    eventActionId: string) {

    const eventProducer = this.pageEventProducers.find(a => a.eventProducerName == eventProducerName)
    if (RtSome(eventProducer).isDefined) {
      const updatedEventProducer = eventProducer.deleteAction(eventType, eventActionId);
      const remaining = this.pageEventProducers.filter(c => c.eventProducerName !== eventProducerName)
      this.pageEventProducers = [...remaining, updatedEventProducer];
    } else {
      throw new Error(`eventProducerName: ${eventProducerName} not found`)
    }
  }

  handleEventStoreCrudOperation(event: EventStoreActionWrapper) {
    if (event.operationType === EventActionCRUDType.DELETE) {
      this.deleteAction(event.producerName, event.eventType, event.eventAction.id);
    } else if (event.operationType === EventActionCRUDType.ADD) {
      this.addAction(event.producerName, event.eventType, event.eventAction)
    } else if (event.operationType === EventActionCRUDType.UPDATE) {
      this.updateAction(event.producerName, event.eventType, event.eventAction);
    }
  }

  addParamBinding(eventProducerName: string, eventType: CommonEventType, paramBinding: ParamBinding) {
    const eventProducer = this.pageEventProducers.find(a => a.eventProducerName == eventProducerName)
    if (RtSome(eventProducer).isDefined) {
      eventProducer.addParamBinding(eventType, paramBinding)
    } else {
      throw new Error(`eventProducerName: ${eventProducerName} not found`)
    }
  }

  deleteParamBinding(eventProducerName: string,
    eventType: CommonEventType,
    paramId: string) {

    const eventProducer = this.pageEventProducers.find(a => a.eventProducerName == eventProducerName)
    if (RtSome(eventProducer).isDefined) {
      const updatedEventProducer = eventProducer.deleteParamBinding(eventType, paramId);
      const remaining = this.pageEventProducers.filter(c => c.eventProducerName !== eventProducerName)
      this.pageEventProducers = [...remaining, updatedEventProducer];
    } else {
      throw new Error(`eventProducerName: ${eventProducerName} not found`)
    }
  }

  /**
   *  call this from control to register events to be fired.
   * @param eventProducerName
   * @returns
   */
  getConfiguredEvents(eventProducerName: string): CommonEventType[] {
    const container = this.pageEventProducers.find(c => c.eventProducerName == eventProducerName)
    if (RtSome(container).isDefined) {
      const events = container.eventActionContainers
      return events.map(e => e.eventType)
    } else {
      return [];
    }
  }

  getEventActionContainerByProducerName(eventProducerName: string): RtOption<EventActionContainer[]> {
    const producer = this.pageEventProducers.find(eventProducer => eventProducer.eventProducerName === eventProducerName);
    if (RtSome(producer).isDefined) {
      return RtSome(producer.eventActionContainers);
    } else {
      return RtNone();
    }
  }

  getEventActionContainer(appEvent: AppEvent): RtOption<EventActionContainer> {
    const producer = this.pageEventProducers.find(eventProducer => eventProducer.eventProducerName === appEvent.eventProducerName);
    if (RtSome(producer).isDefined) {
      const key = EventActionContainerKeyBuilder.build(appEvent.eventType, appEvent.subEventType).key;
      const eventActionContainer = producer.eventActionContainers.find(eventAction => eventAction.eventActionGroupUniqueKey.key === key);
      return RtSome(eventActionContainer);
    } else {
      return RtNone();
    }
  }

  static parse(eventStoreJson: string) {
    const eventStoreErased: EventStore = JSON.parse(eventStoreJson);
    const containers = eventStoreErased.pageEventProducers.map(c => PageEventProducer.parse(c))
    const eventStore = new EventStore(eventStoreErased.pageName, containers)
    return eventStore;
  }

}


export class PageEventProducer {

  constructor(public eventProducerName: string, public producerType: ProducerType,
    public eventActionContainers: EventActionContainer[], public displayName: string) { }

  //call this while creating Event producer from controls

  addEvent(newEventType: CommonEventType, subEventType: RtOption<SubEventType>) {
    const id = IdentityGenerator.getShortID();
    const newEventActionContainer = new EventActionContainer(id, newEventType, subEventType, [], [])
    // const eventProducerEventsContainer = this.container.find(c => c.eventProducerName == eventProducerName)
    const eventActionGroupUniqueKey = EventActionContainerKeyBuilder.build(newEventType, subEventType).key;
    const eventType = this.eventActionContainers.find(a => a.eventActionGroupUniqueKey.key == eventActionGroupUniqueKey)
    if (RtSome(eventType).isDefined) {
      // return this
      throw new Error(`EventType: ${newEventType} already exists for the ${this.eventProducerName}`)
    } else {
      this.eventActionContainers.push(newEventActionContainer)
      return this
    }
  }

  deleteEvent(eventActionGroupUniqueKey: EventActionGroupUniqueKey) {
    //const eventActionGroupUniqueKey = EventActionContainerKeyBuilder.build(newEventType,subEventType)
    const remaining = this.eventActionContainers.filter(a => a.eventActionGroupUniqueKey.key != eventActionGroupUniqueKey.key)
    return new PageEventProducer(this.eventProducerName, this.producerType, remaining, this.displayName)
  }

  updateEvent(oldEventActionGroupUniqueKey: EventActionGroupUniqueKey,
    updatedEventType: CommonEventType, updatedSubEventType: RtOption<SubEventType>) {
    const newEventActionGroupUniqueKey = EventActionContainerKeyBuilder.build(updatedEventType, updatedSubEventType)

    const eventType = this.eventActionContainers.find(a => a.eventActionGroupUniqueKey.key == newEventActionGroupUniqueKey.key)
    //throw if there is eventtype already exists with the same unique key.
    if (RtSome(eventType).isDefined) {
      throw new Error(`EventType: ${updatedEventType} already exists for the ${this.eventProducerName}`)
    } else {
      const toUpdate = this.eventActionContainers.find(a => a.eventActionGroupUniqueKey.key == oldEventActionGroupUniqueKey.key)
      if (RtSome(toUpdate).isDefined) {
        const updated = new EventActionContainer(toUpdate.id, updatedEventType, updatedSubEventType,
          toUpdate.actions, toUpdate.paramBindings)
        const remaining = this.eventActionContainers.filter(a => a.eventActionGroupUniqueKey.key != oldEventActionGroupUniqueKey.key)
        return new PageEventProducer(this.eventProducerName, this.producerType, [...remaining, updated], this.displayName)
      } else {
        throw new Error(`No event found to update with ${oldEventActionGroupUniqueKey}`);
      }
    }
  }


  addAction(eventType: CommonEventType, action: EventAction) {
    const eventActionContainer = this.eventActionContainers.find(e => e.eventType == eventType)
    if (RtSome(eventActionContainer).isDefined) {
      // Temp Fix => should check why this method is triggering twice
      const eventAction = eventActionContainer.actions.find(ac => ac.id == action.id);
      if (!eventAction) eventActionContainer.actions.push(action)
    } else {
      throw new Error(`EventType: ${eventType} is configured for ${this.eventProducerName}`)
    }
  }

  updateAction(eventType: CommonEventType, action: EventAction) {
    const eventActionContainer = this.eventActionContainers.find(e => e.eventType == eventType)
    if (RtSome(eventActionContainer).isDefined) {
      const actionIndex = eventActionContainer.actions.findIndex(ac => ac.id == action.id)//REVIEW not able to find for navigate
      actionIndex >= 0 && (eventActionContainer.actions[actionIndex] = action)
    } else {
      throw new Error(`EventType: ${eventType} is configured for ${this.eventProducerName}`)
    }
  }

  deleteAction(eventType: CommonEventType, eventActionId: string): PageEventProducer {
    const eventActionContainer = this.eventActionContainers.find(e => e.eventType == eventType)
    if (RtSome(eventActionContainer).isDefined) {
      const updateEventActionContainer = eventActionContainer.deleteEventAction(eventActionId)
      const remaining = this.eventActionContainers.filter(e => e.eventType != eventType)
      return new PageEventProducer(this.eventProducerName, this.producerType, [...remaining, updateEventActionContainer], this.displayName)
    } else {
      throw new Error(`EventType: ${eventType} is configured for ${this.eventProducerName}`)
    }
  }

  addParamBinding(eventType: CommonEventType, action: ParamBinding) {
    const eventActionContainer = this.eventActionContainers.find(e => e.eventType == eventType)
    if (RtSome(eventActionContainer).isDefined) {
      eventActionContainer.paramBindings.push(action)
    } else {
      throw new Error(`EventType: ${eventType} is configured for ${this.eventProducerName}`)
    }
  }

  deleteParamBinding(eventType: CommonEventType, paramId: string) {
    const eventActionContainer = this.eventActionContainers.find(e => e.eventType == eventType)
    if (RtSome(eventActionContainer).isDefined) {
      const updateEventActionContainer = eventActionContainer.deleteParamBinding(paramId)
      const remaining = this.eventActionContainers.filter(e => e.eventType != eventType)
      return new PageEventProducer(this.eventProducerName, this.producerType, [...remaining, updateEventActionContainer], this.displayName)
    } else {
      throw new Error(`EventType: ${eventType} is configured for ${this.eventProducerName}`)
    }
  }

  getDefinedActionContainers(eventType: CommonEventType): EventActionContainer[] {
    return this.eventActionContainers.filter(e => e.eventType == eventType)
  }

  getOneActionContainers(eventType: CommonEventType): EventActionContainer {
    const containers = this.getDefinedActionContainers(eventType)
    if (containers.length > 0) {
      throw new Error("More than one Action container found");
    } else {
      return containers[0]
    }
  }

  static parse(container: PageEventProducer) {
    //const container: EventProducerEventsContainer = JSON.parse(json);
    const containers = container.eventActionContainers.map(c => {
      return EventActionContainer.parse(c);
    })
    return new PageEventProducer(container.eventProducerName, container.producerType, containers, container.displayName)
  }

}

export class EventActionContainerKeyBuilder {

  static build(eventType: CommonEventType,
    subEventType: RtOption<SubEventType>): EventActionGroupUniqueKey {
    const suffix = subEventType.map(s => s.id).getOrElseV2("undefined")
    return { key: `${eventType}-${suffix}` }
  }

}

//export
/**
 *  when @eventProducerName 's @eventType fired, then apply @actions
 */
export class EventActionContainer {

  get eventActionGroupUniqueKey(): EventActionGroupUniqueKey {
    return EventActionContainerKeyBuilder.build(this.eventType, this.subEventType)
  }

  constructor(public id: string,
    public eventType: CommonEventType,
    public subEventType: RtOption<SubEventType>,
    public actions: EventAction[],
    public paramBindings: ParamBinding[]) {
  }


  addEventAction(action: EventAction) {
    this.actions.push(action)
    return this;
  }

  deleteEventAction(eventActionId: string): EventActionContainer {
    const remaining = this.actions.filter(a => a.id != eventActionId)
    return new EventActionContainer(this.id, this.eventType, this.subEventType, remaining, this.paramBindings)
  }

  addParamBinding(action: ParamBinding) {
    this.paramBindings.push(action)
    return this;
  }

  deleteParamBinding(paramId: string): EventActionContainer {
    const remainingParams = this.paramBindings.filter(a => a.id != paramId)
    return new EventActionContainer(this.id, this.eventType, this.subEventType, this.actions, remainingParams)
  }

  static parse(container: EventActionContainer) {
    const subEventType = RtOption.parse(container.subEventType);
    return new EventActionContainer(container.id, container.eventType, subEventType, container.actions, container.paramBindings);
  }

}

export enum EventActionType {
  BACKEND = "backend_action",
  SHOW_A_POPUP = "show_popup",
  NAVIGATE_TO = "navigate_to",
  CONTROL_BINDINGS = "control_bindings", // on its own, or another control.
}



export interface EventAction {
  id: string
  actionType: EventActionType,
  eventType: CommonEventType
}

//#
export type DirectValueBindingAction = {
  controlInstanceId: string
} & EventAction

//Param binding
export type ParamBinding = { id: string, fieldName: string, datasourceName: string, param: string }

//1) Create CRUD for EventStore and for respective popups
//2) refacator all controls to provide eventTypes and query eventstore to register configured events
//3) Create Global Event Bus. On event received do following
//  3.1) if action
//     3.1.1) diaglog: Create popup orchestrator to manage lifecycle of various popups.
//     3.1.2) Naviagte: build pages according navigation settings, and invoke navigation
//     3.1.3) Backend action: Invoke backend action api (a.k.a Datasource invocation)
//  3.2) if param mapping
//     3.2.1) STATUS-QUO: publish for other consifigured DS invocation

export enum ControlBindingPayloadType {
  EVENT_DATA = "event_data",
  BEHAVIOR_DATA = "behavior_data"
}

export interface ControlBindingPayload {
  type: ControlBindingPayloadType
}

export class EventData implements ControlBindingPayload {
  type: ControlBindingPayloadType = ControlBindingPayloadType.EVENT_DATA;
  constructor(public data: DsResult, public eventType: CommonEventType) {
  }
}

export class BehaviorData implements ControlBindingPayload {
  type: ControlBindingPayloadType = ControlBindingPayloadType.BEHAVIOR_DATA;
  constructor(public behaviors: BehaviourData[]) {
  }
}

//publish on CONTROL_BINDING_`actionShortId`
/**
 *  subscribe (d:ControlBindingPayload) => {
 *   if(d is EventData )
 * }
 */
