<rt-modal-dialog *ngIf="data">

    <div class="modal-header">
        <h3 class="sub-title rt-mb-0" mat-dialog-title>Filter</h3>
    </div>

    <div class="modal-content rt-mt-5">

        <form [formGroup]="form" class="form task-list-form view-layout" *ngIf="formlyFields?.length">
            <formly-form [model]="model" [fields]="formlyFields" [form]="form" class="row formly-form"></formly-form>
        </form>

        <h3 class="sub-title rt-mb-20 rt-pl-15" mat-dialog-title>Sort By</h3>
        <div class="row">
            <div class="col-md-12 rt-mt-20">
                <mat-form-field>
                    <mat-select placeholder="Select Field" [(ngModel)]="selectedSortField">
                        <mat-option *ngFor="let user of supportedSortFields | orderBy: 'name'" [value]="user">
                            {{user?.paramName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="modal-actions">
        <div class="row">
            <div class="col-md-12">
                <button mat-raised-button (click)="resetFilter()">RESET </button>
                <button mat-raised-button color="primary" (click)="applyFilter()">APPLY </button>
            </div>
        </div>
    </div>

</rt-modal-dialog>