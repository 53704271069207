
import { ChartAttributesEnum } from "../../../../models/chart/chart-models";
import { RtOption, RtSome } from "../../../../utils/option-helper";
import { AttributeGroup } from "../../../constants/attribute-group-constant";
import { AttributeRegistryConstant } from "../../../constants/attribute-registry-constants";
import { Attribute, AttributeType, MobiAttribute } from "../../core/attribute";
import { UsableAttributeValue } from "../../core/attribute-instance";

export class AxisChartModel {

    constructor(public schemeType: string, public showXAxis: boolean, public showXAxisLabel: boolean,
                public xAxisLabel: string,  public showYAxisLeft: boolean, public showYAxisLabel: boolean,
                public yAxisLeftLabel: string, public showGridLines: boolean, public roundDomain: boolean,
                public rotateXAxisTicks: boolean) {
    }

    static parseModel(jsonString: string) {
        const parsedValue: AxisChartModel = JSON.parse(jsonString);
        return new AxisChartModel(parsedValue.schemeType, parsedValue.showXAxis, parsedValue.showXAxisLabel,
                parsedValue.xAxisLabel, parsedValue.showYAxisLeft, parsedValue.showYAxisLabel, parsedValue.yAxisLeftLabel,
                parsedValue.showGridLines, parsedValue.roundDomain, parsedValue.rotateXAxisTicks);
    }
}

export class AxisChartAttribute extends Attribute<AxisChartModel, number|string|boolean> implements MobiAttribute<AxisChartModel ,number|string|boolean> {

    constructor() {
        super(AttributeRegistryConstant.AXIS_CHART_PROPERTIES, AttributeGroup.CONFIGURATION, 'object', AttributeType.CONFIGURATION,
            RtSome(() => this.defaultAxisChartModel()));
    }

    defaultAxisChartModel(): AxisChartModel {
        return new AxisChartModel('ordinal', true, false, 'X Axis', true, false, 'Y-Axis Left', true, false, true);
    }

    generateTSCode(): string {
        return `
            [
                new UsableAttributeValue(this.name, new AxisChartModel(${this.model.schemeType}, ${this.model.showXAxis},
                     ${this.model.showXAxisLabel}, ${this.model.xAxisLabel}, ${this.model.showYAxisLeft}, ${this.model.showYAxisLabel}
                     ${this.model.yAxisLeftLabel}, ${this.model.showGridLines}, ${this.model.roundDomain},
                     ${this.model.rotateXAxisTicks}}), this.attributeType)
            ]
        `;
    }

    clone():Attribute<AxisChartModel, number|string|boolean> {
        return new AxisChartAttribute();
    }

    parseModel(jsonValue: string): AxisChartModel {
        return AxisChartModel.parseModel(jsonValue);
    }

    buildUsableValue(resolvedValue: RtOption<AxisChartModel>): UsableAttributeValue<number|string|boolean>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.SCHEME_TYPE, dim.schemeType, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_XAXIS, dim.showXAxis, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_X_AXIS_LABEL, dim.showXAxisLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.XAXIS_LABEL, dim.xAxisLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_Y_AXIS_LEFT, dim.showYAxisLeft, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_Y_AXIS_LABEL, dim.showYAxisLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.YAXIS_LEFT_LABEL, dim.yAxisLeftLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_GRID_LINES, dim.showGridLines, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.ROUND_DOMAINS, dim.roundDomain, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.ROTATE_XAXIS_TICKS, dim.rotateXAxisTicks, this.attributeType)
            ];
        } else {
            return [];
        }
    }

    buildMobiUsableValue(resolvedValue: RtOption<AxisChartModel>): UsableAttributeValue<number|string|boolean>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.SCHEME_TYPE, dim.schemeType, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_XAXIS, dim.showXAxis, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_X_AXIS_LABEL, dim.showXAxisLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.XAXIS_LABEL, dim.xAxisLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_Y_AXIS_LEFT, dim.showYAxisLeft, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_Y_AXIS_LABEL, dim.showYAxisLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.YAXIS_LEFT_LABEL, dim.yAxisLeftLabel, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.SHOW_GRID_LINES, dim.showGridLines, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.ROUND_DOMAINS, dim.roundDomain, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.ROTATE_XAXIS_TICKS, dim.rotateXAxisTicks, this.attributeType)
            ];
        } else {
            return [];
        }
    }

}
