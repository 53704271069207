export enum OfflineConstants {
    COLOR_ONLINE = "primary",
    COLOR_OFFLINE = "medium",
    NETWORK_STATUS_CHANGE = "networkStatusChange",
    OFFLINE_CAPTURE_STATUS = "_offlineCaptureStatus",
    OFFLINE_FORM = "Offline_Form",
    ENTITY_ID = "id",
    SUB_TASK_ID = "subTaskId",
    OFFLINE = "offline",
    ATTRIBUTES = "attributes",
    IS_FROM_OFFLINE = "isFromOffline",
    OFFLINE_CACHE = "OfflineCache",
    DISPLAY_SCHEMA_NAME = "displaySchemaName"
}

export enum OfflineFormModelType {
    FORM = "Form",
    USERTASK = "UserTask"
}

export enum OfflineTopic {
    DOWNLOAD_COMPLETE = "DOWNLOAD_COMPLETE",
    UPLOAD_COMPLETE = "UPLOAD_COMPLETE"
}