import { Injectable } from "@angular/core";
import { RtOption, RtSome } from "../../../../utils/option-helper";
import { PageService } from "../../../services/page-service";
import { PageEventProducer } from "./event-store";
import { GlobalEventProducer, ProducerProps } from "./producers/global-event-producer";
import { GlobalWebEventProducerRegistry } from "./producers/global-web-event-producer-registry";
import { ControlInstanceWrapper } from "../control-instance-drafts-models";
import { DataSourcePropertyKeyBuilder, ReturnMethodParams } from "../../../data-source/data-source";
import { DataSourceServiceInstanceWrapper } from "../data-source-draft-models";
import { ControlRegistryProvider } from "../../../ide/services/control-registry-provider";
import { ControlRegistryResolver } from "../../../ide/services/common-control-render-service";

export interface EventProducerSchema {
}

export type DatasourceSchema = { dsName: string, schemaName: string, returnMethodParams: ReturnMethodParams[] } & EventProducerSchema;

export type GlobalEventDataSchema = { property: string } & EventProducerSchema;

export class ActionProducedDataSchema {
  constructor(public properties: string[],
    private eventName: String,
    private actionName: String,
    private buttonName: RtOption<String>) { }

  toolTip(): string {
    return ` ${this.eventName} -> ${this.actionName} -> ${this.buttonName}`
  }
}

export enum ProducerType {
  DATASOURCE_SCHEMA = "datasource_scheme",
  GLOBAL_EVENT_DATA = "global_event_data",
}

export type EventProducerSchemaContainer = { producerType: ProducerType, eventProducerName: string, schemas: EventProducerSchema[] }
export type ProducerDataSourceParams = { producerName: string, params: string[] };


@Injectable()
export class EventProducerSchemaProvider {
  private controlRegistry: ControlRegistryProvider;
  constructor(
    private pageService: PageService,
    private globalWebEventProducerRegistry: GlobalWebEventProducerRegistry,
    private controlRegistryResolver: ControlRegistryResolver) {

  }

  resolveSchema(container: EventProducerSchemaContainer): ProducerDataSourceParams {
    if (container.producerType == ProducerType.GLOBAL_EVENT_DATA) {
      return this.resolveGlobalEventParams(container);
    } else {
      return this.resolveDataSourceParams(container);
    }

  }

  private resolveDataSourceParams(container: EventProducerSchemaContainer) {
    const schemasOpt = RtSome((container.schemas as DatasourceSchema[]));

    const producerDataSourceParams = this.getDataSourceParams(schemasOpt, container);
    // .subscribe(producerDataSourceParams => {
    const controlInstanceWrapper: ControlInstanceWrapper = this.pageService.getInstanceByInstanceId(container.eventProducerName);
    if (controlInstanceWrapper == null) {
      // This scenario will come, when the control is deleted, 
      // but the events related to the control which is used in the other control is not deleted.
      console.warn(`ControlInstanceWrapper not found : ${container.eventProducerName}`);
      return { producerName: container.eventProducerName, params: [] };
    };
    this.controlRegistry = this.controlRegistryResolver.resolve(this.pageService.pageType)
    const descriptor = this.controlRegistry.getControlDescriptor(controlInstanceWrapper.controlName);
    if (descriptor.producerParams?.length) {
      producerDataSourceParams.params = descriptor.producerParams.concat(producerDataSourceParams.params)
    }
    return producerDataSourceParams;
    // });
  }

  private getDataSourceParams(schemasOpt: RtOption<DatasourceSchema[]>, container: EventProducerSchemaContainer): ProducerDataSourceParams {
    if (schemasOpt.isDefined) {
      const schemas = schemasOpt.get;
      if (schemas.length > 0) {
        // this.resolveDataSourceParamsUsingDataSourceSchemaName(schemas, container);
        return this.constructDSReturnMethodParams(schemas, container)
      } else {
        return this.resolveParamsUsingParentDataSources(container);
      }
    } else {
      const producerDataSourceParams: ProducerDataSourceParams = { producerName: container.eventProducerName, params: [] };
      return producerDataSourceParams;
    }
  }

  private resolveParamsUsingParentDataSources(container: EventProducerSchemaContainer) {
    const controlInstanceWrapper: ControlInstanceWrapper = this.pageService.getInstanceByInstanceId(container.eventProducerName);
    const parentControlInstanceWithDataSources: RtOption<ControlInstanceWrapper> = RtSome(this.pageService.getParentDataSourceIfAny(controlInstanceWrapper));

    let params: string[] = [];
    if (parentControlInstanceWithDataSources.isDefined) {
      const dataSourceSchemas = this.constructDataSourceSchema(parentControlInstanceWithDataSources.get.instanceId);
      return this.constructDSReturnMethodParams(dataSourceSchemas, container);

    } else {
      const producerDataSourceParams: ProducerDataSourceParams = { producerName: container.eventProducerName, params: params };
      return producerDataSourceParams;
    }
  }

  private constructDSReturnMethodParams(dataSourceSchemas: DatasourceSchema[], container: EventProducerSchemaContainer) {

    const params = dataSourceSchemas.map(dataSourceSchema => {
      const returnResult = dataSourceSchema.returnMethodParams.map(returnMethodParam => DataSourcePropertyKeyBuilder.buildKey(dataSourceSchema.dsName, returnMethodParam.name))
      return returnResult;
    }).flat()
    const producerDataSourceParams: ProducerDataSourceParams = { producerName: container.eventProducerName, params: params };
    return producerDataSourceParams;
  }

  private resolveGlobalEventParams(container: EventProducerSchemaContainer) {
    const params = (container.schemas as GlobalEventDataSchema[]).map(s => s.property);
    const producerDataSourceParams: ProducerDataSourceParams = { producerName: container.eventProducerName, params: params };
    return producerDataSourceParams;
  }

  convertPageEventProducerToEventProducerSchemaContainer(pageEventProducer: PageEventProducer): EventProducerSchemaContainer {
    if (pageEventProducer.producerType === ProducerType.DATASOURCE_SCHEMA) {
      const dataSourceSchemas: DatasourceSchema[] = this.constructDataSourceSchema(pageEventProducer.eventProducerName);
      const eventProducerSchemaContainer: EventProducerSchemaContainer = { producerType: pageEventProducer.producerType, eventProducerName: pageEventProducer.eventProducerName, schemas: dataSourceSchemas };
      return eventProducerSchemaContainer;
    } else {
      const globalEventProducer: GlobalEventProducer<ProducerProps> = this.globalWebEventProducerRegistry.registry.get(pageEventProducer.eventProducerName);
      if (globalEventProducer) {
        const globalEventDataSchema: GlobalEventDataSchema[] = globalEventProducer.props.props.produceParams;
        const eventProducerSchemaContainer: EventProducerSchemaContainer = { producerType: pageEventProducer.producerType, eventProducerName: pageEventProducer.eventProducerName, schemas: globalEventDataSchema };
        return eventProducerSchemaContainer;
      }
      throw new Error(`GlobalEventProducer not found : ${pageEventProducer.eventProducerName}`);
    }
  }

  private constructDataSourceSchema(controlInstanceId: string): DatasourceSchema[] {
    return this.pageService.getControlInstanceDataSourceServiceInstances(controlInstanceId)
      .map((dataSourceServiceInstanceWrapper: DataSourceServiceInstanceWrapper) => {
        const datasourceSchema: DatasourceSchema = {
          dsName: dataSourceServiceInstanceWrapper.dsName,
          schemaName: dataSourceServiceInstanceWrapper.method?.returningSchemaName || dataSourceServiceInstanceWrapper.serviceName, returnMethodParams: dataSourceServiceInstanceWrapper.method?.returningResult
        };
        return datasourceSchema;
      });
  }
}
