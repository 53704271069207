import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Injectable } from "@angular/core";
import { EventStore } from "projects/den-core/page-builder";
import { DenPeekWindowComponent } from "projects/den-core/src/lib/page-builder/control-assistant/den-peek-window/den-peek-window.component";

@Injectable()
export class DenPeekWindowService {
  private overlayRef: OverlayRef;
  _eventStore: EventStore;

  constructor(private overlay: Overlay) {
  }

  openDenPeekWindow(componentRef: () => Promise<any>, controlId: string, controlInstanceId: string, positionX: number, positionY: number, width?: number) {
    this.overlayRef?.detach();
    if (width && (positionX + width >= window.innerWidth)) {
      positionX = positionX - width;
    }
    const positionStrategy = this.overlay.position().global().left(positionX + 'px').top(positionY + 'px')
    this.overlayRef = this.overlay.create({
      height: '400px',
      positionStrategy: positionStrategy
    });
    const denPeekWindow = new ComponentPortal(DenPeekWindowComponent);
    const denpeekWindowComponentRef: ComponentRef<DenPeekWindowComponent> = this.overlayRef.attach(denPeekWindow);
    denpeekWindowComponentRef.instance.componentRef = componentRef;
    denpeekWindowComponentRef.instance.positionX = positionX;
    denpeekWindowComponentRef.instance.positionY = positionY;
    denpeekWindowComponentRef.instance.controlInstanceId = controlInstanceId;
    denpeekWindowComponentRef.instance.controlId = controlId;
    const closeSub = denpeekWindowComponentRef.instance.onClose.subscribe(() => {
      denpeekWindowComponentRef.destroy();
      this.overlayRef?.detach();
      closeSub?.unsubscribe();
    })
    return denpeekWindowComponentRef;
  }

  closeDenPeekWindow() {
    this.overlayRef?.detach();
  }

}
