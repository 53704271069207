import { Observable } from "rxjs";

export abstract class LocalStorageProvider {
    abstract init(databaseName: string, tableName: string): Observable<string>;
    abstract getAll<T>(): Observable<T[]>;
    abstract get<T>(key: string, tableName?: string): Observable<T>;
    abstract set<T>(key: string, value: T, tableName?: string): Observable<string>;
    abstract remove(key: string, tableName?: string): Observable<string>;
    abstract clear(): Observable<string>;
    abstract delete(): Observable<string>;
    abstract close(): void;
}