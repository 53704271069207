
export enum Units {
  AUTO = "auto",
  PX = "px",
  PERCENTAGE = "%",
  FR = "fr",
}

export enum Direction {
  COLUMN = "COLUMN",
  ROW = "ROW",
}

export enum GridCellCorner {
  TOP_LEFT = "TOP_LEFT",
  TOP_RIGHT = "TOP_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}


export enum GridCellPosition {
  /**
   *   #___
   *  |____|
   */
  TOP_LEFT = "TOP_LEFT",
  /**
   *  __#__
   * |_____|
   */
  TOP_IN_BETWEEN = "TOP_IN_BETWEEN",
  /**
  *  _____#
  * |_____|
  */
  TOP_RIGHT = "TOP_RIGHT",
  /**
 *  _____
 * |     |
 * |     #
 * |_____|
 */
  RIGHT_IN_BETWEEN = "RIGHT_IN_BETWEEN",
  /**
*  _____
* |     |
* |_____#
*/
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  /**
  *  _____
  * |     |
  * |__#__|
  */
  BOTTOM_IN_BETWEEN = "BOTTOM_IN_BETWEEN",
  /**
  *  _____
  * |     |
  * #_____|
  */
  BOTTOM_LEFT = "BOTTOM_LEFT",
  /**
   *  _____
   * |     |
   * #     |
   * |_____|
   */
  LEFT_IN_BETWEEN = "LEFT_IN_BETWEEN",
  /**
   *  _____
   * |     |
   * |  #  |
   * |_____|
   */
  CENTER = "CENTER"
}


export enum MergeCriteriaTypeConstants {
  KEEPALLCELLCONTROLS = "KeepAllCellControls",
  KEEPLEFTCELLCONTROLSDISCARDOTHERS = "KeepLeftCellControlsDiscardOthers",
  CANCELMERGECELLS = "CancelMergeCells"
}