
import { Component, ComponentRef, NgModule, ViewContainerRef } from '@angular/core';
import { EventService } from 'projects/den-core';
import { CompanionRequestCommand, ControlAssistantMenuOption, ControlAssistantMenuProvider, ControlAssistantMixin, IControl, IDsResult, PubSubTopic } from 'projects/den-core/page-builder';
import { ICompanionSubMixin } from 'projects/den-core/src/lib/page-builder/controls/core/control-companion/companion-mixin-menu';
import { ControlDeleteEvent } from 'projects/den-core/src/lib/page-builder/controls/core/control-companion/mixins/companion-mixin';
import { AddChildWithDsResultCommand, ChildCommandResolver, ChildCommandType } from 'projects/den-core/src/lib/page-builder/public_api';
import { SubSink } from 'subsink';
import { AppToEditorMessagingService } from '../services/attribute-panel-bridging/control-attribute-panel-messaging.service';
import { ControlAssistantContextMenuComponent, ControlAssistantContextMenuModule } from './control-assistant-context-menu/control-assistant-context-menu.component';

@Component({
  selector: 'den-w-angular-control-assistant-mixin',
  template: ''
})
export class AngularControlAssistantMixin extends ControlAssistantMixin {
  controlAssistantContextMenuComponentRef: ComponentRef<ControlAssistantContextMenuComponent>;
  sink = new SubSink();
  private selectedControlId: string;
  constructMenuItems(): ICompanionSubMixin[] {
    return [];
  }


  viewContainerRef: ViewContainerRef;


  constructor(public eventService: EventService,
    appToEditorMessagingService: AppToEditorMessagingService
  ) {
    super(appToEditorMessagingService);
  }

  initMixin(cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): void {
    this.init(cmd);
  }
  destroyMixin(): void {
    //Unsubscribe all subscriptions
    this.clearAllMenuContainerRefs();
    this.sink.unsubscribe();
  }

  private init(cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>) {
    this.ctrlInstance = cmd.control.controlInstance;
    this.ctrlDescriptor = cmd.control.descriptor;
    this.viewContainerRef = cmd.viewContainerRef;
    this.control = cmd.control;

    this.subscribeForElementSelect();
    this.subscribeForControlUnSelectEvent();
    this.subscribeForElementDelete();
    this.subscribeForContextMenuEvent();
    this.subscribeForMouseEnter();
    this.subscribeForMouseLeave();
    //this.enableDragging();
  }
  getViewContainerRef() {
    return this.viewContainerRef;
  }
  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  setSelectedControlId(selectedControlId: string) {
    this.selectedControlId = selectedControlId;
  }
  getSelectedControlId() {
    return this.selectedControlId;
  }
  // private enableDragging() {
  //   if (this.canEnableDragging()) {
  //     const htmlElement = this.viewContainerRef.element.nativeElement as HTMLElement;
  //     htmlElement.draggable = true;
  //     this.render.listen(htmlElement, "dragstart", (event: DragEvent) => {
  //       const controlReArrangeDropData = new ControlReArrangeDropChildData(this.ctrlInstance.instanceId);
  //       event.dataTransfer.setData("data", JSON.stringify(controlReArrangeDropData));
  //       event.stopPropagation();
  //     })
  //   }
  // }

  // private controlsToExcludeFromDragging = [ControlRegistryConstant.GRID_LAYOUT, ControlRegistryConstant.FORM_CONTAINER_CONTROL];
  // private canEnableDragging() {
  //   return !this.controlsToExcludeFromDragging.some(controlName => controlName === this.ctrlInstance.controlName);
  // }

  subscribeForMouseEnter() {
    const mouseEnter = PubSubTopic.buildMouseEnterTopic(this.control.id);
    this.sink.add(this.eventService.subscribe(mouseEnter, () => {
      if (this.selectedControlId != this.control.id) {
        this.setControlOutlineOnHover();
      }
    }));
  }
  subscribeForMouseLeave() {
    const mouseLeave = PubSubTopic.buildMouseLeaveTopic(this.control.id);
    this.sink.add(this.eventService.subscribe(mouseLeave, () => {
      if (this.selectedControlId != this.control.id) {
        this.clearOutline();
      }
    }));
  }
  setControlOutlineOnHover() {
    this.viewContainerRef.element.nativeElement.style.outline = '#00ff0d solid 0.5px';
  }

  subscribeForElementSelect(): any {
    //On click of control from container it will publish CONTROL_SELECTED event
    const controlSelectTopic = PubSubTopic.buildControlSelectTopic(this.control.id);
    const controlSelectedSub = this.eventService.subscribe(controlSelectTopic, (event) => {
      this.onElementSelect(event);
      this.selectedControlId = this.control.id;
    });
    this.sink.add(controlSelectedSub);
  }

  subscribeForContextMenuEvent(): any {
    const controlSelectTopic = PubSubTopic.buildContextMenuTopic(this.control.id);
    const controlSelectedSub = this.eventService.subscribe(controlSelectTopic, (event: { event: MouseEvent; }) => {
      this.buildContextMenu(event.event);
    });
    this.sink.add(controlSelectedSub);
  }

  subscribeForControlUnSelectEvent() {
    const controlUnSelectTopic = PubSubTopic.buildControlUnSelectTopic(this.control.id);
    this.sink.add(this.eventService.subscribe(controlUnSelectTopic, () => {
      this.clearOutline();
      if (this.selectedControlId != this.control.id) {
        this.clearAllMenuContainerRefs();
      }
      this.selectedControlId = null;
    }));
  }

  subscribeForElementDelete(): void {

    const controlInstanceDeleteTopic = PubSubTopic.buildControlInstanceDeletedTopic(this.ctrlInstance.instanceId);
    const controlInstanceDeletedSub = this.eventService.subscribe(controlInstanceDeleteTopic, ($event: { controlInstanceId: string; }) => {
      if (this.isTheControlCurrentControl($event.controlInstanceId)) {
        this.notifyMixinChanges(new ControlDeleteEvent());
      }
    });
    this.sink.add(controlInstanceDeletedSub);
  }
  private isTheControlCurrentControl(controlInstanceId: string) {
    return this.ctrlInstance?.instanceId === controlInstanceId && this.canSelectCurrentControl();
  }

  private canSelectCurrentControl() {
    if (this.control.parentCmd.commandType === ChildCommandType.NEW_WITH_DS_RESULT) {
      return this.isFirstChild();
    } else {
      return true;
    }
  }

  private isFirstChild() {
    const parentCmd = ChildCommandResolver.as<AddChildWithDsResultCommand>(this.control.parentCmd);
    return parentCmd.firstChild.isDefined;
  }

  clearOutline() {
    this.viewContainerRef.element.nativeElement.style.removeProperty('outline');
  }

  onElementSelect(_event) {
    this.setControlOutline();
  }

  buildContextMenu(event: MouseEvent) {
    this.controlAssistantContextMenuComponentRef?.destroy();
    const menuItems = this.getMenuItems();
    this.controlAssistantContextMenuComponentRef = this.createControlAssistantContextMenuComponent(menuItems, event);
    this.appendAssistantContextMenuOptionsNodeToControlRootElement(this.controlAssistantContextMenuComponentRef, event);
  }

  setControlOutline() {
    this.viewContainerRef.element.nativeElement.style.outline = '#0096FF solid 0.5px';
  }

  clearAllMenuContainerRefs() {
    this.controlAssistantContextMenuComponentRef?.destroy();
  }

  private appendAssistantContextMenuOptionsNodeToControlRootElement(componentRef: ComponentRef<ControlAssistantContextMenuComponent>, event: MouseEvent) {
    const element = this.viewContainerRef.element.nativeElement;
    const menuPosition = { top: `${event.clientY}px`, left: `${event.clientX}px` };
    const assistantOptionsNode = componentRef.location.nativeElement;
    assistantOptionsNode.style.top = menuPosition.top;
    assistantOptionsNode.style.left = menuPosition.left;
    assistantOptionsNode.style.position = "absolute";
    assistantOptionsNode.style.zIndex = 99;
    element.style.position = element.style.position === 'absolute' ? 'absolute' : 'relative';
    element.appendChild(componentRef.location.nativeElement);
  }

  private createControlAssistantContextMenuComponent(menuItems: ControlAssistantMenuOption[], event: MouseEvent) {
    const componentRef = this.viewContainerRef.createComponent(ControlAssistantContextMenuComponent);
    componentRef.instance.controlInstance = this.ctrlInstance;
    componentRef.instance.controlId = this.control.id;
    componentRef.instance.controlDescriptor = this.ctrlDescriptor;
    componentRef.instance.menuItems = menuItems;
    componentRef.instance.event = event;
    return componentRef;
  }
}

@NgModule({
  declarations: [AngularControlAssistantMixin],
  imports: [ControlAssistantContextMenuModule],
  exports: [AngularControlAssistantMixin],
})
//@ts-ignore
export class AngularControlAssistantMixinModule {

}


