import { RtOption, RtNone, RtSome } from "../../../utils/option-helper";
import { Attribute, AttributeType, MobiAttribute } from "../core/attribute";
import { UsableAttributeValue } from "../core/attribute-instance";

export type SelectorOption = { id: number | string, name: string, icon?:string  };
export class SimpleSelectorOptions {
  constructor(public displayLabel: string, public options: SelectorOption[], public displayPlaceholder?:string) { }
}


export class SimpleSelectorAttribute extends Attribute<string, string>  implements MobiAttribute<string, string>{

  constructor(public name: string, public groupName: string, public attributeType: AttributeType, public selectorOptions: SimpleSelectorOptions, public selectedValue: RtOption<string> = RtNone()) {
    super(name, groupName, 'string', attributeType, RtSome(() => this.getDefaultValue()));
  }
  buildMobiUsableValue(resolvedValue: RtOption<string>): UsableAttributeValue<string>[] {
    if (resolvedValue.isDefined) {
      return [new UsableAttributeValue(this.name, resolvedValue.get, this.attributeType)];
    }
    else {
      return [];
    }
  }
  clone(): Attribute<unknown, unknown> {
    return new SimpleSelectorAttribute(this.name, this.groupName, this.attributeType, this.selectorOptions, this.selectedValue);
  }
  generateTSCode(): string {
    return "";
  }
  parseModel(jsonValue: string): string {
    return jsonValue;
  }
  buildUsableValue(resolvedValue: RtOption<string>): UsableAttributeValue<string>[] {
    if (resolvedValue.isDefined) {
      return [new UsableAttributeValue(this.name, resolvedValue.get, this.attributeType)];
    }
    else {
      return [];
    }
  }
  getDefaultValue() {
    if (this.selectedValue.isDefined) {
      return this.selectedValue.get;
    } else {
      return "";
    }
  }
}
