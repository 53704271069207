import { RtOption, RtSome } from "projects/den-core";
import { AttributeRegistryConstant } from "projects/den-core/page-builder";
import { ChartAttributesEnum } from "projects/den-core/src/lib/models/chart/chart-models";
import { Attribute, AttributeType, MobiAttribute, UsableAttributeValue } from "../../../public_api";
import { AttributeGroup } from "../../../constants/attribute-group-constant";

export class ChartAxisDateFormatModel {

    constructor(public applyDateFormat: boolean, public inputDateFormat: string, public outputDateFormat: string) {
    }

    static parseModel(jsonString: string) {
        const parsedValue: ChartAxisDateFormatModel = JSON.parse(jsonString);
        return new ChartAxisDateFormatModel(parsedValue.applyDateFormat, parsedValue.inputDateFormat, parsedValue.outputDateFormat);
    }
}

export class ChartAxisDateFormatAttribute extends Attribute<ChartAxisDateFormatModel, boolean | string> implements MobiAttribute<ChartAxisDateFormatModel,boolean | string>{

    constructor() {
        super(AttributeRegistryConstant.AXIS_DATE_FORMAT, AttributeGroup.CONFIGURATION, 'object', AttributeType.CONFIGURATION,
            RtSome(() => this.defaultChartLegendModel()));
    }


    defaultChartLegendModel(): ChartAxisDateFormatModel {
        return new ChartAxisDateFormatModel(false, '', '');
    }

    generateTSCode(): string {
        return `
            [
                new UsableAttributeValue(this.name, new ChartAxisDateFormatModel(${this.model.applyDateFormat, this.model.inputDateFormat,
            this.model.outputDateFormat}}), this.attributeType)
            ]
        `;
    }

    clone(): Attribute<ChartAxisDateFormatModel, boolean | string> {
        return new ChartAxisDateFormatAttribute();
    }

    parseModel(jsonValue: string): ChartAxisDateFormatModel {
        return ChartAxisDateFormatModel.parseModel(jsonValue);
    }

    buildUsableValue(resolvedValue: RtOption<ChartAxisDateFormatModel>): UsableAttributeValue<boolean | string>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.APPLY_X_AXIS_DATE_FORMAT, dim.applyDateFormat, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.X_AXIS_DATE_INPUT_FORMAT, dim.inputDateFormat, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.X_AXIS_DATE_OUTPUT_FORMAT, dim.outputDateFormat, this.attributeType)
            ];
        } else {
            return [];
        }
    }
    buildMobiUsableValue(resolvedValue: RtOption<ChartAxisDateFormatModel>): UsableAttributeValue<boolean | string>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.APPLY_X_AXIS_DATE_FORMAT, dim.applyDateFormat, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.X_AXIS_DATE_INPUT_FORMAT, dim.inputDateFormat, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.X_AXIS_DATE_OUTPUT_FORMAT, dim.outputDateFormat, this.attributeType)
            ];
        } else {
            return [];
        }
    }
}
