import { DsResultArray, DsResult } from "../../../../../page-builder/data-source/data-source-result-entities";
export class PagingManager {
    public isPagingSupported(): boolean {
        throw new Error("Method not implemented.");
    }

    public updateTotalResultsCount(data: DsResultArray) {
        //Total results count should only be updated if the data is recieved via Web Sockets only
        if (data.isWsResult) {
            data.results.forEach((result: DsResult) => {
                if (result.isDeleted()) {
                    data.totalResults = data.totalResults - 1;
                } else if (result.isUpdated()) {
                    //If item exists then not required to update the total results
                } else {
                    data.totalResults = data.totalResults + 1;
                }
            })
        }
    }

}