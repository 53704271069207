import { RtNone, RtSome } from "../../../utils/option-helper";
import { BoundingClientRect } from "../../controls/css-grid/models/grid-models";
import { ThemeOrCustomColor, RGBA } from "./color-attributeV3";

export class GradientSliderHelper {

  //Reference: http://jsfiddle.net/vksn3yLL/
  static getColorAtClickedPoint(mouseEvent: MouseEvent, gradientColors: GradientColorPoint[], gradientSlider: HTMLElement): ThemeOrCustomColor {
    const boundingBox: BoundingClientRect = gradientSlider.getBoundingClientRect();
    const sliderWidth = boundingBox.width;
    const distanceFromLeftSideOfSliderToClickedPointInPx = mouseEvent.x - boundingBox.x;
    const newStop = (distanceFromLeftSideOfSliderToClickedPointInPx / sliderWidth) * 100;

    //Find closest colors
    const firstColor = this.getFirstColor(distanceFromLeftSideOfSliderToClickedPointInPx, gradientColors);
    const secondColor = this.getSecondColor(distanceFromLeftSideOfSliderToClickedPointInPx, gradientColors);

    //Calculate ratio between the two closest colors
    var firstcolor_x = sliderWidth * (firstColor.color.stop / 100);
    var secondcolor_x = sliderWidth * (secondColor.color.stop / 100) - firstcolor_x;
    var slider_x = sliderWidth * (newStop / 100) - firstcolor_x;
    var ratio = slider_x / secondcolor_x;

    const newColor = this.pickHex(secondColor.color, firstColor.color, ratio);
    const newColorStop = (distanceFromLeftSideOfSliderToClickedPointInPx * 100) / sliderWidth;
    const newThemeOrCustomColor = new ThemeOrCustomColor(RtNone(), RtSome(newColor), newColorStop, 100);
    return newThemeOrCustomColor;
  }

  static getGradientColorPoint(color: ThemeOrCustomColor, id: string, sliderWidth: number) {
    const stopPositionLeftNum = this.calculateStopPositionLeft(color.stop, sliderWidth);
    const stopPositionLeft = `${stopPositionLeftNum}px`;
    const gradientColorPoint: GradientColorPoint = {
      id: id, color: color, stopPositionLeft: stopPositionLeft,
      stopPositionLeftNum: stopPositionLeftNum, isCustomColor: color.customColorOpt.isDefined
    };
    return gradientColorPoint;
  }

  static getWhiteColorInRGBA(): RGBA {
    return { r: 255, g: 255, b: 255, a: 100 };
  }

  static calculateStopPositionLeft(stop: number, sliderWidth: number): number {

    let left = stop * (sliderWidth / 100);
    left = left >= sliderWidth ? sliderWidth : left;
    return left;
  }

  static getUpdatedGradientStopPoint(mouseEvent: MouseEvent, gradientSlider: HTMLElement, selectedGradientPoint: GradientColorPoint) {
    const boundingBox: BoundingClientRect = gradientSlider.getBoundingClientRect();
    const sliderWidth = boundingBox.width;
    const distanceFromLeftSideOfSliderToClickedPointInPx = mouseEvent.x - boundingBox.x;
    const newColorStop = (distanceFromLeftSideOfSliderToClickedPointInPx * 100) / sliderWidth;
    const newLeft = GradientSliderHelper.calculateStopPositionLeft(newColorStop, sliderWidth);
    selectedGradientPoint.color.stop = newColorStop;
    selectedGradientPoint.stopPositionLeftNum = newLeft;
    selectedGradientPoint.stopPositionLeft = `${newLeft}px`;
    return selectedGradientPoint;
  }

  private static getFirstColor(position: number, gradientColors: GradientColorPoint[]) {
    return gradientColors.filter((gradientColor) => gradientColor.stopPositionLeftNum < position)
      .sort((color1, color2) => color2.stopPositionLeftNum - color1.stopPositionLeftNum)[0];
  }
  private static getSecondColor(position: number, gradientColors: GradientColorPoint[]) {
    return gradientColors.filter((gradientColor) => gradientColor.stopPositionLeftNum > position)
      .sort((color1, color2) => color1.stopPositionLeftNum - color2.stopPositionLeftNum)[0];
  }

  private static pickHex(color1: ThemeOrCustomColor, color2: ThemeOrCustomColor, weight): RGBA {
    var p = weight;
    var w = p * 2 - 1;
    var w1 = (w / 1 + 1) / 2;
    var w2 = 1 - w1;
    const color1inRGBA = color1.getRGBAColor;
    const color2inRGBA = color2.getRGBAColor;
    const r = Math.round(color1inRGBA.r * w1 + color2inRGBA.r * w2);
    const g = Math.round(color1inRGBA.g * w1 + color2inRGBA.g * w2);
    const b = Math.round(color1inRGBA.b * w1 + color2inRGBA.b * w2);
    const rgba = { r: r, g: g, b: b, a: 100 }
    return rgba;
  }
}

export type GradientColorPoint = { id: string, color: ThemeOrCustomColor, stopPositionLeft: string, stopPositionLeftNum: number, isCustomColor: boolean };
