import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, Input, NgModule, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Event, NavigationStart, Router } from '@angular/router';
import { IdentityGenerator } from 'projects/den-core';
import { Observable, defer, fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { DenHostDirective, DenHostDirectiveModule } from '../den-host.directive';
import { BasePeekWindowPanel } from './den-peek-window-base-panel';


@Component({
  selector: 'den-w-den-peek-window',
  templateUrl: './den-peek-window.component.html',
  styleUrls: ['./den-peek-window.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DenPeekWindowComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() componentRef: () => Promise<any>;
  @Input() positionX: number;
  @Input() positionY: number;
  @Input() controlId: string;
  @Input() controlInstanceId: string;
  @Output() onClose = new EventEmitter();
  @ViewChild(DenHostDirective, { static: true }) denHost!: DenHostDirective;

  peekWindowOpenStatus = false;
  #documentClickSubs: Observable<unknown>;

  readonly containerId = `peek-window-${IdentityGenerator.guid()}`;
  #childComponentRef: ComponentRef<BasePeekWindowPanel>;
  private subs = new SubSink();
  constructor(
    private elemRef: ElementRef, private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readyForParamMappingEvent && changes.readyForParamMappingEvent.currentValue) {
      this.#childComponentRef.instance['readyForParamMappingEvent'] = changes.readyForParamMappingEvent.currentValue;
    }
    if (changes.readyForEventMapping && changes.readyForEventMapping.currentValue) {
      this.#childComponentRef.instance['readyForEventMapping'] = changes.readyForEventMapping.currentValue;
    }
  }


  ngOnInit(): void {
    this.loadComponent({ loadChildren: this.componentRef() });
    this.subscribeForRouteEvent();
  }
  
  ngAfterViewInit(): void {
    this.documentEventSubscription();
  }


  private documentEventSubscription() {
    this.#documentClickSubs = defer(() => this.documentClick());
    this.subs.add(this.#documentClickSubs.subscribe());
  }
  private documentClick(): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(this.elemRef.nativeElement, 'click').pipe(
      tap((event: MouseEvent) => {
        event.stopPropagation();
      })
    );
  }
  // Ref: https://www.thisdot.co/blog/loading-components-dynamically-in-angular-9-with-ivy
  public loadComponent(cl: ComponentLoader) {
    const vcr = this.denHost.viewContainerRef;
    vcr.clear();
    cl.loadChildren.then(c => {
      const vcr = this.denHost.viewContainerRef;
      vcr.clear();
      this.#childComponentRef = vcr.createComponent(c);
      this.#childComponentRef.instance.controlId = this.controlId;
      this.#childComponentRef.instance.controlInstanceId = this.controlInstanceId;
      this.subs.add(this.#childComponentRef.instance.componentViewInitialized?.subscribe((element) => {
        this.peekWindowOpenStatus = true;
      }))
    });
  }

  closePeekWindow(_containerId) {
    this.onClose.emit();
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  private subscribeForRouteEvent() {
    this.subs.add(this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart)
      )
      .subscribe((e: NavigationStart) => {
        this.onClose.emit();
      }));

  }

}

export interface ComponentLoader {
  loadChildren: Promise<any>;
}

@NgModule({
  declarations: [DenPeekWindowComponent],
  imports: [CommonModule, MatIconModule, DenHostDirectiveModule, DragDropModule],
  exports: [DenPeekWindowComponent],
})
//@ts-ignore
export class DenPeekWindowModule {

}
