export enum AttributeGroup {
  SPACING = 'Spacing',
  APPERANCE = 'Appearance',
  DISPLAY = 'Display',
  EVENT_DATA_BINDER = 'Event Data Binder',
  EVENT_DATA_MAPPER = 'Event Data Mapper',
  ANIMATION = 'Animation',
  CONFIGURATION = "Configuration",
  CHART = "Chart",
  LAYOUT = "Layout",
  TYPOGRAPHY = "Typography",
  POSITION = "Position",
  EFFECTS = "Effects",
  BORDER = "Border",
}