export class BaseApiHelper {
    static filterIsDisabledItems<T>(entities: T, includeDisabled?: boolean): T {
        if (!entities) { throw new Error("Invalid Input parameters."); }
        let entityList: any = null;
        if (entities instanceof Array) {
            entityList = entities;
            entityList = entityList.filter(function (item: any) {
                if (item.hasOwnProperty("enabled")) {
                    if (includeDisabled === true) {
                        return item.enabled === false;
                    }
                    else {
                        return item.enabled;
                    }
                } else {
                    return item
                }
            });
        }
        return entityList;
    }
}