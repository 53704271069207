//converters

import { Injectable } from "@angular/core";
import { DsResult, DsResultArray, IDsResult } from "../../../../data-source/data-source-result-entities";
import { ConvertibleDefinition, ConvertibleType } from "./convertibles";
import { DataConverter, DataConverterDefinition } from "./data-converter";
import { GpsPointLocalReferenceConverter } from "./gps-converter/gps-point-local-reference-converter";
import { SpatialConverterDefinition } from "./gps-converter/models";

//call from control.ts

@Injectable()
export class DsResultsConvertersService {

    constructor(private dataConverter: DataConverter, private gpsPointLocalReferenceConverter: GpsPointLocalReferenceConverter) {
    }

    applyConverters<D extends IDsResult>(dsResultType: string, converters: ConvertibleDefinition[], data: D): D {

        if (dsResultType === DsResultArray.name) {
            const convertedArray = this.applyConvertersOnArray(converters, data as unknown as DsResultArray);
            return convertedArray as unknown as D;
        } else if (dsResultType === DsResult.name) {
            const dsResult = data as unknown as DsResult
            if (dsResult) {
                const array = new DsResultArray(dsResult.dsName, [dsResult], 1, [])
                const convertedArray = this.applyConvertersOnArray(converters, array)
                const convertedDsResult = convertedArray.results[0]
                return convertedDsResult as unknown as D;
            } else {
                return data;
            }
        } else {
            throw new Error(`DsResultsCovertersService: applyConverters: DSResultType: ${dsResultType} did not match either DsResultArray or DsResult`);
        }
    }

    private applyConvertersOnArray(converters: ConvertibleDefinition[], data: DsResultArray): DsResultArray {

        if (converters && converters.length > 0) {
            const converterDefinition = converters[0];
            const convertedDsResultArray = this.convert(converterDefinition, data);
            return this.applyConvertersOnArray(converters.slice(1), convertedDsResultArray);
        } else {
            return data;
        }
    }

    // private applyConvertersOnArray(converters: ConvertibleDefinition[], data: DsResultArray): DsResultArray {

    //     if (converters && converters.length > 0) {
    //         let convertedDsResultArray: DsResultArray = data;
    //         converters.forEach(converterDefinition => {
    //             convertedDsResultArray = this.convert(converterDefinition, convertedDsResultArray);
    //         });
    //         return this.applyConvertersOnArray(converters.slice(1), convertedDsResultArray);
    //     } else {
    //         return data;
    //     }
    // }
    private convert(converterDefinition: ConvertibleDefinition, data: DsResultArray) {
        if (converterDefinition.type == ConvertibleType.DATA_CONVERTER)
            return this.dataConverter.convert(converterDefinition as DataConverterDefinition, data);
        else if (converterDefinition.type == ConvertibleType.lOCAL_COORDINATES_CONVERTER)
            return this.gpsPointLocalReferenceConverter.convert(converterDefinition as SpatialConverterDefinition, data);
        else
            throw new Error("Converter type not supported");
    }
}

