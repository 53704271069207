
import { GridDefinition } from "../models/grid-models";
import { AbstractCssAttribute, Attribute, MobiAttribute } from "../../../attributes/core/attribute";
import { UsableAttributeValue } from "../../../attributes/core/attribute-instance";
import { RtNone, RtOption } from "../../../../utils/option-helper";
import { AttributeGroup } from "../../../constants/attribute-group-constant";

export class CssGridDefinitionAttribute extends AbstractCssAttribute<GridDefinition, GridDefinition> implements MobiAttribute<GridDefinition, GridDefinition> {

  generateTSCode(): string {
    return "";
  }

  constructor(public name: string, public defaultValueOpt: RtOption<() => GridDefinition> = RtNone()) {
    super(name,  AttributeGroup.LAYOUT, "string", defaultValueOpt);
  }
  buildMobiUsableValue(resolvedValue: RtOption<GridDefinition>): UsableAttributeValue<GridDefinition>[] {
    return this.buildUsableValue(resolvedValue);
  }

  clone(): Attribute<unknown, unknown> {
    return new CssGridDefinitionAttribute(this.name, this.defaultValueOpt);
  }

  parseModel(jsonValue: string): GridDefinition {
    return GridDefinition.parse(jsonValue)
  }

  buildUsableValue(resolvedValue: RtOption<GridDefinition>): UsableAttributeValue<GridDefinition>[] {
    if (resolvedValue.isDefined) {
      const gridDefinition = resolvedValue.get;
      // const attributeInstances: UsableAttributeValue<string>[] = [];
      // const cssMap = gridDefinition.toCSSAttribute();
      // cssMap.forEach((val, key) => attributeInstances.push(new UsableAttributeValue(key, val, AttributeType.CSS)));
      // return attributeInstances;
      return [new UsableAttributeValue(this.name, gridDefinition, this.attributeType)];
    } else {
      return [];
    }
  }
}
