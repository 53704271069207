import { ThemeColorType, ThemePalleteKeys, ThemeSettings, TimeSettings } from '../../constants/app-constants';
import { RtNone, RtOption, RtSome } from '../../utils/option-helper';


export class PersonalisationAndLocalizationService {

  private static _themeSetting: RtOption<ThemeSettings> = RtNone();

  private static _timeSettings: RtOption<TimeSettings> = RtNone();

  private static _language: RtOption<string> = RtNone();

  private static getTimeSettings() {
    return this._timeSettings.getOrElseThrow("Timezone is not defined")
  }

  private static getThemeSettings() {
    return this._themeSetting.getOrElseThrow("Theme is not defined")
  }

  public static set themeSetting(themeSetting: ThemeSettings) {
    this._themeSetting = RtSome(themeSetting);
  }

  public static set appTimeZone(timeSettings: TimeSettings) {
    this._timeSettings = RtSome(timeSettings);
  }

  public static set language(language: string) {
    this._language = RtSome(language);
  }

  public static get colorValues() {
    return this.getThemeSettings().colorValues;
  }

  public static get timeZone(): string {
    if (this.getTimeSettings().canGuessTZ)
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    else
      return this.getTimeSettings().appTZ;
  }

  public static get dateFormat(): string {
    return this.getTimeSettings().dateFormat
  }

  public static get language() {
    return this._language.getOrElseThrow("Theme is not defined");
  }

  public static getColor(colorType: ThemeColorType, key: ThemePalleteKeys): string {
    const colorValue = this.getThemeSettings().colorValues.find(t => t.colorType == colorType && t.key == key);
    if (colorValue)
      return colorValue.value;
    else
      throw new Error(`Color value is not available for ${colorType} - ${key}`);
  }
}

// @Injectable({ providedIn: 'root' })
// export class PersonalisationAndLocalizationService {

//     // @Output()
//     // themeChanged = new EventEmitter<ThemeColorsContainer>()

//     #ThemeSetting: ThemeSetting;

//     private constructor() {
//         // this.themeChanged.subscribe((newContainer: ThemeColorsContainer) => {
//         //     this.themeContainer = newContainer
//         // })
//     }

//     public set setThemeSetting(themeSetting: ThemeSetting) {
//         this.#ThemeSetting = themeSetting;
//     }

//     getAllColors() {
//         return this.#ThemeSetting.colorValues;
//     }

//     // get primaryColor(): string {
//     //     return this.#ThemeSetting.primary
//     // }

//     // get accentColor(): string {
//     //     return this.#ThemeSetting.accent
//     // }

//     // get warnColor(): string {
//     //     return this.#ThemeSetting.primary
//     // }

//     //  primaryContrastColor(key:ThemePalleteKeys): string {
//     //     return this.#ThemeSetting.primary
//     // }

//     //  accentContrastColor(key:ThemePalleteKeys): string {
//     //     return this.#ThemeSetting.accent
//     // }

//     //  warnContrastColor(key:ThemePalleteKeys): string {
//     //     return this.#ThemeSetting.primary
//     // }

//     // get primaryContrastColor(): string {
//     //     return this.#themeContainer.primary_contrast
//     // }

//     // get accentContrastColor(): string {
//     //     return this.#themeContainer.accent_contrast
//     // }

//     // get warnContrastColor(): string {
//     //     return this.#themeContainer.warn_contrast
//     // }

//     // get typography(): string {
//     //     return this.#themeContainer.typography
//     // }

// }

