import { Injectable } from "@angular/core";
import { EventService } from "../../../../../../providers/event-service/event.service";
import { Observable, lastValueFrom } from "rxjs";
import { AppEventTopics } from "../../app-event-topics";
import { BehaviorData, EventData } from "../../event-store";
import { ControlBindingsAction, ControlBindingsContainer } from "./control-bindings-action-models";
import { AppEvent, ApplyActionCommand, PageEventActionStrategy } from "../../event-models";
import { RtOption, RtSome } from "projects/den-core/src/lib/utils/option-helper";
import { ProducerType } from "../../event-schema-provider";

@Injectable()
export class ControlBindingsActionStrategy implements PageEventActionStrategy<ControlBindingsAction> {
  private publishedTopicsMap: Map<string, string> = new Map();

  constructor(private eventService: EventService) {

  }
  applyActions(cmds: ApplyActionCommand<ControlBindingsAction>[], event: AppEvent): Promise<void> {
    const cmd$ = new Observable<void>(() => {
      cmds.map((cmd) => {
        this.publishActionData(cmd, event);
      })
    })
    return lastValueFrom(cmd$);
  }

  private publishActionData(cmd: ApplyActionCommand<ControlBindingsAction>, event: AppEvent) {
    cmd.action.containers.forEach((binding: ControlBindingsContainer) => {
      this.publishBehaviorData(binding, cmd, event);
      this.publishEventData(binding, cmd, event);
    })
  }

  private publishBehaviorData(binding: ControlBindingsContainer, cmd: ApplyActionCommand<ControlBindingsAction>, event: AppEvent) {
    const hasBehaviorsToPublish = binding.behaviors?.length > 0;
    if (hasBehaviorsToPublish) {
      let topic: string;
      if (binding.idPropertyName) {
        this.publishUnApplyBehaviorData(cmd.action.id, binding.controlInstanceId);
        const fieldValue = cmd.data?.data.find(d => d.fieldName == binding.idPropertyName)
        topic = AppEventTopics.buildControlBindingTopicForUniqueControl(cmd.action.id, binding.controlInstanceId, fieldValue?.value);
        this.setUnApplyBehaviorTopic(cmd.action.id, binding.controlInstanceId, fieldValue?.value);
      } else {
        topic = AppEventTopics.buildControlBindingTopic(cmd.action.id, binding.controlInstanceId);
      }
      const behaviorData: BehaviorData = new BehaviorData(binding.behaviors);
      this.eventService.publish(topic, behaviorData, RtSome(event?.eventProducerName));
    }
  }

  private publishEventData(binding: ControlBindingsContainer, cmd: ApplyActionCommand<ControlBindingsAction>, event: AppEvent) {
    let topic: string;
    if (binding.canApplyEventDataAsDataSource && binding.idPropertyName && event?.producerType != ProducerType.GLOBAL_EVENT_DATA) {
      const fieldValue = cmd.data?.data.find(d => d.fieldName == binding.idPropertyName)
      topic = AppEventTopics.buildControlBindingTopicForUniqueControl(cmd.action.id, binding.controlInstanceId, fieldValue?.value);
    } else if (binding.canApplyEventDataAsDataSource) {
      topic = AppEventTopics.buildControlBindingTopic(cmd.action.id, binding.controlInstanceId);
    }
    const eventData: EventData = new EventData(cmd.data, cmd.action?.eventType);
    this.eventService.publish(topic, eventData, RtSome(binding.controlInstanceId));
  }

  private setUnApplyBehaviorTopic(actionId: string, controlInstanceId: string, fieldValue: string) {
    const key = this.constructKeyForUnApply(actionId, controlInstanceId);
    const unApplyBehaviorsTopic = AppEventTopics.buildControlBindingUnApplyTopicForUniqueControl(actionId, controlInstanceId, fieldValue);
    this.publishedTopicsMap.set(key, unApplyBehaviorsTopic);
  }

  private publishUnApplyBehaviorData(actionId: string, controlInstanceId: string) {
    const key = this.constructKeyForUnApply(actionId, controlInstanceId);
    const unApplyBehaviorsTopic = this.publishedTopicsMap.get(key);
    if (unApplyBehaviorsTopic) {
      this.eventService.publish(unApplyBehaviorsTopic, {});
      this.publishedTopicsMap.delete(key);
    }
  }


  private constructKeyForUnApply(actionId: string, controlInstanceId: string) {
    return `${actionId}_${controlInstanceId}`;
  }
}
