import { AttributeThemeType } from "./attribute";

export class AttributeInstance<T = any> {
  constructor(public name: string, public value: T | string | number | boolean, public attributeType: string) { //controlInstance

  }

  getCssAttribute(): string {
    return this.name + ':' + this.value;
  }
}

export class AttributeInstanceHolder {

  constructor(public name: string,
    public value: string | number | boolean | any,
    public attributeType: string,
    public attributeThemeType: AttributeThemeType = AttributeThemeType.NON_THEMEABLE_ATTRIBUTE) {
  }

}

export class UsableAttributeValue<U> {

  constructor(public name: string, public value: U, public attributeType: string) { //controlInstance
  }

  withUpdatedValue(newValue: U) {
    return new UsableAttributeValue(this.name, newValue, this.attributeType)
  }

  as<U2>() {
    return this.value as unknown as U2;
  }
}


//s3 -> (behaviour,controlNames[], attributeInstance[])
//db -> (controlInstance, attributeInstance[])
//
//
//Model class for ControlAttributeInstance
// export class ControlAttributeInstance extends coreModels.AppEntity {

//   public id: string;
//   public name: string;
//   public attributeType: string
//   public value: any;
//   public controlInstanceId: string;
//   public pageName: string;
//   public isRemoved: boolean;
//   public page?: Page;

//   constructor() {
//     super();
//   }
// }
