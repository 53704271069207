
import { RtOption, RtSome } from "../../../utils/option-helper";
import { AnimationAttributeName } from "../animation/animation-constants";
import { UsableAttributeValue } from "./attribute-instance";

export class ModelAndUsableValueContainer<M, U> {
  constructor(public model: RtOption<M>, public usableValues: UsableAttributeValue<U>[]) { }

  hasAnimationValue(): boolean {
    return this.usableValues.map(u => u.name).includes(AnimationAttributeName.ANIMATION);
  }

  hasAttribute(name: string): boolean {
    //AnimationAttribute.getName()
    return this.usableValues.map(u => u.name).includes(name)
  }

  value(attributeName: string): RtOption<U> {
    return RtSome(this.usableValues.find(u => u.name == attributeName)).map(u => u.value)
  }


}
