import { Directive, OnDestroy, Type } from "@angular/core";
import { RtNone, RtOption, RtSome } from "../../../../../utils/option-helper";
import { Observable, Subject } from "rxjs";
import { AttributeInstance } from "../../../../attributes/core/attribute-instance";
import { IDsResult } from "../../../../data-source/data-source-result-entities";
import { ControlType, IControl, RenderedControlInstance } from "../../control-model";
import { CompanionMixinItems } from "../companion-mixin-menu";
import { CompanionRequestCommand } from "../control-companion-models";

export enum ControlEventType {
  DRAG_ENTER = "dragenter",
  DRAG_LEAVE = "dragleave",
  CLICK = "click",
  UN_CLICK = "un_click",
  ESCAPE = "escape",
  DROP = "drop",
  ON_LOAD = "on_load",
  MOUSE_ENTER = "mouse_enter",
  MOUSE_LEAVE = "mouse_leave",
  MOUSE_OVER = "mouse_over",
  MOUSE_OUT = "mouse_out",
  CONTEXT_MENU = "contextmenu",
}
export class CompanionMixinHolder {

  //mixin: () => Promise<Type<ICompanionMixin>> ---> Lazy loaded companion mixin component
  //mixinInitiationEvent: --> event on which mixin to be initiated
  //mixinDestroyEvent: --> events on which mixin to be destroyed
  constructor(public mixin: () => Promise<Type<CompanionMixin>>, public mixinInitiationEvent: ControlEventType, public mixinDestroyEvents: ControlEventType[]) {

  }
}

export abstract class CompanionMixinRegistryProvider {
  constructor() { }

  registry: Map<string, CompanionMixinHolder>;

  getCompanionMixin(controlGroupName: string): RtOption<CompanionMixinHolder> {
    const mixinHolder = this.registry.get(controlGroupName);
    return mixinHolder ? RtSome(mixinHolder) : RtNone();
  }
}

export abstract class ControlAssistantMixinRegistryProvider {
  constructor() { }

  registry: Map<ControlType, CompanionMixinHolder>;

  getControlAssistantMixin(controlType: ControlType): RtOption<CompanionMixinHolder> {
    const mixinHolder = this.registry.get(controlType);
    return mixinHolder ? RtSome(mixinHolder) : RtNone();
  }
}

export enum CompanionMixinChangeEventType {
  CONTROL_DROPPED = "control_dropped",
  CONFIGURATIONS_CHANGE = "configurations_change",
  ATTRIBUTES_CHANGE = "attributes_change",
  CONTROL_DELETE = "control_delete",
}

export abstract class CompanionMixinChangeEvent {
  abstract eventType: CompanionMixinChangeEventType;
  constructor(public data: unknown) {

  }

}
export class ControlDroppedEvent extends CompanionMixinChangeEvent {
  eventType: CompanionMixinChangeEventType = CompanionMixinChangeEventType.CONTROL_DROPPED;
  constructor(data: unknown) {
    super(data);
  }
}

export type AttributeInstanceUpdateData = { allAttributeInstances: AttributeInstance[], updatedAttributeInstance: AttributeInstance }
export class AttributesChangeEvent extends CompanionMixinChangeEvent {
  eventType: CompanionMixinChangeEventType = CompanionMixinChangeEventType.ATTRIBUTES_CHANGE;
  constructor(data: AttributeInstanceUpdateData) {
    super(data);
  }
}
export class ControlDeleteEvent extends CompanionMixinChangeEvent {
  eventType: CompanionMixinChangeEventType = CompanionMixinChangeEventType.CONTROL_DELETE;
  constructor() {
    super({});
  }
}

@Directive()
export abstract class CompanionMixin extends CompanionMixinItems implements OnDestroy {

  /**
   * The control for which the companion is attached to
   */
  controlName: string;

  /**
   * runtime instance of the control
   */
  control: IControl<unknown>;

  abstract initMixin(cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): void;

  abstract destroyMixin(): void;

  //notifyMixinChanges(): void;
  private mixinChangesSubject: Subject<CompanionMixinChangeEvent>;

  constructor() {
    super();
    this.mixinChangesSubject = new Subject<CompanionMixinChangeEvent>();
  }

  getMixinChangesNotifier(): Observable<CompanionMixinChangeEvent> {
    const observable = this.mixinChangesSubject.asObservable();
    return observable;
  };

  typedControl<C extends IControl<unknown>>() {
    return this.control as C;
  }

  protected notifyMixinChanges(companionMixinChangeEvent: CompanionMixinChangeEvent) {
    this.mixinChangesSubject.next(companionMixinChangeEvent);
  }

  //This method will be fired when a child control is dragged and dropped in the current control.
  // This will be called from ControlCompanion
  public onChildControlAdded(childControl: RenderedControlInstance) {
    //Do nothing. If any control mixin is interested, can override this method and implement the expected functionality
  }

  ngOnDestroy(): void {
    this.destroyMixin();
  }
}

// /**
//  *   _      _
//  *  |        |
//  *
//  *  |_      _|
//  */
// export class GridChildCompanionMixin implements ICompanionMixin {

//   controlName: String = ControlRegistryConstant.GRID_LAYOUT

//   name: String;

//   initMixin(_cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): void {
//     throw new Error("Method not implemented.");
//   }
//   notifyMixinChanges() {
//     throw new Error("Method not implemented.");
//   }
//   controlInstance: IControl<unknown>;

// }

// /**
//  *      |
//  *  ds1 |
//  *      |________
//  *          ds2
//  */
// export class ChartCompanionMixin implements ICompanionMixin {

//   controlName: String  // ControlRegistryConstant.CHAR

//   initMixin(_cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): void {
//     throw new Error("Method not implemented.");
//   }
//   notifyMixinChanges() {
//     throw new Error("Method not implemented.");
//   }
//   controlInstance: IControl<unknown>;


// }
