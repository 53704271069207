import { RtNone, RtSome } from "../../../../../utils/option-helper";
import { AppToEditorMessagingService } from "../../../../services/attribute-panel-bridging/control-attribute-panel-messaging.service";
import { ControlDescriptor } from "../../control-descriptor";
import { ControlInstanceWrapper } from "../../control-instance-drafts-models";
import { AppEventTopics, ControlAssistantOptionSelectedEvent } from "../../event/app-event-topics";
import { ControlAssistantMenuOption, ControlAssistantMenu } from "../control-assistant/control-assistant-menu-provider";
import { CompanionMixin } from "./companion-mixin";

export abstract class ControlAssistantMixin extends CompanionMixin {

    public ctrlInstance: ControlInstanceWrapper;
    public ctrlDescriptor: ControlDescriptor;
    
    constructor(public appToEditorMessagingService: AppToEditorMessagingService, ) {
        super();
    }


    getMenuItems() {
        const additionalMenuItems: ControlAssistantMenuOption[] = [
            this.constructDataSourceMenuItem(),
            this.constructRecommendedControlsMenuItem(),
            this.constructDeleteControlMenuItem(),
            this.constructEventManagerMenuItem(),
        ];
        // const menuItems = [...this.controlAssistantMenuProvider.getMenuItems(), ...additionalMenuItems];
        const menuItems = [...additionalMenuItems];
        return menuItems;
    }

    private constructDataSourceMenuItem(): ControlAssistantMenuOption {
        return {
            name: ControlAssistantMenu.DATA_SOURCE,
            iconName: `svg-icons-charvaka-db`,
            component: RtNone(),
            callBackFunc: RtSome((event: MouseEvent) => {
                const openControlEventStoreTopic = AppEventTopics.buildOpenDataBindingTopic();
                this.publishToEditor(event, openControlEventStoreTopic);
            })
        };
    }
    private publishToEditor(event: MouseEvent, openControlEventStoreTopic: string) {
        const openControlEventStoreEvent: ControlAssistantOptionSelectedEvent = {
            controlInstanceId: this.ctrlInstance.instanceId, controlName: this.ctrlInstance.name,
            event_ClientX: event.clientX, event_ClientY: event.clientY, controlId: this.control.id
        };
        this.appToEditorMessagingService.publishToEditor(openControlEventStoreTopic, openControlEventStoreEvent);
    }

    private constructRecommendedControlsMenuItem(): ControlAssistantMenuOption {
        return {
            name: ControlAssistantMenu.RECOMMENED_CONTROLS,
            iconName: `material-icons-add-box`,
            component: RtNone(),
            callBackFunc: RtSome((event: MouseEvent) => {
                const openControlEventStoreTopic = AppEventTopics.buildOpenRecommendedControlsTopic();
                this.publishToEditor(event, openControlEventStoreTopic);
            })
        };
    }
    private constructDeleteControlMenuItem(): ControlAssistantMenuOption {
        return {
            name: ControlAssistantMenu.DELETE,
            iconName: `material-icons-delete`,
            component: RtNone(),
            callBackFunc: RtSome((event: MouseEvent) => {
                const openControlEventStoreTopic = AppEventTopics.buildDeleteControlTopic();
                this.publishToEditor(event, openControlEventStoreTopic);
            })
        };
    }
    private constructEventManagerMenuItem(): ControlAssistantMenuOption {
        return {
            name: ControlAssistantMenu.MANAGE_EVENTS,
            iconName: `svg-icons-charvaka-db`,
            component: RtNone(),
            callBackFunc: RtSome((event: MouseEvent) => {
                const openControlEventStoreTopic = AppEventTopics.buildOpenControlEventStoreTopic();
                this.publishToEditor(event, openControlEventStoreTopic);
            })
        };
    }
}