import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class ArraySortPipe implements PipeTransform {

  transform(array: any, field?: any): any {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      const varA = (typeof a[field] === 'string') ?
        a[field].toUpperCase() : a[field];
      const varB = (typeof b[field] === 'string') ?
        b[field].toUpperCase() : b[field];

      if (varA < varB) {
        return -1;
      } else if (varA > varB) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}
