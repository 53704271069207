import { ButtonConfirmationModel } from "projects/den-core/src/lib/page-builder/attributes/button-confirmation/button-confirmation-attribute"
import { FilterOperator } from "../../../../../../models/base.models"
import { BindingTypeEnum } from "../../../../../../page-builder/data-source/data-source"
import { RtNone, RtOption } from "../../../../../../utils/option-helper"
import { ActionProducedDataSchema } from "../../event-schema-provider"
import { EventAction } from "../../event-store"

export type BackendAction = {
  //beActionType: BackendActionType,
  pageApiType: string,
  pageApiGroup: string,
  pageApiName: string,
  pageApiMethodName: string,
  pageApiMethodType: string,
  params: BackendActionBoundParam[],
  actionProducedDataSchema: ActionProducedDataSchema[],
  confirmationDialogConfiguration: ButtonConfirmationModel,
  actionEventMessage: ActionEventMessage //Toast Message
} & EventAction
//params and everything else will be part of PageApi (a.k.a. datasource)


export type BackendActionBoundParam = {
  name: string,
  type: string,
  boundPropertyName?: string,
  boundPropertyProducerName?: string,
  defaultValue?: string | number | boolean,
  bindingType: BindingTypeEnum,
  isArray: boolean,
  operator: FilterOperator,
}

export class ActionEventMessage {
  constructor(public successMessage: string, public failureMessage: string, public isActionEvent: boolean) { }
}

export class BackendActionReceivedParamBuffer {

  private value: any | any[];

  constructor(public param: BackendActionBoundParam) {

  }

  getValue() {
    if (this.value != null) {
      return this.value;
    } else if (this.param.defaultValue != null) {
      return this.param.defaultValue;
    } else {
      return null;
    }
  }

  applyValue(datasource: RtOption<any>, selectedStatus: RtOption<boolean> = RtNone()) {
    if (!datasource.isDefined) { return; }
    if (selectedStatus.isDefined && selectedStatus.get === false) {
      this.removeValue(datasource.get);
    } else {
      this.setValue(datasource.get);
    }
  }

  private setValue(value: any) {
    if (this.param.isArray) {
      if (Array.isArray(value)) {
        this.value = value;
      }
      //  else if (Array.isArray(this.value) && this.param.canAccumalate) {
      //   this.value.push(value);
      // }
      else {
        this.value = [value];
      }
    } else {
      this.value = value;
    }
  }

  private removeValue(value: any) {
    if (this.param.isArray) {
      if (Array.isArray(this.value)) {
        this.value = this.value.filter(v => v !== value);
      } else {
        console.error('No data available to remove');
      }
    } else {
      this.value = null;
    }
  }
}
