import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  private loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loader$: Observable<boolean>;

  constructor(){
    this.loader$ = this.loaderSubject.asObservable();
  }

  start() {
    this.loaderSubject.next(true)
  }

  stop() {
    this.loaderSubject.next(false);
  }
}