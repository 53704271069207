import { ChangeDetectorRef, Directive, ElementRef, Injector, Renderer2, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { GroupByDialogComponent } from "projects/den-core/src/lib/components-library/group-by-dialog/group-by-dialog.component";
import { TaskListFilterComponent } from "projects/den-core/src/lib/page-builder/controls/core/task-list-filter/task-list-filter.component";
import { ModalService } from "projects/den-core/src/lib/modal-service/modalservice";
import { SchemaService } from "projects/den-core/src/lib/providers/schema/schema.service";
import { Observable, Subscriber } from "rxjs";
import { EntitySchemaFieldContainer } from "../../../models/schema/schema-models";
import { RtNone, RtOption, RtSome } from "../../../utils/option-helper";
import { AttributeInstance, UsableAttributeValue } from "../../attributes/core/attribute-instance";
import { PubSubTopic } from "../../constants/pub-sub-topic-constants";
import { ControlPropertyDefinitionValue } from "../../controls/core/control-instance";
import { CtrlEventRaised } from "../../controls/core/event/control-event";
import { ReturnMethodParamsWrapper } from "../../data-source/data-source";
import { DsResult, DsResultArray, IDsArrayOrGroupedResult } from "../../data-source/data-source-result-entities";
import { ICommonControlRenderService } from "../../ide/services/common-control-render-service";
import { SimpleAngularControlInfo } from "../../ide/services/parent-control";
import { FilterAndSortResult } from "../../models/model";
import { AngularContainerControlHelper } from "../helper/angular-container-control-helper";
import { DialogWidthConstants, FilterAndSortModalData, GrpByModalData } from "../list-view-models";
import { AngularListControl } from "./angular-list-control";
import { NORecordsConfiguration } from "../../constants/common-constants";


@Directive()
export abstract class AngularListDroppableContainerControl<D extends IDsArrayOrGroupedResult, R extends ICommonControlRenderService<SimpleAngularControlInfo>> extends AngularListControl<D, R> {

    // entitySchemaFields: EntitySchemaField[];
    //dataSource: any;

    responseData: RtOption<DsResultArray>;
    completeList: RtOption<DsResultArray>;

    style: any;
    public internalAttributeValues: AttributeInstance[] = [];
    mainContainerId: string;
    private cdkRef: ChangeDetectorRef;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    constructor(injector: Injector, public el: ElementRef, public renderer: Renderer2, public schemaService: SchemaService,
        public modalService: ModalService, resultClass: { new(...args: any[]): D; }) {
        super(injector, resultClass);
        this.cdkRef = injector.get(ChangeDetectorRef);
    }

    reApplyConfigurationAttributes(_configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
        this.applyConfigurationAttributes(_configurationAttributeValues);
    }

    applyConfigurationAttributes(_configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
        super.applyConfigurationAttributes(_configurationAttributeValues);
    }

    applyPropertyDefinitions(_propertyDefinitions: ControlPropertyDefinitionValue[]): void {
        this.propertyDefinitions = _propertyDefinitions;
        super.applyPropertyDefinitions(_propertyDefinitions);
    }

    onControlEventReceivedFn(_event: CtrlEventRaised): void {
        // throw new Error('Method not implemented.');
        // do nothing;
    }

    onContainerClick(dataSource: DsResult[], instanceId: string, hightlightBgColor?: boolean) {
        if (instanceId.includes('~')) {
            instanceId = instanceId.split('~')[0];
        } else {
            instanceId = instanceId;
        }
        const clickableNodeList = document.querySelectorAll(".pb-clickable-control[id*=" + instanceId + "]");
        if (clickableNodeList && clickableNodeList.length > 0) {
            clickableNodeList.forEach(node => {
                node.classList.remove('rt-selected');
            });
        }
        if (clickableNodeList.length && hightlightBgColor) {
            this.renderer.addClass(this.el.nativeElement.firstChild, 'rt-selected');
        }
        this.onClick(dataSource);
    }

    reApplyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]) {
        this.style = undefined;
        this.applyCSSAttributes(cssAttributeValues);
    }
    applyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]) {

        AngularContainerControlHelper.updateHeightIfAutoHeightIsEnabled(this.el, this.renderer, cssAttributeValues);
        AngularContainerControlHelper.applyStylesToSelector(this.el, this.renderer, cssAttributeValues);
        const styleObject = this.style ? RtSome(this.style) : RtNone();
        this.style = AngularContainerControlHelper.updateStylesForSelf(styleObject, cssAttributeValues);
    }

    tryRemoveChildControlsIfAny(): void {
        this.cdkRef.detectChanges();
    }

    renderNoRecordsFoundElement(empty: string): void {
        const parent: any = document.getElementById(this.controlInstance.instanceId);
        const id = this.getNoRecordsID();
        const noRecordsElement = document.getElementById(id);
        if (noRecordsElement) {
            parent.removeChild(noRecordsElement)
        }
        const div = document.createElement('div');
        div.id = id;
        div.className = 'rt-text-center rt-bold-2x rt-full-width rt-full-height rt-flex-center';
        const text = document.createTextNode(empty);
        div.appendChild(text);
        parent.appendChild(div);
        // find the current elemet using instanceID
        // append 'No records found/user provide default value' to parent
    }

    getNoRecordsID() {
        return this.controlInstance.instanceId + NORecordsConfiguration.NORECORDS;
    }

    openGrpByDialog(): void {
        this.getGrpByDialogInputs().subscribe((grpByInput: GrpByModalData) => {
            // TODO add below code for group by
            this.modalService.invoke(GroupByDialogComponent, grpByInput, DialogWidthConstants.width).subscribe((result: EntitySchemaFieldContainer | {}) => {
                if (result) {
                    this.saveGrpByFieldAndInvokeDs(result);
                }
            });
        });
    }

    private getGrpByDialogInputs(): Observable<GrpByModalData> {
        return new Observable((observer: Subscriber<GrpByModalData>) => {
            const returnMethodParamsWrappers = this.getReturnMethodParamsWrapper();
            observer.next({
                schemaFields: returnMethodParamsWrappers,
                selectedGroupByField: this.getGroupedEntitySchemaField(returnMethodParamsWrappers)
            });
        });
    }

    private getGroupedEntitySchemaField(schemaFields: ReturnMethodParamsWrapper[]): ReturnMethodParamsWrapper {
        return schemaFields.find(esf => esf.name ===
            (this.groupedField?.isDefined && this.groupedField.get));
    }

    private saveGrpByFieldAndInvokeDs(result: EntitySchemaFieldContainer | {}) {
        if (Object.keys(result)?.length) {
            this.groupedField = RtSome((result as EntitySchemaFieldContainer)?.entitySchemaField.label);
            super.onChangeManageGrpingAndSortingIfApplicable(RtSome(this.data));
        } else {
            // Reset
            this.groupedField = RtNone();
            const flattenedData = this.groupedDataContainer?.flatten();
            this.groupedDataContainer = null;
            super.onChangeManageGrpingAndSortingIfApplicable(RtSome(flattenedData), true);

        }
    }

    openFilterAndSortDialog(): void {
        this.getfilterAndSortDialogInputs().subscribe((filterAndSortInput: FilterAndSortModalData) => {
            filterAndSortInput.controlInstanceId = this.controlInstance.instanceId;
            this.modalService.invoke(TaskListFilterComponent, filterAndSortInput, DialogWidthConstants.width).subscribe((result: FilterAndSortResult) => {
                if (result) {
                    const hasValues = result?.model ? Object.values(result?.model).some(c => c) : true;
                    this.saveOrUpdateSortField(result);
                    this.saveFilterPropsAndInvokeDs(hasValues, result);
                }
            });
        });
    }

    saveOrUpdateSortField(result: FilterAndSortResult) {
        if (result?.sortBy) {
            this.sortField = new RtOption(result?.sortBy.name);
        } else {
            this.sortField = RtNone();
        }
    }

    private getfilterAndSortDialogInputs(): Observable<FilterAndSortModalData> {
        return new Observable((observer: Subscriber<FilterAndSortModalData>) => {
            //   TODO reviw Nagarju => resolveSchemaFields
            const dsInstance = this.getDatasourceIfConfigured();
            const methodParameters = dsInstance.method?.returningResult.map(r => new ReturnMethodParamsWrapper(dsInstance.dsName, r)).flat();
            const schemaFields = methodParameters;
           
            observer.next({
                returnMethodParams: this.columnList?.dsPropertyName?.length ? this.getFilterableCols(schemaFields) : schemaFields,
                selectedFilterData: this.getSelectedFilterData(),
                selectedSortField: this.getSelectedSortField(schemaFields),
                isClientFilter: this.isServerSideFilteringEnabled ? !this.isServerSideFilteringEnabled : false
            });
        });
    }

    private getFilterableCols(schemaFields: ReturnMethodParamsWrapper[]): ReturnMethodParamsWrapper[] {
        return schemaFields.filter(esf => this.columnList?.dsPropertyName?.includes(esf.name));
    }

    private getSelectedSortField(schemaFields: ReturnMethodParamsWrapper[]): ReturnMethodParamsWrapper {
        return schemaFields.find(esf => esf.name === (this.sortField?.isDefined && this.sortField.get));
    }

    private getSelectedFilterData(): Record<string, any> {
        if (this.filterValues) {
            return this.filterValues;
        } else {
            return typeof this.filterSettings === 'object' ? this.filterSettings : null;
        }
    }

    private saveFilterPropsAndInvokeDs(hasValues: boolean, result: FilterAndSortResult): void {
        if ((!hasValues && this.getSelectedFilterData()) || (hasValues && JSON.stringify(result?.model) !== JSON.stringify(this.getSelectedFilterData())) || result?.sortBy) {
            this.filterValues = Object.assign({}, result?.model);
            if (this.isServerSideFilteringEnabled) {
                this.paginator.pageIndex = 0;
                super.applyServerSideFilter(this.filterValues);
            } else {
                this.setFilterSettings();
                throw Error("Review why markAsRestore is required");
            }
        }
    }

    private setFilterSettings(): void {
        this.updateFilterSettings(this.filterValues);

        if (this.filterValues && typeof this.filterValues === 'object') {
            this.filterValues = this.removeEmptyObjects(this.filterValues);
            this.eventService.publish(PubSubTopic.buildCssPropertiesTopicId(this.controlInstance.instanceId),
                { controlInstance: this.controlInstance, pageId: this.controlInstance.pageName });
        }
    }

    removeEmptyObjects(obj: Record<string, any>) {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
                delete obj[key];
            }
        }
        return obj;
    }


    ngOnDestroy() { super.ngOnDestroy(); }

}
