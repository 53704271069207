import { Injectable } from "@angular/core";
import { RtOption, RtSome } from "../../../../utils/option-helper";
import { Attribute, UsableValueResolver } from "../../../attributes/core/attribute";
import { AttributeInstanceHolder, UsableAttributeValue } from "../../../attributes/core/attribute-instance";
import { ModelAndUsableValueContainer } from "../../../attributes/core/model-and-usable-value-container";
import { AttributeResolverType } from "../attribute-instance-resolver";
import { ControlDescriptor } from "../control-descriptor";


@Injectable()
export class AttributeHydrationService {

  constructor(private usableValueResolver: UsableValueResolver) {

  }

  async hydrateInstances(allAttributeValues: AttributeInstanceHolder[], attributeResolverType: AttributeResolverType,
    descriptor: ControlDescriptor) {
    if (attributeResolverType === AttributeResolverType.ON_INIT) {
      return this.hydrateAndBuildAttributeInstancesByDescriptorAttributes(allAttributeValues, descriptor);
    } else {
      return this.hydrateAndBuildAttributeInstances(allAttributeValues, descriptor);
    }
  }

  /**
  * takes persisted instances and uses descriptor provided attributes
  *
  * @param persistedAttributeValues user overriden values
  */
  private async hydrateAndBuildAttributeInstancesByDescriptorAttributes(persistedAttributeValues: AttributeInstanceHolder[],
    descriptor: ControlDescriptor): Promise<ModelAndUsableValueContainer<unknown, unknown>[]> {
    const descriptorAttributes = await descriptor.getAttributes();
    return descriptorAttributes.map(attribute => {
      const persistedInstance = persistedAttributeValues.find(a => a.name.toLowerCase() == attribute.name.toLowerCase());
      //it will inetrnally convert to empty if undefined or null
      const persistedInstanceOpt = RtSome(persistedInstance);
      return this.applyAttributeInstanceAndResolveUsableValue(persistedInstanceOpt, attribute);
      // return hydratedAttribute.getUsableValues();
    }).flat();
  }

  private async hydrateAndBuildAttributeInstances(persistedAttributeValues: AttributeInstanceHolder[],
    descriptor: ControlDescriptor): Promise<ModelAndUsableValueContainer<unknown, unknown>[]> {

    const descriptorAttributes = await descriptor.getAttributes();
    const attributeValuesList = persistedAttributeValues.map(persistedInstance => {
      const attribute = descriptorAttributes.find(a => a.name.toLowerCase() == persistedInstance.name.toLowerCase());
      //it will inetrnally convert to empty if undefined or null
      const persistedInstanceOpt = RtSome(persistedInstance);
      return this.applyAttributeInstanceAndResolveUsableValue(persistedInstanceOpt, attribute);
    }).flat();
    return attributeValuesList?.filter(c => c.model != null);
  }

  /**
   *
   * @param attributeInstance user overriden value
   * @param decoratedAttribute attribute which is decorated on each control's descriptor. e.g. bg-color-attribute
   */
  applyAttributeInstanceAndResolveUsableValue<M, U>(attributeInstance: RtOption<AttributeInstanceHolder>,
    decoratedAttribute: Attribute<M, U>): ModelAndUsableValueContainer<M, U> {
    if (decoratedAttribute == undefined) {
      return this.buildContainer(null, null)
    }
    if (attributeInstance.isDefined && attributeInstance.get.value != null) {
      const parsedModel = decoratedAttribute.parseModel(attributeInstance.get.value);
      const usableValues: UsableAttributeValue<U>[] = this.usableValueResolver.buildUsableValue<M, U>(decoratedAttribute, RtSome(parsedModel));
      return this.buildContainer(RtSome(parsedModel), usableValues)
    } else {
      const model = decoratedAttribute.defaultValueOpt.map(fn => fn())
      const usableValues = this.usableValueResolver.buildUsableValue(decoratedAttribute, model);
      return this.buildContainer(model, usableValues)
    }
  }

  buildContainer<M, U>(model: RtOption<M>, usableValues: UsableAttributeValue<U>[]) {
    return new ModelAndUsableValueContainer(model, usableValues)
  }
}
