import { Injector } from "@angular/core";
import { DenDraftReaderApi } from "../base-apis/den-draft-reader-api";
import { SessionStorageProvider } from "../providers/abstract/session-storage-provider";

// @Injectable()
export class DenDraftCacheableReaderApi extends DenDraftReaderApi {
    sessionStorageService: SessionStorageProvider;
    constructor(injector: Injector, entityName: string) {
        super(injector, entityName, true);
        this.sessionStorageService = injector.get<SessionStorageProvider>(SessionStorageProvider);
    }
}
