import { isValid } from "date-fns";
import { DateTimeHelper, FKResolverHelper, RtOption, RtSome } from "projects/den-core";
import { DateTimeFieldConstants } from "projects/den-core/contants";
import { ControlInstanceWrapper, DsResultValue, FormatTypes, NAConstant } from "projects/den-core/page-builder";
import { ControlPropertyDefinitionValue, DataSourceHelper } from "projects/den-core/src/lib/page-builder/controls/core/control-instance";
import { ExtendedDsResultValue } from "../../../../../den-core/src/lib/page-builder/helpers/extended-ds";

export class ControlsHelper {

    static resolveNameForDefaultValueAndFkeys(displayPropertyName: string, dsResultValues: DsResultValue[], propertyDefinition: ControlPropertyDefinitionValue, defaultValue: string, fkeys: string[] = []) {
        if (displayPropertyName) {
            if (dsResultValues) {
                const valueToBind = this.getDatasourceValue(displayPropertyName, dsResultValues, propertyDefinition);
                if (valueToBind.isDefined) {
                    defaultValue = valueToBind.get;
                }
            }
        } else {
            const valueToBind = propertyDefinition?.value ? FKResolverHelper.resolveFkeys(propertyDefinition.value, new ExtendedDsResultValue(dsResultValues), fkeys) : null;
            if (valueToBind) {
                defaultValue = valueToBind;
            }
        }
        return defaultValue;
    }
    static getDatasourceValue(dsBoundPropertyName: string, dsResultValues: DsResultValue[], propertyDefinition: ControlPropertyDefinitionValue): RtOption<any> {
        const dsBoundProperty: DsResultValue = dsResultValues.find(ds => ds.fieldName === dsBoundPropertyName);
        if (dsBoundProperty) {
            let resolvedValue = DataSourceHelper.getValueForBinding(propertyDefinition, dsBoundProperty);
            if (!resolvedValue && resolvedValue != 0) {
                resolvedValue = propertyDefinition.value ? propertyDefinition.value : NAConstant.NA;
            }
            return RtSome(resolvedValue);
        } else if (propertyDefinition && propertyDefinition.dsPropertyName == dsBoundPropertyName && propertyDefinition.value) {
            return RtSome(propertyDefinition.value);
        } else {
            return RtSome(NAConstant.NA);
        }
    }

    static applyDefaultValue(controlInstance: ControlInstanceWrapper, propertyDefinitionValue: string, label: string) {
        let defaultValue;
        let propertyDefinition = controlInstance.getPropertyDefinition(propertyDefinitionValue);
        if (propertyDefinition?.value) {
            defaultValue = propertyDefinition.value;
        } else {
            defaultValue = label;
        }
        return defaultValue;
    }


    static resolveNameForDefaultValueAndFkeysForLabelControl(displayPropertyName: string, dsResultValues: DsResultValue[], propertyDefinition: ControlPropertyDefinitionValue, defaultValue: string, dateDisplayFormat: string) {
        if (displayPropertyName) {
            if (dsResultValues) {
                const valueToBind = this.getDatasourceValue(displayPropertyName, dsResultValues, propertyDefinition);
                if (valueToBind.isDefined) {
                    defaultValue = valueToBind.get;
                }
            }
        } else {
            const valueToBind = propertyDefinition?.value ? this.resolveFkeysForLabelControl(propertyDefinition.value, new ExtendedDsResultValue(dsResultValues), [], dateDisplayFormat) : null;
            if (valueToBind) {
                defaultValue = valueToBind;
            }
        }
        return defaultValue;
    }

    //To display the date range value when Event data binder/mapper applied to a control
    static checkForDatePresentationFormatForControls(defaultValue: any, dateDisplayFormat: string) {//TODO: resolve for original date with time on change of formatDate
        if (defaultValue && typeof defaultValue === 'object' && DateTimeFieldConstants.START_DATE_TIME in defaultValue && DateTimeFieldConstants.END_DATE_TIME in defaultValue) {
            const startDateTime = this.formatDateTimeWithOutTimeZone(defaultValue[DateTimeFieldConstants.START_DATE_TIME], dateDisplayFormat)
            const endDateTime = this.formatDateTimeWithOutTimeZone(defaultValue[DateTimeFieldConstants.END_DATE_TIME], dateDisplayFormat)
            defaultValue = `${startDateTime} - ${endDateTime}`;
        } else {
            const date = DateTimeHelper.parseDateString(defaultValue)
            const isValidDate = isValid(date)
            if ((typeof defaultValue != 'number') && isValidDate && dateDisplayFormat && date) {
                defaultValue = DateTimeHelper.getFormattedDateString(dateDisplayFormat, defaultValue);
            }
        }
        return defaultValue;
    }

    static formatDateTimeWithOutTimeZone(value: string, dateDisplayFormat: string) {
        const date = new Date(value)
        const isoDateString = date.toISOString();
        const isoStringWithoutTimezone = isoDateString.substring(0, isoDateString.length - 1);
        const dateWithoutTimezone = new Date(isoStringWithoutTimezone);
        return DateTimeHelper.getFormattedDateString(dateDisplayFormat ? dateDisplayFormat : FormatTypes.mm_dd_yyyy, dateWithoutTimezone)
    }

    static resolveFkeysForLabelControl(value: string, dataSource: ExtendedDsResultValue, fkeys: string[] = [], dateDisplayFormat: string) {
        const matches = value ? value.match(/\{([^{}]+)\}/g) : [];
        if (matches && matches.length) {
            matches.forEach(match => {
                const propertyName = match.replace(/[{}]/g, '');
                const isPropertyIsApi = fkeys?.some(k => k === propertyName);
                let ds: string;
                if (isPropertyIsApi) {
                    const dsvalue = dataSource.getValueByFieldName(propertyName);
                    ds = Array.isArray(dsvalue) ? dsvalue?.join(',') : dsvalue || 'NA';
                } else {
                    ds = this.resolveFKEYCustomPropAndDirectValueForLabelControl(propertyName, ds, dataSource, fkeys, dateDisplayFormat);
                }
                value = value ? value.replace(match, (typeof ds === 'number' || typeof ds === 'boolean') ? ds : ds || 'NA') : 'NA';
            });
        }
        return value;
    }

    private static resolveFKEYCustomPropAndDirectValueForLabelControl(propertyName: string, ds: string, dataSource: ExtendedDsResultValue, fkeys: string[], dateDisplayFormat: string) {
        ds = dataSource.getValueByFieldName(propertyName);
        return this.checkForDatePresentationFormatForControls(ds, dateDisplayFormat);
    }

    static getDSResultValueBasedOnPropertyName(actualdata: DsResultValue[], dsPropertyName: string) {
        return actualdata.find(dsResultArray => dsResultArray.fieldName === dsPropertyName);
    }


}


