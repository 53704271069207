export class PasswordRecoveryConstants {
    static EMAIL = 'devum-Email';
    static PHONE = 'devum-Phone';
    static RECOVERY_PASSWORD = 'devum-recoverPassword';
    static UPDATE_PASSWORD = 'devum-updatePassword';
    static LOGIN_FORM = 'devum-login';
}
export class IdentifierType {
    static INTERNAL = 'internal';
    static EXTERNAL = 'external'
}
export class Route {
    static SECURITY = 'security';
    static LOGIN = 'login';
    static INTERNAL = 'internal';
    static MANAGE_ROLE = 'manage_role';
    static SITES_APPS = 'sites-apps';
    static SECURITY_INTERNAL = `${Route.SECURITY}/${Route.INTERNAL}`;
    static SECURITY_EXTERNAL = `${Route.SECURITY}/${Route.LOGIN}`;
    static SECURITY_SITES_APPS = `${Route.SECURITY}/${Route.SITES_APPS}`;
}

// export enum RuntimeMode {
//     DEVELOPMENT_MODE = 2,
//     APP_MODE = 1
// }

export class AuthenticationExceptionCodes {
    static NO_APPS = 70002; //TODO: Update with actual
    static IS_FORCE_CHANGE_PASSWORD = 70003; 
}

  
  