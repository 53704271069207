import { ScaleType } from "@swimlane/ngx-charts";
import { ChartAttributesEnum, ChartTypes } from "projects/den-core/src/lib/models/chart/chart-models";
import { ColorHelper } from "projects/den-core/src/lib/page-builder/attributes/color/color-helper";

export class ChartColorBindingHelper {

    static applyDefaultColorConfigurationAttributes(reference: any) {
        ChartColorBindingHelper.constructColorDetails(reference);
        ChartColorBindingHelper.constructLineChartColors(reference);
        return reference;
    }

    private static constructColorDetails(reference: any) {
        const colors = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.COLORS];
        const extraColorProp = reference.extraColorProp ? reference.extraColorProp : null;
        const colorCodes: string[] = reference.dataSource ? reference.dataSource.map(items => {
            const colorCodeObj = items.find(field => field.fieldName === extraColorProp);
            return colorCodeObj ? colorCodeObj.value : null;
        }) : [];
        reference[ChartAttributesEnum.LEGEND_COLORS] = this.getLegendColorsOnChartType(reference);
        reference[ChartAttributesEnum.LEGEND_COLOR_SCHEME] = { name: 'legendColorScheme', selectable: true, group: ScaleType.Ordinal, domain: reference[ChartAttributesEnum.LEGEND_COLORS] };
        // TODO Kushal check color property
        const legendColors = reference[ChartAttributesEnum.LEGEND_COLORS][0];
        reference[ChartAttributesEnum.COLORS] = colorCodes.find(code => code != null) ? ColorHelper.setCorrectColorCodes(colorCodes, legendColors) : this.setDefaultColorIfNoColors(colors || []);
        reference.colorCodes = { name: 'chartColorTheme', selectable: true, group: ScaleType.Ordinal, domain: reference[ChartAttributesEnum.COLORS] };
    }

    private static getLegendColorsOnChartType(reference: any) {
        if (this.isBarLineChartType(reference) || this.isStackedVerticalBarLineChartType(reference)) {
            return [...reference.CONFIG_BINDINGS[reference.BINDING_PROPS.COLORS], ...reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LINE_COLOR]];
        }
        return [reference.CONFIG_BINDINGS[reference.BINDING_PROPS.COLORS]];
    }

    private static constructLineChartColors(reference: any) {
        const lineColors = reference.CONFIG_BINDINGS[reference.BINDING_PROPS.LINE_COLOR];
        reference[ChartAttributesEnum.LINE_COLOR] = lineColors;
        reference.lineColorTheme = { name: 'lineColorTheme', selectable: true, group: ScaleType.Ordinal, domain: reference[ChartAttributesEnum.LINE_COLOR] };
    }

    private static setDefaultColorIfNoColors(colors: string[]): string[] {
        if (colors.length === 0) {
            return ['#73a30b'];
        }
        return colors;
    }

    private static isBarLineChartType(reference: any) {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BAR_LINE_CHART_TYPES] && reference.CONFIG_BINDINGS[reference.BINDING_PROPS.BAR_LINE_CHART_TYPES].type === ChartTypes.barLineChart;
    }

    private static isStackedVerticalBarLineChartType(reference: any) {
        return reference.CONFIG_BINDINGS[reference.BINDING_PROPS.STACKED_VERTICAL_BAR_LINE_CHART_TYPES] && reference.CONFIG_BINDINGS[reference.BINDING_PROPS.STACKED_VERTICAL_BAR_LINE_CHART_TYPES].type === ChartTypes.stackedBarLineChart;
    }
}