import { Injectable } from "@angular/core";
import { IDsResult } from "../../../data-source/data-source-result-entities";
import { DataSourceAdapterService } from "./data-source-adapter.service";


@Injectable()
export class DataSourceAdapterFactory {
  constructor() {
  }

  getDataSourceAdapter<D extends IDsResult>(): DataSourceAdapterService {
    return new DataSourceAdapterService();
  }
}
