export class AttributeRegistryConstant {
  public static readonly PADDING = 'Spacing';
  // public static readonly MARGIN = 'Margin';
  public static readonly BORDER = 'Border';
  public static readonly BACKGROUND_IMAGE = 'Background Image';
  public static readonly TYPOGRAPHY = 'Typography';
  public static readonly DIMENSIONS = 'Dimensions';
  public static readonly MINMAXDIMENSIONS = 'MinMaxDimensions';
  public static readonly DISPLAY = 'Display';
  public static readonly DIMENSIONS_NO_AUTOHEIGHT = 'Dimension';
  public static readonly GPS_POINT = 'gps_point';

  public static readonly MAP_LAYER_COLOR = 'MAP_LAYER_COLOR';
  public static readonly MAP_LAYER_FILL = 'MAP_LAYER_FILL';
  public static readonly MAP_LAYER_OPACITY = 'MAP_LAYER_OPACITY';

  public static readonly IS_LAYER_SELECTER_REQUIRED = 'IS_LAYER_SELECTER_REQUIRED';
  public static readonly LABEL_COLOR = 'labelColor';
  public static readonly POSITION_ATTRIBUTE = 'position';
  public static readonly FLOAT = 'float';
  public static readonly OVERFLOW = 'overflow';
  public static readonly HYPERLINK = 'Hyperlink';
  public static readonly MOBI_HYPERLINK = 'MobiHyperlink';
  public static readonly FORM = 'Form';
  public static readonly CURSOR = 'Cursor';
  public static readonly USER_TASK_FORM = 'Usertask Form';
  public static readonly FORM_VIEW_UPDATE = 'Form View/Update ';
  public static readonly THERMAL_PRINTER = 'Thermal Printer';
  public static readonly DELETE_CONTROL_TYPE = 'Delete Control Type';
  public static readonly PRINT_SECTION_CONTROL_ID = 'Print Section Control ID';
  public static readonly SVG_ICON = 'Svg Icon';
  public static readonly COLOR = 'color';
  public static readonly COLOR_V2 = 'color';
  public static readonly BACKGROUND_COLOR = 'background-color';
  public static readonly OFFLINE_BACKGROUND_COLOR = 'offline-bg-color';
  public static readonly ENABLE_OFFLINE = 'enableOffline';
  public static readonly SHOW_HIDE_OFFLINE = 'show-hide-offline';
  public static readonly PROGRESS_BAR_BACKGROUND_COLOR = 'progress-bar-background-color';
  public static readonly BACKGROUND_MORE_OPTIONS = 'background-more-options';
  public static readonly BORDER_COLOR = 'border-color';
  public static readonly CUSTOM_USERTASK_FORM = 'Custom Usertask Form';
  public static readonly BORDER_RADIUS = 'Border Radius';
  public static readonly CUSTOM_POPUP = 'Custom Popup';
  public static readonly CUSTOM_IMPORT = 'Custom Import';
  public static readonly CUSTOM_EXPORT = 'Custom Export';
  public static readonly EMBEDDED_FORM = 'Embedded Form';
  public static readonly GRID_CONFIGURATIONS = 'Grid Configurations';
  public static readonly GRID_AREA = 'Grid Area';
  public static readonly BOX_SHADOW = 'Box Shadow';
  public static readonly STANDARD_MODEL_ATTRIBUTES = 'Model Attributes';
  public static readonly UOM_ATTRIBUTES = 'Unit of Measure';
  public static readonly EVENT_DATA_BINDER = 'Event Data Binder';
  public static readonly EVENT_DATA_MAPPER = 'Event Data Mapper';
  public static readonly FORM_EVENT_DATA_MAPPER = 'Form Event Data Mapper';
  public static readonly PLACE_HOLDER = 'Place Holder';
  public static readonly MOBI_PADDING_MARGIN = 'Padding Margin';
  public static readonly TEXT_OVERFLOW = 'text-overflow';
  public static readonly WHITE_SPACE = 'white-space';
  public static readonly BUTTON_CONFIRMATION_CONFIG = 'Button Confirmation';
  public static readonly REPORT_LAYOUT_CONTROL_CONFIRMATION_CONFIG = 'Report Layout Control Confirmation';
  public static readonly DATE_RANGE_MAX_VALIDATION = 'Max Validation'
  public static readonly CALIBRATION_POINT = 'CALIBRATION_POINT';
  public static readonly ORIGIN_POINT = 'ORIGIN_POINT';
  public static readonly PLACE_ITEMS = 'PLACE ITEMS';
  public static readonly CONTAINER_SELECTOR = 'Container Selector';
  public static readonly ICON_BADGE_OPTIONS = 'Icon Badge Options';
  public static readonly FORM_FIELD_VALUE_PUBLISHER = 'Form Field Value Publisher';
  public static readonly FORM_FIELD_FOR_TOAST_MESSAGE = 'Form Field for toast message';
  public static readonly FORM_CONTAINER_CONTROL_SELECTOR = 'Form Container Control Selector';
  public static readonly DEFAULT_VALUE = 'Default Value';
  public static readonly USER_TASK_FORM_BUTTON_BG_COLOR = 'User task Form Button BG Color';
  public static readonly GROUPED_LIST_COLUMNS_CONFIG = 'Grouped List Columns Configuration';
  // three_js attributes
  public static readonly CAMERA_CONFIGURATIONS = 'Camera Configurations';
  public static readonly MODEL_CONFIGURATIONS = 'Model Configurations';
  public static readonly CAMERA_CONTROL_CONFIGURATIONS = 'Camera Control Configurations';
  public static readonly LIGHTING_CONTROL_CONFIGURATIONS = 'Lighting Configurations';
  public static readonly MATERIAL_CONFIGURATIONS = 'Material Configurations';
  public static readonly DEFAULT_VIEW_CONFIGURATIONS = 'Default View Configuration';
  public static readonly POST_PROCESSING_CONTROL_CONFIGURATIONS = 'Post Processing Control';
  public static readonly FONT_SIZE = 'font-size';
  public static readonly OPACITY = 'opacity';
  public static readonly DE_HIGHLIGHT_OPACITY = 'de-highlight-opacity';
  public static readonly ZOOM_ON_HIGHLIGHT = 'zoom On Highlight';
  public static readonly MOBI_USER_PROFILE_CONFIG = 'Mobi User Profile Configurations';

  public static readonly TOGGLE_COLOR = 'Toggle Color';
  public static readonly SELECTED_CONTAINER = 'selectedContainer'
  // public static readonly MENU_BACKGROUND_COLOR = 'Menu Background Color';
  // public static readonly SELECTED_MENU_BACKGROUND_COLOR = 'Selected Menu Background Color';
  // public static readonly  MENU_TEXT_COLOR = 'Menu Text Color';

  public static readonly HIGHLIGHT_COLOR = 'highlight-color';
  public static readonly MULTI_LAYER_HIGHLIGHT_COLOR = 'multi-layer-highlight-color';
  public static readonly HOVER_COLOR = 'hover-color';
  public static readonly FORE_COLOR = 'fore-color';
  public static readonly HOVER_FONT_COLOR = 'hover-font-color';
  public static readonly THEME_BACKGROUND_COLOR = 'theme-background-color';
  public static readonly THEME_FOREGROUND_COLOR = 'theme-foreground-color';
  public static readonly TOOLTIP_BACKGROUND_COLOR = 'tooltip-background-color';
  public static readonly TOOLTIP_FOREGROUND_COLOR = 'tooltip-foreground-color';
  public static readonly MENU_BACKGROUND_COLOR = 'menu-background-color';
  public static readonly HEADER_BACKGROUND_COLOR = 'header background color';
  public static readonly HEADER_FOREGROUND_COLOR = 'header foreground color';
  public static readonly MENU_ICON_COLOR = 'menu-icon-color';
  public static readonly ROW_BACKGROUND_COLOR = 'row background color';
  public static readonly ROW_HOVER_COLOR = 'row  hover color';
  public static readonly FILTER_PLACEHOLDER_COLOR = 'filter placeholder color';

  public static readonly ANIMATION = 'animation';

  public static readonly  PYRAMID_COLUMN = 'Pyramid column';
  public static readonly  PYRAMID_COLOR ='Pyramid Chart color'
  public static readonly  PROGRESS_BAR_TRACK_COLOR ='Track color'
  public static readonly  PROGRESS_BAR_ACTIVE_TRACK_COLOR ='Track Active color'
  public static readonly CHECK_BOX_COLOR = 'Check Box Color'

  //Chart properties
  public static readonly CHART_DIMENSION = 'Chart Dimension';
  public static readonly BAR_CHART_PROPERTIES = 'Bar Chart Properties';
  public static readonly AXIS_CHART_PROPERTIES = 'Axis Chart Properties';
  public static readonly PIE_CHART_PROPERTIES = 'Pie Chart Properties';
  public static readonly LINE_AREA_CHART_TYPE = 'Line Area Chart Type';
  public static readonly PIE_CHART_TYPE = 'Pie Chart Type';
  public static readonly GROUP_BAR_CHART_TYPE = 'Group Bar Chart Type';
  public static readonly BAR_LINE_CHART_PROPERTIES = 'Bar Line Chart Properties';
  public static readonly CHART_COLORS = 'Chart Colors';
  public static readonly LINE_COLORS = 'Line Colors';
  public static readonly BAR_LINE_BAR_COLOR = 'Bar Color';
  public static readonly LEGEND_PROPERTIES = 'Legend Properties';
  public static readonly AXIS_DATE_FORMAT = 'Axis Date Format Properties';
  public static readonly TREEMAP_CHART_PROPERTIES = 'Tree Map Chart Properties';
  public static readonly CHART_XY_COLOR_PROPERTIES = 'Chart X-Y Axis Color Properties';


  
  //SiteMenuItems

  public static readonly SITE_MAPPING = 'Site Mapping';
  public static readonly ICONSIZE = 'Icon Size';
  public static readonly ICON_RIGHT_SPACE = 'Icon Space';
  public static readonly ZOOM_TO_OBJECT = 'Zoom To Object';

  // Leafer attributes

  public static readonly LEAFLET_DEFAULT_LOCATION = 'Default Location';
  public static readonly LEAFLET_TILE_PROVIDER = 'Tile Provider';

  public static readonly LOGOUTBUTTON = 'LOGOUTBUTTON';
  public static readonly WIDTH = 'WIDTH';

  // Permission
  public static readonly PERMISSION = 'permission';

  //Multi DS Select
  public static readonly MULTI_DS_SELECT = 'Multi Ds Select';

  //Single DS Select
  public static readonly SINGLE_DS_SELECT = 'Single Ds Select';

  public static readonly QR_CODE_PROPERTIES = 'QR Code Properties';
  public static readonly PRINTER_PROPERTIES = 'Printer Properties';
  public static readonly GROUP_HEADER_COLOR = 'Group Header Color';
  public static readonly GROUP_HEADER_FONTSIZE = 'Group Header Size';
  public static readonly AGGREGATE_COLOR = 'Aggregate Total Color';
  public static readonly GROUP_HEADER_WIDTH = 'Group Header Width';

  public static readonly UNIQUE_CODE_GENERATOR = 'Unique Code Generator';
  public static readonly ROUTING_ROUTES = 'Routes Configuration';
  public static readonly ROUTING_PARAMS = 'Routes Param Configuration';
  public static readonly PRINT_IN_NEXT_PAGE = 'Print following content in next page';

  public static readonly SCAN_TYPE = 'Scan type';

}
