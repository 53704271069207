import { ElementRef, Injector, Renderer2 } from "@angular/core";
import { RtNone, RtSome } from "../../../utils/option-helper";
import { UsableAttributeValue } from "../../attributes/core/attribute-instance";
import { NORecordsConfiguration } from "../../constants/common-constants";
import { DsResult } from "../../data-source/data-source-result-entities";
import { ICommonControlRenderService } from "../../ide/services/common-control-render-service";
import { IControlInfo } from "../../ide/services/parent-control";
import { AngularContainerControlHelper } from "../helper/angular-container-control-helper";
import { AngularControl } from "./angular-control";

export abstract class AngularDroppableContainerControl<CI extends IControlInfo, R extends ICommonControlRenderService<CI>> extends AngularControl<CI, R> {

    style: any;
    mainContainerId: string;

    constructor(
        injector: Injector,
        public el: ElementRef,
        public renderer: Renderer2,
    ) {
        // TODO new()
        super(injector);

    }

    onContainerClick(dataSource: DsResult) {

        this.onClick([dataSource]);
    }

    reApplyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]) {
        this.style = undefined;
        this.applyCSSAttributes(cssAttributeValues);
    }

    applyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]) {
        AngularContainerControlHelper.updateHeightIfAutoHeightIsEnabled(this.el, this.renderer, cssAttributeValues);
        AngularContainerControlHelper.applyStylesToSelector(this.el, this.renderer, cssAttributeValues);
        const styleObject = this.style ? RtSome(this.style) : RtNone();
        this.style = AngularContainerControlHelper.updateStylesForSelf(styleObject, cssAttributeValues);
    }

    tryRemoveChildControlsIfAny(): void {

        throw Error("TODO: Revisit pagination along with filters, parentSectionIndex");
    }

    renderNoRecordsFoundElement(empty: string): void {
        const parent: any = document.getElementById(this.controlInstance.instanceId);
        const id = this.getNoRecordsID();
        const noRecordsElement = document.getElementById(id);
        if (noRecordsElement) {
            parent.removeChild(noRecordsElement)
        }
        const div = document.createElement('div');
        div.id = id;
        div.className = 'rt-text-center rt-bold-2x';
        const text = document.createTextNode(empty);
        div.appendChild(text);
        parent.appendChild(div);
        // find the current elemet using instanceID
        // append 'No records found/user provide default value' to parent
    }

    getNoRecordsID() {
        return this.controlInstance.instanceId + NORecordsConfiguration.NORECORDS;
    }


}

