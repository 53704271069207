import { DsResultValue } from "projects/den-core/page-builder";
import { BaseChart, BaseChartSeries, ChartDateXAxisFormat } from "projects/den-core/src/lib/models/chart/chart-models";
import { ChartDataConstructionHelper } from './chart-data-construction-helper';

export class ListChartDataConstructionHelper {

    //Bar chart list data handle
    static handleChartDsResultArray(chartDsRes: DsResultValue[][], xAxisProperty: string,
        yAxisPropertyList: string[], yAxisProperty: string, xAxisDateFormat: ChartDateXAxisFormat, parentField?:string) {
        let series: BaseChartSeries[] = [];

        if (yAxisPropertyList.length === 0) {
            chartDsRes.forEach(dsRes => {
                const seriesValue = this.constructBarChartSeries(dsRes, xAxisProperty, yAxisProperty,
                    xAxisDateFormat);
                const exists = series.some(value => value.name === seriesValue.name);
                if(!exists){
                    series.push(seriesValue);
                }
            });
        } else {
            chartDsRes.forEach(dsRes => {
                series = series.concat(this.constructBarChartSeriesArray(dsRes, xAxisProperty, yAxisPropertyList,
                    xAxisDateFormat, parentField));
            });
        }

        return series;
    }

     //Group bar chart
     static handleGroupBarChart(chartDsRes: DsResultValue[][], xAxisProperty: string,
        yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat): BaseChart[] {
        let baseChart: BaseChart[] = [];
        const nameSet = new Set();

        if (yAxisPropertyList.length !== 2) {
            console.error('yAxisPropertyList length should be 2');
            return baseChart;
        }

        chartDsRes.forEach(dsRes => {
            const name = ChartDataConstructionHelper.getValue(dsRes, xAxisProperty);
            if(name){
                nameSet.add(name);
            }else{
                nameSet.add('NA');
            }
        });

        if (nameSet.size > 0) {
            let nameArray = [...nameSet];
            nameArray.forEach((name: string) => {
                const series = this.constructGroupBarChartSeries(chartDsRes, xAxisProperty, yAxisPropertyList,
                    xAxisDateFormat, name);
                baseChart.push(new BaseChart(name, series));
            });
        }
        return baseChart;
    }

    private static constructBarChartSeriesArray(chartDsRes: DsResultValue[], xAxisProperty: string,
        yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat, parentField?:string):
        BaseChartSeries[] {
        let series: BaseChartSeries[] = [];
        const seriesName = ChartDataConstructionHelper.formatSeriesName(chartDsRes, xAxisProperty, xAxisDateFormat);
        yAxisPropertyList.forEach(ya => {
            const value = ChartDataConstructionHelper.getSeriesValue(chartDsRes, ya);
            const parentPropertyValue = ChartDataConstructionHelper.getTreeMapParentValue(chartDsRes, parentField);
            series.push(new BaseChartSeries(seriesName, value, null, parentPropertyValue));
        });
        return series;
    }

    private static constructBarChartSeries(chartDsRes: DsResultValue[], xAxisProperty: string,
        yAxisProperty: string, xAxisDateFormat: ChartDateXAxisFormat):BaseChartSeries {
        const seriesName = ChartDataConstructionHelper.formatSeriesName(chartDsRes, xAxisProperty, xAxisDateFormat);
        const value = ChartDataConstructionHelper.getSeriesValue(chartDsRes, yAxisProperty);
        return new BaseChartSeries(seriesName, value);
    }

    //Line chart list data handle
    static handleLineAreaChart(chartDsRes: DsResultValue[][], xAxisProperty: string,
        yAxisPropertyList: string[], xAxisDateFormat: ChartDateXAxisFormat): BaseChart[] {
        let baseChart: BaseChart[] = [];
        const nameSet = new Set();

        if (yAxisPropertyList.length !== 2) {
            console.error('yAxisPropertyList length should be 2');
            return baseChart;
        }

        chartDsRes.forEach(dsRes => {
            const name = ChartDataConstructionHelper.getValue(dsRes, yAxisPropertyList[0]);
            if(name){
                nameSet.add(name);
            }else{
                nameSet.add('NA');
            }
        });

        if (nameSet.size > 0) {
            let nameArray = [...nameSet];
            nameArray.forEach((name: string) => {
                const series = this.constructLineAreaChartSeries(chartDsRes, xAxisProperty, yAxisPropertyList,
                    xAxisDateFormat, name);
                baseChart.push(new BaseChart(name, series));
            });
        }
        return baseChart;
    }

    private static constructLineAreaChartSeries(chartDsRes: DsResultValue[][], xAxisProperty: string, yAxisPropertyList: string[],
        xAxisDateFormat: ChartDateXAxisFormat, baseName: string): BaseChartSeries[] {
        let series: BaseChartSeries[] = [];
        chartDsRes.forEach(dsRes => {
            const name = ChartDataConstructionHelper.getValue(dsRes, yAxisPropertyList[0]);
            if (baseName === name) {
                const name = ChartDataConstructionHelper.formatSeriesName(dsRes, xAxisProperty, xAxisDateFormat);
                const value = ChartDataConstructionHelper.getValue(dsRes, yAxisPropertyList[1]);
                series.push(new BaseChartSeries(name, value));
            }
        });
        return series;
    }

    private static constructGroupBarChartSeries(chartDsRes: DsResultValue[][], xAxisProperty: string, yAxisPropertyList: string[],
        xAxisDateFormat: ChartDateXAxisFormat, baseName: string): BaseChartSeries[] {
        let series: BaseChartSeries[] = [];
        chartDsRes.forEach(dsRes => {
            const name = ChartDataConstructionHelper.getValue(dsRes,xAxisProperty);
            if (baseName === name) {
                const name = ChartDataConstructionHelper.formatSeriesName(dsRes, yAxisPropertyList[0], xAxisDateFormat);
                const value = ChartDataConstructionHelper.getValue(dsRes, yAxisPropertyList[1]);
                series.push(new BaseChartSeries(name, value));
            }
        });
        return series;
    }
}
