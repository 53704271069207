export class WriterApisRegistry {

      static AdvancedEntitySchemaSettingWriterApi = "AdvancedEntitySchemaSettingWriterApi"
      static AdvancedSchemaFieldSettingWriterApi = "AdvancedSchemaFieldSettingWriterApi"
      static AppDeploymentWriterApi = "AppDeploymentWriterApi"
      static AppPermittedOperationWriterApi = "AppPermittedOperationWriterApi"
      static AppRolePermissionsWriterApi = "AppRolePermissionsWriterApi"
      static AppSettingsWriterApi = "AppSettingsWriterApi"
      static AppTableWriterApi = "AppTableWriterApi"
      static AppUserpoolMappingWriterApi = "AppUserpoolMappingWriterApi"
      static BasicBridgingServiceWriterApi = "BasicBridgingServiceWriterApi"
      static BoundedContextSchemaMappingWriterApi = "BoundedContextSchemaMappingWriterApi"
      static BoundedContextWriterApi = "BoundedContextWriterApi"
      static CFSBackendActionUtilizerBridgingServiceWriterApi = "CFSBackendActionUtilizerBridgingServiceWriterApi"
      static CharvakaFluentFunctionCompositionWriterApi = "CharvakaFluentFunctionCompositionWriterApi"
      static CharvakaFluentServiceConverterApi = "CharvakaFluentServiceConverterApi"
      static CharvakaFluentServiceUtilizerWriterApi = "CharvakaFluentServiceUtilizerWriterApi"
      static CharvakaFluentServiceWriterApi = "CharvakaFluentServiceWriterApi"
      static CharvakaProcessDefintionWriterApi = "CharvakaProcessDefintionWriterApi"
      static CharvakaProcessElementReferenceWriterApi = "CharvakaProcessElementReferenceWriterApi"
      static CharvakaProcessSpatialConfigurationWriterApi = "CharvakaProcessSpatialConfigurationWriterApi"
      static CharvakaProcessUserRolesConfigurationWriterApi = "CharvakaProcessUserRolesConfigurationWriterApi"
      static CharvakaProcessWriterApi = "CharvakaProcessWriterApi"
      static CharvakaTestCaseDetailPersistableWriterApi = "CharvakaTestCaseDetailPersistableWriterApi"
      static CharvakaTestCaseWriterApi = "CharvakaTestCaseWriterApi"
      static CommonUserAccountsAndRolesWriterApi = "CommonUserAccountsAndRolesWriterApi"
      static ComposerGroupWriterApi = "ComposerGroupWriterApi"
      static ControlAttributeInstanceWriterApi = "ControlAttributeInstanceWriterApi"
      static ControlInstanceWriterApi = "ControlInstanceWriterApi"
      static CreateAppUsingTemplateApi = "CreateAppUsingTemplateApi"
      static CreateTemplateWriterApi = "CreateTemplateWriterApi"
      static CustomPermissionWriterApi = "CustomPermissionWriterApi"
      static CustomerMicroserviceWriterApi = "CustomerMicroserviceWriterApi"
      static DataSourceServiceInstanceWriterApi = "DataSourceServiceInstanceWriterApi"
      static DevumLexBotWriterApi = "DevumLexBotWriterApi"
      static DevumNotificationTemplateWriterApi = "DevumNotificationTemplateWriterApi"
      static DevumNotificationWriterApi = "DevumNotificationWriterApi"
      static DevumOpenAiWriterApi = "DevumOpenAiWriterApi"
      static DevumStreamingBridgeServiceWriterApi = "DevumStreamingBridgeServiceWriterApi"
      static DevumUserPoolWriterApi = "DevumUserPoolWriterApi"
      static DraftFinalizationWriterApi = "DraftFinalizationWriterApi"
      static DynamicFormsCreateApi = "DynamicFormsCreateApi"
      static DynamicFormsDeleteApi = "DynamicFormsDeleteApi"
      static DynamicFormsUpdateApi = "DynamicFormsUpdateApi"
      static EntityColumnConfigurationWriterApi = "EntityColumnConfigurationWriterApi"
      static EntityFieldConditionOperatorWriterApi = "EntityFieldConditionOperatorWriterApi"
      static EntityFieldConditionWriterApi = "EntityFieldConditionWriterApi"
      static EntityFieldDisplayConditionWriterApi = "EntityFieldDisplayConditionWriterApi"
      static EntityPrePostProcessorCFSUtilizerBridgingServiceWriterApi = "EntityPrePostProcessorCFSUtilizerBridgingServiceWriterApi"
      static EntitySchemaFieldFilterWriterApi = "EntitySchemaFieldFilterWriterApi"
      static EntitySchemaFieldWriterApi = "EntitySchemaFieldWriterApi"
      static EntitySchemaPrecisMappingWriterApi = "EntitySchemaPrecisMappingWriterApi"
      static EntitySchemaPrecisWriterApi = "EntitySchemaPrecisWriterApi"
      static EntitySchemaWriterApi = "EntitySchemaWriterApi"
      static FieldApiCFSUtilizerBridgingServiceWriterApi = "FieldApiCFSUtilizerBridgingServiceWriterApi"
      static FileHandlingForTemplateApi = "FileHandlingForTemplateApi"
      static FileIOWriterApi = "FileIOWriterApi"
      static FileServiceWriterApi = "FileServiceWriterApi"
      static FluentServiceTaskCFSUtilizerBridgingServiceWriterApi = "FluentServiceTaskCFSUtilizerBridgingServiceWriterApi"
      static GlobalDynamicFormsCreateApi = "GlobalDynamicFormsCreateApi"
      static GlobalDynamicFormsDeleteApi = "GlobalDynamicFormsDeleteApi"
      static GlobalDynamicFormsUpdateApi = "GlobalDynamicFormsUpdateApi"
      static HelpCategoryWriterApi = "HelpCategoryWriterApi"
      static HelpWriterApi = "HelpWriterApi"
      static ImportExportWriterApi = "ImportExportWriterApi"
      static InstanceDetailWriterApi = "InstanceDetailWriterApi"
      static LabelWriterApi = "LabelWriterApi"
      static MViewParamsMappingWriterApi = "MViewParamsMappingWriterApi"
      static MViewTransactionDateMappingWriterApi = "MViewTransactionDateMappingWriterApi"
      static ManageMobileAppWriterApi = "ManageMobileAppWriterApi"
      static MaterializedViewCFSUtilizerBridgingServiceWriterApi = "MaterializedViewCFSUtilizerBridgingServiceWriterApi"
      static MaterializedViewDefinitionWriterApi = "MaterializedViewDefinitionWriterApi"
      static MenuItemWriterApi = "MenuItemWriterApi"
      static MobileSettingWriterApi = "MobileSettingWriterApi"
      static ModelConfigurationWriterApi = "ModelConfigurationWriterApi"
      static OrganizationDisposeWriterApi = "OrganizationDisposeWriterApi"
      static PageEventsContainerWriterApi = "PageEventsContainerWriterApi"
      static PageOfflineStrategyWriterApi = "PageOfflineStrategyWriterApi"
      static PageParameterWriterApi = "PageParameterWriterApi"
      static PageWriterApi = "PageWriterApi"
      static PostOrgSubscriptionApi = "PostOrgSubscriptionApi"
      static PostUserActionProcessorCFSUtilizerBridgingServiceWriterApi = "PostUserActionProcessorCFSUtilizerBridgingServiceWriterApi"
      static QuickStyleTemplateWriterApi = "QuickStyleTemplateWriterApi"
      static ReactiveFluentServiceWriterApi = "ReactiveFluentServiceWriterApi"
      static ReportScheduleWriterApi = "ReportScheduleWriterApi"
      static RestResponderCFSBridgeWriterApi = "RestResponderCFSBridgeWriterApi"
      static RetryDeploymentApi = "RetryDeploymentApi"
      static RoleDeleteWriterApi = "RoleDeleteWriterApi"
      static SiteCustomAttributeWriterApi = "SiteCustomAttributeWriterApi"
      static SiteMapWriterApi = "SiteMapWriterApi"
      static SiteTableWriterApi = "SiteTableWriterApi"
      static TaskDetailsWriterApi = "TaskDetailsWriterApi"
      static TestCaseExecutionResultWriterApi = "TestCaseExecutionResultWriterApi"
      static TimerBridgingServiceWriterApi = "TimerBridgingServiceWriterApi"
      static UserAccountAppMappingWriterApi = "UserAccountAppMappingWriterApi"
      static UserAccountOTPWriterApi = "UserAccountOTPWriterApi"
      static UserAccountSiteMappingWriterApi = "UserAccountSiteMappingWriterApi"
      static UserActionWriterApi = "UserActionWriterApi"
      static UserAppSettingsWriterApi = "UserAppSettingsWriterApi"
      static UserAuthorizerApi = "UserAuthorizerApi"
      static UserRoleDeleteWriterApi = "UserRoleDeleteWriterApi"
      static UserSettingWriterApi = "UserSettingWriterApi"
      static ZKSettingsWriterApi = "ZKSettingsWriterApi"

}