import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { DynamicLoaderComponent } from "./dynamic-loader.component";

const MAT_MODULES = [
    MatDialogModule,
];

@NgModule({
    imports: [
        CommonModule,
        ...MAT_MODULES,
    ],
    declarations: [
        DynamicLoaderComponent,
    ],
    exports: [
        DynamicLoaderComponent,
    ]
})
export class DynamicLoaderModule { }