import { AdvancedFilter, FilterProperty, PageProperties, SortingField, SortingOperator, SortingProperty } from "../models/base.models";

export class AdvancedFilterHelper {
    static getAdvancedFilter(filterProperties?: FilterProperty[], sortingProperties?: SortingProperty, pageNumber?: number, pageSize?: number): AdvancedFilter {
        const advanceFilter: AdvancedFilter = new AdvancedFilter();
        const pageProperty = new PageProperties(pageNumber ? pageNumber : 1, pageSize ? pageSize : null);

        if (filterProperties && filterProperties.length > 0) {
            for (const element of filterProperties) {
                advanceFilter.filterProperties.push(new FilterProperty(element.propertyName, element.operator, element.value1, element.value2, element.valueList));
            }
        }

        advanceFilter.sortingProperties = sortingProperties ? sortingProperties : new SortingProperty([new SortingField('id', 1)], SortingOperator.DESCENDING_ORDER);
        advanceFilter.pageProperties = pageProperty;
        advanceFilter.attributes = [];
        return advanceFilter;
    }
}
