import { AbstractControl, ValidatorFn } from "@angular/forms";
import { CFSDataTypes } from "projects/den-core/contants";
import { EntitySchemaFieldContainer } from "projects/den-core/schema-models";
import { FormRenderService } from "../shared/service/form-render-service";


export class FormValidator {
  constructor(private formRenderService: FormRenderService) {
  }

  uniqueValidation(schemaFieldContainer: EntitySchemaFieldContainer): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } => {
      const controlValue = this.validateAndReturnString(control.value);
      const uniqueValuesList: string[] = this.checkForDuplicateValue(schemaFieldContainer);
      const isUniqueValue = uniqueValuesList.indexOf(controlValue) === -1;
      return isUniqueValue ? null : { 'uniqueValidation': control.value };
    }
  };

  private validateAndReturnString(value: string | any) {
    if (Array.isArray(value) || !isNaN(value)) {
      return value;
    } else {
      return value ? value.toLowerCase().trim() : value;
    }
  }

  checkForDuplicateValue(s: EntitySchemaFieldContainer & { distinctValues?: string[]; }) {
    const distinctValues = this.convertToLowerCaseForStringType(s);
    const formData = this.formRenderService.getData();
    const currentValue = this.validateAndReturnString(formData ? formData[s.fieldName] : null);
    return distinctValues.filter(item => item !== currentValue)
  }

  convertToLowerCaseForStringType(s: EntitySchemaFieldContainer & { distinctValues?: string[] }) {
    if (s.entitySchemaField.fieldType === CFSDataTypes.NUMBER || s.entitySchemaField.fieldType === CFSDataTypes.DOUBLE) {
      return s.distinctValues || [];
    } else {
      return s.distinctValues ? s.distinctValues.map(item => item.toLowerCase().trim()) : [];
    }
  }
}
