import { NumberFieldType, RegExpTypes } from "../../../../../den-web/src/app/page-builder/models/model";

export class RegExPatterns {
    public static DUPLICATE_SPACES_REPLACE = /\s\s+/g;
    //Credits: http://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
    public static ISO_DATE_TIME = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;

    // reference: http://www.pelagodesign.com/blog/2009/05/20/iso-8601-date-validation-that-doesnt-suck/
    //public static ISO_DATE_TIME = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/

    public static NON_ALPHA_NUMERIC = /\W+/g;
    public static NUMBER = /^[0-9]*$/;
    // public static ALL_NUMBER =  /^[0-9]$/;
    public static NUMBER_WITH_DECIMAL = /^[0-9.0]+(?:\.[0-9.]+)?$/;
    public static DECIMAL = /^\d+([,.]\d+)?$/;
    public static ALPHABETS = /^[a-zA-Z]*$/;
    public static ALPHA_NUMERIC = /^[a-zA-Z|0-9]*$/;
    public static ALPHA_NUMERIC_SPL_CHARACTER = /^[a-zA-Z|0-9|\W]*$/;
    public static SPL_CHARACTER = /^[\W]*$/;
    public static NUMERIC_SPL_CHARACTER = /^[0-9|\W]*$/;
    public static ALPHABET_SPL_CHARACTER = /^[a-zA-Z|\W]*$/;
    public static NUMBER_WITH_SPACE = /^[a-zA-Z0-9 ]*$/;
    public static NUMBER_WITH_ONE_DECIMAL = /^[0-9]+(\.[0-9]{1})?$/;
    public static NUMBER_WITH_TWO_DECIMAL = /^[0-9]+(\.[0-9]{1,2})?$/;
    public static NUMBER_WITH_THREE_DIGIT = /^[0-9]{1,3}$/;
    public static DATE_TIME_PICKER = /(2[0-9]{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) ([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])/;
    public static DATE_PICKER = /(2[0-9]{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/;
    public static TIME_PICKER = /([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])/;
    public static IMAGE_UPLOAD_PATTERN = /\.(jpg|jpeg|png|gif|tif|tiff)$/;
    public static ddMMyyyyRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    public static DATA_URL_TO_FILE = /:(.*?);/;
    public static BOOLEAN = /^([Tt][Rr][Uu][Ee]|[Ff][Aa][Ll][Ss][Ee])$/;
    public static TEMPLATE = /(<([^>]+)>)/i;
    public static VALID_FIELD_NAME = /^(?=.*[a-zA-Z])[a-zA-Z0-9_-]*$/;
    public static VALID_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
    public static VALID_EMAIL = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
    public static VALID_USERNAME = /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u;
    public static FUNCTION_MATCHES = /([^{}]+)(?=\})/g;
    public static VARIABLE_MATCHES = /(?<=\[).+?(?=\])/g;
    public static FUNCTION_ARGUMENTS = /\(\s*([^)]+?)\s*\)/;
}
export class RegExpType {

    static numRegex: NumberFieldType[] = [
        { name: RegExpTypes.NO_DECIMAL, label: 'No decimal' },
        { name: RegExpTypes.ONE_DECIMAL, label: 'Upto 1 decimal' },
        { name: RegExpTypes.TWO_DECIMAL, label: 'Upto 2 decimal' },
        { name: RegExpTypes.NONE, label: 'None' }
    ];


}

