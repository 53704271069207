export class ReaderApisRegistry {

      static AdvancedEntitySchemaSettingReaderApi = "AdvancedEntitySchemaSettingReaderApi"
      static AdvancedSchemaFieldSettingReaderApi = "AdvancedSchemaFieldSettingReaderApi"
      static AppDeploymentHistoryReaderApi = "AppDeploymentHistoryReaderApi"
      static AppDeploymentReaderApi = "AppDeploymentReaderApi"
      static AppPermittedOperationReaderApi = "AppPermittedOperationReaderApi"
      static AppRolePermissionsReaderApi = "AppRolePermissionsReaderApi"
      static AppSettingsReaderApi = "AppSettingsReaderApi"
      static AppTableReaderApi = "AppTableReaderApi"
      static AppTemplateReaderApi = "AppTemplateReaderApi"
      static AppUserpoolMappingReaderApi = "AppUserpoolMappingReaderApi"
      static AuditLogReaderApi = "AuditLogReaderApi"
      static AuditTrailReaderApi = "AuditTrailReaderApi"
      static BackendActionsReaderApi = "BackendActionsReaderApi"
      static BasicBridgingServiceReaderApi = "BasicBridgingServiceReaderApi"
      static BoundedContextReaderApi = "BoundedContextReaderApi"
      static BoundedContextSchemaMappingReaderApi = "BoundedContextSchemaMappingReaderApi"
      static CFSBackendActionUtilizerBridgingServiceReaderApi = "CFSBackendActionUtilizerBridgingServiceReaderApi"
      static CacheVersionReaderApi = "CacheVersionReaderApi"
      static CharvakaElementReaderApi = "CharvakaElementReaderApi"
      static CharvakaFluentFunctionCompositionReaderApi = "CharvakaFluentFunctionCompositionReaderApi"
      static CharvakaFluentServiceReaderApi = "CharvakaFluentServiceReaderApi"
      static CharvakaFluentServiceUtilizerReaderApi = "CharvakaFluentServiceUtilizerReaderApi"
      static CharvakaProcessDefintionReaderApi = "CharvakaProcessDefintionReaderApi"
      static CharvakaProcessElementReferenceReaderApi = "CharvakaProcessElementReferenceReaderApi"
      static CharvakaProcessReaderApi = "CharvakaProcessReaderApi"
      static CharvakaProcessSpatialConfigurationReaderApi = "CharvakaProcessSpatialConfigurationReaderApi"
      static CharvakaProcessUserRolesConfigurationReaderApi = "CharvakaProcessUserRolesConfigurationReaderApi"
      static CharvakaProcessViewerMenuItemReaderApi = "CharvakaProcessViewerMenuItemReaderApi"
      static CharvakaTestCaseDetailPersistableReaderApi = "CharvakaTestCaseDetailPersistableReaderApi"
      static CharvakaTestCaseReaderApi = "CharvakaTestCaseReaderApi"
      static CommonUserAccountsAndRolesReaderApi = "CommonUserAccountsAndRolesReaderApi"
      static ComposerGroupReaderApi = "ComposerGroupReaderApi"
      static ControlAttributeInstanceReaderApi = "ControlAttributeInstanceReaderApi"
      static ControlInstanceReaderApi = "ControlInstanceReaderApi"
      static CustomPermissionReaderApi = "CustomPermissionReaderApi"
      static CustomerMicroserviceReaderApi = "CustomerMicroserviceReaderApi"
      static DataEntityReaderApi = "DataEntityReaderApi"
      static DataSourceServiceInstanceReaderApi = "DataSourceServiceInstanceReaderApi"
      static DenDataTypeEnumReaderApi = "DenDataTypeEnumReaderApi"
      static DevumFullTextSearchReaderApi = "DevumFullTextSearchReaderApi"
      static DevumLexBotReaderApi = "DevumLexBotReaderApi"
      static DevumNotificationReaderApi = "DevumNotificationReaderApi"
      static DevumNotificationTemplateReaderApi = "DevumNotificationTemplateReaderApi"
      static DevumOpenAiReaderApi = "DevumOpenAiReaderApi"
      static DevumStreamingBridgeServiceReaderApi = "DevumStreamingBridgeServiceReaderApi"
      static DevumUserPoolReaderApi = "DevumUserPoolReaderApi"
      static DummyReaderApi = "DummyReaderApi"
      static DynamicFormsReaderApi = "DynamicFormsReaderApi"
      static EntityApiExecutorReaderApi = "EntityApiExecutorReaderApi"
      static EntityColumnConfigurationReaderApi = "EntityColumnConfigurationReaderApi"
      static EntityFieldConditionOperatorReaderApi = "EntityFieldConditionOperatorReaderApi"
      static EntityFieldConditionReaderApi = "EntityFieldConditionReaderApi"
      static EntityFieldDisplayConditionReaderApi = "EntityFieldDisplayConditionReaderApi"
      static EntityPrePostProcessorCFSUtilizerBridgingServiceReaderApi = "EntityPrePostProcessorCFSUtilizerBridgingServiceReaderApi"
      static EntitySchemaFieldFilterReaderApi = "EntitySchemaFieldFilterReaderApi"
      static EntitySchemaFieldReaderApi = "EntitySchemaFieldReaderApi"
      static EntitySchemaPrecisMappingReaderApi = "EntitySchemaPrecisMappingReaderApi"
      static EntitySchemaPrecisReaderApi = "EntitySchemaPrecisReaderApi"
      static EntitySchemaReaderApi = "EntitySchemaReaderApi"
      static FieldApiCFSUtilizerBridgingServiceReaderApi = "FieldApiCFSUtilizerBridgingServiceReaderApi"
      static FileServiceReaderApi = "FileServiceReaderApi"
      static FluentServiceExecutionRegistryReaderApi = "FluentServiceExecutionRegistryReaderApi"
      static FluentServiceTaskCFSUtilizerBridgingServiceReaderApi = "FluentServiceTaskCFSUtilizerBridgingServiceReaderApi"
      static GlobalDynamicFormsReaderApi = "GlobalDynamicFormsReaderApi"
      static HelpCategoryReaderApi = "HelpCategoryReaderApi"
      static HelpReaderApi = "HelpReaderApi"
      static InstanceDetailsReaderApi = "InstanceDetailsReaderApi"
      static LabelReaderApi = "LabelReaderApi"
      static LandingPageReaderApi = "LandingPageReaderApi"
      static LexBotRequestHandlerReaderApi = "LexBotRequestHandlerReaderApi"
      static MViewParamsMappingReaderApi = "MViewParamsMappingReaderApi"
      static MViewTransactionDateMappingReaderApi = "MViewTransactionDateMappingReaderApi"
      static ManageMobileAppReaderApi = "ManageMobileAppReaderApi"
      static MaterializedViewCFSUtilizerBridgingServiceReaderApi = "MaterializedViewCFSUtilizerBridgingServiceReaderApi"
      static MaterializedViewDefinitionReaderApi = "MaterializedViewDefinitionReaderApi"
      static MenuItemReaderApi = "MenuItemReaderApi"
      static MobileSettingReaderApi = "MobileSettingReaderApi"
      static ModelConfigurationReaderApi = "ModelConfigurationReaderApi"
      static MonitoringReaderApi = "MonitoringReaderApi"
      static MsFluentFunctionMetadataPersistableReaderApi = "MsFluentFunctionMetadataPersistableReaderApi"
      static PageEventsContainerReaderApi = "PageEventsContainerReaderApi"
      static PageOfflineStrategyReaderApi = "PageOfflineStrategyReaderApi"
      static PageParameterReaderApi = "PageParameterReaderApi"
      static PageReaderApi = "PageReaderApi"
      static PostUserActionProcessorCFSUtilizerBridgingServiceReaderApi = "PostUserActionProcessorCFSUtilizerBridgingServiceReaderApi"
      static PreLoginApi = "PreLoginApi"
      static QuickStyleTemplateReaderApi = "QuickStyleTemplateReaderApi"
      static ReactiveFluentServiceReaderApi = "ReactiveFluentServiceReaderApi"
      static ReportDateRangeReaderApi = "ReportDateRangeReaderApi"
      static ReportScheduleReaderApi = "ReportScheduleReaderApi"
      static RestResponderCFSBridgeReaderApi = "RestResponderCFSBridgeReaderApi"
      static RoleReaderApi = "RoleReaderApi"
      static SiteCustomAttributeReaderApi = "SiteCustomAttributeReaderApi"
      static SiteMapReaderApi = "SiteMapReaderApi"
      static SiteTableReaderApi = "SiteTableReaderApi"
      static TaskApiBindingsReaderApi = "TaskApiBindingsReaderApi"
      static TaskDetailsReaderApi = "TaskDetailsReaderApi"
      static TestCaseExecutionResultReaderApi = "TestCaseExecutionResultReaderApi"
      static ThemeReaderApi = "ThemeReaderApi"
      static TimerBridgingServiceReaderApi = "TimerBridgingServiceReaderApi"
      static UserAccountAppMappingReaderApi = "UserAccountAppMappingReaderApi"
      static UserAccountOTPReaderApi = "UserAccountOTPReaderApi"
      static UserAccountReaderApi = "UserAccountReaderApi"
      static UserAccountSiteMappingReaderApi = "UserAccountSiteMappingReaderApi"
      static UserAccountViewReaderApi = "UserAccountViewReaderApi"
      static UserActionReaderApi = "UserActionReaderApi"
      static UserAppSettingsReaderApi = "UserAppSettingsReaderApi"
      static UserRoleReaderApi = "UserRoleReaderApi"
      static UserSettingReaderApi = "UserSettingReaderApi"
      static ZKSettingsReaderApi = "ZKSettingsReaderApi"

}