import { Type } from '@angular/core';
import { MenuTypeEnum } from '../../../models/base.models';
import { Attribute, SimpleCssAttribute } from '../../attributes/core/attribute';
import { DataViewResponseTypes } from '../../constants/common-constants';
import { DSBindTypeEnum, PropertyDefinitionEnum, SingleOrArrayEnum } from '../../models/enums';
import { DisplayBindingType } from './control-instance';
import { ControlType } from './control-model';
import { CommonEventType, StandardEvents } from './event/event-types';

/**
 *  Label: 1) value
 *
 */
export class ControlPropertyDefinition<T> {
  constructor(public controlAttributeName: string,
    public type: 'number' | 'string' | 'array' | 'function' = 'string',
    public defaultValue?: T | Function,
    public dependencies?: { propertyName: string; value: string; }[],
    public displayBindingType?: DisplayBindingType) { }
}

export class ControlConditionCategory {
  constructor(public name: string) { }
}
export class DataTagsContainer {
  constructor(public mViewResponseTypes: DataViewResponseTypes[], public dataTypes: string[], public fieldNameTags: string[]) { }
}

export type DefaultAndOverriddenAttributes = { default: Attribute<unknown, unknown>[], overridden: Attribute<unknown, unknown>[]; };
export abstract class ControlDescriptor {


  //Configuration Time
  // 1 MViewResponseValue -> (label -> MViewItem, DivList -> MViewTimeSeriesItem, or calendar | DataTable -> MView2DMatrix)
  // 2 FieldType/DataType -> (e.g. DivList/Dropdown/multiSelect -> Array, Label -> String)
  // 3 if FieldName contains ("") -> (e.g. LabelControl with status style -> "status", NoteControl -> "Notes")

  #configurationComponentRef: () => Promise<Type<any>>;

  private allAttributes: Attribute<unknown, unknown>[];

  public ctrlEventTypes: CommonEventType[];

  public defaultControlConditionCategory = [new ControlConditionCategory('Default')];
  public propertyDefinitions: ControlPropertyDefinition<any>[] = [];

  // public get attributes() {
  //   return this.allAttributes;
  // }

  public async getAttributes() {
    if (!this.allAttributes) {
      await this.buildLazyAttributesWithOverridden();
    }
    return this.allAttributes;
  }

  public async cloneAttributes(): Promise<Attribute<unknown, unknown>[]> {
    //const clonedAttributes = this.allAttributes.map(attribute => attribute.clone());
    const attributes = await this.getAttributes();
    const clonedAttributes = attributes.map(attribute => attribute.clone());
    return clonedAttributes;
  }

  //protected abstract buildAttributes(): DefaultAndOverriddenAttributes;
  protected abstract buildAttributesAsync(): Promise<Type<ControlAttributes>>;
  //protected buildAttributesAsync: any;

  constructor(public controlName: string,
    public controlGroup: string,
    public controlIcon: string,
    public supportedDataSourceType: SingleOrArrayEnum,
    // public defaultAttributes: Attribute<unknown, unknown>[],
    // public overriddenAttributes: Attribute<unknown, unknown>[],
    public isContainer: boolean,
    public ctrlType: ControlType,
    propertyDefinitions?: ControlPropertyDefinition<any>[],
    public description = '',
    ctrlEventTypes: CommonEventType[] = [],
    public dsBindTypes: DSBindTypeEnum[] = [DSBindTypeEnum.SINGLE],
    public isBookMark?: boolean,
    // provide additional conditions e.g., Add, delete buttons of grid, etc.,
    // In normal scenario, no additioal categories are required;
    public controlConditionCategory?: ControlConditionCategory[],
    public supportedDirectValueFields?: string[],
    public dataTagsContainer = new DataTagsContainer([], [], []),
    public display: boolean = true,
    public menuType: MenuTypeEnum = MenuTypeEnum.HTML,
    public eventsToAttachOnInstanceCreation = [],
    public producerParams: string[] = [],
  ) {
    this.ctrlEventTypes = [...ctrlEventTypes, StandardEvents.CLICK, StandardEvents.TRIGGER_DATASOURCE, StandardEvents.EMPTINESS, StandardEvents.CLOSE];
    const propertyDefintions = propertyDefinitions ? propertyDefinitions : [];

    this.propertyDefinitions = [new ControlPropertyDefinition(PropertyDefinitionEnum.nestedProperty, 'string', ''), ...propertyDefintions];
  }

  private async buildLazyAttributesWithOverridden() {
    const attributeInstance = await this.buildAttributesAsync();
    const defaultAndOverriddenAttributes: DefaultAndOverriddenAttributes = new attributeInstance().buildAttributes();
    const allAttributes = AttributesAggregatorService.aggregateAttributes(defaultAndOverriddenAttributes.default, defaultAndOverriddenAttributes.overridden);
    this.allAttributes = allAttributes;
  }

  getAllControlConditionCategory() {
    return [...this.defaultControlConditionCategory, this.controlConditionCategory];
  }

  isAMatch(mViewResponseValueType: DataViewResponseTypes): boolean {
    //TODO: Also check  data types and field name tags
    return this.dataTagsContainer.mViewResponseTypes.includes(mViewResponseValueType);
  }

  registerConfiguration(componentRef: () => Promise<Type<any>>) {
    this.#configurationComponentRef = componentRef;
  }

  getConfigurationComponentRef() {
    return this.#configurationComponentRef;
  }
}
export abstract class ControlAttributes {
  abstract buildAttributes(): DefaultAndOverriddenAttributes;
}

export class ControlInfo {
  constructor(public controlName: string,
    public controlGroup: string,
    public controlIcon: string,
    public ctrlType: ControlType,
    public description = '',
    public display: boolean = true,
    public menuType: MenuTypeEnum = MenuTypeEnum.HTML
  ) {

  }
}

export class QuickStyle {
  constructor(public styleName: string, _styles: SimpleCssAttribute<unknown>[]) { }
}
export interface QuickStylesProvider {
  styles: QuickStyle[];
}

export class AttributesAggregatorService {
  static aggregateAttributes(allAttributes: Attribute<unknown, unknown>[], overriddenAttributes: Attribute<unknown, unknown>[]): Attribute<unknown, unknown>[] {
    const attributesThatAreNotOverridden = allAttributes.filter(attribute => !overriddenAttributes.some(overriddenAttr => overriddenAttr.name === attribute.name));
    const aggregated = [...attributesThatAreNotOverridden, ...overriddenAttributes];
    return aggregated;
  }
}
