
import { RtSome } from "../../../../utils/option-helper";
import { Units } from "../enums/grid-enums";
import {
  ColumnDefinition, ColumnsContainer, Dimension, GridCell, GridDefinition, GridDimensions,
  GridGap, GridSize, GridTemplateArea, GridTemplateAreasContainer, RowDefinition, RowsContainer
} from "../models/grid-models";


export class GridDefinitionHelper {
  static getDefaultGridVariant1Definition(gridSize: GridSize): GridDefinition {
    // By default render 2 X 2 grid along with row and column grips

    const columns = this.constructColumnDefinitions(gridSize.totalColumns);
    const columnsContainer = new ColumnsContainer(columns);

    const rows = this.constructRowDefinitions(gridSize.totalRows);
    const rowsContainer = new RowsContainer(rows);

    const areas: GridTemplateArea[] = this.constructDefaultAreas(gridSize.totalRows, gridSize.totalColumns);
    const gridAreasContainer = new GridTemplateAreasContainer(areas, gridSize);

    const rowGap = new Dimension(RtSome(10), Units.PX);
    const columnGap = new Dimension(RtSome(10), Units.PX);
    const gridGap = new GridGap(rowGap, columnGap);

    const gridDimensions = new GridDimensions(new Dimension(RtSome(100), Units.PERCENTAGE), new Dimension(RtSome(100), Units.PERCENTAGE))

    const twoByTwoGrid = new GridDefinition(columnsContainer, rowsContainer, gridAreasContainer, gridGap, gridDimensions);
    return twoByTwoGrid;
  }

  static getDefaultGridVariant2Definition(gridSize: GridSize): GridDefinition {
    // By default render 2 X 2 grid along with row and column grips

    const columns = this.constructVariant2ColumnDefinitions(gridSize.totalColumns);
    const columnsContainer = new ColumnsContainer(columns);

    const rows = this.constructVariant2RowDefinitions(gridSize.totalRows);
    const rowsContainer = new RowsContainer(rows);

    const areas: GridTemplateArea[] = this.constructDefaultAreas(gridSize.totalRows, gridSize.totalColumns);
    const gridAreasContainer = new GridTemplateAreasContainer(areas, gridSize);

    const rowGap = new Dimension(RtSome(10), Units.PX);
    const columnGap = new Dimension(RtSome(10), Units.PX);
    const gridGap = new GridGap(rowGap, columnGap);

    const gridDimensions = new GridDimensions(new Dimension(RtSome(100), Units.PERCENTAGE), new Dimension(RtSome(95), Units.PERCENTAGE))

    const twoByTwoGrid = new GridDefinition(columnsContainer, rowsContainer, gridAreasContainer, gridGap, gridDimensions);
    return twoByTwoGrid;
  }

  // static reConstructGridDefinition(gridDefinition: GridDefinition): GridDefinition {
  //   const rowsContainer = this.newRowsContainer(gridDefinition.rowsContainer.rows);
  //   const columnsContainer = this.newColumnsContainer(gridDefinition.columnsContainer.columns);
  // }

  // private static newDimension(dimension: Dimension): Dimension {
  //   return new Dimension(RtOptionHelper.reconstruct<number>(dimension.value), dimension.unit);
  // }
  // private static newGridDimensions(gridDimensions: GridDimensions): GridDimensions {
  //   const rowsContainer: GridDimensions = new GridDimensions(this.newDimension(gridDimensions.width), this.newDimension(gridDimensions.height));
  //   return rowsContainer;
  // }
  // private static newRowsContainer(rowDefinitions: RowDefinition[]): RowsContainer {
  //   const rowDefinitionsReConstructed: RowDefinition[] = rowDefinitions.map(rd => new RowDefinition(rd.index, this.newDimension(rd.rowHeight)));
  //   const rowsContainer: RowsContainer = new RowsContainer(rowDefinitionsReConstructed);
  //   return rowsContainer;
  // }
  // private static newColumnsContainer(columnDefinitions: ColumnDefinition[]): ColumnsContainer {
  //   const rowDefinitionsReConstructed: ColumnDefinition[] = columnDefinitions.map(rd => new ColumnDefinition(rd.index, this.newDimension(rd.columnWidth)));
  //   const columnContainer: ColumnsContainer = new ColumnsContainer(rowDefinitionsReConstructed);
  //   return columnContainer;
  // }

  // private static newAreasContainer(gridTemplateAreas: GridTemplateArea[], gridSize: GridSize): GridTemplateAreasContainer {
  //   const gridTemplateAreasReConstructed: GridTemplateArea[] = gridTemplateAreas.map(rd => new GridTemplateArea(rd.gridCells.map(gc => new GridCell(gc.rowIndex, gc.columnIndex))));
  //   const areasContainer: GridTemplateAreasContainer = new GridTemplateAreasContainer(gridTemplateAreasReConstructed, gridSize);
  //   return areasContainer;
  // }

  private static constructVariant2ColumnDefinitions(totalColumns: number): ColumnDefinition[] {
    const columnDefinitions: ColumnDefinition[] = [];
    for (let columnIndex = 1; columnIndex <= totalColumns; columnIndex++) {
      if (columnIndex % 2 == 0) {
        columnDefinitions.push(new ColumnDefinition(columnIndex, new Dimension(RtSome(200), Units.PX)));
      } else {
        columnDefinitions.push(new ColumnDefinition(columnIndex, new Dimension(RtSome(1), Units.FR)));
      }
    }
    return columnDefinitions;
  }

  static constructColumnDefinitions(totalColumns: number): ColumnDefinition[] {
    const columnDefinitions: ColumnDefinition[] = [];
    for (let columnIndex = 1; columnIndex <= totalColumns; columnIndex++) {
      columnDefinitions.push(new ColumnDefinition(columnIndex, new Dimension(RtSome(1), Units.FR)))
    }
    return columnDefinitions;
  }

  private static constructVariant2RowDefinitions(totalRows: number): RowDefinition[] {
    const rowDefinitions: RowDefinition[] = [];
    for (let columnIndex = 1; columnIndex <= totalRows; columnIndex++) {
      if (columnIndex % 2 == 0) {
        rowDefinitions.push(new RowDefinition(columnIndex, new Dimension(RtSome(200), Units.PX)));
      } else {
        rowDefinitions.push(new RowDefinition(columnIndex, new Dimension(RtSome(1), Units.FR)));
      }
    }
    return rowDefinitions;
  }

  static constructRowDefinitions(totalRows: number): RowDefinition[] {
    const rowDefinitions: RowDefinition[] = [];
    for (let rowIndex = 1; rowIndex <= totalRows; rowIndex++) {
      rowDefinitions.push(new RowDefinition(rowIndex, new Dimension(RtSome(1), Units.FR)))
    }
    return rowDefinitions;
  }

  static constructDefaultAreas(totalRows: number, totalColumns: number): GridTemplateArea[] {
    const areas: GridTemplateArea[] = [];
    for (let rowIndex = totalRows; rowIndex > 0; rowIndex--) {
      for (let colIndex = totalColumns; colIndex > 0; colIndex--) {
        areas.push(new GridTemplateArea([new GridCell(rowIndex, colIndex)]))
      }
    }
    return areas;
  }

  static constructAreaName(areaBeginningRowIndex:number, areaBeginningColumnIndex:number): string {
    return `R_${areaBeginningRowIndex}_C_${areaBeginningColumnIndex}`;
  }


}
