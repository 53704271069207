import { Directive, Injector } from "@angular/core";
import { SimpleAngularControlInfo } from "../../ide/services/parent-control";
import { AngularDefaultControlRenderService } from "../../services/render/strategy/angular-default-control-render-service";
import { AngularControl } from "./angular-control";

@Directive()
export abstract class SimpleAngularControl extends AngularControl<SimpleAngularControlInfo, AngularDefaultControlRenderService>  {

    controlRenderService: AngularDefaultControlRenderService;

    constructor(injector: Injector) {
        super(injector);
        this.controlRenderService = injector.get(AngularDefaultControlRenderService);
    }
}