import { RtNone, RtOption } from "../../../utils/option-helper";
import { AttributeGroup } from "../../constants/attribute-group-constant";
import { AttributeRegistryConstant } from "../../constants/attribute-registry-constants";
import { Attribute, AttributeType, MobiAttribute } from "../core/attribute";
import { UsableAttributeValue } from "../core/attribute-instance";

export type EventDataBinder = { producerName: string, actionId: string, fieldMapper: { fieldName: string, controlPropertyName: string }[] };
export class EventDataBinderAttribute extends Attribute<EventDataBinder[], EventDataBinder[]> implements MobiAttribute<EventDataBinder[], EventDataBinder[]> {

  constructor(public controlPropertyName: string[]) {
    super(AttributeRegistryConstant.EVENT_DATA_BINDER, AttributeGroup.EVENT_DATA_BINDER, "object", AttributeType.CONFIGURATION, RtNone());
  }

  buildMobiUsableValue(resolvedValue: RtOption<EventDataBinder[]>): UsableAttributeValue<EventDataBinder[]>[] {
    return this.buildUsableValue(resolvedValue);
  }
  generateTSCode(): string {
    return "";
  }
  clone(): Attribute<unknown, unknown> {
    return new EventDataBinderAttribute(this.controlPropertyName);
  }
  parseModel(jsonValue: string): EventDataBinder[] {
    const eventDataBinder: EventDataBinder[] = JSON.parse(jsonValue);
    return eventDataBinder;
  }
  buildUsableValue(resolvedValue: RtOption<EventDataBinder[]>): UsableAttributeValue<EventDataBinder[]>[] {
    if (resolvedValue.isDefined) {
      const usableAttributeValue = new UsableAttributeValue(this.name, resolvedValue.get, this.attributeType);
      return [usableAttributeValue];
    } else {
      return [];
    }
  }

}
