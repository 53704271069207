export class MobiPopupControlRegistry {
    private static popupControls: Map<string, HTMLIonModalElement | HTMLIonAlertElement | HTMLIonToastElement> = new Map<string, HTMLIonModalElement | HTMLIonAlertElement | HTMLIonToastElement>();

    static registerPopupControl(controlInstanceId: string, popupControl: HTMLIonModalElement | HTMLIonAlertElement | HTMLIonToastElement): void {
        this.popupControls.set(controlInstanceId, popupControl);
    }

    static destroyPopupControl(): void {
        const popups = Array.from(this.popupControls.values());
        popups.forEach(popup => {
            popup.dismiss();
        });
        this.popupControls.clear();
    }
}