import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ControlRenderProgressComponent } from "../../controls/control-render-progress/control-render-progress.component";

@Injectable()
export class ControlRenderProgressIndicatorService {
  private readonly panelClass = 'modal-dialog-wrapper';
  constructor(private dialog: MatDialog,) {

  }
  private dialogRef: MatDialogRef<ControlRenderProgressComponent>;
  showProgressIndicator() {
    this.showDialog()
  }
  hideProgressIndicator() {
    this.dialogRef?.close();
  }
  private showDialog() {
    this.dialogRef = this.dialog.open(ControlRenderProgressComponent, {
      data: {},
      width: `450px`,
      panelClass: this.panelClass,
      disableClose: true,
      autoFocus: false,
      height: 'auto',
    });
  }
}
