import { DsResultValue } from "projects/den-core/page-builder";

export class ExtendedDsResultValue {
    value: DsResultValue[];
    constructor(value: DsResultValue[]) {
        this.value = value;
    }
    getValueByFieldName(propertyName: string) {
        const dsResultValue = this.value.find(val => val.fieldName === propertyName);
        return dsResultValue?.referenceData.isDefined ? dsResultValue.referenceData.get : dsResultValue?.value;
    }
};
export class ExtendedDsResultWithChildrenValue {
    value: DsResultValue[];
    children: ExtendedDsResultWithChildrenValue[];
    isChecked?: boolean;
    constructor(value: DsResultValue[], children: ExtendedDsResultWithChildrenValue[] = []) {
        this.value = value;
        this.children = children;
    }
    getValueByFieldName(propertyName: string) {
        const dsResultValue = this.value.find(val => val.fieldName === propertyName);
        return dsResultValue?.referenceData.isDefined ? dsResultValue.referenceData.get : (dsResultValue?.hasValue ? dsResultValue.value : null);
    }
    clone() {
        return new ExtendedDsResultWithChildrenValue(this.value, this.children);
    }
};