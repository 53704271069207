<rt-modal-dialog>
  <div class="modal-content rt-m-20">
    <div class="rt-font-15 rt-text-center site-warning">
      <div class="row rt-m-10 rt-justify-center">
        <img src="./assets/img/no_access.png" alt="">
      </div>
      <p>{{message}}</p>
      <p>Please contact your admin!</p>
      <button class="site-button" (click)="onClose()">CLOSE</button>
    </div>
  </div>
</rt-modal-dialog>