import { ReturnMethodParamsWrapper } from "../data-source/data-source";


export interface GrpByModalData {
    schemaFields: ReturnMethodParamsWrapper[];
    selectedGroupByField: ReturnMethodParamsWrapper;
}

export interface FilterAndSortModalData {
    returnMethodParams: ReturnMethodParamsWrapper[];
    selectedFilterData: Record<string, any>;
    selectedSortField: ReturnMethodParamsWrapper;
    isClientFilter: boolean;
    controlInstanceId?: string;
}

export enum DialogWidthConstants {
    width = 500
}

export enum ListView {
    PAGE_SIZE = 'pageSize',
    TITLE = 'title',
    COLUMNS = 'columnList',
    DEFAULT_SORT_FIELD = 'Default Sort Field',
    DEFAULT_GROUPBY_FIELD = 'Default GroupBy Field',
    ENABLE_GROUPBY = 'showGroupBy',
    ENABLE_FILTER = 'showFilter',
    ENABLE_SERVER_SIDE_FILTER = 'isServerSideFilteringEnabled',
    FILTER_SETTINGS = 'filterSettings',
    SHOW_PAGINATION = 'showPagination',
    PDF_TITLE = 'pdfTitle',
    PANEL_HEIGHT = 'panelHeight',
    MINIFY_COLUMNS = 'minifyColumnList',
  }
  