import { AttributeRegistryConstant } from "../../page-builder/constants/attribute-registry-constants";

export class MobiControlRegistryConstant {
    static readonly LAYOUT: string = 'LAYOUT';
    static readonly CONTAINER: string = 'CONTAINER';
    static readonly MOBI_GRID: string = 'MOBI_GRID';
    static readonly MOBI_FORM: string = 'MOBI_FORM';
    static readonly MOBI_FORM_ACTION_BUTTON = 'MOBI FORM ACTION BUTTON';
    static readonly MOBI_USER_TASK_CONTAINER_CONTROL = 'MOBI USER TASK CONTAINER CONTROL';
    static readonly QR_CODE_GENERATOR = 'QR_CODE_GENERATOR';
    static readonly BUTTON: string = 'BUTTON';
    static readonly LOGOUTBUTTON: string = AttributeRegistryConstant.LOGOUTBUTTON;
    static readonly LABEL: string = 'LABEL';
    static readonly INPUT: string = 'INPUT';
    static readonly ICONS: string = 'ICONS';
    static readonly ICON_TOGGLE: string = 'ICON TOGGLE';
    static readonly CHECK_BOX: string = 'CHECK_BOX';
    static readonly CHECKBOX_LIST: string = 'CHECKBOX_LIST';
    static readonly RADIO_BUTTON: string = 'RADIO_BUTTON';
    static readonly IMAGE: string = 'IMAGE';
    static readonly SELECT: string = 'SELECT';
    static readonly SEARCHABLE_SELECT: string = 'SEARCHABLE_SELECT';
    static readonly MULTI_SELECT: string = 'MULTI_SELECT';

    static readonly FAB: string = 'FLOATING ACTION BUTTON';
    static readonly BACK_BUTTON: string = 'BACK_BUTTON';
    static readonly DATE_TIME: string = 'DATE_TIME';
    static readonly CARD: string = 'CARD';
    static readonly LIST: string = 'LIST';
    static readonly LIST_PAGINATION = 'ListPagination';
    static readonly TAB: string = 'TAB';
    static readonly ACCORDION: string = 'ACCORDION';
    static readonly AVATAR: string = 'AVATAR';
    static readonly TOOLBAR: string = 'TOOLBAR';
    static readonly REORDER: string = 'REORDER';
    static readonly ITEM: string = 'ITEM';

    static readonly CHILD_PAGE_CONTROL: string = 'CHILD_PAGE_CONTROL';
    static readonly MENU: string = 'MENU';
    static readonly STANDARD_MENU: string = 'STANDARD_MENU';
    static readonly CHIP: string = 'CHIP';
    static readonly TOGGLE: string = 'TOGGLE';
    static readonly RANGE: string = 'RANGE';
    static readonly BADGE: string = 'BADGE';
    static readonly PROGRESS_BAR: string = 'PROGRESS_BAR';
    static readonly SPINNER: string = 'SPINNER';
    static readonly REFRESHER: string = 'REFRESHER';
    static readonly USER_PROFILE = 'USERPROFILE';

    static readonly DATE_RANGE: string = 'DATE_RANGE';
    static readonly STATIC_ASSETS = 'STATICASSETS';
    static readonly MOBI_FORM_ACTION_SAVE_ALL_BUTTON = 'MOBI FORM ACTION SAVE ALL BUTTON';
    public static readonly UNIQUE_CODE_GENERATOR = 'UniqueCodeGenerator';
    public static readonly QR_SCANNER = 'Qr Scanner';
    public static readonly BLUETOOTH_PRINTER = 'Bluetooth printer';

    // Charts
    static readonly PIE_CHART: string = 'Pie';
    public static readonly GROUPED_LIST_BAR_CHARTS = 'Grouped Bar';


    /* leaflet */
    public static readonly LEAFLET_MAP = '2D Map';
    public static readonly LEAFLET_STATIC_LAYER = '2D Static Layer';
    public static readonly LEAFLET_DYNAMIC_LAYER = '2D Dynamic Layer';
    public static readonly LEAFLET_ENTITY_LIST_ITEM = '2D Entity List Item';
    public static readonly SINGLE_LEAFLET_POLYGON = 'Single Polygon';
    public static readonly LEAFLET_SINGLE_MARKER = 'Single Marker';
    public static readonly SINGLE_LEAFLET_CIRCLE = 'Single Circle';
}
