
import { DateTimeHelper } from "../../../../../utils/date-time-helper";
import { RtOption } from "../../../../../utils/option-helper";
import { ReturnMethodParamsWrapper } from "../../../../data-source/data-source";
import { DsResult, DsResultArray } from '../../../../data-source/data-source-result-entities';
import { SharedHelper } from "../../../../helpers/shared-helper";
import { GroupedData } from "../shared/models/list-view.model";


export class GroupingManager {

    constructor() { }

    applyGroupBy(dataSource: RtOption<DsResultArray>, groupedFieldName: RtOption<string>, groupedReturnMethodParam: ReturnMethodParamsWrapper): GroupedData[] {
        if (!groupedReturnMethodParam || !groupedFieldName.isDefined || !dataSource.isDefined) {
            throw new Error('Invalid input for applyGroupBy() method');
        }

        const groupedTasks: GroupedData[] = [];
        const groupedData = this.groupByAndReturnObject(dataSource.get.results, groupedFieldName?.get);

        const groupedDataProperties = Object.keys(groupedData);

        groupedDataProperties?.forEach((gdk: string | number) => {
            const groupName = this.resolveGroupName(groupedData[gdk], gdk, groupedFieldName);
            const dsResultArray = new DsResultArray(dataSource.get.dsName, groupedData[gdk], dataSource.get.totalResults, dataSource.get.fks, dataSource.get.isWsResult);
            groupedTasks.push(new GroupedData(groupName, dsResultArray));
        });
        return groupedTasks;
    }

    private resolveGroupName(dsResultArray: DsResult[], groupKey: string | number, groupedFieldName: RtOption<string>): string | number {

        if (!groupedFieldName || !dsResultArray || !groupKey) {
            throw new Error('Invalid input for resolveGroupName() method');
        }

        if (SharedHelper.isDateTime(groupKey as string)) {
            const dateTime = groupKey ? DateTimeHelper.getFormattedDateString('dd/MM/yyyy HH:mm', groupKey as string) : 'NA';
            return dateTime === 'Invalid date' ? 'NA' : dateTime;
        }
        return this.returnReferenceDataIfAnyOrGroupKey(dsResultArray, groupKey, groupedFieldName);
    }


    private returnReferenceDataIfAnyOrGroupKey(dsResultList: DsResult[], groupKey: string | number, groupedFieldName: RtOption<string>): string | number {

        if (!dsResultList || !groupedFieldName || !groupKey) {
            throw new Error('Invalid input for getKeyPropertyName() method');
        }

        const [firstObj] = dsResultList;
        const displayObj = firstObj.data.find(r => r.fieldName === groupedFieldName.get);
        if (displayObj?.referenceData.isDefined) {
            return displayObj.referenceData.get as string || 'NA';
        }

        return groupKey;
    }
    private groupByAndReturnObject(objectArray: any[], property: string): object {
        const groups = objectArray.reduce((acc: { [x: string]: any[]; }, obj: DsResult) => {
            const key = obj.data.find(r => r.fieldName === property)?.value || "NA";
            //   const key = obj[property] || "NA";
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
        return groups;
    }
}
