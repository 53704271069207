export class ControlTypeConstant {

  public static readonly COMMON = 'Basic Elements';
  public static readonly ADVANCE = 'Advanced Elements';
  public static readonly LEAFLET = 'Leaflet';
  public static readonly TWO_D = '2D';
  public static readonly THREEJS = 'ThreeJS';
  public static readonly CONTAINER = 'Container';
  public static readonly LAYOUT = 'Page Layout';
  public static readonly NAVIGATION = 'Navigation';
  public static readonly CHART_MULTIPLE = 'Charts(Multiple)';
  public static readonly CHART_SINGLE = 'Charts(Single)';
  public static readonly STATIC_ASSETS = 'Static Asset';
  public static readonly FORM = 'Form';
  public static readonly THREEJS_LAYERS = 'ThreeJs Layer';
  public static readonly THREE_JS_GEOMETRY = 'ThreeJs Geometry';
  public static readonly THREE_D_GEOMETRY = '3D Geometry';
  public static readonly SECTIONS = "Sections";
  public static readonly LEAFLET_LAYERS = "Leaflet Layer";
  public static readonly TWO_D_ELEMENTS = "2D Elements";
  public static readonly THREE_D_ELEMENTS = "3D Elements";
  public static readonly REPORT = "Report";
}
