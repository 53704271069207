import { AppEntity } from "projects/den-core/base-models";
import { DataSourceMethodInstance } from "../../data-source/data-source";

export class DataSourceServiceInstanceWrapper extends AppEntity {
    public type: string;
    public group: string;
    public method: DataSourceMethodInstance;
    public serviceName: string;
    public dsName: string;
    public pageName: string;
    public controlInstanceId: string;
    public label: string;
    public isMaster: boolean;
    public wsTopicResult: { name: string, template: string; }[];
    public joinKey?: string;
    public finalResponseType?: string;
    constructor() {
        super()
    }
    clone(): DataSourceServiceInstanceWrapper {
        const newDs = JSON.parse(JSON.stringify(this));;
        newDs.method = this.method.clone();
        newDs.clone=this.clone;
        return newDs;
    }

    get isDependent(){
        return true;
    }
}
