import { Injectable, Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogProvider } from "projects/den-core";
import { ModalDataContainer } from "projects/den-core/contants";
import { ConfirmationDialogComponent } from "../../../../../den-core/src/lib/components-library/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogConfig } from "../../../../../den-core/src/lib/constants/table.constant";


@Injectable()
export class DialogService extends DialogProvider {


    public _dialog = this.injector.get(MatDialog);

    constructor(public injector: Injector) {
        super();
    }

    showErrorDialog<T>(_error: any, _next?: (value: T) => void) {
        throw new Error("DialogService: Implement showErrorDialog");
        // const dialogRef = this._dialog.open(ReactivateEntityDialogComponent, {
        //     width: '750px',
        //     panelClass: 'modal-dialog-wrapper',
        //     data: error,
        //     autoFocus: false
        // });
        // dialogRef.afterClosed().subscribe(next);
    }

    showDeleteDialog<T>(data: ModalDataContainer, next?: (value: T) => void) {
        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            ...{
                data: data,
                autoFocus: false
            }, ...ConfirmationDialogConfig.DIALOG_CONFIG
        });
        dialogRef.afterClosed().subscribe(next);
    }

}
