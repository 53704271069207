<div class="disable-delete-wrapper rt-p-15">
    <ng-container *ngIf="disposalType == disposalTypeEnum.Delete; then deleteEntity else disableEntity">
    </ng-container>
</div>
<ng-template #disableEntity>

    <ng-container
        *ngIf="shouldNotDelete == true; then disableEntityWithDependencies else disableEntityWithNoDependencies">
    </ng-container>

    <ng-template #disableEntityWithDependencies>
        <h4 mat-dialog-title class="rt-font-18 rt-mb-10">Disable {{entityName}}</h4>
        <div mat-dialog-content>
            <span class="rt-orange-color rt-pb-15 rt-show">Warning! This {{entityName}} cannot be disabled as it has
                associated
                dependencies with the following entity(s)</span>
            <div class="rt-border-dashed rt-px-15 rt-pb-15" *ngIf="showEntityUsageBorder">

                <div *ngFor="let entityUsage of entityUsages">
                    <div class="row rt-justify-between rt-mt-15" *ngIf="entityUsage.usages.length > 0">
                        <label>{{entityUsage.artifactGroup}}</label>
                        <a class="rt-primary-text-color">Goto</a>
                    </div>

                    <ul class="entity-usage-list" *ngFor="let usage of entityUsage.usages">
                        <li>{{usage.entity}} </li>
                    </ul>
                </div>

            </div>
        </div>
        <div mat-dialog-actions class="rt-justify-end">
            <button mat-raised-button color="primary" (click)="close()" cdkFocusInitial>CLOSE</button>
        </div>

    </ng-template>

    <ng-template #disableEntityWithNoDependencies>
        <h4 mat-dialog-title class="rt-font-18 rt-mb-10">{{headerText}} {{entityName}}</h4>
        <div mat-dialog-content>
            <span>Are you sure want to disable this {{entityName}}?</span>
            <p>
                This {{entityName}} will no longer be available for use in an app configuration.
                Once disabled, it can be reinstated by enabling it again.
            </p>
            <div class="rt-border-dashed rt-px-15 rt-pb-15" *ngIf="showEntityUsageBorder">
                <div class="rt-py-10">The following Devum objects are associated with this {{entityName}}</div>
                <div *ngFor="let entityUsage of entityUsages">
                    <div class="row rt-justify-between rt-mt-15" *ngIf="entityUsage.usages.length > 0">
                        <label>{{entityUsage.artifactGroup}}</label>
                    </div>

                    <ul class="entity-usage-list" *ngFor="let usage of entityUsage.usages">
                        <li>{{usage.entity}}</li>
                    </ul>
                </div>

            </div>
        </div>
        <div mat-dialog-actions class="rt-justify-end">
            <button mat-raised-button (click)="close()">CANCEL</button>
            <button mat-raised-button color="primary" (click)="disable()" cdkFocusInitial>YES, {{headerText}}</button>
        </div>
    </ng-template>

</ng-template>

<ng-template #deleteEntity>
    <h4 mat-dialog-title class="rt-font-18 rt-mb-10" *ngIf="shouldNotDelete">Resolve Dependencies of {{entityName}}</h4>
    <h4 mat-dialog-title class="rt-font-18 rt-mb-10" *ngIf="!shouldNotDelete">Delete {{entityName}}</h4>

    <div mat-dialog-content>
        <div *ngIf="shouldNotDelete">
            <div *ngFor="let entityUsage of entityUsages">
                <div class="row rt-justify-between rt-mt-15" *ngIf="entityUsage.usages.length > 0">
                    <label>{{entityUsage.artifactGroup}}</label>
                </div>

                <ul class="entity-usage-list" *ngFor="let usage of entityUsage.usages">
                    <li>{{usage.entity}} {{usage.values.length ?"in " + usage.values.join(', '):'' }} </li>
                </ul>
            </div>
        </div>
        <div *ngIf="!shouldNotDelete">
            <span>Are you sure want to delete this {{entityName}}?</span>
            <p class="rt-orange-color">
                Warning! The {{entityName}} will no longer be accessible and cannot be linked to any
                app permissions and/or user accounts. This action cannot be undone.
            </p>
        </div>
    </div>
    <div mat-dialog-actions class="rt-justify-end">
        <button mat-raised-button (click)="close()">{{shouldNotDelete?'CLOSE':'CANCEL'}}</button>

        <button mat-raised-button color="primary" *ngIf="!shouldNotDelete" (click)="delete()" cdkFocusInitial>YES,
            DELETE</button>
    </div>
</ng-template>