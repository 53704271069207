export class MarkerOption {
  id: number;
  name: string;
  geometry: Geometry;
  style?: L.MarkerOptions;
}

export class Geometry {
  id?: number;
  name?: string;
  type: string;
  coordinates: Array<Array<Array<number>>> | Array<number>;
  hidden?: boolean;
}

export class PolygonOption {
  id: number | string;
  name: string;
  geometry: Geometry;
  style?: L.PathOptions;
  isClickable?: boolean = false;
  isAlreadyMapped?: boolean = false;
  coordinatesArea?: number;
}

export class CircleMarkerOption {
  id: number;
  name: string;
  geometry: Geometry;
  style?: L.CircleMarkerOptions;
  popup?: Popup;
}
export class Popup {
  openOnClick: boolean;
}
