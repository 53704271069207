import { HttpHeaders } from '@angular/common/http';
import { Injector, Type } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable, Observer, Subscriber, Subscription } from 'rxjs';
import { CrudType } from '../constants/constant';
import { AdvancedFilter, BaseType, DependencyProperty, FilterOperator, FilterProperty, GlobalBaseEntity, StatusContainer } from '../models/base.models';
import { UniqueValidator } from '../models/shared.models';
import { DialogProvider } from '../providers/abstract/dialog-provider';
import { ForeignKeyEntityResolverProvider } from '../providers/abstract/foreign-key-entity-Provider';
import { HttpClientProvider } from '../providers/abstract/http-client-provider';
import { LoaderServiceProvider } from '../providers/abstract/loader-service-provider';
import { LocalStorageProvider } from '../providers/abstract/local-storage-provider';
import { ToastServiceProvider } from '../providers/abstract/toast-service-provider';
import { EventService } from '../providers/event-service/event.service';
import { PageRefreshService } from '../providers/page-refresh/page-refresh.service';
// import { AbstractControl, ValidatorFn } from '../ts-defintions/angular';

export interface IDenBaseWriterApi {
    getByFilter<T>(advancedFilter: AdvancedFilter, dependencies?: Array<DependencyProperty>): Observable<T>;
    getByFilterWithUrl<T>(advancedFilter: AdvancedFilter, url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    getByCustomFilterWithUrl<T>(data: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    getByAnyFilterType<T>(payload: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    validateDuplicates(propertyName: string, filterProperties?: FilterProperty[], filterOperator?: FilterOperator): ValidatorFn;
}

export class DenBaseWriterApi {

    public toastService: ToastServiceProvider;
    public eventService: EventService;
    private _dialog: DialogProvider;

    public http: HttpClientProvider;
    public loaderService: LoaderServiceProvider;
    public indexedDbService: LocalStorageProvider;
    public dependencyService: ForeignKeyEntityResolverProvider;

    protected baseUrl: string;
    public baseUrlForCache: string;

    isCacheable: any;
    // deleteSelectionPublisher = new Subject();
    public pageRefresh = this.injector.get(PageRefreshService);

    entity: any;

    constructor(public injector: Injector, url: string, entityName: string, _isCacheable: boolean) {
        // super(injector, url, entityName, isCacheable);
        this.initProviders(url);
        this.baseUrl = entityName === '' ? this.validateBaseUrl(url) : url + entityName;
        this.baseUrlForCache = entityName === '' ? this.validateBaseUrl(url) : url;
    }

    private initProviders(url: string) {
        this.http = this.injector.get(HttpClientProvider as Type<HttpClientProvider>);
        this.loaderService = this.injector.get(LoaderServiceProvider);
        this.indexedDbService = this.injector.get(LocalStorageProvider);
        this.dependencyService = this.injector.get(ForeignKeyEntityResolverProvider);
        this.dependencyService.baseUrl = url;
        this.toastService = this.injector.get(ToastServiceProvider);
        this.eventService = this.injector.get(EventService);
        this._dialog = this.injector.get(DialogProvider);
    }


    constructUrl(url: string | any) {
        if (!url) {
            return '';
        }
        if (!url.startsWith('/')) {
            return '/'.concat(url);
        } else {
            return url;
        }
    }

    removeEntityFromCache() {
        return new Promise((resolve, reject) => {
            let getListUrl = this.baseUrl;
            let getPreciseListUrl = this.baseUrl + '/precis';
            return Promise.all([this.checkAndRemoveFromCache(getListUrl), this.checkAndRemoveFromCache(getPreciseListUrl)]).then(resolve, reject);
        });
    }

    getByFilter<T>(advancedFilter: AdvancedFilter, dependencies?: Array<DependencyProperty>): Observable<T> {
        return this.getByFilterWithUrl(advancedFilter, '/filter', dependencies);
    }

    getByFilterWithUrl<T>(advancedFilter: AdvancedFilter, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return this.getByAnyFilterType(advancedFilter, url, dependencies);
    }

    public validate(data: any) {
        let message: boolean;
        if (data.results && data.results.length > 0) {
            message = true;
        } else {
            message = false;
        }
        return message;
    }

    closeFlexContent() {
        throw new Error('Method not implemented.');
    }

    notifyObservers(_arg0: null) {
        throw new Error('Method not implemented.');
    }

    getByCustomFilterWithUrl<T>(data: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return this.getByAnyFilterType(data, url, dependencies);
    }

    getByAnyFilterType<T>(payload: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            this.loaderService.incrementLoaderCount();
            return this.post(url, payload).subscribe(
                (data: T) => {
                    this.loaderService.decrementLoaderCount();
                    this.loaderService.incrementLoaderCount();
                    this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
                        (dependencyData: T) => {
                            observer.next(dependencyData);
                            this.loaderService.decrementLoaderCount();
                        });
                }, (error: ErrorEvent) => {
                    observer.error(error);
                    this.loaderService.decrementLoaderCount();
                });
        });
    }

    deleteByCustomUrlAndPayload(payload: { ids: string[]; }, url: string): Observable<StatusContainer> {
        return new Observable((observer: Observer<StatusContainer>) => {
            this.loaderService.incrementLoaderCount();
            return this.post(url, payload).subscribe(
                (data: StatusContainer) => {
                    this.loaderService.decrementLoaderCount();
                    // if (isMultipleDelete === true) {
                    //     this.publishOnDeleteMultipleEntities(isMultipleDelete, payload.ids);
                    // }
                    observer.next(data)
                }, (error: ErrorEvent) => {
                    observer.error(error);
                    this.loaderService.decrementLoaderCount();
                });
        });
    }

    validateDuplicates(propertyName: string, filterProperties?: FilterProperty[], filterOperator?: FilterOperator): ValidatorFn {
        return (control: AbstractControl) => {
            let message: boolean;
            const filter = new AdvancedFilter();
            const userInput = control.value;
            filter.addFilterProperty(new FilterProperty(propertyName, filterOperator ? filterOperator : FilterOperator.EQUALS_IGNORE_CASE, typeof (userInput) === 'string' ? userInput.trim() : userInput));
            if (filterProperties && filterProperties.length > 0) {
                filterProperties.forEach(filterProperty => {
                    if (FilterOperator.IN === filterProperty.operator) {
                        filter.addFilterProperty(new FilterProperty(filterProperty.propertyName, filterProperty.operator, filterProperty.valueList));
                    } else {
                        filter.addFilterProperty(new FilterProperty(filterProperty.propertyName, filterProperty.operator, filterProperty.value1));
                    }
                });
            }
            filter.pageProperties.pageNumber = null;
            filter.pageProperties.pageSize = null;
            return new Observable((observer: Observer<any>) => {
                this.loaderService.incrementLoaderCount();
                const subscription = this.getByFilter(filter).subscribe(
                    (data: any) => {
                        this.loaderService.decrementLoaderCount();
                        message = this.validate(data);
                        observer.next(!message ? null : { 'DuplicateName': true });
                        observer.complete();
                        subscription.unsubscribe();
                    }, (error: ErrorEvent) => {
                        observer.error(error);
                        this.loaderService.decrementLoaderCount();
                    });
            }).toPromise();
        };
    }

    validateDuplicate(data: UniqueValidator): Promise<boolean> {
        if (data.value && data.value.length) {
            const filter = new AdvancedFilter();
            const propertyName: string = data.propertyName ? data.propertyName : 'name';
            const filterOperator: FilterOperator = data.filterOperator ? data.filterOperator : FilterOperator.EQUALS_IGNORE_CASE;
            filter.addFilterProperty(new FilterProperty(propertyName, filterOperator, data.value.trim()));
            this.addMoreFilters(filter, data);
            filter.pageProperties.pageNumber = null;
            filter.pageProperties.pageSize = null;
            return new Observable(
                (observer: Subscriber<any>) => {
                    this.checkForDuplicates(data, filter, observer);
                }).toPromise();
        } else {
            return Promise.resolve(true);
        }
    }

    private checkAndRemoveFromCache(url: string) {
        return new Promise((resolve, reject) => {
            this.indexedDbService.get(url).subscribe((data) => {
                if (data) {
                    this.indexedDbService.remove(url).subscribe();
                }
                resolve('success');
            }, (error: ErrorEvent) => reject(error.message));
        });
    }

    private delete<T>(url: string): Observable<any> {
        // const data: DeleteSelectionPublisher = new DeleteSelectionPublisher();
        // data.isMultiDelete = false;
        url = url.replace('/', '');
        const constructedUrl = this.baseUrl + this.constructUrl(url);

        return this.http.delete<T>(constructedUrl).pipe(res => {
            // this.deleteSelectionPublisher.next(data);
            return res;
        });
    }

    private validateBaseUrl(url: string) {
        if (!url) { return ''; }
        if (url.endsWith('/')) { return url.slice(0, -1); } else { return url; }
    }

    private put<T>(url: string | number, entity: T): Observable<any> {
        const constructedUrl = this.baseUrl + this.constructUrl(url);
        return new Observable((observer: Observer<T>) => {
            this.http.put<T>(constructedUrl, entity).subscribe(res => {
                if (this.isCacheable) {
                    this.removeEntityFromCache();
                }
                observer.next(res);
                return res;
            }, (error: ErrorEvent) => { observer.error(error); });
        });
    }

    private checkForDuplicates(data: UniqueValidator, filter: AdvancedFilter, observer: Subscriber<any>) {
        let subscription: Subscription;
        let isDuplicateValue = false;
        if (data.url) {
            subscription = this.getByFilterWithUrl(filter, data.url).subscribe((responseData: any) => {
                isDuplicateValue = this.isDuplicated(responseData);
                observer.next(isDuplicateValue);
                observer.complete();
                subscription.unsubscribe();
            }, (error: ErrorEvent) => {
                observer.error(error);
            });
        } else {
            subscription = this.getByFilter(filter).subscribe((responseData: any) => {
                isDuplicateValue = this.isDuplicated(responseData);
                observer.next(isDuplicateValue);
                observer.complete();
                subscription.unsubscribe();
            }, (error: ErrorEvent) => {
                observer.error(error);
            });
        }
    }

    private isDuplicated(data: any): boolean {
        let isDuplicateValue = false;
        if (data.results && data.results.length > 0 || data.totalResults > 0) {
            isDuplicateValue = true;
        } else {
            isDuplicateValue = false;
        }
        return isDuplicateValue;
    }

    private addMoreFilters(filter: AdvancedFilter, data: UniqueValidator) {
        if (data.filterProperties && data.filterProperties.length > 0) {
            data.filterProperties.forEach(filterProperty => {
                if (FilterOperator.IN === filterProperty.operator) {
                    filter.addFilterProperty(new FilterProperty(filterProperty.propertyName, filterProperty.operator, filterProperty.valueList));
                } else {
                    filter.addFilterProperty(new FilterProperty(filterProperty.propertyName, filterProperty.operator, filterProperty.value1));
                }
            });
        }
    }

    protected remove<T>(url: string): Observable<any> {
        return this.delete<T>(url);
    }

    removeById<T>(id: string): Observable<any> {
        return this.delete<T>(id);
    }

    removeByIdWithUrl<T>(url: string, id: string): Observable<any> {
        const urlWithId = url + id;
        return this.delete<T>(urlWithId);
    }

    removeByUrl<T>(url: string): Observable<any> {
        return this.delete<T>(url);
    }

    uploadFiles<T>(url: string, fileList: FormData): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post<T>(this.baseUrl + this.constructUrl(url), fileList, { headers: headers });
    }

    add<T>(entity: T & { name?: string; }, isNotCloseFlexContent?: boolean, entityName?: string): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.loaderService.incrementLoaderCount();
            if (entity && entity['name']) {
                entity['name'] = entity['name'].trim();
            }
            const subscription = this.post<T>('', entity).subscribe(
                (data: any) => {
                    this.loaderService.decrementLoaderCount();
                    this.resolveDependenciesForPageRefresh(data, observer, subscription);
                }, (error) => {
                    this.loaderService.decrementLoaderCount();
                    this.pageRefresh.setActionInstance(entity, CrudType.INSERT);
                    this.handleErrorOnAdd(error, observer, isNotCloseFlexContent, entityName);
                });
        });
    }

    protected update<T>(entity: any, url: string): Observable<any> {
        if (entity && entity['name']) {
            entity['name'] = entity['name'].trim();
        }
        return new Observable((observer) => {
            this.put<T>('/' + url, entity)
                .subscribe(
                    (res) => {
                        observer.next(res);
                    }, (error: ErrorEvent) => observer.error(error)
                );
        });
    }

    updateById<T>(entity: any, id: string): Observable<any> {
        if (entity && entity['name']) {
            entity['name'] = entity['name'].trim();
        }
        return new Observable((observer) => {
            this.put<T>('/' + id, entity)
                .subscribe(
                    (res) => {
                        observer.next(res);
                    }, (error: ErrorEvent) => observer.error(error)
                );
        });
    }

    updateByIdWithUrl<T>(entity: any, url: string, _id: string): Observable<any> {
        if (entity && entity['name']) {
            entity['name'] = entity['name'].trim();
        }
        return new Observable((observer) => {
            this.put<T>('/' + url, entity)
                .subscribe(
                    (res) => {
                        observer.next(res);
                    }, (error: ErrorEvent) => observer.error(error)
                );
        });
    }

    addList<T>(entityList: T[]) {
        return this.addOrUpdateList<T>(entityList);
    }

    updateList<T>(entityList: T[]) {
        return this.addOrUpdateList<T>(entityList);
    }

    createEntity<T>(entity: T & { name?: string; }, entityName: string, isNotCloseFlexContent?: boolean): Observable<T> {
        return new Observable((observer) => {
            this.add(entity, isNotCloseFlexContent, entityName)
                .subscribe({
                    next: (responseData: string | any) => {
                        if (responseData === 'Duplicate value for Name field') {
                            observer.next(null);
                            this.toastService.showError(entityName + ' already exists');
                        } else if (responseData === 'NAME_ALREADY_EXISTS') {
                            observer.next(null);
                            this.toastService.showError(entityName + ' already exists');
                        } else {
                            observer.next(responseData);
                            this.toastService.showSuccess(entityName + ' created');
                        }
                    },
                    error: (error: ErrorEvent) => observer.error(error)
                });
        });
    };

    updateEntity<T extends GlobalBaseEntity | any>(entity: T | any, entityName: string, isNotCloseFlexContent?: boolean): Observable<T> {
        return new Observable((observer) => {
            this.loaderService.incrementLoaderCount();
            this.updateById(entity, entity.id)
                .subscribe(
                    (responseData) => {
                        this.loaderService.decrementLoaderCount();
                        if (responseData === 'Duplicate value for Name field') {
                            observer.next(null);
                            this.toastService.showError(entityName + ' already exists');
                        } else if (responseData === 'NAME_ALREADY_EXISTS') {
                            observer.next(null);
                            this.toastService.showError(entityName + ' already exists');
                        } else {
                            if (this.pageRefresh.dependencyList && this.pageRefresh.dependencyList.length > 0) {
                                this.dependencyService.checkDependenciesAndResolveForSingleEntity(entity, this.pageRefresh.dependencyList).subscribe((resolvedData: any) => {
                                    this.pageRefresh.setActionInstance(resolvedData, CrudType.UPDATE);
                                    // this.entity.next(resolvedData);
                                    observer.next(resolvedData);
                                });
                            } else {
                                this.pageRefresh.setActionInstance(entity, CrudType.UPDATE);
                                // this.entity.next(entity);
                                observer.next(entity);
                            }
                            this.toastService.showSuccess(entityName + ' updated');
                            if (!isNotCloseFlexContent || isNotCloseFlexContent === undefined) {
                            }
                        }
                    },
                    (error: ErrorEvent) => observer.error(error));
        });
    };

    deleteEntity(record: BaseType, successCallback?: Function): Observable<boolean> {
        if (!record) { this.toastService.showError('Invalid Input'); }
        return new Observable((observer) => {
            this.deleteDialog(record.name, record.label, observer, null, successCallback);
        });
    };

    deleteEntityById(id: string, entityName: string, successCallback?: Function): Observable<boolean> {
        return new Observable((observer) => {
            this.deleteDialog(id, entityName, observer, null, successCallback);
        });
    };

    deleteEntityByIdWithUrl(entityUrl: string, id: string, entityName: string, successCallback?: Function): Observable<boolean> {
        if (!entityUrl || !id) { this.toastService.showError('Invalid Input'); }
        return new Observable((observer) => {
            this.deleteDialog(id, entityName, observer, entityUrl, successCallback);
        });
    }

    onDeleteConfirmation(id: string, entityName: string, observer: Observer<boolean>, entityUrl?: string, successCallback?: Function) {
        this.loaderService.incrementLoaderCount();
        const url = entityUrl ? entityUrl + '/' : '/';
        this.removeByIdWithUrl(url, id).subscribe((isConfirmToDelete: boolean) => {
            if (isConfirmToDelete) {
                this.loaderService.decrementLoaderCount();
                //this.entity.next(isConfirmToDelete);
                observer.next(true);
                this.toastService.showSuccess(entityName + ' deleted');
                if (successCallback) {
                    successCallback();
                }
            }
        }, (error: any) => {
            this.loaderService.decrementLoaderCount();
            observer.error(error);
        });
    }

    deleteByIds(data: { ids: string[]; }): Observable<StatusContainer> {
        return this.deleteByCustomUrlAndPayload(data, 'deleteList');
    }

    post<T>(url: string, entity: T | T[]): Observable<any> {
        const constructedUrl = this.baseUrl + this.constructUrl(url);

        return new Observable((observer) => {
            this.http.post<T>(constructedUrl, entity).subscribe(res => {
                // Commented  by nag
                // if (this.isCacheable) {
                //     this.removeEnttityFromCache()
                // }
                observer.next(res);
            }, (error: ErrorEvent) => observer.error(error));
        });
    }

    // TODO: Handle the pageRefresh.
    private resolveDependenciesForPageRefresh(data: any, observer: Observer<any>, subscription: Subscription) {
        if (this.pageRefresh.dependencyList && this.pageRefresh.dependencyList.length > 0) {
            this.dependencyService.checkDependenciesAndResolveForSingleEntity(data, this.pageRefresh.dependencyList).subscribe((resolvedData: any) => {
                this.pageRefresh.setActionInstance(resolvedData, CrudType.INSERT);
                this.onAddSuccess(resolvedData, observer, subscription);
            });
        } else {
            this.pageRefresh.setActionInstance(data, CrudType.INSERT);
            this.onAddSuccess(data, observer, subscription);
        }
    }

    private onAddSuccess(data: any, observer: Observer<any>, subscription: Subscription) {
        observer.next(data);
        observer.complete();
        subscription.unsubscribe();
    }

    private handleErrorOnAdd(error: { status: number; error: { message: string; }; }, observer: Observer<any>, isNotCloseFlexContent?: boolean, entityName?: string) {
        this.toastService.showError(error.error.message);
        observer.error(error);
    }

    private addOrUpdateList<T>(entityList: Array<T>): Observable<any> {
        return this.post<T>('/list', entityList);
    }

    private deleteDialog(id: string, entityName: string, observer: Observer<boolean>, entityUrl?: string, successCallback?: Function): void {
        const message = entityName ? `Are you sure want to delete this ${entityName} ?` : '';
        const data = { deleteEntityMessage: message, message: message };
        this._dialog.showDeleteDialog(data, this.handleDeleteCloseDialog(id, entityName, observer, entityUrl, successCallback));
    }

    //TODO: Handle delete else part is missing.
    private handleDeleteCloseDialog(id: string, entityName: string, observer: Observer<boolean>, entityUrl: string, successCallback: Function): any {
        return (isDelete: boolean) => {
            if (isDelete) {
                this.onDeleteConfirmation(id, entityName, observer, entityUrl, successCallback);
            }
        };
    }
}
