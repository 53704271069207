import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDataContainer } from '../../constants/constant';

@Component({
  selector: 'rt-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  entity: ModalDataContainer;;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalDataContainer) {
    this.entity = this.data;
  }

  ngOnInit(): void { }

}
