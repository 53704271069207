import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingIndicatorService } from "../providers/loader/loading-indicator-service";

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorInterceptor implements HttpInterceptor {

  private activeRequests = 0;

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests === 0) {
      this.loadingIndicatorService.start();
    }
    this.activeRequests++;
    return next.handle(req).pipe(
      finalize(() => {
        this.stopLoader();
      })
    );
  }

  private stopLoader() {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loadingIndicatorService.stop();
    }
  }

}