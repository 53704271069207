import { Inject, Injectable } from '@angular/core';
import { AppEntityContainer } from 'projects/den-core/src/lib/base-apis/draft-manager';
import { DynamicReaderApi } from 'projects/den-core/src/lib/base-apis/dynamic-reader-api';
import { Observable, PartialObserver, zip } from 'rxjs';
import { DenBaseEnumApi } from '../../base-apis/den-base-enum-api';
import { DenBaseReaderApi } from '../../base-apis/den-reader-base-api';
import { ConfigService } from '../../config/config-service';
import { AppEntity, BaseDependencyProperty, DependencyProperty, DependencyResolvedData, DynamicDependencyProperty } from '../../models/base.models';
import { LocalStorageProvider } from '../abstract/local-storage-provider';


@Injectable()
export class DraftDependencyService {
    baseUrl: string = this.configService.getConfig().serverURL;
    constructor(@Inject(LocalStorageProvider) private indexedDbService: LocalStorageProvider,
        @Inject(ConfigService) private configService: ConfigService,
    ) {
    }

    checkDependenciesAndResolve<T extends AppEntity>(entities: Array<AppEntityContainer<T>>, dependencies: BaseDependencyProperty[]): Observable<Array<AppEntityContainer<T>>> {
        return new Observable(observer => {
            if (!dependencies || dependencies.length === 0) {
                observer.next(entities);
                return;
            } else {
                this.getDependenciesData(dependencies).subscribe((dependenciesData: DependencyResolvedData<T>[]) => {
                    this.resolveDependencies(entities, dependenciesData);
                    observer.next(entities);
                }, () => {
                    observer.next(entities);
                });
            }
        });
    }
    private resolveDependencies<T extends AppEntity>(entities: Array<AppEntityContainer<T>>, dependenciesData: DependencyResolvedData<T>[]) {
        if (entities instanceof Array) {
            entities.forEach(entity => {
                this.recursivelyPopulateDependenciesData(entity.entity, dependenciesData);
            });
        } else {
            this.recursivelyPopulateDependenciesData((entities as unknown as AppEntityContainer<T>).entity, dependenciesData);
        }

        return entities;
    }

    private isDependency<T>(i: string, dependenciesData: Array<DependencyResolvedData<T>>) {
        return dependenciesData.find((item) => (item.key === i));
    }

    private recursivelyPopulateDependenciesData<T>(entity: T, dependenciesData: Array<DependencyResolvedData<T>>) {
        for (const i in entity) {
            if (entity.hasOwnProperty(i)) {
                if (this.isDependency(i, dependenciesData)) {
                    if (entity[i] instanceof Array) {
                        const propertyArrayData: T[] = [];
                        (entity[i] as unknown as T[]).forEach((nestedEntity: any) => {
                            propertyArrayData.push(this.getValueForDependency(dependenciesData, i, nestedEntity)[0]);
                        });
                        entity[this.getObjectPropertyName(i)] = propertyArrayData;
                    } else {
                        entity[this.getObjectPropertyName(i)] = this.getValueForDependency(dependenciesData, i, entity[i])[0];
                    }
                } else if (entity[i] instanceof Array) {
                    (entity[i] as unknown as T[]).forEach((nestedEntity: any) => {
                        this.recursivelyPopulateDependenciesData(nestedEntity, dependenciesData);
                    });
                } else if (entity[i] instanceof Object) {
                    this.recursivelyPopulateDependenciesData(entity[i], dependenciesData as unknown as Array<DependencyResolvedData<any>>);
                }
            }
        }
    }

    private getValueForDependency<T extends { name?: string; }>(dependenciesData: Array<DependencyResolvedData<T>>, key: string, value: any) {
        if (!dependenciesData || !key) { throw new Error('Invalid input parameters.'); }

        if (!value) { return []; }

        const res = dependenciesData.find((item) => (item.key === key)).value || [];

        if (res.length === 0) {
            return [];
        }
        return res.filter(item => item.name == value);
    }

    private getObjectPropertyName(propertyName: string): string {
        return propertyName.substring(0, propertyName.length - 2);
    }


    private getDependenciesData<T>(dependencies: BaseDependencyProperty[]): Observable<DependencyResolvedData<T>[]> {
        return new Observable(observer => {
            const uniqueDependencies: BaseDependencyProperty[] = this.getUniqueListBy(dependencies, 'api');
            const dependenciesData: DependencyResolvedData<T>[] = [];
            this.getUniqueDependenciesData<T>(uniqueDependencies)
                .subscribe((resolvedDependent: DependencyResolvedData<T>[]) => {
                    dependencies.forEach(dep => {
                        dependenciesData.push({
                            key: dep.idPropertyName,
                            value: resolvedDependent.find(res => res.api.name === dep.api.name).value || [],
                            api: dep.api
                        });
                    });
                    observer.next(dependenciesData);
                });
        });
    }

    private getUniqueDependenciesData<T>(dependencies: BaseDependencyProperty[] | DynamicDependencyProperty[]): Observable<DependencyResolvedData<T>[]> {
        return new Observable(observer => {
            const dependencyData: Observable<any>[] = new Array();
            dependencies.forEach((dep: BaseDependencyProperty) => {
                dependencyData.push(this.getDependencyResolver(dep));
            });
            zip(...dependencyData).subscribe((data: DependencyResolvedData<T>[]) => observer.next(data));
        });
    }

    private getDependencyResolver<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        if (dep instanceof DependencyProperty) {
            if (dep.api instanceof DenBaseReaderApi) {
                return this.getReaderApiData(dep);
            } else if (dep.api instanceof DenBaseEnumApi) {
                return this.getEnumApiData(dep);
            } else {
                throw new Error(`Dependency API is not defined`);
            }
        } else if (dep instanceof DynamicDependencyProperty) {
            if (dep.api instanceof DynamicReaderApi) {
                return this.getDynamicReaderApiData(dep);
            } else {
                throw new Error(`Dependency API is not defined`);
            }
        } else {
            throw new Error(`Invalid dependency property type, should be of BaseDependencyProperty`);
        }
    }

    public getDependencyData<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        return this.getDependencyResolver(dep);
    }

    private getEnumApiData<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        return new Observable(observer => {
            if (dep.api instanceof DenBaseEnumApi) {
                return observer.next({ key: dep.api.name, value: dep.api.getList() as unknown as T[], api: dep.api });
            }
        });
    }

    private getDynamicReaderApiData<T>(dep: DynamicDependencyProperty): Observable<DependencyResolvedData<T>> {
        return new Observable(observer => {
            if (dep.api instanceof DynamicReaderApi) {
                // DynamicFormsReaderApi.
                dep.api.getCollectionList<T>(dep.schemaName, [])
                    .subscribe(
                        (d: T[]) => {
                            return observer.next({ key: dep.schemaName, value: d, api: dep.api });
                        },
                        () => observer.next({ key: dep.schemaName, value: [], api: dep.api }));
            }
        });
    }

    private getUniqueListBy<T extends { [key: string]: any; }>(arr: T[], key: string) {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    }

    private getReaderApiData<T>(dependency: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        return new Observable(observer => {
            if (dependency.api instanceof DenBaseReaderApi) {
                const readerApi: DenBaseReaderApi = dependency.api;
                this.indexedDbService.get<T[]>(readerApi.name).subscribe((data: T[]) => {
                    if (data) {
                        observer.next({ key: readerApi.name, value: JSON.parse(JSON.stringify(data)), api: readerApi });
                    } else {
                        this.getDependencyDataFromDb(dependency, observer);
                    }
                }, () => {
                    observer.next({ key: readerApi.name, value: [], api: readerApi });
                });
            } else {
                observer.next({ key: dependency.api.name, value: [], api: dependency.api });
            }

        });
    }
    private getDependencyDataFromDb<T>(dependency: BaseDependencyProperty, observer: PartialObserver<DependencyResolvedData<T>>) {
        if (dependency.api instanceof DenBaseReaderApi) {
            const readerApi: DenBaseReaderApi = dependency.api;
            const isCacheable = readerApi.isCacheable;
            if (dependency.resolveWithFullEntity === true) {
                this.resolveGetList<T>(readerApi, isCacheable, observer);
            } else {
                this.resolvePreciseList<T>(readerApi, isCacheable, observer);
            }
        }
    }

    private resolveGetList<T>(readerApi: DenBaseReaderApi, isCacheable: boolean, observer: PartialObserver<DependencyResolvedData<T>>) {
        readerApi.getList([], true)
            .subscribe(
                (data: T[]) => {
                    if (isCacheable) {
                        this.indexedDbService.set(readerApi.name, JSON.parse(JSON.stringify(data))).subscribe;
                    }
                    observer.next({ key: readerApi.name, value: data, api: readerApi });
                },
                () => {
                    observer.next({ key: readerApi.name, value: [], api: readerApi });
                });
    }

    private resolvePreciseList<T>(readerApi: DenBaseReaderApi, isCacheable: boolean, observer: PartialObserver<DependencyResolvedData<T>>) {
        readerApi.getPreciseList(true)
            .subscribe(
                (data: T[]) => {
                    if (isCacheable) {
                        this.indexedDbService.set(readerApi.name, JSON.parse(JSON.stringify(data))).subscribe;
                    }
                    observer.next({ key: readerApi.name, value: data, api: readerApi });
                },
                () => {
                    observer.next({ key: readerApi.name, value: [], api: readerApi });
                });
    }



}
