import { Inject, Injectable } from '@angular/core';
import { ConfigService } from '../../config/config-service';
import { DataEntity } from '../../models/base.models';
import { PubSubTopic } from '../../page-builder/constants/pub-sub-topic-constants';
import { CacheVersionData, EntityDataTypeEnum } from '../../providers/abstract/app-cache-manager-provider';
import { HttpClientProvider } from '../../providers/abstract/http-client-provider';
import { LoaderServiceProvider } from '../../providers/abstract/loader-service-provider';
import { LocalStorageProvider } from '../../providers/abstract/local-storage-provider';
import { SessionStorageProvider } from '../../providers/abstract/session-storage-provider';
import { EventService } from '../../providers/event-service/event.service';
import { EntityCacheManagerProvider } from './entity-cache-manager.provider';
@Injectable()
export class EntityCacheManagerService extends EntityCacheManagerProvider {

    constructor(
        @Inject(LocalStorageProvider) public localStorage: LocalStorageProvider,
        @Inject(LoaderServiceProvider) public loaderService: LoaderServiceProvider,
        @Inject(HttpClientProvider) public http: HttpClientProvider,
        @Inject(ConfigService) public configService: ConfigService,
        @Inject(SessionStorageProvider) public sessionStorageService: SessionStorageProvider,
        @Inject(EventService) public eventService: EventService,
    ) {
        super(localStorage, loaderService, http, configService, sessionStorageService);
    }

    removeEntityFromCache(dataEntity: DataEntity, entityCacheMessage?: CacheVersionData) {
        const serverURL = this.configService.getConfig().serverURL;

        const getListUrl = dataEntity.basePath;
        this.checkAndRemoveFromCache(getListUrl);
        const getPreciseListUrl = dataEntity.basePath + '/precis';
        this.checkAndRemoveFromCache(getPreciseListUrl);
        if (entityCacheMessage.dataType === EntityDataTypeEnum.RUN_TIME) {
            this.getRunTimeDataEntity(serverURL, getListUrl);
        }
    }

    private getRunTimeDataEntity(serverURL: string, getListUrl: string) {
        const url = serverURL + getListUrl;
        this.http.get(url).subscribe({
            next: (data) => {
                this.localStorage.set(getListUrl, JSON.parse(JSON.stringify(data))).subscribe();
                this.eventService.publish(PubSubTopic.ENTITY_CACHE_UPDATE, true);
                this.loaderService.decrementLoaderCount();
            },
            error: (error) => {
                this.loaderService.decrementLoaderCount();
            }
        });
    }
}
