//Data converters
import { ConvertibleDefinition, IDsResultConverter } from "./convertibles";
import { Injectable } from "@angular/core";
import { DsResult, DsResultArray, DsResultValue } from "../../../../data-source/data-source-result-entities";
import { RtSome } from "../../../../../../lib/utils/option-helper";
// import { evaluate } from "mathjs";

/**
 * setToFieldName: The name of the field in the data source to which the result should be set. for e.g. total_distance
 * expression: Math expression to derive the desired value. for e.g. ${ds1.distance_with_load} + ${ds1.distance_without_load}
 */
export type DataConverterDefinition = { setToFieldName: string, expression: string } & ConvertibleDefinition;

@Injectable()
export class DataConverter implements IDsResultConverter<DataConverterDefinition> {

  //reference: https://mathjs.org/examples/index.html
  // Note: Make a donation, and use their documentation as part of user guide.
  convert(definition: DataConverterDefinition, data: DsResultArray): DsResultArray {
    const updatedResults = data.results.map(row => {
      const expression = this.resolveExpression(definition.expression, row.data);
      const value = this.resolveAndEvaluate(expression, row);
      const data: DsResultValue = row.data.find(field => field.fieldName == definition.setToFieldName);
      if (data) {
        data.value = value;
      }
      return row;
    });
    return data.overrideResults(updatedResults);
  }

  resolveAndEvaluate(expression: string, dsResult: DsResult): any {
    try {
      const resolvedExpression = this.resolveExpression(expression, dsResult.data);
      return eval(resolvedExpression);
    } catch {
      console.warn('Invalid JSON data/No data', `${dsResult.id}`,`${expression}`);
      return null;
    }
    //Uncomment the below code to use mathjs when the service can be lazy loaded
    // return evaluate(resolvedExpression)
  }

  //expression: ${distance_with_load} + ${distance_without_load}
  resolveExpression(expression: string, rows: DsResultValue[]): string {
    if (rows.length == 0)
      return expression
    else {
      const field = rows[0];
      // const partiallyResolvedExpression = expression.replace(/\$\{([^}]*)\}/g, field.originalValue)
      if (RtSome(field?.originalValue).isDefined) {
        const partiallyResolvedExpression = expression.replace(`\${${field.fieldName}}`, field.originalValue)
        return this.resolveExpression(partiallyResolvedExpression, rows.slice(1));
      } else {
        return this.resolveExpression(expression, rows.slice(1));
      }
    }
  }
}
