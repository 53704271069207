import { Inject, Injectable } from "@angular/core";
import { RtSome, SessionStorageProvider, ToastServiceProvider } from "projects/den-core";
import { Observable } from "rxjs";
import { OfflineIndexDBManagerService } from "projects/den-core/src/lib/caching/local-storage-manager/offline-index-db-manager.service";
import { OfflineFormModel, OfflinePageFormModel, OfflineVariableConfig } from "../model/offline-model";
import { OfflineDownloadedDsResult } from "projects/den-core/src/lib/models/extended.models";

@Injectable()
export class OfflineManagerService {

    #OFFLINE_CACHE_KEY = 'OfflineCache';

    constructor(
        @Inject(OfflineIndexDBManagerService) public offlineIndexDBManagerService: OfflineIndexDBManagerService,
        @Inject(SessionStorageProvider) private sessionStorageService: SessionStorageProvider,
    ) {
        this.initDatabase();
    }

    initDatabase() {
        this.offlineIndexDBManagerService.init("Offline-db", this.#OFFLINE_CACHE_KEY).subscribe(() => {
            console.info('Database initialized');
        }, (error: ErrorEvent) => {
            console.error(error);
        });
    }

    #getKey(pageName: string, controlInstanceId: string, dataSourceId: string,) {
        return `${pageName}_${controlInstanceId}_${dataSourceId}`;
    }

    saveOffline(pageName: string, controlInstanceId: string, dataSourceId: string, value: any) {
        const key = this.#getKey(pageName, controlInstanceId, dataSourceId);
        this.offlineIndexDBManagerService.set(key, value, this.#OFFLINE_CACHE_KEY).subscribe(() => {
        }, (error: ErrorEvent) => {
            console.error(error);
        }).unsubscribe();
    }

    saveApiExecutorResponseInOffline(apiName: string, fieldName: string, value: any) {
        const key = `${fieldName}_${apiName}`;
        this.offlineIndexDBManagerService.set(key, value, this.#OFFLINE_CACHE_KEY).subscribe(() => {
        }, (error: ErrorEvent) => {
            console.error(error);
        }).unsubscribe();
    }

    saveOfflineForm(value: Map<string, OfflinePageFormModel>) {
        const key = "Offline_Form";
        this.offlineIndexDBManagerService.set(key, value, this.#OFFLINE_CACHE_KEY).subscribe(() => {
        }, (error: ErrorEvent) => {
            console.error(error);
        }).unsubscribe();
    }

    saveOfflineVariables(value: Map<string, { lastSync: string, variables: OfflineVariableConfig[] }>) {
        const key = "Offline_Variables";
        this.offlineIndexDBManagerService.set(key, value, this.#OFFLINE_CACHE_KEY).subscribe(() => {
        }, (error: ErrorEvent) => {
            console.error(error);
        }).unsubscribe();
    }

    saveOfflineIcons(s3Urls: Map<string, string>) {
        const key = "Offline_Icons";
        this.offlineIndexDBManagerService.set(key, s3Urls, this.#OFFLINE_CACHE_KEY).subscribe(() => {
        }, (error: ErrorEvent) => {
            console.error(error);
        }).unsubscribe();
    }

    saveDownloadedDate(date: string) {
        const key = "Downloaded_Date";
        this.offlineIndexDBManagerService.set(key, date, this.#OFFLINE_CACHE_KEY).subscribe(() => {
        }, (error: ErrorEvent) => {
            console.error(error);
        }).unsubscribe();
    }

    saveDownloads(downloads: Map<string, OfflineDownloadedDsResult[]>) {
        const key = "Downloads";
        this.offlineIndexDBManagerService.set(key, downloads, this.#OFFLINE_CACHE_KEY).subscribe(() => {
        }, (error: ErrorEvent) => {
            console.error(error);
        }).unsubscribe();
    }

    getOfflineVariables() {
        const key = "Offline_Variables";
        return this.offlineIndexDBManagerService.get(key, this.#OFFLINE_CACHE_KEY);
    }

    getOffline(pageName: string, controlInstanceId: string, dataSourceId: string): Observable<any> {
        const key = this.#getKey(pageName, controlInstanceId, dataSourceId);
        return this.offlineIndexDBManagerService.get(key, this.#OFFLINE_CACHE_KEY);
    }

    getOfflineApiExecutorResponse(apiName: string, fieldName: string): Observable<any> {
        const key = `${fieldName}_${apiName}`;
        return this.offlineIndexDBManagerService.get(key, this.#OFFLINE_CACHE_KEY);
    }

    getOfflineFormData() {
        const key = "Offline_Form";
        return this.offlineIndexDBManagerService.get(key, this.#OFFLINE_CACHE_KEY);
    }

    getOfflineFormDataByName(name: string) {
        return new Observable(observer => {
            this.getOfflineFormData().subscribe((data: Map<string, OfflinePageFormModel>) => {
                if (RtSome(data).isDefined) {
                    const offlineFormModels: OfflineFormModel[] = [...data.values()]?.map(model => { return model.offlineFormModel })?.flat() || [];
                    const offlineEntityValues = offlineFormModels.filter(model => model.isUploaded == false && model.name == name).map(model => model.value);
                    observer.next(offlineEntityValues);
                }
                observer.next([]);
            });
        });
    }

    removeOffline(key: string): Observable<any> {
        return this.offlineIndexDBManagerService.remove(key, this.#OFFLINE_CACHE_KEY);
    }

    getOfflineData() {
        return this.offlineIndexDBManagerService.getAllDataWithKeys();
    }

    getOfflineIcons() {
        const key = "Offline_Icons";
        return this.offlineIndexDBManagerService.get(key, this.#OFFLINE_CACHE_KEY);
    }

    getDownloadedDate() {
        const key = "Downloaded_Date";
        return this.offlineIndexDBManagerService.get(key, this.#OFFLINE_CACHE_KEY);
    }

    getDownloads() {
        const key = "Downloads";
        return this.offlineIndexDBManagerService.get(key, this.#OFFLINE_CACHE_KEY);
    }
}