import { Injectable } from "@angular/core";
import { RtSome } from "../../../../../../utils/option-helper";
import { PageEventProducer } from "../../event-store";
import { DesktopEvents, SubEventType } from "../../event-types";
import { ProducerProps, GlobalEventProducerInfo, GlobalEventProducer, GlobalEventData } from "../global-event-producer";

export class GpsEventProducerProps extends ProducerProps {

    GEO_LOCATION = DesktopEvents.GEO_LOCATION;
    //WATCH_GEO_LOCATION = DesktopEvents.WATCH_GEO_LOCATION;

    FREQUENCY_IN_SECS_PARAM = "frequency"

    LATITUDE = { property: "latitude" }
    LONGITUDE = { property: "longitude" }

    get props(): GlobalEventProducerInfo {
      return {
        producerName: "Gps service",
        description: "Provides gps services",
        iconName: "",
        eventTypes: [this.GEO_LOCATION],
        eventParams: [this.FREQUENCY_IN_SECS_PARAM],
        produceParams: [],
      }
    }
  }

  @Injectable()
  export class GpsEventProducer extends GlobalEventProducer<GpsEventProducerProps> {

    watchIds:number[] = []

    get props(): GpsEventProducerProps {
      return new GpsEventProducerProps()
    }

    init(pageEventProducer: PageEventProducer) {
      const eventActionContainers = pageEventProducer.getDefinedActionContainers(this.props.GEO_LOCATION)
      const eventActions = eventActionContainers.map(container => {
        const subEventType: SubEventType = container.subEventType.getOrElseThrow("subEventType need to be defined")
        const keyCombParam = subEventType.prop<string>(this.props.FREQUENCY_IN_SECS_PARAM)

        const watchID = navigator.geolocation.watchPosition((position:GeolocationPosition) => {
          this.onGpsLocationChanged(position, subEventType);
        });
        this.watchIds.push(watchID)
      })

    }

    onGpsLocationChanged(position:GeolocationPosition, subEventType: SubEventType) {
      const lat: GlobalEventData = { schema: this.props.LATITUDE, value: new Date() }
      const lon: GlobalEventData = { schema: this.props.LONGITUDE, value: new Date() }
      this.produce(this.props.GEO_LOCATION, RtSome(subEventType), [lat,lon])
      //fire page event
    }

    dispose() {
      this.watchIds.forEach(watchId => {
        navigator.geolocation.clearWatch(watchId);
      })
    }

  }
