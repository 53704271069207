export class MobiAttributeRegistryConstant{
    public static readonly BACKGROUND_COLOR = 'background-color';
    public static readonly BACKGROUND = 'background';
    public static readonly COLOR = 'color';
    public static readonly RIPPLE_COLOR = 'Ripple color';
    public static readonly COLOR_ACTIVATED = 'Color Activated';
    public static readonly COLOR_FOCUSED = 'Color Focused';
    public static readonly COLOR_HOVER = 'Color Hover';
    public static readonly BACKGROUND_CHECKED = 'Background Checked';
    public static readonly CHECKMARK_COLOR = 'Checkmark Color';
    public static readonly BORDER_COLOR_CHECKED = "Border Color Checked";
    public static readonly CHECK_MARK_WIDTH = 'Checkmark Width';
    public static readonly PROGRESS_BACKGROUND = 'Progress Background';

    public static readonly HANDLE_WIDTH = 'Handle Width';
    public static readonly HANDLE_HEIGHT = 'Handle Height';
    public static readonly HANDLE_MAX_HEIGHT = 'Handle Max Height';
    public static readonly HANDLE_SPACING = 'Handle Spacing';
    public static readonly HANDLE_BACKGROUND = 'Handle Background';
    public static readonly HANDLE_BACKGROUND_CHECKED = 'Handle Background Checked';
    public static readonly HANDLE_BORDER_RADIUS = 'Handle Border Radius';
    public static readonly HANDLE_BOX_SHADOW = 'Handle Box Shadow';
    public static readonly SIZE = 'Size';

    public static readonly DETAIL_ICON_COLOR = 'Detail Icon Color';
    public static readonly DETAIL_ICON_FONTSIZE = 'Detail Icon Fontsize';
    public static readonly DETAIL_ICON_OPACITY = 'Detail Icon Opacity';
    public static readonly HIGHLIGHT_COLOR_FOCUSED = 'Highlight Color Focused';
    public static readonly HIGHLIGHT_COLOR_VALID = 'Highlight Color Valid';
    public static readonly HIGHLIGHT_COLOR_INVALID = 'Highlight Color Invalid';
    public static readonly HIGHLIGHT_HEIGHT = 'Highlight Height';
    public static readonly INNER_BORDER_WIDTH = 'Inner Border Width';
    public static readonly INNER_BOX_SHADOW = 'Inner Box Shadow';
    public static readonly INNER_PADDING_BOTTOM = 'Inner Padding Bottom';
    public static readonly INNER_PADDING_END = 'Inner Padding End';
    public static readonly INNER_PADDING_START = 'Inner Padding Start';
    public static readonly INNER_PADDING_TOP = 'Inner Padding Top';
    public static readonly MIN_HEIGHT = 'Min Height';

    public static readonly BAR_BACKGROUND = 'Bar Background';
    public static readonly BAR_BACKGROUND_ACTIVE = 'Bar Background Active';
    public static readonly BAR_BORDER_RADIUS = 'Bar Borderradius';
    public static readonly BAR_HEIGHT = 'Bar Height';
    public static readonly HEIGHT = 'Height';
    public static readonly KNOB_BACKGROUND = 'Knob Background';
    public static readonly KNOB_BORDER_RADIUS = 'Knob Borderradius';
    public static readonly KNOB_BOX_SHADOW = 'Knob Boxshadow';
    public static readonly KNOB_SIZE = 'Knob Size';
    public static readonly PIN_BACKGROUND = 'Pin Background';
    public static readonly PIN_COLOR = 'Pin Color';
    public static readonly MENU_ICON_FILL = 'menu icon fill';
    public static readonly OVERFLOW = 'overflow';
    public static readonly FORM_CONTAINER_CONTROL_SELECTOR = 'Form Container Control Selector';

}