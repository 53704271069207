export class StringHelper {

    public static toCamelCase(content: string): string {
        return content
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    }
    public static capitalizeFirstLetters(name: string): string {
        return name?.toLowerCase().replace(/^\w|\s\w/g, (word: string) => {
            return word?.toUpperCase();
        });
    }
    public static sentenceToPascelCaseWithReplaceUnderscoreWithSpace(sentence: string): string {
        return this.capitalizeFirstLetters(sentence.replace(/_/g, ' '));
    }
    public static sentenceToLowerCaseWithUnderscore(name: string): string {
        return name?.trim().replace(/ /g, '_').toLowerCase();
    }

    public static insertSpaceIntoPascalCase(inputString: string): string {
        if (!inputString) {
            throw new Error('invalid input for insertSpaceIntoPascalCase()');
        }
        return inputString.replace(/([A-Z])/g, ' $1').trim();
    }
    public static removeSpaceFromString(inputString: string): string {
        if (!inputString) {
            throw new Error('invalid input for removeSpaceFromString()');
        }
        return inputString.replace(/ /g, '');
    }
    public static removeFirstAndLastCharacter(inputString: string): string {
        if (!inputString) {
            throw new Error('invalid input for removeFirstAndLastCharacter()');
        }
        return inputString.slice(1, -1);
    }
    public static firstletterToLowerCase(inputString: string): string {
        if (!inputString) {
            throw new Error('invalid input for firstletterToLowerCase()');
        }
        return inputString.charAt(0).toLowerCase() + inputString.slice(1);
    }
    public static removePXFromStringAndConvertToNumber(inputString: string) {
        if (!inputString) {
            throw new Error('invalid input for removePXFromString()');
        }
        return Number(inputString.replace(/px/i, '').trim());
    }

    public static removePercentageFromStringAndConvertToNumber(inputString: string) {
        if (!inputString) {
            throw new Error('invalid input for removePXFromString()');
        }
        return Number(inputString.replace(/%/i, '').trim());

    }
    public static removeUnitFromStringAndConvertToNumber(inputString: string, unit: string) {
        if (!inputString) {
            throw new Error('invalid input for removePXFromString()');
        }
        return Number(inputString.replace(unit, '').trim());

    }
    static convertStringToNumber(input: string) {
        const numeric = Number(input);
        return numeric;
    }
    static getItemByPropertyName<T, U>(array: Array<T>, propertyName: string, propertyValue: U): T {
        return array.find(function (arr) { return (arr[propertyName] === propertyValue); });
    }

    static createCodeFromLabel(label: string) {
        return label ? label.substring(0, 4).toUpperCase() : null;
    }

    static createNameFromLabel(label: string) {
        return label ? label.toLowerCase().replace(/ /g, "_") : null;//NOTE: replace(" ", "_") was replacing only first space
    }
    static replaceDotWithUnderscore(data: string) {
        return data.replace(/\./g, '_');
    }
    static replaceUnderscoreWithDot(data: string) {
        return data.replace(/_/g, '.');
    }
}