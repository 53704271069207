import { AdditionalEntity } from "./base.models";

export class ArtifactUsageUIContainer implements AdditionalEntity {

    public usageEvaluation: string;
    public entityUsagesUI: Array<EntityUsageUI>;

    constructor() {
    }
}

export class EntityUsageUI implements AdditionalEntity {

    public artifactGroup: string;
    public usages: Array<EntityUsage>;

    constructor() {
    }
}

export class EntityUsage implements AdditionalEntity {

    public entity: string;
    public description: string;
    public severity: string;
    public count: number;
    public values: Array<string>;

    constructor() {
    }
}

export class DeleteByIdsCommand implements AdditionalEntity {

    public ids: Array<string>;

    constructor() {
    }
}