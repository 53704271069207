import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClientProvider } from "./../abstract/http-client-provider";

@Injectable()
export class AngularHttpProvider implements HttpClientProvider {


    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    get<T>(constructedUrl: string, params: { [param: string]: any; }): Observable<T> {
        return this.http.get<T>(constructedUrl, { params: params }).pipe(res => {
            return res;
        });
    }

    getObject(constructedUrl: string): Observable<Object> {
        return this.http.get(constructedUrl);
    }

    post<T>(constructedUrl: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {

        return this.http.post<T>(constructedUrl, body, options);
    }

    put<T>(constructedUrl: string, entity: T): Observable<T> {
        return this.http.put<T>(constructedUrl, entity);
    }

    delete<T>(constructedUrl: string): Observable<T> {
        return this.http.delete<T>(constructedUrl);
    }
}