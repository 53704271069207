import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
    selector: '[disableEnterKey]'
})

export class DisableEnterKeyDirective {

    @HostListener('keydown', ['$event'])

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
}

@NgModule({
    declarations: [
        DisableEnterKeyDirective
    ],
    exports: [
        DisableEnterKeyDirective
    ]
})

export class DisableEnterKeyDirectiveModule { }