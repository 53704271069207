import { AllMeasuresUnits } from "convert-units/definitions/all"
import { UOMOptions } from "../../../uom/uom-reference-service"
import { RtOption, RtSome, RtNone } from "../../../utils/option-helper"
import { Attribute, AttributeType } from "../core/attribute"
import { UsableAttributeValue } from "../core/attribute-instance"

export class UomSelectorModel {
  constructor(private _selectedUOM: AllMeasuresUnits,
    private _uomOptions: RtOption<UOMOptions>,
    private _rounding: RtOption<number>,) {
  }

  get selectedUOM(): AllMeasuresUnits { return this._selectedUOM }

  set selectedUOM(value: AllMeasuresUnits) { this._selectedUOM = value }

  get uomOptions(): UOMOptions { return this._uomOptions.getOrElseUndefined }

  set uomOptions(value: UOMOptions) { this._uomOptions = RtSome(value) }

  get rounding(): number { return this._rounding.getOrElseUndefined }
  set rounding(value: number) {
    this._rounding = RtSome(value)
  }

  static parse(uomModel: UomSelectorModel): UomSelectorModel {
    const selectedUOM = uomModel._selectedUOM;
    const uomOptions = RtOption.parse(uomModel._uomOptions);
    const rounding = RtOption.parse(uomModel._rounding);
    const parsedUomModel = new UomSelectorModel(selectedUOM, uomOptions, rounding);
    return parsedUomModel;
  }
}

export class UomSelectorAttribute extends Attribute<UomSelectorModel, UomSelectorModel> {
  constructor(attributeName: string, private uomAbbr: AllMeasuresUnits) {
    super(attributeName, null, 'string', AttributeType.CONFIGURATION,
      RtSome(() => UomSelectorAttribute.createDefaultValue(this.uomAbbr)));
  }

  generateTSCode(): string {
    console.warn("UomSelectorAttribute: generateTSCode not implemented.");
    return "";
  }
  clone(): Attribute<unknown, unknown> {
    const attribute = new UomSelectorAttribute(this.name, this.uomAbbr);
    return attribute;
  }
  parseModel(jsonValue: string): UomSelectorModel {
    const model = JSON.parse(jsonValue);
    const reConstructed = UomSelectorModel.parse(model);
    return reConstructed;
  }
  buildUsableValue(resolvedValue: RtOption<UomSelectorModel>): UsableAttributeValue<UomSelectorModel>[] {
    if (resolvedValue.isDefined) {
      return [new UsableAttributeValue(this.name, resolvedValue.get, this.attributeType)];
    }
    return [];
  }

  public static createDefaultValue(uomAbbr: AllMeasuresUnits) {
    // const default = this.uomReferenceService.unit(SpatialUOMConstants.SPATIAL_BASE_UOM)
    return new UomSelectorModel(uomAbbr, RtSome({ exclude: [], cutOffNumber: undefined, system: undefined }), RtNone())
  }
}
