import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Route } from '../../../den-core/src/lib/security/security.constants';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  { path: Route.SECURITY, loadChildren: () => import('./security/security.module').then(m => m.SecurityModule) },
  { path: '', loadChildren: () => import('./app-bootstrap/devum-web-app/devum-web-app.module').then(m => m.DevumWebAppModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }]
})
export class AppRoutingModule { }
