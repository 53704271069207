export enum CFSDataTypes {
    STRING_VALUE = "StringValue",
    DOUBLE = "DoubleValue",
    NUMBER = "NumberValue",
    BOOLEAN = "BooleanValue",
    ARRAY_BYTE = "ArrayByteValue",
    DATE_TIME = "DateTimeValue",
    LOCAL_DATE = "LocalDateValue",
    ANY_REF = "AnyRefValue",
    VOID = "VoidValue",
    DATA_TABLE = "DataTable",
    DATA_ITEM = "DataItem",
    DATA_CELL = "DataTableCell",
    KENDO_QUERY = "KendoQuery",
    CONDITION_BLOCK_CONTAINER = "ConditionBlockContainer",
    UNKNOWN = "unknown",
    ANY_REF_LIST = "AnyRefValueList",
    LOCAL_TIME = "LocalTimeValue",
    GEOMETRY = "Geometry"
}

export enum FSDataTypes {
    STRING_VALUE = "String",
    DOUBLE = "double",
    NUMBER = "long",
    BOOLEAN = "boolean",
    OBJECT = "Object",
    BYTE = "byte[]",
    DATE_TIME = "DateTime",
    LOCAL_DATE = "LocalDate",
    VOID = "void",
    DATA_TABLE = "DataTable",
    DATA_ITEM = "DataItem",
    KENDO_QUERY = "KendoQuery",
    CONDITION_BLOCK_CONTAINER = "ConditionBlockContainer",
    UNKNOWN = "unknown",
    LOCAL_TIME = "LocalTime",
    GEOMETRY = "Geometry"
}
export const CFSPrimitiveDataTypes = ["StringValue", "DoubleValue", "NumberValue", "BooleanValue", "ArrayByteValue", "DateTimeValue", "LocalDateValue",]