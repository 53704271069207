
<div class="pb-companion-menu-item-wrapper">
  <mat-button-toggle-group multiple *ngIf="menuItems.length > 0" class="height-35" (click)="menuClick($event)">
    <mat-button-toggle *ngFor="let menu of menuItems" (change)="menuSelect(menu.displayName)" [value]="menu.displayName"
      [name]="menu.displayName" [checked]="menu.enabled" [matTooltip]="menu.displayName">
      <button mat-button class="rt-px-0" [matMenuTriggerFor]="subMenu.childMenu"
        *ngIf="menu.children && menu.children.length > 0">
        <img src="./assets/svg/threejs/toolbox/{{menu.iconName || 'group.svg'}}" alt="menu.displayName"
          class="rt-dimension-15">
        <mat-icon>expand_more</mat-icon>
      </button>
      <lib-pb-companion-submenu-item #subMenu [subItems]="menu.children">
      </lib-pb-companion-submenu-item>
      <button mat-button class="rt-px-0" *ngIf="!menu.children || menu.children.length === 0">
        <img src="./assets/svg/threejs/toolbox/{{menu.iconName}}" alt="menu.displayName" class="rt-dimension-15">
      </button>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
