export class ControlStateManager {

    static controlValueRegistry = new Map<string, unknown>();

    static setValue<T>(controlId: string, value: T) {
        this.controlValueRegistry.set(controlId, value);
    }

    static getValue(controlId: string) {
        return this.controlValueRegistry.get(controlId);
    }

    static clear(){
        this.controlValueRegistry = new Map();
    }
}