import { IDsResult } from '../../data-source/data-source-result-entities';
import { PageService } from '../../services/page-service';
import { ControlInstanceWrapper } from './control-instance-drafts-models';
import { AbstractDSStateManager } from './data-source-state-manager/abstract-ds-state-manager';

export class ControlReadinessCheckService<D extends IDsResult> {

  private _isViewInitialized = false;
  private _allAttribututesInitialized: boolean;

  constructor(private pageService: PageService,
    private dataSourceStateManager: AbstractDSStateManager<D>) {

  }

  reSetStateManager(dataSourceStateManager: AbstractDSStateManager<D>) {
    this.dataSourceStateManager = dataSourceStateManager;
  }

  setAsViewInitialized() {
    this._isViewInitialized = true;
  }

  setAttributeInitialized() {
    this._allAttribututesInitialized = true;
  }

  //---- checks required to render children for a control with datasource. e.g. Div List as parent
  get isReadyToAdaptDataAndRenderChildren(): boolean {
    return this._isViewInitialized && this.isDataReceived && this._allAttribututesInitialized;
  }
  // loadChildrenOnDemand() {
  // load Children
  // unload children
  // attrs: on & off events ;

  /*
  when on => render children
  when off => remove all children or clearAllChildren;
  when on => reloadChildren -> re-build command

  */
  // }

  private get isDataReceived(): boolean {
    //read from dataSource manager
    return this.dataSourceStateManager.isDataReceived();
  }

  //----- checks whether control is ready to children when datasource is not applicable. e.g. DIV, Grid as parents
  isViewInitializedAndHasNoDatasource(controlInstance: ControlInstanceWrapper): boolean {
    return this._isViewInitialized && this.hasNoDataSourceConfigured(controlInstance) && this._allAttribututesInitialized;
  }
  isViewInitializedAndHasDatasourceAndNotInitialized(controlInstance: ControlInstanceWrapper):boolean{
    return this._isViewInitialized && !this.hasNoDataSourceConfigured(controlInstance) && this._allAttribututesInitialized;
  }


  hasNoDataSourceConfigured(controlInstance: ControlInstanceWrapper): boolean {
    return this.pageService.hasNoDataSourceConfigured(controlInstance.instanceId);
  }


}

