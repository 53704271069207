<rt-modal-dialog *ngIf="data">

    <div class="modal-header">
        <h3 class="sub-title rt-mb-0" mat-dialog-title>Group By</h3>
    </div>

    <div class="modal-content">
        <div class="row">
            <div class="col-md-12">
        <mat-form-field>
            <mat-select placeholder="Select Field" [(ngModel)]="selectedSchemaField">
                <!-- <mat-option>Select Field</mat-option> -->
                <mat-option *ngFor="let user of supportedGroupByFields | orderBy: 'name'" [value]="user">
                    {{user?.displayName || user?.fieldName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
    </div>

    <div class="modal-actions">
        <div class="row">
            <div class="col-md-12">
                <button mat-raised-button (click)="resetGroupBy()">RESET </button>
                <button mat-raised-button  color="primary" (click)="applyGroupBy()">APPLY </button>
            </div>
        </div>
    </div>

</rt-modal-dialog>