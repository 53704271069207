import { Injectable, Injector } from "@angular/core";
import { GlobalEventProducerRegistry } from "./global-event-producer-registry";
import { AppParamsEventProducerProps, AppParamsEventProducer } from "./impls/app-params-event-producer";
import { GpsEventProducerProps, GpsEventProducer } from "./impls/gps-event-producer";
import { KeyBoardEventProducerProps, KeyBoardEventProducer } from "./impls/keyboard-event-producer";
import { PageParamsEventProducerProps, PageParamsEventProducer } from "./impls/page-params-event-producer";

@Injectable()
export class GlobalWebEventProducerRegistry extends GlobalEventProducerRegistry {

  constructor(private injector: Injector) {
    super();
    this.registerGlobalEventProducers();
  }


  private registerGlobalEventProducers() {
    this.registry.set(new GpsEventProducerProps().producerName, this.injector.get(GpsEventProducer))
    this.registry.set(new AppParamsEventProducerProps().producerName, this.injector.get(AppParamsEventProducer))
    this.registry.set(PageParamsEventProducerProps.PRODUCER_NAME, this.injector.get(PageParamsEventProducer))
    this.registry.set(new KeyBoardEventProducerProps().producerName, this.injector.get(KeyBoardEventProducer))
  }


}
