import { ChartAttributesEnum } from "../../../../models/chart/chart-models";
import { RtOption, RtSome } from "../../../../utils/option-helper";
import { AttributeGroup } from "../../../constants/attribute-group-constant";
import { AttributeRegistryConstant } from "../../../constants/attribute-registry-constants";
import { Attribute, AttributeType, MobiAttribute } from "../../core/attribute";
import { UsableAttributeValue } from "../../core/attribute-instance";

export class LegendChartModel {

    constructor(public showLegend: boolean, public position: string, public align: string, public hSpace: number, public vSpace: number, public legendColor: string, 
        public legendFontSize : string, public legendFontWeight : string, public legendFontStyle : string
    ) {
    }

    static parseModel(jsonString: string) {
        const parsedValue: LegendChartModel = JSON.parse(jsonString);
        return new LegendChartModel(parsedValue.showLegend, parsedValue.position, parsedValue.align, parsedValue.hSpace, parsedValue.vSpace, parsedValue.legendColor, parsedValue.legendFontSize, parsedValue.legendFontWeight, parsedValue.legendFontStyle);
    }
}

export class ChartLegendAttribute extends Attribute<LegendChartModel, boolean | string | number> implements MobiAttribute<LegendChartModel,boolean | string | number> {

    constructor() {
        super(AttributeRegistryConstant.LEGEND_PROPERTIES, AttributeGroup.CONFIGURATION, 'object', AttributeType.CONFIGURATION,
            RtSome(() => this.defaultChartLegendModel()));
    }


    defaultChartLegendModel(): LegendChartModel {
        return new LegendChartModel(true, 'Top', 'Vertical', 0, 0, 'black', '16px','normal','normal');
    }

    generateTSCode(): string {
        return `
            [
                new UsableAttributeValue(this.name, new LegendChartModel(${this.model.showLegend}, ${this.model.position}, ${this.model.align}, ${this.model.hSpace}, ${this.model.vSpace}, ${this.model.legendColor}, ${this.model.legendFontSize}, ${this.model.legendFontWeight}, ${this.model.legendFontStyle}}), this.attributeType)
            ]
        `;
    }

    clone(): Attribute<LegendChartModel, boolean | string | number> {
        return new ChartLegendAttribute();
    }

    parseModel(jsonValue: string): LegendChartModel {
        return LegendChartModel.parseModel(jsonValue);
    }

    buildUsableValue(resolvedValue: RtOption<LegendChartModel>): UsableAttributeValue<boolean | string | number>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.SHOW_LEGEND, dim.showLegend, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_POSITION, dim.position, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_ALIGNMENT, dim.align, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_H_SPACE, dim.hSpace, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_V_SPACE, dim.vSpace, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_COLOR, dim.legendColor, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_FONT, dim.legendFontSize, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_FONT_WIEGHT, dim.legendFontWeight, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_FONT_STYLE, dim.legendFontStyle, this.attributeType)
            ];
        } else {
            return [];
        }
    }

    buildMobiUsableValue(resolvedValue: RtOption<LegendChartModel>): UsableAttributeValue<boolean | string | number>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.SHOW_LEGEND, dim.showLegend, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_POSITION, dim.position, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_ALIGNMENT, dim.align, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_H_SPACE, dim.hSpace, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_V_SPACE, dim.vSpace, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_COLOR, dim.legendColor, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_FONT, dim.legendFontSize, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_FONT_WIEGHT, dim.legendFontWeight, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.LEGEND_FONT_STYLE, dim.legendFontStyle, this.attributeType)
            ];
        } else {
            return [];
        }
    }

}
