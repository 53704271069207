import { Injectable } from "@angular/core";
import SignaturePad from "signature_pad";

@Injectable()
export class SignaturePadService {

    signaturePad: SignaturePad;

    constructor() {

    }

    init(signaturePadElement: any) {
        this.signaturePad = new SignaturePad(signaturePadElement.nativeElement);
        this.signaturePad.clear();
        this.signaturePad.penColor = 'rgb(0, 0, 0)';
    }

    clearInit() {
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    isCanvasBlank(): boolean {
        if (this.signaturePad) {
            return this.signaturePad.isEmpty() ? true : false;
        }
        return true;
    }

    clear() {
        this.signaturePad.clear();
    }

    undo() {
        const data = this.signaturePad.toData();
        if (data) {
            data.pop();
            this.signaturePad.fromData(data);
        }
    }

    getEncodedData() {
        const encodedStr = this.signaturePad.toDataURL();
        return encodedStr;
    }

}