import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'den-w-general-error-handler',
  templateUrl: './general-error-handler.component.html',
  styleUrls: ['./general-error-handler.component.scss']
})
export class GeneralErrorHandlerComponent implements OnChanges {

  @Input() isSubsciptionError: boolean;
  @Input() tryAgainButtonLabel: string;
  @Output() onTryAgainClicked = new EventEmitter();

  errorMainMessage: string = 'Oops, Something Went Wrong';
  errorSubMessage: string = 'Please try again in a few minutes and if the error persists, please contact the admin.';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSubsciptionError?.currentValue) {
      this.errorMainMessage = `You don't have any active subscriptions`;
      this.errorSubMessage = 'Please contact your admin to renew the subscription';
    }
  }

  onTryAgain() {
    this.onTryAgainClicked.emit();
  }
}

@NgModule({
  declarations: [GeneralErrorHandlerComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [GeneralErrorHandlerComponent]
})
export class GeneralErrorHandlerModule { }
