import { Type } from '@angular/core';
import { RtOption } from '../../../../../utils/option-helper';


export enum ControlAssistantMenu {
  DATA_SOURCE = "DataSource",
  CONDITION_BUILDER = "Condition builder",
  CONFIGURE_EVENTS = "Configure Events",
  EVENTS = "Events",
  RECOMMENED_CONTROLS = "Recommended Controls",
  DELETE = "Delete",
  PUBLISH_PARENT_FIELD = "Publish parent field",
  INTERACTION_EVENT = "Interaction Event",
  MANAGE_EVENTS = "Manage Events",
}
export type ControlAssistantMenuOption = { name: ControlAssistantMenu, iconName: string, component: RtOption<() => Promise<Type<unknown>>>, callBackFunc: RtOption<(event: MouseEvent) => void> };
export abstract class ControlAssistantMenuProvider {

  abstract menuItems: ControlAssistantMenuOption[];
  abstract parentDsMenuItems: ControlAssistantMenuOption[];
  abstract controlEventMenuItems: ControlAssistantMenuOption[];
  // abstract interactionEventMenuItems: ControlAssistantMenuOption[];

  getMenuItems(): ControlAssistantMenuOption[] {
    return this.menuItems;
  }
  getMenuItem(name: ControlAssistantMenu): ControlAssistantMenuOption {
    return this.menuItems.find(menuItem => menuItem.name === name);
  }
  getParentDsMenuItems(): ControlAssistantMenuOption[] {
    return this.parentDsMenuItems;
  }
  getControlEventMenuItems(): ControlAssistantMenuOption[] {
    return this.controlEventMenuItems;
  }
  getInteractionEventMenuItems(): ControlAssistantMenuOption[] {
    return []
  }

}
