import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { Amplify } from 'aws-amplify';


if (environment.production) {
  enableProdMode();
}

// Amplify.configure({
//   region: 'us-east-1',
//   identityPoolRegion: 'us-east-1',
//   userPoolId: 'us-east-1_BZLGEyqsf',
//   userPoolWebClientId: '2726t3dc9b5sr7cims6elnrba6',
//   oauth: {
//     domain: "wintest-auth.auth.us-east-1.amazoncognito.com",
//     responseType: "token",
//     redirectSignIn: "http://localhost:4200",
//     redirectSignOut: "http://localhost:4200",
//   }
// });


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
