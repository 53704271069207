import { IdentityGenerator } from "../../../../../utils/identity-generator"
import { EventService } from "../../../../../providers/event-service/event.service"
import { RtOption } from "../../../../../utils/option-helper"
import { DsResult, DsResultBuilder } from "../../../../data-source/data-source-result-entities"
import { GlobalEventDataSchema as GlobalEventDataSchema, ProducerType } from "../event-schema-provider"
import { PageEventProducer } from "../event-store"
import { CommonEventType, SubEventType } from "../event-types"
import { GlobalEventOrchestrator } from "../global-event-orchestrator"
import { Directive } from "@angular/core"
import { AppEvent } from "../event-models"

//type ProducerName

export type GlobalEventProducerInfo = {
    producerName: string,
    description: string,
    iconName: string,
    eventTypes: CommonEventType[],
    eventParams: string[],
    produceParams: GlobalEventDataSchema[],
}

export type GlobalProducer = { props: GlobalEventProducerInfo, producer: GlobalEventProducer<ProducerProps> }

export type GlobalEventData = { schema:GlobalEventDataSchema, value:any}

export interface ValueRequester {
    retrieve():DsResult
}

//consider global settings as well in-addition to event specific settings.
// e.g. location accuracy
@Directive()
export abstract class GlobalEventProducer<P extends ProducerProps>  {

    //abstract globalEventProducerInfo(): GlobalEventProducerInfo;

    abstract init(pageEventProducer: PageEventProducer)

    abstract dispose();

    abstract get props(): P

    constructor(public eventService: EventService) {
    }



    produce(eventType: CommonEventType,
        subEventType:RtOption<SubEventType>,
         data: GlobalEventData[]) {

        const id = IdentityGenerator.getShortID()
        const dsName = `${this.props.producerName}-data`
        const simpleDataArray = data.map(d => {return {fieldName:d.schema.property, value:d.value}})
        const dsReuslt = DsResultBuilder.createNew(id,dsName,simpleDataArray)
        const event:AppEvent = {
            eventType: eventType,
            eventProducerName: this.props.producerName,
            dsResults: [dsReuslt],
            subEventType:subEventType,
            producerType: ProducerType.GLOBAL_EVENT_DATA
        }
        this.eventService.publish(GlobalEventOrchestrator.APP_EVENT, event)
    }
}

export abstract class ProducerProps {
    get producerName(): string {
        return this.props.producerName
    }
    // abstract eventTypes: CommonEventType[] = [DesktopEvents.GEO_LOCATION, DesktopEvents.WATCH_GEO_LOCATION]

    abstract get props(): GlobalEventProducerInfo
}
