import { Injectable } from '@angular/core';
import { IConfig } from '../models/base.models';
import { WebStorageEnum } from '../constants/storage-constants';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private config: IConfig | undefined;
  constructor() { }

  setConfig(config: IConfig) {
    this.config = config;
    localStorage.setItem(WebStorageEnum.CONFIGURATION, JSON.stringify(this.config));
  }

  getConfig(): IConfig {
    return this.config || JSON.parse(localStorage.getItem(WebStorageEnum.CONFIGURATION))
  }
}
