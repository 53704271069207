import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ModalDialogComponent } from "./modal-dialog.component";

const MAT_MODULES = [
    MatIconModule,
    MatDialogModule,
    MatButtonModule
];

@NgModule({
    imports: [
        CommonModule,
        ...MAT_MODULES,
    ],
    declarations: [ModalDialogComponent],
    exports: [ModalDialogComponent, MatDialogModule]
})
export class ModalDialogModule { }