import { Injectable } from "@angular/core";
import { RtNone, RtOption, RtSome } from "../../../../utils/option-helper";
import { AttributeInstance } from "../../../attributes/core/attribute-instance";
import { DsResult, IDsResult } from "../../../data-source/data-source-result-entities";
import { AttributeInstanceResolver, AttributeResolverType } from "../attribute-instance-resolver";
import { ControlInstanceWrapper } from "../control-instance-drafts-models";
import { ControlAccessability, ControlVisibility, IControl } from "../control-model";
import { BehaviourData, ControlConditionGroupEvaluationResult } from "./models/control-conditions";

@Injectable()
export class ConditionBehaviourService {

  //should we support condition builder only controls with data type 'DsResult' ?
  /**
   *  1) query attributeInstances,
   *  2)
   * @param conditionId
   * @param dsResult
   */
  applyBehaviours<D extends IDsResult, C extends IControl<D>>(control: C, dsResult: DsResult, controlInstanceWrapper: ControlInstanceWrapper, behaviours: BehaviourData[]) {
    behaviours.forEach((behaviour: BehaviourData) => {
      this.applyBehaviourAttributes(control, dsResult, behaviour);
    })
  }

  applyVisibilityAndAccessability<D extends IDsResult, C extends IControl<D>>(control: C, controlConditionEvaluationResults: ControlConditionGroupEvaluationResult[]) {
    controlConditionEvaluationResults.filter(result => result.canExecuteBehaviour).forEach((result: ControlConditionGroupEvaluationResult) => {
      const visibility = result.controlConditionGroup.isShow ? ControlVisibility.SHOW : ControlVisibility.HIDE;
      const accessability = result.controlConditionGroup.isEnable ? ControlAccessability.ENABLE : ControlAccessability.DISABLE;
      control.applyVisibility(visibility);
      control.applyAccessability(accessability);
    })
  }

  async applyBehaviourAttributes<D extends IDsResult, C extends IControl<D>>(control: C, dsResult: DsResult, behaviour: BehaviourData) {
    const behaviourAttributes: AttributeInstance[] = behaviour.attributeValues;
    const updatedAttributes = this.applyDataOnAttributeInstances(behaviourAttributes, dsResult);
    const attributes = await control.descriptor.getAttributes();
    const allAttributeInstanceHolder = AttributeInstanceResolver.convertAttributeInstanceToAttributeInstanceHolder(control.controlInstance.controlName,
       updatedAttributes, attributes);
    control.applyProperties(allAttributeInstanceHolder, AttributeResolverType.ON_CONFIGURATION_CHANGE);
  }

  applyDataOnAttributeInstances(attributeInstances: AttributeInstance[], dsResult: DsResult): AttributeInstance[] {
    const updatedResults = attributeInstances.map((attributeInstance: AttributeInstance) => {
      return this.updateAttributeInstanceValue(attributeInstance, dsResult);
    })
    return updatedResults;
  }

  private updateAttributeInstanceValue(attributeInstance: AttributeInstance, dsResult: DsResult): AttributeInstance {
    const resolvedValue = this.replaceExpressionInSideValueWithData(dsResult, attributeInstance.value);
    return new AttributeInstance(attributeInstance.name, resolvedValue, attributeInstance.attributeType);
  }

  private replaceExpressionInSideValueWithData(dsResult: DsResult, valueString: string) {
    valueString = this.replaceComplexPropertyValues(valueString, dsResult);
    valueString = this.replacePrimitivePropertyValue(valueString, dsResult);
    return valueString;
  }

  private replacePrimitivePropertyValue(valueString: string, dsResult: DsResult) {
    const regex = /\$\{(.*?)\}/g;
    const regexMatches = [...valueString.matchAll(regex)];
    regexMatches.forEach((regexMatchArray: RegExpMatchArray) => {
      const dsPropertyName = regexMatchArray[1].trim();
      const propertyValue = dsResult.data.find(dsResVal => dsResVal.fieldName === dsPropertyName).value;
      valueString = propertyValue;
    });
    return valueString;
  }

  private replaceComplexPropertyValues(valueString: string, dsResult: DsResult) {
    const regex = /\"\$\{(.*?)\}\"/g;
    const regexMatches = [...valueString.matchAll(regex)];
    regexMatches.forEach((regexMatchArray: RegExpMatchArray) => {
      const dsPropertyName = regexMatchArray[1].trim();
      const propertyValue = dsResult.data.find(dsResVal => dsResVal.fieldName === dsPropertyName).value;
      valueString = valueString.replace(regexMatchArray[0], propertyValue);
    });
    return valueString;
  }
}
