import { FilterOperator, FilterProperty } from 'projects/den-core/base-models';

export class DenAdvanceFilterHelper<T> {
  private _totalItems: number;

  get totalItems() {
    return this._totalItems;
  }
  private operatorExecution = new Map<string, Function>();
  private currentPageItems: T[] = [];
  private currentFilteredItems: T[] = [];
  constructor(
    private items: T[],
    private pageSize = 10,
    private maxPages = 10
  ) {
    this.currentFilteredItems = items.slice();
    this.registerOperations();
  }

  private registerOperations() {
    this.operatorExecution.set(FilterOperator.EQUALS, this.filterEquals);
    this.operatorExecution.set(FilterOperator.IN, this.filterValueList);
    this.operatorExecution.set(FilterOperator.IS_NOT_NULL, this.filterNotNull);
  }

  getPageItems(currentPage: number, pageSize: number = this.pageSize): T[] {
    // get new pager object for specified page
    const pager = this.paginate(
      this.currentFilteredItems.length,
      currentPage,
      pageSize,
      this.maxPages
    );
    this._totalItems = pager.totalItems;

    // get new page of items from items array
    this.currentPageItems = this.currentFilteredItems.slice(
      pager.startIndex,
      pager.endIndex + 1
    );

    return this.currentPageItems;
  }

  applyFilterOnAppContainer(filterProperties: FilterProperty[] = []) {
    if (filterProperties.length) {
      let filteredItems: any = this.items;
      filterProperties.forEach((fp: any) => {
        if (fp.operator === FilterOperator.IN) {
          filteredItems = filteredItems.filter((_) =>
            fp.valueList.includes(_.entity[fp.propertyName])
          );
        } else if (fp.operator === FilterOperator.CONTAINS_IGNORE_CASE) {
          filteredItems = filteredItems.filter(
            (_) =>
              _.entity[fp.propertyName]?.toLowerCase().includes(fp.value1?.toLowerCase())
          );
        } else {
          filteredItems = filteredItems.filter(
            (_) =>
              _.entity[fp.propertyName]?.toLowerCase() ==
              fp.value1?.toLowerCase()
          );
        }
      });
      this.currentFilteredItems = filteredItems;
    } else {
      this.currentFilteredItems = this.items.slice();
    }
  }
  applyFilter(filterProperties: FilterProperty[] = [], allItems?: T[]) {
    if (filterProperties.length) {
      let filteredItems = allItems ? allItems : this.items;
      filterProperties.forEach((fp) => {
        if (fp.operator === FilterOperator.IN) {
          filteredItems = this.filterValueList(filteredItems, fp);
        } else if (fp.operator === FilterOperator.IS_NOT_NULL) {
          filteredItems = this.filterNotNull(filteredItems, fp);
        } else {
          filteredItems = this.filterEquals(filteredItems, fp);
        }
      });
      this.currentFilteredItems = filteredItems;
    } else {
      this.currentFilteredItems = this.items.slice();
    }
  }

  private filterValueList(filteredItems: T[], fp: FilterProperty): T[] {
    return filteredItems.filter((_) =>
      fp.valueList.includes(_[fp.propertyName])
    );
  }

  private filterEquals(filteredItems: T[], fp: FilterProperty): T[] {
    if (fp.operator === FilterOperator.EQUALS) {
      return filteredItems.filter((_) => _[fp.propertyName] == fp.value1);
    } else {
      return filteredItems.filter((_) =>
        typeof _[fp.propertyName] == 'string'
          ? _[fp.propertyName]?.toLowerCase().includes(fp.value1?.toLowerCase())
          : _[fp.propertyName] == fp.value1
      );
    }
  }

  private filterNotNull(filteredItems: T[], fp: FilterProperty): T[] {
    return filteredItems.filter((_) => _[fp.propertyName] !== null);
  }

  /*
        Credit: https://github.com/cornflourblue/jw-paginate/blob/master/src/jw-paginate.ts
    */
  private paginate(
    totalItems: number,
    currentPage: number,
    pageSize: number,
    maxPages: number
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
