import { ChartAttributesEnum } from "../../../../models/chart/chart-models";
import { RtOption, RtSome } from "../../../../utils/option-helper";
import { AttributeGroup } from "../../../constants/attribute-group-constant";
import { AttributeRegistryConstant } from "../../../constants/attribute-registry-constants";
import { Attribute, AttributeType, MobiAttribute } from "../../core/attribute";
import { UsableAttributeValue } from "../../core/attribute-instance";

export class ChartDimensionModel {
    constructor(public width: number, public height: number, public fitContainer: boolean, public bufferSpace?: number) {

    }

    static parseModel(jsonString: string) {
        const parsedValue: ChartDimensionModel = JSON.parse(jsonString);
        return new ChartDimensionModel(parsedValue.width, parsedValue.height, parsedValue.fitContainer, parsedValue.bufferSpace);
    }
}

export class ChartDimensionAttribute extends Attribute<ChartDimensionModel, number | string | boolean> implements MobiAttribute<ChartDimensionModel, number | string | boolean> {

    constructor() {
        super(AttributeRegistryConstant.CHART_DIMENSION, AttributeGroup.CONFIGURATION, 'object', AttributeType.CONFIGURATION, RtSome(() => this.defaultDimension()));
    }

    defaultDimension(): ChartDimensionModel {
        return new ChartDimensionModel(300, 300, false, 50);
    }

    generateTSCode(): string {
        return `
            [
                new UsableAttributeValue(this.name, new ChartDimensionModel(${this.model.width}, ${this.model.height}, ${this.model.fitContainer},${this.model.bufferSpace}}), this.attributeType)
            ]
        `;
    }

    clone(): Attribute<ChartDimensionModel, number | string | boolean> {
        return new ChartDimensionAttribute();
    }

    parseModel(jsonValue: string): ChartDimensionModel {
        return ChartDimensionModel.parseModel(jsonValue);
    }

    buildUsableValue(resolvedValue: RtOption<ChartDimensionModel>): UsableAttributeValue<number | string | boolean>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.WIDTH, dim.width, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.HEIGHT, dim.height, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.FIT_CONTAINER, dim.fitContainer, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.BUFFER_SPACE, dim.bufferSpace, this.attributeType),
                //new UsableAttributeValue(ChartAttributesEnum.FIT, dim.width, this.attributeType)
            ];
        } else {
            return [];
        }
    }

    buildMobiUsableValue(resolvedValue: RtOption<ChartDimensionModel>): UsableAttributeValue<number | string | boolean>[] {
        if (resolvedValue.isDefined) {
            const dim = resolvedValue.get;
            return [
                new UsableAttributeValue(ChartAttributesEnum.WIDTH, dim.width, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.HEIGHT, dim.height, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.FIT_CONTAINER, dim.fitContainer, this.attributeType),
                new UsableAttributeValue(ChartAttributesEnum.BUFFER_SPACE, dim.bufferSpace, this.attributeType)
            ];
        } else {
            return [];
        }
    }

}
