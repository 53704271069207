import { AdvancedFilter, FilterProperty, SchemaControlType } from "projects/den-core/base-models";
import { TaskStatusConstants } from "../constants/app-constants";

export class UserTaskListHelper {

    static MAX_COLUMN_COUNT = 3;
    static swapFilterPropertiesToAttributes(filter: AdvancedFilter, processFields: string[]) {
        const filterProperties = JSON.parse(JSON.stringify(filter.filterProperties));
        filter.filterProperties = [];
        filter.attributes = [];
        filterProperties.forEach((property: FilterProperty) => {

            if (property.propertyName === 'IsActive') {
                property.propertyName = 'processInstanceState';
            }
            const processFieldFilterProperty = processFields.find(field => field === property.propertyName);
            if (processFieldFilterProperty) {
                filter.attributes.push(property);
            } else {
                filter.filterProperties.push(property);
            }
        });

        return filter;
    }

    static getActionList(callbackFn1, taskStatusCallbackFn1, taskStatusCallbackFn2) {
        return [
            { name: 'Edit', cssCallbackFn: taskStatusCallbackFn2, callbackFn: callbackFn1 },
            { name: 'View', cssCallbackFn: taskStatusCallbackFn1, callbackFn: callbackFn1 }

        ];
    }
    static displayErrorActionNone(response: any): string {
        return (response?.instanceDetails?.processInstanceState !== TaskStatusConstants.FAILED.toUpperCase()) ? 'rt-hidden' : '';
    }
    // static getColumnDetails(resolvedData: ColumnAndDependencyDetailsContainer, columnDetails: ColumnDetails[], entitySchemaFields: SchemaFieldAndFieldSetting[],
    //     processFields: string[], _arrayProp: EntitySchemaField[], namedFkeyEntitySchemaFields: EntitySchemaField[], fileUploadUrl2D: string, userAccountReaderApi) {

    //     const labelColor: LabelColor = new LabelColor();
    //     labelColor.backGroundColor = 'BGColor';
    //     const overdueBGColor: LabelColor = new LabelColor();
    //     overdueBGColor.backGroundColor = 'overdueColor';
    //     let dependencies = [new DependencyProperty(ProcessFields.TASK_ASSIGNEE_ID, userAccountReaderApi)];
    //     SchemaListColumnConstructionHelper.regControlTypeAndDescriptor();
    //     // _arrayProp = SchemaListColumnConstructionHelper.createArrayPropertiesBasedOnIsArray(entitySchemaFields);
    //     resolvedData = SchemaListColumnConstructionHelper.getColumnDetailsByType(entitySchemaFields, fileUploadUrl2D);
    //     columnDetails = resolvedData.columnDetails;
    //     const instanceStateProp = columnDetails.find(i => (i.propertyName === 'taskStatus'));
    //     if (instanceStateProp) {
    //         instanceStateProp.labelColor = labelColor;
    //     }
    //     const overdueProp = columnDetails.find(i => (i.propertyName === 'isOverdue'));
    //     if (overdueProp) {
    //         overdueProp.labelColor = overdueBGColor;
    //     }
    //     columnDetails.forEach(col => {
    //         if (col.propertyName === 'dueDate' || col.propertyName === 'followUpDate') {
    //             const dateTimeFormat = new DateFormat();
    //             dateTimeFormat.outputFormat = 'DD/M/YYYY HH:mm';
    //             col.dateFormat = dateTimeFormat;
    //         }
    //     });
    //     const index = columnDetails.findIndex(column => column.propertyName === ProcessFields.TASK_ASSIGNEE_ID);
    //     if (index !== -1) {
    //         columnDetails[index].isFilterable = false;
    //     }
    //     dependencies = [...dependencies, ...resolvedData.dependencyData];
    //     this.hideTaskFieldsByDEfault(columnDetails, processFields);
    //     return { dependencies, columnDetails };
    // }
    // private static hideTaskFieldsByDEfault(columnDetails: ColumnDetails[], processFields: string[]) {//NOTE:unused
    //     processFields.forEach(field => {
    //         if (field !== 'taskStatus') {
    //             const index = columnDetails.findIndex(column => column.propertyName === field);
    //             if (index) {
    //                 columnDetails[index].showColumnFlex = false;
    //                 columnDetails[index].showColumnMain = false;
    //             }
    //         }
    //     });
    // }
    static isAPIorNamedFkeyFieldType(controlType: string) {
        if (controlType) {
            return controlType === SchemaControlType.FOREIGN_KEY;
        }
        return null;
    }
    static getSupportedFilterTypes() {
        return [SchemaControlType.TEXT_BOX, SchemaControlType.FOREIGN_KEY, SchemaControlType.DATE_PICKER, SchemaControlType.DATE_TIME_PICKER, SchemaControlType.CHECK_BOX];
    }
    static getSupportedSortingFields() {
        return [SchemaControlType.TEXT_BOX, SchemaControlType.DATE_PICKER];
    }
    static groupBy(objectArray: any[], property: string) {
        return objectArray.reduce((acc: { [x: string]: any[]; }, obj: { [x: string]: any; }) => {
            const key = obj[property] || "NA";
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }
    static getConfiguredApiFieldName(fieldName: string, columnName: string) {
        const displayName = `${fieldName}-${columnName}`;
        return this.toPascelCase(displayName);
    }
    private static toPascelCase(content: string): string {
        const text = content.replace(/_/g, ' ');
        return text
            .replace(/(\w)(\w*)/g, ($0, $1, $2) => { $0.toUpperCase(); return $1.toUpperCase() + $2.toLowerCase(); });
    }

}
