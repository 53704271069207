import { ComponentRef, Injector, Renderer2, RendererFactory2, Type } from '@angular/core';
import { DenAuthorizeService, EventService, RtNone, RtOption, SessionStorageProvider } from 'projects/den-core';
import { AttributeInstance, AttributeRegistryConstant, UsableAttributeValue } from 'projects/den-core/page-builder';
import { Observable } from 'rxjs';
import { PermissionModel } from '../../attributes/permission/permission-attribute';
import { DOMIdConstant } from '../../constants/dom-id-constants';
import { AddChildBaseCommand } from '../../controls/core/child-rendered-event';
import { ControlDescriptor } from '../../controls/core/control-descriptor';
import { ControlInstanceWrapper } from '../../controls/core/control-instance-drafts-models';
import { IControl } from '../../controls/core/control-model';
import { CommonControlRenderService, ComponentRegisterService } from '../../ide/services/common-control-render-service';
import { ControlRegistryProvider } from '../../ide/services/control-registry-provider';
import { AngularChildControlInfo, IChildControlInfo, SimpleAngularControlInfo } from '../../ide/services/parent-control';
import { PageService } from '../page-service';
import { ParameterTopicService } from '../parameter-topic-service';
export abstract class AngularControlRenderService<PI extends SimpleAngularControlInfo> extends CommonControlRenderService<PI> {

  public renderer: Renderer2;
  public eventService: EventService;
  public componentRegisterService: ComponentRegisterService;
  sessionStorageProvider: SessionStorageProvider;
  denAuthorizeService: DenAuthorizeService;

  abstract renderAndAppendChildControl(childInstance: ControlInstanceWrapper, parentNode: Node, parentControlName: string, currentNode: Node, cmd: AddChildBaseCommand,
    parentInstanceId: string, parentSectionIndex: RtOption<number>): boolean;

  constructor(injector: Injector) {
    super(injector.get(ControlRegistryProvider), injector.get(ParameterTopicService), injector.get(PageService));
    this.renderer = injector.get(RendererFactory2).createRenderer(null, null);
    this.componentRegisterService = injector.get(ComponentRegisterService);
    this.eventService = injector.get(EventService);
    this.sessionStorageProvider = injector.get(SessionStorageProvider);
    this.denAuthorizeService = injector.get(DenAuthorizeService);

  }

  // https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_node_replacechild
  renderAndAppendControl(controlInstance: ControlInstanceWrapper, cmd: AddChildBaseCommand, parent: PI): Observable<AngularChildControlInfo> {
    return new Observable(observer => {

      const parentNode: Node = this.getParentNode(parent);
      const controlDescriptor = this.controlRegistry.getControlDescriptor(controlInstance.controlName);
      const permissionAttrValue = controlInstance.allAttributeValues.find(c => c.name === AttributeRegistryConstant.PERMISSION)?.value;
      const parsedPermissionModel = permissionAttrValue && PermissionModel.parse(permissionAttrValue);
      // If permissionModel is defined check authorization
      if (this.isPermissionModelDefined(parsedPermissionModel) && !this.sessionStorageProvider.getIsAdmin()) {
        this.denAuthorizeService.isAuthorized((parsedPermissionModel).group, (parsedPermissionModel).entityId, (parsedPermissionModel).operationType).subscribe((isAuthorized) => {
          if (isAuthorized) {
            this.createControlAndInit(controlInstance, parent, controlDescriptor, cmd, parentNode, observer);
          } else {
            // TODO: handle not-authorized with custom message
            observer.next(null)
          }
        });
      } else {
        //  if permissionmodel is not defined continue the normal flow
        this.createControlAndInit(controlInstance, parent, controlDescriptor, cmd, parentNode, observer);
      }
    });
  }

  private createControlAndInit(controlInstance: ControlInstanceWrapper, parent: PI, controlDescriptor: ControlDescriptor, cmd: AddChildBaseCommand, parentNode: Node, observer) {
    this.controlRegistry.getControlType(controlInstance.controlName).then(controlType => {
      const componentRef: ComponentRef<IControl<unknown>> = this.createControlReferenceAndRegister(controlType, parent, controlInstance, controlDescriptor, cmd);

      const parentControlName = parent.parentInstance ? parent.parentInstance.controlName : null;
      const currentNode: Node = componentRef.location.nativeElement;
      const parentSectionIndex = RtNone<number>();
      this.renderAndAppendChildControl(controlInstance, parentNode, parentControlName, currentNode, cmd, parent?.parentInstance?.instanceId, parentSectionIndex);
      const angularChildControlInfo = new AngularChildControlInfo(componentRef, parent.viewContainerRef);
      observer.next(angularChildControlInfo);
    });
  }

  private isPermissionModelDefined(permissionAttr: PermissionModel) {
    if (permissionAttr) {
      return Object.values(permissionAttr).every(p => p !== null);
    } else {
      return null;
    }
  }

  private createControlReferenceAndRegister(controlType: Type<IControl<unknown>>, parent: PI, controlInstance: ControlInstanceWrapper, controlDescriptor: ControlDescriptor, cmd: AddChildBaseCommand) {
    // VERIFYING IF THE DATA IS FROM WEB SOCKET & THE CONTROL IS FIRST LEVEL CHILD, THEN RENDERING CHILD AT INDEX 0
    const options = (this.isFirstLevelChild(controlInstance) && cmd.dsResult?.isWsResult) ? { index: 0 } : {};
    const componentRef = parent.viewContainerRef.createComponent(controlType, options);
    componentRef.instance.initControl(controlInstance, controlDescriptor, cmd);
    this.componentRegisterService.addToRegister(componentRef);
    return componentRef;
  }


  private isFirstLevelChild(controlInstance: ControlInstanceWrapper) {
    return this.pageService.hasDataSourcesConfigured(controlInstance.parentInstanceId)
  }

  destroyChildren(childControlRefs: IChildControlInfo[], _parent: PI, _parentInstance: ControlInstanceWrapper): void {
    if (childControlRefs) {
      const childCompRefs = childControlRefs.map(c => (c as AngularChildControlInfo).ref);
      childCompRefs.forEach(childControlRef => {
        this.destroyNestedChildren(childControlRef.instance)
        childControlRef.destroy();
      });
    }
  }

  private destroyNestedChildren(control: IControl<unknown>): void {
    control.children.forEach(child => {
      this.destroyNestedChildren(child.childControlRef.control);
    });
    control.destroy();
  }

  private getParentNode(parent: SimpleAngularControlInfo): Node {
    let node: Node;
    if (parent && parent.parentInstance && (parent.parentInstance.instanceId !== null)) {
      node = document.getElementById(parent.parentInstance.instanceId);
    } else {
      node = document.getElementById(DOMIdConstant.PAGE_BUILDER_CONTAINER);
    }
    if (!node) {
      console.error(`Current node is not available`);
    }
    return node;
  }

  destroyControlByInstanceId(instanceId: string) {
    this.componentRegisterService.destroyControlByInstanceId(instanceId);
  }
}


