import { AttributeRegistryConstant } from "./attribute-registry-constants";

export class WebControlRegistryConstant {
    public static readonly CHILD_PAGE_CONTROL = 'Child Page Control';
    public static readonly ROUTING_CONTROL = 'Routing Control';
    public static readonly BUTTON = 'Button';
    public static readonly FORM_ACTION_SAVE_ALL_BUTTON = 'Form Action Save All Button';
    public static readonly LOGOUTBUTTON = AttributeRegistryConstant.LOGOUTBUTTON;
    public static readonly API_EXECUTOR_BUTTON = 'API Executor Button';
    public static readonly CALENDAR = 'Calendar';
    // public static readonly ADVANCED_CALENDAR = 'AdvancedCalendar';
    public static readonly COLOR_PICKER = 'Color Picker';
    public static readonly SCHEDULE = 'Schedule';
    public static readonly LABEL = 'Label';
    public static readonly PROGRESS_BAR = 'ProgressBar';
    public static readonly HEADING = 'Heading';
    public static readonly PARAGRAPH = 'Paragraph';
    public static readonly CONTAINER = 'Container';
    public static readonly CONTAINER_MINIFY = 'ContainerMinify';
    public static readonly LIST = 'List';
    public static readonly LIST_PAGINATION = 'ListPagination';
    public static readonly GROUPED_LIST = 'GroupedList';
    public static readonly GRID_LAYOUT = 'GridLayout';
    public static readonly LAYOUT = 'Layout';
    public static readonly TOGGLE = 'Toggle';
    public static readonly INPUT = 'TextBox';
    public static readonly ICONS = 'Icon';
    public static readonly ICON_TOGGLE = 'IconToggle';
    public static readonly IMAGE = 'Image';
    public static readonly STATIC_ASSETS = 'StaticAssets';
    public static readonly DYNAMIC_TAB = 'DynamicTab';
    public static readonly STATIC_TAB = 'StaticTab';
    public static readonly SELECT = 'Droplist';
    public static readonly CHECK_BOX = 'Checkbox';
    public static readonly RADIO_BUTTON = 'Radio';
    public static readonly CARD = 'Card';
    public static readonly PYRAMID_CHART = 'pyramidChart';
    public static readonly PYRAMID_CHART_LIST = 'PyramidList'

    public static readonly CARD_LIST = 'CardList';
    public static readonly DATE = 'Date';
    public static readonly DATE_TIME_DISPLAY = 'DateTimeDisplay';
    public static readonly TEXT_AREA = 'TextArea';
    public static readonly IMAGE_SLIDER = 'Slides';
    public static readonly SCROLL_PANEL = 'ScrollPanel';
    public static readonly FILE_UPLOAD = 'Files';
    public static readonly FILE_DOWNLOAD = 'DownloadFile';
    public static readonly TIME_LINE_LIST = 'TimeLineList';
    public static readonly FLOATING_PANEL = 'FloatingPanel';
    public static readonly BULLET_FLOW = 'BulletFlow';
    public static readonly STATIC_ACCORDION = 'StaticAccordion';
    public static readonly TREE_VIEW = 'TreeView';
    public static readonly DATETIME = 'DateTime';
    public static readonly YEAR_MONTH = 'YearMonth';
    public static readonly TIME = 'Time';
    public static readonly DATERANGE = 'DateRange';
    public static readonly DATERANGE_LIST = 'DateRangeList';
    public static readonly PAGESLIDE = 'PageSlide';
    public static readonly MULTISELECT = 'MultiSelect';
    public static readonly DIALOG = 'Dialog';
    public static readonly QR_CODE = 'QrCode';
    public static readonly DELETE_CONTROL = 'Delete';
    public static readonly PROGRESS = 'Progress';
    public static readonly SVG_ICON = 'Svg Icon';
    public static readonly CHECKBOX_LIST = 'Checkbox List';
    public static readonly SINGLE_CHECKBOX = 'Single Checkbox';
    public static readonly IMPORT_CSV_OR_DOWNLOAD_TEMPLATE = 'ImportCSVOrDownLoadTemplate';
    public static readonly IMPORT_TEMPLATE = 'ImportTemplate';
    public static readonly EXPORT_TEMPLATE = 'ExportTemplate';
    public static readonly USER_PROFILE = 'UserProfile';
    public static readonly SITE_APP_SELECTOR = 'SiteAppSelector';
    public static readonly SLIDER = 'Slider';
    public static readonly SLIDER_PAGINATOR = 'SliderPaginator';
    public static readonly ALERT = 'Alert'
    public static readonly UNIQUE_CODE_GENERATOR = 'UniqueCodeGenerator'
    public static readonly USERS_AND_ROLES = 'UsersAndRoles'
    /* leaflet */
    public static readonly LEAFLET_MAP = '2D Map';
    public static readonly LEAFLET_STATIC_LAYER = '2D Static Layer';
    public static readonly LEAFLET_DYNAMIC_LAYER = '2D Dynamic Layer';
    public static readonly LEAFLET_MARKER_LIST = 'Marker List';
    public static readonly LEAFLET_SINGLE_MARKER = 'Single Marker';

    public static readonly SINGLE_LEAFLET_CIRCLE = 'Single Circle';
    public static readonly SINGLE_LEAFLET_POLYGON = 'Single Polygon';
    public static readonly LEAFLET_SINGLE_POPUP = 'Single Popup';
    public static readonly LEAFLET_POLYLINE = 'Polyline';
    public static readonly SINGLE_LEAFLET_HEAT_MAP = 'Single HeatMap';
    public static readonly LEAFLET_ANIMATION_MARKER = 'AnimationMarker';
    public static readonly LEAFLET_ENTITY_LIST = '2D Entity List';
    public static readonly LEAFLET_ENTITY_REAL_TIME_LIST = '2D Entity Real Time List';
    public static readonly LEAFLET_ENTITY_LIST_ITEM = '2D Entity List Item';
    public static readonly LEAFLET_POPUP_CONTROL = "Popup";
    public static readonly LEAFLET_GEOJSON_FILE_CONTROL = "GeoJSON Loader";
    public static readonly LEAFLET_TIFF_FILE_CONTROL = "Tiff Loader";
    public static readonly SINGLE_LEAFLET_LABEL = '2D Single Label'
    public static readonly ROUTE_DETAILS = 'Route Details';

    /* Cesium */
    // public static readonly CESIUM_MAP = 'Cesium Map';
    // public static readonly CESIUM_VIEWER = 'C-Viewer';
    // public static readonly CESIUM_LABEL = 'Label';
    // public static readonly CESIUM_LINE = 'Line';
    // public static readonly CESIUM_CIRCLE = 'Circle';
    // public static readonly CESIUM_HEATMAP = 'Heat map';
    // public static readonly CESIUM_MODEL = 'Cesium Model';
    // public static readonly CESIUM_HIGHLIGHT = 'Cesium Highlights';
    // public static readonly CESIUM_BILLBOARD = 'Cesium Billboard';
    // public static readonly CESIUM_TILES = 'C-Tiles';
    // public static readonly CESIUM_POLYGON = 'Cesium Polygon';

    /* 3D */
    public static readonly THREE_D_VIEWER = '3D-Viewer';
    public static readonly THREEJS_VIEWER = '3-Viewer';
    public static readonly THREE_D_BILL_BOARD = 'ThreeJs Billboard';
    public static readonly THREE_D_ELEMENTS = '3-Object Control';

    // public static readonly THREEJS_POPUP = '3-Object Control';

    /* Charts */
    public static readonly BAR_CHARTS = 'Horizontal/Vertical Bar';
    public static readonly BAR_CHARTS_LIST = 'Horizontal/Vertical Bar';
    public static readonly LINE_AREA_CHARTS = 'Single Line/Area';
    public static readonly LINE_AREA_CHARTS_LIST = 'Line/Area';
    public static readonly PROGRESS_CHART_LIST = 'Progress List';
    public static readonly PIE_CHART = 'Pie';
    public static readonly PIE_CHART_LIST = 'Pie';
    public static readonly PROGRESS_CHART = 'Progress Chart';
    public static readonly CUSTOM_PROGRESS_CHART = 'Custom Progress Chart';
    public static readonly BAR_LINE_CHART = 'Bar Line';
    public static readonly STACKED_VERTICAL_BAR_LINE_CHART = 'Stacked Vertical Line';
    public static readonly GROUPED_BAR_CHARTS = 'Grouped Bar';
    public static readonly GROUPED_LIST_BAR_CHARTS = 'Grouped Bar';
    public static readonly GUAGE_CHART = 'Guage Chart';
    public static readonly HEAT_MAP = 'Heat Map';
    public static readonly BUBBLE_CHART = 'Bubble Chart';
    public static readonly NUMBER_CARDS = 'Number Cards';
    public static readonly TREE_MAP = 'Tree Map';
    public static readonly ORGANOGRAM_CHART = 'Organogram Chart';
    public static readonly POLAR_RADAR_CHART = 'Polar/Radar Chart';
    public static readonly STACKED_TRIANGLE_CHART = 'Stacked Triangle Chart';
    public static readonly STACKED_TRIANGLE = 'Stacked Triangle';


    /** HTML GRID */
    public static readonly HYPERLINK = 'Hyperlink';
    public static readonly FORM = 'Form';
    public static readonly FORM_LIST = 'FormList';
    public static readonly USER_TASK_FORM = 'Usertask Form';
    public static readonly FORM_VIEW_UPDATE = 'Form View/Update';
    public static readonly THERMAL_PRINTER = 'Printer';
    public static readonly DRAW_LINE = 'Draw line';
    public static readonly HIDDEN_CONTROL = 'Hidden Control';
    public static readonly HIDDEN_LIST_CONTROL = 'Hidden List Control';
    public static readonly DIV_LIST_CONTROL = 'Div List Control';

    public static readonly CUSTOM_USERTASK_FORM = 'Custom Usertask Form';
    public static readonly CUSTOM_POPUP = 'Custom Popup';
    public static readonly EMBEDDED_FORM = 'Embedded Form';
    public static readonly FORM_CONTAINER_CONTROL = 'Form Container Control';
    public static readonly USER_TASK_CONTAINER_CONTROL = 'User Task Container Control';
    public static readonly FIELD_CONTAINER_CONTROL = 'Field Container Control';
    public static readonly FORM_ACTION_BUTTON = 'Form Action Button';
    public static readonly QR_CODE_GENERATOR = 'QR Code Generator';
    public static readonly THREE_D_ENVIRONMENT = '3D Environment';
    public static readonly THREE_D_SPATIAL_ENVIRONMENT = '3D Spatial Environment';
    public static readonly THREE_D_STATIC_LIST = '3D Static List';
    public static readonly THREE_D_ENTITY_LIST = '3D Entity List';
    public static readonly THREE_D_ENTITY_REAL_TIME_LIST = '3D Entity Real Time List';
    public static readonly THREE_D_ENTITY_LIST_ITEM = '3D Entity List Item';
    public static readonly THREE_D_FONT_CONTROL = '3D Font-Viewer';
    public static readonly THREE_D_GROUP_CONTROL = '3D Group Control';
    public static readonly THREE_D_CUBOID_GEOMETRY_CONTROl = '3D Cuboid Geometry Control';
    public static readonly THREE_D_CIRCLE_GEOMETRY_CONTROl = '3D Circle Geometry Control';
    public static readonly THREE_D_CONE_GEOMETRY_CONTROl = '3D Cone Geometry Control';
    public static readonly THREE_D_CYLINDER_GEOMETRY_CONTROl = '3D Cylinder Geometry Control';
    public static readonly THREE_D_DODECAHEDRON_GEOMETRY_CONTROl = '3D Dodecahedron Geometry Control';
    public static readonly THREE_D_ICOSAHEDRON_GEOMETRY_CONTROl = '3D Icosahedron Geometry Control';
    public static readonly THREE_D_LATHE_GEOMETRY_CONTROl = '3D Lathe Geometry Control';
    public static readonly THREE_D_OCTAHEDRON_GEOMETRY_CONTROl = '3D Octahedron Geometry Control';
    public static readonly THREE_D_PLANE_GEOMETRY_CONTROl = '3D Plane Geometry Control';
    public static readonly THREE_D_RING_GEOMETRY_CONTROl = '3D Ring Geometry Control';
    public static readonly THREE_D_SPHERE_GEOMETRY_CONTROl = '3D Sphere Geometry Control';
    public static readonly THREE_D_TETRAHEDRON_GEOMETRY_CONTROl = '3D Tetrahedron Geometry Control';
    public static readonly THREE_D_TORUS_GEOMETRY_CONTROl = '3D Torus Geometry Control';
    public static readonly THREE_D_TORUS_KNOT_GEOMETRY_CONTROl = '3D TorusKnot Geometry Control';
    public static readonly THREE_D_TUBE_GEOMETRY_CONTROl = '3D Tube Geometry Control';
    public static readonly THREE_D_EXTRUDE_GEOMETRY_CONTROl = '3D Extrude Geometry Control';
    public static readonly THREE_D_SHAPE_GEOMETRY_CONTROl = '3D Shape Geometry Control';
    public static readonly THREE_D_LINE_GEOMETRY_CONTROl = '3D Line Geometry Control';

    /** SECTIONS */
    public static readonly CUSTOM_ACCORDION_CONTROL = 'Custom Accordion Control';
    public static readonly CUSTOM_TAB_CONTROL = 'Custom Tab Control';
    public static readonly CUSTOM_DATA_LIST = 'Custom Data List';
    public static readonly DATA_GRID = 'Data Grid';

    /*
    Menu Controls
    */
    public static readonly STANDARD_MENU = 'Standard Menu';
    public static readonly FLAT_MENU = 'Flat Menu';
    public static readonly COLLAPSIBLE_MENU = 'Collapsible Menu';
    public static readonly NAV = 'Nav';
    public static readonly CORNER_RADIAL = 'Corner Radial';
    public static readonly HORIZONTAL_MENU = 'Horizontal Menu';
    public static readonly FLOATING_CIRCULAR = 'Floating Circular';
    public static readonly CUSTOM_MENU = 'Custom Menu'

    public static readonly CONATINER_EXPORT_PDF = 'Container Export Pdf'

    /*
   REPORT Controls
   */
    public static readonly REPORT_LAYOUT = 'Report Layout';
    public static readonly REPORT_LAYOUT_CONTAINER = 'Report Layout Container';
    public static readonly REPORT_HEADING_CONTROL = 'Report Heading Control';
    public static readonly REPORT_FOOTER_CONTROL = 'Report Footer Control';
    public static readonly REPORT_DATA_GRID = 'Report Data Grid';
    public static readonly REPORT_LABEL = 'Report Label';
    public static readonly REPORT_LIST = 'Report List';
    public static readonly REPORT_GROUP_LIST = 'Report Group List';
    public static readonly REPORT_ICONS = 'Report Icon';
    public static readonly REPORT_IMAGE = 'Report Image';
    public static readonly REPORT_BUTTON = 'Report Button';
    public static readonly REPORT_CONTAINER = 'Report Container';
    public static readonly REPORT_SELECT = 'Report Droplist';
    public static readonly REPORT_MULTISELECT = 'Report MultiSelect';
    public static readonly REPORT_DATE = 'Report Date';
    public static readonly REPORT_DATETIME = 'Report DateTime';
    public static readonly REPORT_DATERANGE = 'Report DateRange';
    public static readonly REPORT_YEAR_MONTH = 'ReportYearMonth';
    public static readonly REPORT_HEADER = 'Report Header';

    public static readonly REPORT_CONTAINER_EXPORT_PDF = 'Report Container Export Pdf'
    public static readonly REPORT_BAR_CHARTS = 'Report Horizontal/Vertical Bar';
    public static readonly REPORT_BAR_CHARTS_LIST = 'Report Horizontal/Vertical Bar List';
    public static readonly REPORT_LINE_AREA_CHARTS = 'Report Line/Area';
    public static readonly REPORT_LINE_AREA_CHARTS_LIST = 'Report Line/Area List';
    public static readonly REPORT_PIE_CHART_LIST = 'Report Pie List';
    public static readonly REPORT_BAR_LINE_CHART = 'Report Bar Line Chart';
    public static readonly REPORT_GROUPED_BAR_CHARTS = 'Report Grouped Bar Chart';
    public static readonly REPORT_GROUPED_LIST_BAR_CHARTS = 'Report Grouped List Bar Chart';
    public static readonly REPORT_STACKED_TRIANGLE_CHART = 'Report Stacked Triangle Chart';
    public static readonly REPORT_STACKED_VERTICAL_BAR_LINE_CHART = 'Report Stacked Vertical Line Chart';
}
