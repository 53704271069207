export class ControlCompanionEventsTopics {
  static SHOW_COMPANION_REQUEST = "SHOW_COMPANION_REQUEST";
  static DESTROY_COMPANION = "DESTROY_COMPANION";
  static ON_CONTROL_DATA_UPDATED = "ON_CONTROL_DATA_UPDATED";

  static LAYER_CONTROL_DROPPED(instanceId: string) {
    return `LAYER_CONTROL_DROPPED_${instanceId}`;
  }

}
