import { Injectable } from '@angular/core';
import configureMeasurements, { BestResult, Converter, Measure, UnitDescription } from 'convert-units';
import allMeasures, { AllMeasures, AllMeasuresSystems, AllMeasuresUnits } from 'convert-units/definitions/all';
import area from 'convert-units/definitions/area';
import length from 'convert-units/definitions/length';
import mass from 'convert-units/definitions/mass';
import speed from 'convert-units/definitions/speed';
import time from 'convert-units/definitions/time';
import volume from 'convert-units/definitions/volume';
import { RtNone, RtOption, RtSome } from '../utils/option-helper';


@Injectable()
export class UOMReferenceService {

  private get allUnis(): UnitDescription[] {
    return this._allUnits.map(u => u).getOrElse(() => this.initAllUnits())
  }

  private initAllUnits(): UnitDescription[] {
    const allUnis = this.converter().list();
    this._allUnits = RtSome(allUnis);
    return allUnis;
  }
  private _allUnits: RtOption<UnitDescription[]> = RtNone();

  converter(value?: number): Converter<AllMeasures, AllMeasuresSystems, AllMeasuresUnits> {
    const convert = configureMeasurements(allMeasures)(value);
    return convert;
  }

  /**
   *
   * @returns ['metric', 'SI', 'imperial', 'bits', 'bytes', 'unit']
   */
  systems(): string[] {
    const systems = this.converter().list().map(item => item.system)
      .filter((value, index, self) => self.indexOf(value) === index)
    return systems;
  }

  /**
   * Returns available systems to which the measure belongs to
   * @param measure measure names to filter systems
   * @returns
   */
  systemsByMeasure(measure: string): string[] {
    const systems = this.allUnis.filter(item => item.measure === measure).map(item => item.system)
      .filter((value, index, self) => self.indexOf(value) === index)
    return systems;
  }

  /**
   *
   *
   * @returns {string[]} ['acceleration', 'angle', 'apparentPower', 'area', 'charge', 'current', 'digital',
   *  'each', 'energy', 'force', 'frequency', 'illuminance', 'length', 'mass', 'pace', 'partsPer', 'pieces', 'power', 'pressure',
   * 'reactiveEnergy', 'reactivePower', 'speed', 'temperature', 'time', 'voltage', 'volume', 'volumeFlowRate']
   * @memberof UOMHelper
   */
  measures(): string[] {
    return this.converter().measures()
  }

  list(measureName: AllMeasures): UnitDescription[] {
    const list = this.converter().list(measureName)
    return list;
  }

  /**
   * Returns list of available units that march measureName and systemName
   * @param measureName measure name
   * @param systemName system name
   * @returns
   */
  unitsByMeasureAndSystem(measureName: string, systemName: string): UnitDescription[] {
    const units = this.allUnis.filter(item => item.measure === measureName && item.system === systemName);
    return units;
  }


  /**
   * Returns match unit for the unitAbbr
   * @param unitAbbr unit abbreviation
   * @returns
   */
  unit(unitAbbr: AllMeasuresUnits): UnitDescription {
    const unit = this.converter().describe(unitAbbr);
    // const unit = this.allUnis.find(item => item.abbr === unitAbbr);
    return unit;
  }

  /**
   *
   * Returns the abbreviated measures that the value can be
   * converted to.
   *
   * @param {string} forMeasure e.g. time
   * @returns {string[]} e.g. ['ns', 'mu', 'ms', 's', 'min', 'h', 'd', 'week', 'month', 'year']
   * @memberof UOMHelper
   */
  possibilitiesByMeasure(forMeasure: AllMeasures): AllMeasuresUnits[] {
    const possibilities = this.converter().possibilities(forMeasure)
    return possibilities;
  }

  /**
   *
   *
   * @param {string} abbr e.g. ms
   * @returns {string[]} e.g. ['ns', 'mu', 'ms', 's', 'min', 'h', 'd', 'week', 'month', 'year']
   * @memberof UOMReferenceService
   */
  possibilitiesByUnitAbbr(abbr: AllMeasuresUnits): AllMeasuresUnits[] {
    const possibilities = this.converter().from(abbr).possibilities();
    // const possibilities = this.converter().possibilities(abbr);
    return possibilities;
  }

  defaultRecommendedMeasure(): Measure<string, string>[] {
    const recTypes = [
      volume,
      mass,
      length,
      area,
      time,
      speed
    ]
    return recTypes
  }

  //runtime usage while binding

  convert(value: number, from: AllMeasuresUnits, to: RtOption<AllMeasuresUnits>,
    options: RtOption<UOMOptions>) {
    if (to.isDefined)
      this.converter(value).from(from).to(to.get)
    else if (options.isDefined)
      this.converter(value).from(from).toBest(options.get)
  }

  convertDirect(value: number, from: AllMeasuresUnits, to: AllMeasuresUnits): number {
    return this.converter(value).from(from).to(to);
  }
  convertToBest(value: number, from: AllMeasuresUnits, options: UOMOptions): RtOption<BestResult | null> {
    return RtSome(this.converter(value).from(from).toBest(options));
  }

}

export type UOMOptions = {
  exclude?: AllMeasuresUnits[] | undefined;
  cutOffNumber?: number | undefined;
  system?: AllMeasuresSystems | undefined;
}