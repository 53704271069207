import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'den-w-control-render-progress',
  templateUrl: './control-render-progress.component.html',
  styleUrls: ['./control-render-progress.component.scss']
})
export class ControlRenderProgressComponent implements OnInit {

  onCancelClick = (event: MouseEvent) => this.#onContinueClick(event);
  constructor(private dialogRef: MatDialogRef<ControlRenderProgressComponent>) { }

  ngOnInit(): void {
  }

  #onContinueClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dialogRef.close();
  }
}
