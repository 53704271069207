export enum ImageControlEnum {
    IMAGE = 'image',
    UPLOADIMAGE = 'uploadImage',
    BADGE_VALUE = 'badgeValue',
    BADGE_SIZE = 'badgeSize',
    SELECT_BADGE_COLOR = 'Select badge color',
    SELECT_BADGE_SIZE = 'Select badge size',
    SHOW_BADGE = 'Show badge',
    SELECT_IMAGE_SHAPE = 'Select image shape',
    SHOW_DEFAULT_IMAGE = 'showDefaultImage',
    BADGE = 'badge',
    ENLARGE_IMAGE_ON_CLICK = 'Enlarge Image on Click',
}
