import { IonicSafeString } from "@ionic/angular";
import { RtOption } from "../../../../../../utils/option-helper";
import { BackendAction } from "../../event-actions/backend/backend-action-models";
import { NavigateAction } from "../../event-actions/navigation/navigation-action-models";
import { EventAction } from "../../event-store";

export enum MobiPopupType {

  //https://ionicframework.com/docs/api/modal#sheet-modal
  MODAL = "modal",
  // CARD_MODAL = "card_modal",
  // BOTTOM_SHEET = "sheet_modal",

  //https://ionicframework.com/docs/api/alert
  ALERT = "alert",

  //https://ionicframework.com/docs/api/toast
  TOAST = "toast"

}

export type MobiPopupAction = {
  popupType: MobiPopupType,
  popupControlName: string,
  popupSettings: MobiPopup,
} & EventAction;

export interface MobiPopup {
}

export type ModalPopup = {
  showBackdrop?: boolean;
  backdropDismiss?: boolean;
  swipeToClose?: boolean;

  //bottom sheet
  breakpoints?: number[];
  initialBreakpoint?: number;
  backdropBreakpoint?: number;
  dialogTitle?: string;
  width?: string;
  height?: string;
} & MobiPopup & EventAction;


enum PopupButtonType {
  CLOSE = 'close',
  BACKEND_ACTION = "backend_action"
}

export type PopupUserButton = {
  text: string;
  role?: 'cancel' | 'destructive' | string;
  beAction: RtOption<BackendAction>;
  navigation: RtOption<NavigateAction>;
}

export type AlertAction = {
  header?: string;
  subHeader?: string;
  message?: string | IonicSafeString;
  buttons: PopupUserButton[]
} & MobiPopup

export type ToastAction = {
  header?: string;
  message?: string | IonicSafeString;
  buttons: PopupUserButton[]
  duration?: number;
  position?: 'top' | 'bottom' | 'middle';
  hPosition?: 'start' | 'center' | 'end' | 'left' | 'right';
  color?: 'primary' | 'secondary' | 'tertiary' | 'light' | 'medium' | 'dark' | 'success' | 'warning' | 'danger';
  dismissButton?: boolean
  retrieveClose:false
} & MobiPopup
