import { ChangeDetectorRef, ElementRef, Injector, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { RtOption } from '../../../utils/option-helper';
import { UsableAttributeValue } from '../../attributes/core/attribute-instance';
import { EventDataBinder } from '../../attributes/event-data-binder/event-data-binder-attribute';
import { EventDataMapper } from '../../attributes/event-data-mapper/event-data-mapper-attribute';
import { Control } from '../../controls/core/control';
import { ControlPropertyDefinitionValue } from '../../controls/core/control-instance';
import { ControlInstanceDraftHelper } from '../../controls/core/control-instance-drafts-models';
import { ControlAccessability, ControlVisibility } from '../../controls/core/control-model';
import { CtrlEventRaised } from '../../controls/core/event/control-event';
import { DsResult, DsResultValue } from '../../data-source/data-source-result-entities';
import { EmptyChildCommonControlRenderService, ICommonControlRenderService } from '../../ide/services/common-control-render-service';
import { IControlInfo, SimpleAngularControlInfo } from '../../ide/services/parent-control';
import { AngularContainerControlHelper } from '../helper/angular-container-control-helper';
import { CSSPropertyNameConstants } from '../../constants/css-property-name-constants';
import { ControlTypeConstant } from '../../constants/control-type-constants';

export abstract class AngularControl<CI extends IControlInfo,
  R extends ICommonControlRenderService<CI> = EmptyChildCommonControlRenderService> extends Control<DsResult, CI, R> {
  style: any;
  private elementRef: ElementRef;
  constructor(injector: Injector) {
    super(injector, DsResult);
    this.elementRef = injector.get(ElementRef);
    this.viewContainerRef = injector.get(ViewContainerRef);
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
  }

  dataSource: any;
  CONFIG_BINDINGS: any = {};
  propertyDefinitions: ControlPropertyDefinitionValue[];
  tooltipClass: string;
  tooltipStyles: {};
  applyPropertyDefinitions(_propertyDefinitions: ControlPropertyDefinitionValue[]): void {
    this.propertyDefinitions = _propertyDefinitions;
  }

  onControlEventReceivedFn(_event: CtrlEventRaised): void {
    // throw new Error('Method not implemented.');
    // do nothing;
  }

  tryRemoveChildControlsIfAny(): void {
    // throw new Error("Method not implemented.");
  }

  reApplyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
    this.CONFIG_BINDINGS = {};
    this.applyConfigurationAttributes(configurationAttributeValues)
  }
  getEventDataMapperValue(eventDataMapper: EventDataMapper, data: RtOption<DsResult>, controlPropertyName: string) {
    const eventDataMapperFieldName = eventDataMapper.fieldMapper.find(fm => fm.controlPropertyName === controlPropertyName)?.fieldName;
    const dsResultValue = data?.get?.data.find(d => d.fieldName === eventDataMapperFieldName) as DsResultValue;
    if (eventDataMapperFieldName === dsResultValue?.fieldName) {
      return dsResultValue?.referenceData.isDefined ? dsResultValue?.referenceData.get : dsResultValue?.value;
    }
    return null;
  }
  getEventDataBindingValue(eventDataMapper: EventDataBinder, data: RtOption<DsResult>, controlPropertyName: string) {
    const eventDataMapperFieldName = eventDataMapper.fieldMapper.find(fm => fm.controlPropertyName === controlPropertyName)?.fieldName;
    const dsResultValue = data?.get?.data.find(d => d.fieldName === eventDataMapperFieldName) as DsResultValue;
    if (eventDataMapperFieldName === dsResultValue?.fieldName) {
      return dsResultValue?.referenceData.isDefined ? dsResultValue?.referenceData.get : dsResultValue?.value;
    }
    return null;
  }
  applyConfigurationAttributes(configurationAttributeValues: UsableAttributeValue<unknown>[]): void {
    configurationAttributeValues.forEach(i => {
      this.CONFIG_BINDINGS[i.name] = i.value;
      this[i.name] = i.value;
    });
  }

  constructControlInfo(): SimpleAngularControlInfo {
    this.tooltipClass = ControlInstanceDraftHelper.getCssClassName(this.controlInstance);
    return new SimpleAngularControlInfo(this.viewContainerRef, this.controlInstance);
  }

  getControlInfo(): Observable<SimpleAngularControlInfo> {
    return new Observable((observer) => {
      observer.next(this.constructControlInfo());
    });
  }

  reApplyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]) {
    this.style = undefined;
    this.applyCSSAttributes(cssAttributeValues);
  }

  applyCSSAttributes(cssAttributeValues: UsableAttributeValue<unknown>[]) {
    if (this.style) {
      this.applyGridAreaAttribute(cssAttributeValues);
      this.updateCssAttributeValue(cssAttributeValues);
    } else {
      this.applyGridAreaAttribute(cssAttributeValues);
      this.style = AngularContainerControlHelper.convertToJsonObj(cssAttributeValues);
    }
    this.applyCSSAttributesUsingClass(this.style);
    this.applyTooltipStyles(cssAttributeValues);
  }

  private applyTooltipStyles(cssAttributeValues) {
    const bgColor = cssAttributeValues.find(obj => obj.name === "tooltip-background-color");
    const color = cssAttributeValues.find(obj => obj.name === "tooltip-foreground-color");
    const tooltipWidth = cssAttributeValues.find(obj => obj.name === CSSPropertyNameConstants.TOOLTIP_WIDTH);
    // if (bgColor || color) {
    if (this.tooltipStyles) {
      cssAttributeValues.forEach(css => {
        if (css.name == 'tooltip-background-color') {
          this.tooltipStyles['background-color'] = css.value;
        } else if (css.name == 'tooltip-foreground-color') {
          this.tooltipStyles['color'] = css.value;
        }
      });
    } else {
      this.tooltipStyles = { 'background-color': bgColor?.value, 'color': color?.value };
    }
    const tooltipWidthValue = tooltipWidth ? tooltipWidth.value : 100;
    this.removeAndAddTootipStyle(this.tooltipStyles, tooltipWidthValue);
  }
  //}

  removeAndAddTootipStyle(tooltipStyles: {}, tooltipWidthValue: number) {
    const styleId = this.controlInstance.instanceId + `-tooltip-style`;
    const findStyle = document.getElementById(styleId);
    if (findStyle != null) {
      document.head.removeChild(findStyle);
    }
    const style = document.createElement('style');
    style.id = styleId;
    const styleProperty = '{--mdc-plain-tooltip-container-color:' + tooltipStyles['background-color'] + '!important;--mdc-plain-tooltip-supporting-text-color:' + tooltipStyles['color'] + '!important;width:' + tooltipWidthValue + 'px;display:block !important; overflow:visible !important;white-space:normal !important}';
    style.innerHTML = '.' + ControlInstanceDraftHelper.getCssClassName(this.controlInstance) + styleProperty + '.' + ControlInstanceDraftHelper.getCssClassName(this.controlInstance) + ' .mdc-tooltip__surface{max-width:' + tooltipWidthValue + 'px !important}';
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  applyCSSAttributesUsingClass(cssAttributeValues: Object) {
    const findStyle = document.getElementById(this.controlInstance.instanceId + `-style`);
    if (findStyle != null) {
      document.head.removeChild(findStyle);
    }
    const style = document.createElement('style');
    style.id = this.controlInstance.instanceId + `-style`;
    // style.innerHTML = '.' + this.helper.getCssClassName(this.controlInstance) + ' {  ' + cssAttributeValues.map(a => a.name + ':' + a.value).join('; ') + '; }';

    let txt = "";
    for (let x in cssAttributeValues) {
      txt += `${x} : ${cssAttributeValues[x]}; `;
    }

    style.innerHTML = `.${this.helper.getCssClassName(this.controlInstance)} {  ${txt} }`;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  applyGridAreaAttribute(cssAttributeValues: UsableAttributeValue<unknown>[]) {
    const gridAreaAttribute = cssAttributeValues.find(a => a.name === "grid-area");
    if (gridAreaAttribute && this.elementRef) {
      this.elementRef.nativeElement.style["grid-area"] = gridAreaAttribute.value;
    }
  }

  updateCssAttributeValue(cssAttributeValues: UsableAttributeValue<unknown>[]) {
    cssAttributeValues.forEach(css => {
      this.style[css.name] = css.value;
    });
  }

  private displayValue: string = "block";
  applyVisibility(visibility: ControlVisibility) {
    //console.error(`applyVisibility(${visibility}) not implemented for ${this.controlInstance.controlName} control`);
    if (visibility === ControlVisibility.HIDE) {
      const currentDisplayValue = this.elementRef.nativeElement.style.display;
      if (currentDisplayValue && currentDisplayValue !== "none") {
        this.displayValue = currentDisplayValue;
      }
      this.elementRef.nativeElement.style.display = 'none';
    } else if (visibility === ControlVisibility.SHOW) {
      this.elementRef.nativeElement.style.display = this.displayValue;
    }
    else {
      console.error(`Unknown visibility ${visibility}  for ${this.controlInstance.controlName} control`);
    }
  }

  applyAccessability(accessability: ControlAccessability) {
    //Disable will only work for button/fieldset/input/select/textarea
    if (accessability === ControlAccessability.DISABLE) {
      this.elementRef.nativeElement.disabled = true;
       // commented below code because light color is applied for the behaviour/conditon based controls -https://app.clickup.com/t/86cv8du09
      // if (this.descriptor.controlGroup === ControlTypeConstant.CONTAINER) {
      //   this.elementRef.nativeElement.classList.add('rt-disable');
      // }
    } else if (accessability === ControlAccessability.ENABLE) {
      this.elementRef.nativeElement.disabled = false;
      // if (this.descriptor.controlGroup === ControlTypeConstant.CONTAINER) {
      //   this.elementRef.nativeElement.classList.remove('rt-disable');
      // }
    } else {
      console.error(`Unknown accessability ${accessability} for ${this.controlInstance.controlName} control`);
    }
  }
}
