<div class="rt-mt-10">
    <h3 class="rt-px-20">Select the Site & App</h3>

    <div mat-dialog-content>
        <div class="row rt-mx-10 container">
            <div class="col-md-6 rt-pl-0 rt-pr-5">
                <label class="rt-bold-2x rt-show rt-pt-10">SITES</label>
                <ul *ngIf="sites.length" class="list-wrapper rt-mt-10">
                    <li class="list rt-border rt-cursor-pointer rt-mb-5 rt-border-radius-9"
                        *ngFor="let site of sites ; let i = index" (click)="onSelectingSite(site)">
                        <div class="rt-clear list-item"
                            [ngClass]="{ 'active': (site?.siteCode === selectedSite?.siteCode)}">
                            <div class="row rt-items-center">
                                <div class="rt-p-10">
                                    <label>{{ site?.name || "NA" }}</label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="rt-red-color" *ngIf="sites.length === 0">No Sites Mapped to this User. Please contact
                    Administrator!
                </div>
            </div>
            <div class="col-md-6 rt-pl-0 rt-pr-5">
                <label class="rt-bold-2x rt-show rt-pt-10">APPS</label>
                <ul *ngIf="apps.length" class="list-wrapper rt-mt-10">
                    <li class="list rt-border rt-cursor-pointer rt-mb-5 rt-border-radius-9"
                        *ngFor="let app of apps ; let j = index" (click)="onSelectingApp(app)">
                        <div class="rt-clear list-item"
                            [ngClass]="{ 'active': (app?.appCode === selectedApp?.appCode)}">
                            <div class="row rt-items-center rt-p-5">
                                <img class="rt-dimension-30 rt-flex-items-auto"
                                    [src]=" app?.clientLogo || './assets/img/no_image.png'">
                                <div class="rt-pl-10 rt-flex-items rt-text-ellipsis">
                                    <label>{{ app?.name || "NA" }}</label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="rt-red-color" *ngIf="apps.length === 0">No Apps Mapped to this User. Please contact
                    Administrator!
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <div class="row">
            <div class="col-md-12 rt-buttons rt-text-right">
                <button mat-raised-button class="rt-mr-5" [mat-dialog-close]="false">Cancel</button>

                <button mat-raised-button color="primary" (click)="onContinue()"
                    [disabled]="disableButton">CONTINUE</button>
            </div>
        </div>
    </div>
</div>