import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'rt-modal-dialog',
  templateUrl: './modal-dialog.component.html',
})
export class ModalDialogComponent implements OnInit, OnDestroy {
  @Input() modalMaxMinButtons?: boolean;
  private readonly maxDialogClass = 'max-modal-dialog-wrapper';
  private readonly maxIcon = 'svg-icons-maximize';
  private readonly minIcon = 'svg-icons-minimize';

  constructor() { }

  ngOnInit() {
    if (this.modalMaxMinButtons === undefined) {
      this.modalMaxMinButtons = false;
    } else {
      this.modalMaxMinButtons = true;
    }
  }

  modalMaximize(event) {
    // toggle maximize icon to minimize icon and changed width and height of modal dailog with max-modal-dailog-wrapper class
    // if maximize
    if (event.target.className.indexOf(this.maxIcon) > 0) {
      event.target.classList.remove(this.maxIcon); // removed max icon
      event.target.classList.add(this.minIcon);   //  toggle with min icon
      document.body.classList.add(this.maxDialogClass); // added max-modal-dailog-wrapper class to body
    } else { // if minimize
      event.target.classList.remove(this.minIcon); // removed min icon
      event.target.classList.add(this.maxIcon); //  toggle with max icon
      document.body.classList.remove(this.maxDialogClass); // removed max-modal-dailog-wrapper class to body
    }
  }

  ngOnDestroy() {
    // removed max class from body
    document.body.classList.remove(this.maxDialogClass);

  }
}
