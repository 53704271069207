import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICompanionSubMixin } from '../../companion-mixin-menu';


export interface CompanionMenuItem {
  displayName: string;
  iconName: string;
  enabled: boolean;
  children?: CompanionMenuItem[];
}

@Component({
  selector: 'lib-pb-companion-menu-item',
  templateUrl: './pb-companion-menu-item.component.html',
  styleUrls: ['./pb-companion-menu-item.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PbCompanionMenuItemComponent implements OnInit {

  // @Input() menuItems: ICompanionMixinMenu[];
  private allMenuItems: ICompanionSubMixin[] = [];
  menuItems: CompanionMenuItem[] = [];
  @ViewChild('childMenu', { static: true }) public childMenu: any;

  constructor() { }

  ngOnInit(): void {
  }

  public groupMenuItemsAndRender(menuItems: ICompanionSubMixin[]) {
    this.destroyPreviousSubMixins();
    this.allMenuItems = menuItems;
    const menus: CompanionMenuItem[] = [];

    menuItems.forEach(m => {
      const groupName = m.groupName.isDefined ? m.groupName.get : undefined;
      const menuIndex = menus.findIndex(me => me.displayName === groupName);
      // const displayName = m.groupName.isDefined ? m.groupName.get : m.name;
      if (m.groupName.isDefined) {
        if (menuIndex >= 0) {
          menus[menuIndex].children = [...menus[menuIndex].children, { displayName: m.name, iconName: m.icon, enabled: m.isEnabled() }];
        } else {
          menus.push({ displayName: m.groupName.get, iconName: m.icon, enabled: false, children: [{ displayName: m.name, iconName: m.icon, enabled: m.isEnabled() }] });
        }
      } else {
        menus.push({ displayName: m.name, iconName: m.icon, enabled: m.isEnabled(), children: [] });
      }
    });

    this.menuItems = menus;

  }

  private destroyPreviousSubMixins() {
    if (this.allMenuItems?.length > 0) {
      this.allMenuItems.forEach(subMixin => {
        if (subMixin.keepAlive === undefined || subMixin.keepAlive === false) {
          subMixin.onDestroy();
        }
      });
    }
  }

  public menuSelect(name: string) {
    const selectMenu = this.allMenuItems.find(m => m.name === name);
    if (selectMenu) {
      selectMenu.toggle();
    }
  }

  public menuClick($event: MouseEvent) {
    $event.stopPropagation();
  }
}
