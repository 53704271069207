import { FormlyControlTypes, SchemaControlType } from "projects/den-core/base-models";
import { EntitySchemaFieldContainer } from "projects/den-core/schema-models";
import { FormFieldOption } from "../constants/domain-modeller.constants";


export class ControlTypeFormlyRegistry {

    private static controls: Map<SchemaControlType, FormlyControlTypes> = new Map();

    constructor() {
    }


    static registerFormlyControls() {
        this.controls.set(SchemaControlType.TEXT_BOX, FormlyControlTypes.INPUT);
        this.controls.set(SchemaControlType.FOREIGN_KEY, FormlyControlTypes.SINGLE_SELECT);
        this.controls.set(SchemaControlType.DATE_TIME_PICKER, FormlyControlTypes.DATE_TIME);
        this.controls.set(SchemaControlType.DATE_PICKER, FormlyControlTypes.DATE);
        this.controls.set(SchemaControlType.FILE_UPLOAD, FormlyControlTypes.FILE_UPLOAD);
        this.controls.set(SchemaControlType.STATIC_SELECT, FormlyControlTypes.SINGLE_SELECT);
        this.controls.set(SchemaControlType.TIME_PICKER, FormlyControlTypes.TIME);
        this.controls.set(SchemaControlType.RADIO_BUTTON_LIST, FormlyControlTypes.RADIO_BUTTON);
        this.controls.set(SchemaControlType.CHECK_BOX_LIST, FormlyControlTypes.MULTI_CHECKBOX);
        this.controls.set(SchemaControlType.CHECK_BOX, FormlyControlTypes.CHECK_BOX);
        this.controls.set(SchemaControlType.GEO_FENCE, FormlyControlTypes.MAP);
        this.controls.set(SchemaControlType.GEO_LOCATION, FormlyControlTypes.LOCATION2D);
        this.controls.set(SchemaControlType.LOCATION3D, FormlyControlTypes.LOCATION3D);
        this.controls.set(SchemaControlType.COLOR_PICKER, FormlyControlTypes.COLOR_PICKER);
        this.controls.set(SchemaControlType.TEXT_AREA, FormlyControlTypes.TEXT_AREA);
        this.controls.set(SchemaControlType.RICH_TEXT, FormlyControlTypes.RICH_TEXT);
        this.controls.set(SchemaControlType.QUESTIONNAIRE, FormlyControlTypes.QUESTIONNAIRE);
        this.controls.set(SchemaControlType.RESOURCE_BROWSER, FormlyControlTypes.RESOURCE_BROWSER);
        this.controls.set(SchemaControlType.RESOURCE_BROWSER_3D, FormlyControlTypes.RESOURCE_BROWSER_3D);
    }

    static getformlyType(field: EntitySchemaFieldContainer): FormlyControlTypes {
        if (ControlTypeFormlyRegistry.isFkeyLazyLoaded(field)) {
            return FormlyControlTypes.SEARCHABLE_CONTROL;
        } else
            return this.controls?.get((field.entitySchemaField.controlType === SchemaControlType.API ? field.advancedSchemaFieldSetting?.apiSubFieldType : field.entitySchemaField.controlType) as unknown as SchemaControlType);
    }
    static getFormlyControlType(controlType: SchemaControlType): FormlyControlTypes {
        return this.controls.get(controlType);
    }


    static isFkeyLazyLoaded(field: EntitySchemaFieldContainer) {
        return field.entitySchemaField.controlType === SchemaControlType.FOREIGN_KEY && field.advancedSchemaFieldSetting?.attributes[FormFieldOption.LAZY_LOAD_ENABLED] && JSON.parse(field.advancedSchemaFieldSetting?.attributes[FormFieldOption.LAZY_LOAD_ENABLED]);
    }
}
