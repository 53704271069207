import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { EventService } from "../../../../providers/event-service/event.service";
import { Subscription } from "rxjs";
import { IDsResult } from "../../../data-source/data-source-result-entities";
import { CompanionMixinType, ControlCompanionMixinHolder, ControlRegistryProvider } from "../../../ide/services/control-registry-provider";
import { ChildCommandResolver } from "../child-rendered-event";
import { IControl } from "../control-model";
import { CompanionTriggerResolverService } from "./companion-triggers/companion-trigger-resolver.service";
import { ICompanionTriggerService } from "./companion-triggers/companion-trigger.service";
import { ControlCompanion, ControlMixin } from "./control-companion";
import { ControlCompanionEventsTopics } from "./control-companion-constants";
import { CompanionDataChangeCommand, CompanionRequestCommand, DestroyCompanionCommand } from "./control-companion-models";
import { CompanionMixinRegistryProvider, ControlAssistantMixinRegistryProvider } from "./mixins/companion-mixin";
import { SubMixinMenuManagerService } from "./services/sub-mixin-menu-manager/sub-mixin-menu-manager.service";
import { PageService } from "../../../services/page-service";
import { EventStoreWriterService } from "../event/event-store-writer.service";
import { AppToEditorMessagingService } from "../../../services/attribute-panel-bridging/control-attribute-panel-messaging.service";
import { DraftPageWriterService } from "../../../services/page/draft-page-writer-service";
import { ControlRenderProgressIndicatorService } from "../../../services/control-render-progress-indicator/control-render-progress-indicator.service";

@Injectable()
export class ControlCompanionManager {

  private companionMap: Map<string, CompanionComponentData> = new Map();
  private showCompanionSubscription: Subscription;
  private destroyCompanionSubscription: Subscription;
  private updateCompanionDataSubscription: Subscription;
  public renderer: Renderer2;

  constructor(private eventService: EventService, private controlCompanionRegistry: ControlRegistryProvider, private pageService: PageService, private companionMixinRegistryProvider: CompanionMixinRegistryProvider,
    private controlAssistantMixinRegistryProvider: ControlAssistantMixinRegistryProvider, private rendererFactory: RendererFactory2,
    private companionTriggerResolverService: CompanionTriggerResolverService, private subMixinMenuManagerService: SubMixinMenuManagerService,
    private eventStoreWriterService: EventStoreWriterService, private draftPageWriterService: DraftPageWriterService,
    private appToEditorMessagingService: AppToEditorMessagingService, private controlRenderProgressIndicatorService: ControlRenderProgressIndicatorService) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public init() {

    //1) Initialises the control companions on data received from the even service
    this.showCompanionSubscription = this.eventService.subscribe(ControlCompanionEventsTopics.SHOW_COMPANION_REQUEST, (cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>) => {
      if (!this.companionMap.get(cmd.control.id)) {
        this.createCompanion(cmd);
      }
    });

    //2) Destroy companion: Companion destroy can happen from multiple places e.g. esc key pressed, clicked outside or the global design on/off
    this.destroyCompanionSubscription = this.eventService.subscribe(ControlCompanionEventsTopics.DESTROY_COMPANION, (cmd: DestroyCompanionCommand<IDsResult, IControl<IDsResult>>) => {
      this.destroyCompanion(cmd);
    });


    //3) Updates the companion data
    this.updateCompanionDataSubscription = this.eventService.subscribe(ControlCompanionEventsTopics.ON_CONTROL_DATA_UPDATED, (cmd: CompanionDataChangeCommand) => {
      this.onCompanionDataUpdated(cmd);
    });
  }

  private onCompanionDataUpdated(cmd: CompanionDataChangeCommand) {
    const companionData = this.companionMap.get(cmd.controlInstance.instanceId);
    if (companionData) {
      companionData.companionInstance.onControlDataUpdated(cmd);
    }

  }
  destroy() {
    this.destroyAllCompanions();
    this.showCompanionSubscription?.unsubscribe();
    this.destroyCompanionSubscription?.unsubscribe();
    this.updateCompanionDataSubscription?.unsubscribe();
  }

  destroyAllCompanions() {
    this.companionMap.forEach((companionComponentData: CompanionComponentData) => {
      companionComponentData.companionInstance.destroy();
    });
    this.companionMap.clear();
  }

  destroyCompanion(cmd: DestroyCompanionCommand<IDsResult, IControl<IDsResult>>) {
    const companionComponentData: CompanionComponentData = this.companionMap.get(cmd.control.id);
    if (companionComponentData) {
      this.companionMap.delete(cmd.control.id);
      companionComponentData.companionInstance.destroy();
    }
  }

  private async createCompanion(cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>) {
    // 1) Resolve the companion trigger service
    const companionTriggerService = this.resolveCompanionTriggerService(cmd);

    //2) Create instance of the ControlCompanion. ControlCompanion manages the life cycle of all companion mixins
    const controlCompanion = new ControlCompanion(this.eventService, this.pageService, this.draftPageWriterService,
      companionTriggerService, this.subMixinMenuManagerService, this.controlCompanionRegistry, this.eventStoreWriterService,
      this.appToEditorMessagingService, this.controlRenderProgressIndicatorService);

    //3) Resolve the mixins applicable to the control using mixin registries (ControlRegistry, CompanionMixinRegistry and ControlAssistantMixinRegistry)
    const mixins = this.getMixins(cmd);

    //4) Initialise the companion with control mixins
    controlCompanion.init(cmd, mixins);

    //5) Add the control companion to the companion map. This is used to destroy the companion when the control is destroyed
    this.companionMap.set(cmd.control.id, { cmd: cmd, companionInstance: controlCompanion });
  }

  private resolveCompanionTriggerService(cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): ICompanionTriggerService {
    const controlType = cmd.control.descriptor.ctrlType;
    const companionTriggerService = this.companionTriggerResolverService.resolveCompanionTriggerService(controlType);
    return companionTriggerService;
  }


  private getMixins(cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): ControlMixin[] {
    const mixins: ControlMixin[] = [];

    //1) Resolve self mixins from ControlRegistry
    const controlMixinHolder: ControlCompanionMixinHolder = this.controlCompanionRegistry.getControlCompanionMixins(cmd.control.controlInstance.controlName)
      .find(holder => holder.mixinType === CompanionMixinType.SELF_MIXIN);
    if (controlMixinHolder) {
      mixins.push({ mixinHolder: controlMixinHolder.mixin, companionMixinType: CompanionMixinType.SELF_MIXIN });
    }

    // 2) Resolve control group mixins from control CompanionMixinRegistry
    const controlGroupName = cmd.control.descriptor.controlGroup;
    const companionMixinPromiseOption = this.companionMixinRegistryProvider.getCompanionMixin(controlGroupName);
    if (companionMixinPromiseOption.isDefined) {
      mixins.push({ mixinHolder: companionMixinPromiseOption.get, companionMixinType: CompanionMixinType.GROUP_MIXIN });
    }

    // 3) Resolve control assistant mixins from control AssistantMixinRegistry by control type e.g. Angular, Leaflet, ThreeJs
    const controlType = cmd.control.descriptor.ctrlType;
    const controlAssistantMixinPromiseOption = this.controlAssistantMixinRegistryProvider.getControlAssistantMixin(controlType);
    if (controlAssistantMixinPromiseOption.isDefined) {
      mixins.push({ mixinHolder: controlAssistantMixinPromiseOption.get, companionMixinType: CompanionMixinType.ASSISTANT_MIXIN });
    }

    // 4) Resolve control assistant mixins from ControlRegistry of type PARENT_MIXIN e.g. css grid provides mix to manage all its children
    const instanceProvider = ChildCommandResolver.resolveParentInstance(cmd.control.parentCmd);
    if (instanceProvider.isDefined) {
      //Find immediate parent control
      const parentControlName = instanceProvider.get.parentInstanceInfo.controlName;
      //Find mixins registered for the parent control
      const controlMixinHolder: ControlCompanionMixinHolder = this.controlCompanionRegistry.getControlCompanionMixins(parentControlName)
        .find(holder => holder.mixinType === CompanionMixinType.PARENT_MIXIN);
      if (controlMixinHolder) {
        mixins.push({ mixinHolder: controlMixinHolder.mixin, companionMixinType: CompanionMixinType.PARENT_MIXIN });
      }
    }

    return mixins;
  }

}

export type CompanionComponentData = { cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>, companionInstance: ControlCompanion<IDsResult, IControl<IDsResult>> };
