import { Directive, NgModule, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[denHost]'
})
export class DenHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }
}
@NgModule({
  declarations: [DenHostDirective],
  imports: [],
  exports: [DenHostDirective],
})
//@ts-ignore
export class DenHostDirectiveModule {

}
