import { Injectable } from "@angular/core";
import { SchemaControlType, SchemaSubControlType } from "projects/den-core/base-models";
import { FormControlRegistyProvider } from "./form-control-registry-provider";
import { FormFieldType } from "./form-field-type";
import { FieldTypeConfig } from "@ngx-formly/core";

@Injectable()
export class WebFormControlRegistry extends FormControlRegistyProvider {
    constructor() {
        super();
    }

    registerFormControls() {
        this.formControls.set(SchemaControlType.TEXT_BOX, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/input-type/input-type.component').then(c => c.InputTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.LOCATION3D, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/location-3d/location-3d.component').then(c => c.Location3DComponent) as Promise<FormFieldType<FieldTypeConfig>>;
        });
        this.formControls.set(SchemaControlType.DATE_PICKER, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/datepicker-type/datepicker-type.component').then(c => c.DatepickerTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.CHECK_BOX, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/check-box-type/check-box-type.component').then(c => c.CheckBoxTypeComponent) as Promise<FormFieldType<boolean>>;
        });
        this.formControls.set(SchemaControlType.CHECK_BOX_LIST, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/check-box-list-type/check-box-list-type.component').then(c => c.CheckBoxListTypeComponent) as Promise<FormFieldType<boolean>>;
        });
        this.formControls.set(SchemaControlType.RADIO_BUTTON_LIST, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/radio-type/radio-type.component').then(c => c.RadioTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.TEXT_AREA, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/textarea-type/textarea-type.component').then(c => c.TextAreaTypeComponent) as Promise<FormFieldType<string>>;
        });

        this.formControls.set(SchemaControlType.SIGNATURE_PAD, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/signature-pad-type/signature-pad-type.component').then(c => c.SignaturePadTypeComponent) as Promise<FormFieldType<string>>;
        });

        this.formControls.set(SchemaControlType.DATE_TIME_PICKER, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/datetime-type/datetime-type.component').then(c => c.DatetimeTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.FOREIGN_KEY, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/sub-control-types/select-type/select-type.component').then(c => c.SelectTypeComponent) as Promise<FormFieldType<string | string[]>>;
        });
        this.formControls.set(SchemaControlType.STATIC_SELECT, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/static-select/static-select.component').then(c => c.StaticSelectComponent) as Promise<FormFieldType<string | string[]>>;
        });
        this.formControls.set(SchemaControlType.RESOURCE_BROWSER_3D, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/resource-browser/resource-browser.component').then(c => c.ResourceBrowserComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.RESOURCE_BROWSER, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/resource-browser/resource-browser.component').then(c => c.ResourceBrowserComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.COLOR_PICKER, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/color-picker-type/color-picker-type.component').then(c => c.ColorPickerTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.GEO_FENCE, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/map-type/map-type.component').then(c => c.MapTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.GEO_LOCATION, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/geo-location-type/geo-location-type.component').then(c => c.GeoLocationTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.FILE_UPLOAD, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/file-upload-type/file-upload-type.component').then(c => c.FileUploadTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.TIME_PICKER, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/time-type/time-type.component').then(c => c.TimeTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.QUESTIONNAIRE, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/questionnaire-type/questionnaire-type.component').then(c => c.QuestionnaireTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.RICH_TEXT, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/rich-text-editor-type/rich-text-editor-type.component').then(c => c.RichTextEditorTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaControlType.SEARCHABLE_CONTROL, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/searchable-control-type/searchable-control-type.component').then(c => c.SearchableControlTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaSubControlType.RADIO_BUTTONS, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/sub-control-types/radio-buttons-type/radio-buttons-type.component').then(c => c.RadioButtonsTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaSubControlType.BUTTONS, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/sub-control-types/buttons-type/buttons-type.component').then(c => c.ButtonsTypeComponent) as Promise<FormFieldType<string>>;
        });
        this.formControls.set(SchemaSubControlType.BUTTONS_LIST, () => {
            return import('../../../../../../../den-web/src/app/page-builder/controls/components/angular/form/fields/sub-control-types/buttons-type-list/buttons-type-list.component').then(c => c.ButtonsTypeListComponent) as Promise<FormFieldType<string>>;
        });
    }
}
