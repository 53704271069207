import { OfflineConstants } from "../constants/mobile/mobile-offline-constant";

export class IdentityGenerator {
  static MAGIC_BASE = 900000000000000;
  static getLongId(increment: number) {
    return IdentityGenerator.MAGIC_BASE + increment;
  }

  static guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  /**
  *
  * @returns Random short id of length 10 characters
  */
  static getShortID() {
    const elementUUID = [...Array(10)].map(() => Math.random().toString(36)[2]).join('');
    return elementUUID;
  }

  /**
   *
   * @returns Random short id of length 5 characters
   */
  static getVeryShortId() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  }

  /**
   *
   * @returns Random very short id of length 3 characters
   */
  static getSuperShortId() {
    return this.generateId(3);
  }

  private static generateId(numberOfCharacters: number) {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, numberOfCharacters);
  }

  static getMediumId() {
    return this.generateId(5);
  }

  static getNumericCode(length: number) {
    const chars = "0123456789";
    return IdentityGenerator.genRandomCode(length, chars);
  }

  static getAlaphabeticCodeUpperCase(length: number) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZ";
    return IdentityGenerator.genRandomCode(length, chars);
  }

  static getAlaphabeticCodeLowerCase(length: number) {
    const chars = "abcdefghiklmnopqrstuvwxyz";
    return IdentityGenerator.genRandomCode(length, chars);
  }

  static getAlaphaNumericCodeUpperCase(length: number) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZ0123456789";
    return IdentityGenerator.genRandomCode(length, chars);
  }

  static getAlaphaNumericCodeLowerCase(length: number) {
    const chars = "abcdefghiklmnopqrstuvwxyz0123456789";
    return IdentityGenerator.genRandomCode(length, chars);

  }

  static genRandomCode(length: number, chars: String) {
    const charLength = chars.length;
    let code = '';
    for (let index = 0; index < length; index++) {
      code += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return code;
  }


  static getOfflineId() {
    function hexCode() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return hexCode() + hexCode() + hexCode() + hexCode() +
      hexCode() + hexCode();
  }
}
