import { ComponentRef, ViewContainerRef } from "@angular/core";
import { IControl } from "projects/den-core/page-builder";
import { ControlInstanceWrapper } from "../../controls/core/control-instance-drafts-models";

export interface IControlInfo {

}

export class SimpleAngularControlInfo implements IControlInfo {

  constructor(public viewContainerRef: ViewContainerRef,
    public parentInstance: ControlInstanceWrapper) { }
}

export class RootAngularControlInfo implements IControlInfo {

  constructor(public viewContainerRef: ViewContainerRef) { }

}



export interface IChildControlInfo {

  get id(): string;

  control: IControl<unknown>;

  // get componentRef(): ComponentRef<IControl<unknown>>;

  updateData(data: unknown): void;

  destroy(): void;
}

export class AngularChildControlInfo implements IChildControlInfo {

  constructor(public ref: ComponentRef<IControl<unknown>>, public viewContainerRef: ViewContainerRef) { }

  get control(): IControl<unknown> {
    return this.ref.instance;
  }

  get componentRef(): ComponentRef<IControl<unknown>> {
    return this.ref;
  }

  get id(): string {
    return this.ref.instance.id;
  }
  updateData(data: unknown): void {
    this.ref.instance.updateData(data);
  }

  destroy(): void {
    //do nothing as Angular itself will take care of destroying its component along with its subscriptions
    //this.ref.instance.destroy()
  }
}

export class StandardChildControlInfo implements IChildControlInfo {

  constructor(private _control: IControl<unknown>) { }

  get control(): IControl<unknown> {
    return this._control;
  }

  get componentRef(): ComponentRef<IControl<unknown>> {
    throw new Error("Method not implemented.");
  }

  get id(): string {
    return this._control.id;
  }
  updateData(data: unknown): void {
    this._control.updateData(data);
  }

  destroy(): void {
    this._control.destroy();
  }
}
