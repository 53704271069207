
import { Tween, update } from "@tweenjs/tween.js";
import { ComposedAnimation } from "../../page-builder/attributes/animation/animation-attribute";
import { AnimationProperty } from "../../page-builder/attributes/animation/animation-models";
import { CommonAnimationSettings } from "../../page-builder/attributes/animation/common-animation-settings";
import { ModelAndOneUsableValueContainer } from "../../page-builder/attributes/animation/model-and-usable-value-container";
import { IControl } from "../../page-builder/controls/core/control-model";


export class TweenBuilder {

  tween: Tween<any>

  constructor(composedAnimation: ComposedAnimation,
     control: IControl<unknown>,
     container: ModelAndOneUsableValueContainer<unknown, unknown>) {

    const animationSettings = composedAnimation.animationSettings
    animationSettings.applyCurrentUsableValue(container)
    const commonSettings = composedAnimation.animationSettings.commonSettings
    const props = animationSettings.resolvedFrom()
    // const element = this.resolveTargetElement(control)
    //commonSettings.getRepeatTimes

    const tween1 = new Tween(props).to(animationSettings.to, commonSettings.duration).easing(commonSettings.easing)
    const tween2 = this.applyBeginningDelay(tween1, commonSettings)
    const tween3 = this.applyRepeat(tween2, commonSettings)
    const tween4 = this.applyReverse(tween3, commonSettings)
    const tween5 = tween4.onUpdate(function () {
      const newAttributeInstanceHolder = animationSettings.rebuildUsableValue(props)
      control.applyUsableProperties([newAttributeInstanceHolder])
    })

    this.tween = tween5
  }


  start() {
    this.tween = this.tween.start()
    return this;
  }

  stop() {
    this.tween = this.tween.stop()
    return this;
  }

  chain(tween: Tween<any>) {
    this.tween = this.tween.chain(tween)
    return this;
  }

  onComplete(callback: (object: any) => void) {
    this.tween = this.tween.onComplete(callback)
    return this;
  }

  applyBeginningDelay(tween: Tween<any>, commonSettings: CommonAnimationSettings) {
    if (commonSettings.beginningDelay.isDefined) {
      return tween.delay(commonSettings.beginningDelay.get);
    } else {
      return tween;
    }
  }

  applyRepeat(tween: Tween<any>, commonSettings: CommonAnimationSettings) {
    if (commonSettings.repeat) {
      return tween.repeat(commonSettings.getRepeatTimes).repeatDelay(commonSettings.repeatDelay);
    } else {
      return tween;
    }
  }

  applyReverse(tween: Tween<any>, commonSettings: CommonAnimationSettings) {
    return tween;
    //TODO: Cant find the reversed.
    //
    // if (commonSettings.reverse) {
    //   return tween.(commonSettings.getRepeatTimes);
    // } else {
    //   return tween;
    // }
  }

  buildProps(composedAnimation: ComposedAnimation) {
    switch (composedAnimation.animationProperty) {
      case AnimationProperty.COLOR: {

      }
    }
  }
}

function animate(time) {
  requestAnimationFrame(animate);
  update(time);
}
requestAnimationFrame(animate);
