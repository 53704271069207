import { ViewContainerRef } from "@angular/core";
import { defer, fromEvent, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { EventService } from "../../../../../providers/event-service/event.service";
import { PubSubTopic } from "../../../../constants/pub-sub-topic-constants";
import { IDsResult } from "../../../../data-source/data-source-result-entities";
import { AppToEditorMessagingService, SelectedControlData } from "../../../../services/attribute-panel-bridging/control-attribute-panel-messaging.service";
import { AddChildWithDsResultCommand, ChildCommandResolver, ChildCommandType } from "../../child-rendered-event";
import { IControl } from "../../control-model";
import { CompanionRequestCommand } from "../control-companion-models";
import { CompanionMixinHolder, ControlEventType } from "../mixins/companion-mixin";
import { ICompanionTriggerService } from "./companion-trigger.service";
import { PageStateManager } from "../../../../services/page/page-state-manager";
export class LeafletCompanionTriggerService implements ICompanionTriggerService {

  private eventsSubject: Subject<ControlEventType>;
  private viewContainerRef: ViewContainerRef;
  private control: IControl<unknown>;
  private subs = new SubSink();

  constructor(public eventService: EventService, public appToEditorMessagingService: AppToEditorMessagingService) {
    this.eventsSubject = new Subject();
  }

  subscribeForEvents(mixins: CompanionMixinHolder[], cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): Observable<ControlEventType> {
    this.viewContainerRef = cmd.viewContainerRef;
    this.control = cmd.control;
    //Click listener should be there irrespective of whether any mixin is depending on it or not as this is the only place dom event listener for click is added.
    this.listenForControlSelectEvent();
    this.listenForContextMenuEvent();
    this.subscribeForControlSelectEvent();
    this.subscribeForElementSelectedFromOutline();
    const allControlEventType = mixins.map(mixin => [mixin.mixinInitiationEvent, ...mixin.mixinDestroyEvents]).flat();
    allControlEventType.forEach((controlEventType: ControlEventType) => this.registerForControlEvents(controlEventType));
    return this.eventsSubject.asObservable();
  }

  destroy() {
    this.subs.unsubscribe();
  }
  private registerForControlEvents(controlEvents: ControlEventType) {
    switch (controlEvents) {
      case ControlEventType.UN_CLICK:
        this.subscribeForControlUnSelectEvent();
        break;
      case ControlEventType.ESCAPE:
        this.subscribeForEscapeKeyPressedEvent();
        break;
    }
  }
  private listenForControlSelectEvent() {
    const click: Observable<Event> = defer(() => this.clickEvent());
    this.subs.add(click.subscribe());
  }
  private listenForContextMenuEvent() {
    const event: Observable<Event> = defer(() => this.contextMenuEvent());
    this.subs.add(event.subscribe());
  }
  private subscribeForControlSelectEvent() {
    const controlSelectTopic = PubSubTopic.buildControlSelectTopic(this.control.id);

    this.subs.add(this.eventService.subscribe(controlSelectTopic, () => {
      this.publishControlUnSelectEventIfAny();

      this.eventsSubject.next(ControlEventType.CLICK);

      PageStateManager.setSelected(this.control.id, this.control.descriptor.controlName);

      //Publish CONTROL_INSTANCE_SELECTED for selecting control in outline, change right side panels etc.
      // this.eventService.publish(PubSubTopic.CONTROL_INSTANCE_SELECTED, { ctrlInstance: this.control.controlInstance, ctrlDescriptor: this.control.descriptor, controlId: this.control.id });

      const selectedControlData: SelectedControlData = { controlInstanceId: this.control.controlInstance.instanceId, controlId: this.control.id };
      const topic = PubSubTopic.CONTROL_INSTANCE_SELECTED;
      this.appToEditorMessagingService.publishToEditor(topic, selectedControlData);
    }));
  }
  private clickEvent(): Observable<Event> {
    return fromEvent(this.viewContainerRef.element.nativeElement, 'click').pipe(tap(($event: MouseEvent) => {
      $event.stopPropagation();
    }));

  }
  private contextMenuEvent(): Observable<Event> {
    return fromEvent(this.viewContainerRef.element.nativeElement, 'contextmenu').pipe(tap(($event: MouseEvent) => {
      $event.stopPropagation();
    }));
  }
  private subscribeForElementSelectedFromOutline() {
    const controlInstanceSelectedFromOutlineTopic = PubSubTopic.buildElementSelectedFromOutlineTopic(this.control.controlInstance.instanceId);
    const controlInstanceSelectedSub = this.eventService.subscribe(controlInstanceSelectedFromOutlineTopic, ($event: { controlInstanceId: string; }) => {
      if (this.isTheControlCurrentControl($event.controlInstanceId)) {
        const controlSelectTopic = PubSubTopic.buildControlSelectTopic(this.control.id);
        this.eventService.publish(controlSelectTopic, { ctrlInstance: this.control.controlInstance });
      }
    });
    this.subs.add(controlInstanceSelectedSub);
  }

  private isTheControlCurrentControl(controlInstanceId: string) {
    return this.control.controlInstance?.instanceId === controlInstanceId && this.canSelectCurrentControl();
  }

  private canSelectCurrentControl() {
    if (this.control.parentCmd.commandType === ChildCommandType.NEW_WITH_DS_RESULT) {
      return this.isFirstChild();
    } else {
      return true;
    }
  }

  private isFirstChild() {
    const parentCmd = ChildCommandResolver.as<AddChildWithDsResultCommand>(this.control.parentCmd);
    return parentCmd.firstChild.isDefined;
  }

  private publishControlUnSelectEventIfAny() {
    const previouslySelectedControl = PageStateManager.getSelected();
    if (previouslySelectedControl.isDefined) {
      const controlUnSelectTopic = PubSubTopic.buildControlUnSelectTopic(previouslySelectedControl.get);
      this.eventService.publish(controlUnSelectTopic, {});
    }
    const isParamMappingMode = PageStateManager.getParamMappingMode();
    if (isParamMappingMode) {
      const controlUnSelectTopic = PubSubTopic.buildRemoveEventMenuTopic();
      this.eventService.publish(controlUnSelectTopic, {});
      PageStateManager.clearParamMappingMode();
    }
  }

  private subscribeForControlUnSelectEvent() {
    const controlUnSelectTopic = PubSubTopic.buildControlUnSelectTopic(this.control.id);
    this.subs.add(this.eventService.subscribe(controlUnSelectTopic, () => {
      this.eventsSubject.next(ControlEventType.UN_CLICK);
    }));
  }

  private subscribeForEscapeKeyPressedEvent() {
    this.subs.add(this.eventService.subscribe(PubSubTopic.ON_ESC_KEY_PRESSED, () => {
      this.publishControlUnSelectEventIfAny();
      PageStateManager.clearSelection();
      this.eventsSubject.next(ControlEventType.ESCAPE);
    }));
  }
}

