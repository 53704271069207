import { ViewContainerRef } from "@angular/core";
import { defer, fromEvent, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { EventService } from "../../../../../providers/event-service/event.service";
import { PubSubTopic } from "../../../../constants/pub-sub-topic-constants";
import { IDsResult } from "../../../../data-source/data-source-result-entities";
import { AppToEditorMessagingService, SelectedControlData } from "../../../../services/attribute-panel-bridging/control-attribute-panel-messaging.service";
import { AddChildWithDsResultCommand, ChildCommandResolver, ChildCommandType } from "../../child-rendered-event";
import { IControl } from "../../control-model";
import { CompanionRequestCommand } from "../control-companion-models";
import { CompanionMixinHolder, ControlEventType } from "../mixins/companion-mixin";
import { ICompanionTriggerService } from "./companion-trigger.service";
import { PageStateManager } from "../../../../services/page/page-state-manager";


export class AngularCompanionTriggerService implements ICompanionTriggerService {

  private eventsSubject: Subject<ControlEventType>;
  private viewContainerRef: ViewContainerRef;
  private control: IControl<unknown>;
  private subs = new SubSink();

  constructor(public eventService: EventService, public appToEditorMessagingService: AppToEditorMessagingService) {
    this.eventsSubject = new Subject();
  }

  subscribeForEvents(mixins: CompanionMixinHolder[], cmd: CompanionRequestCommand<IDsResult, IControl<IDsResult>>): Observable<ControlEventType> {
    this.viewContainerRef = cmd.viewContainerRef;
    this.control = cmd.control;

    //Click listener should be there irrespective of whether any mixin is depending on it or not as this is the only place dom event listener for click is added.
    this.listenForControlSelectEvent();
    this.listenForContextMenuEvent();
    this.listenForMouseOverEvent();
    this.listenForMouseOutEvent();

    const allControlEventType = mixins.map(mixin => [mixin.mixinInitiationEvent, ...mixin.mixinDestroyEvents]).flat();
    allControlEventType.forEach((controlEventType: ControlEventType) => this.registerForControlEvents(controlEventType));
    return this.eventsSubject.asObservable();
  }

  destroy() {
    this.subs.unsubscribe();
  }

  private registerForControlEvents(controlEvents: ControlEventType) {

    switch (controlEvents) {
      case ControlEventType.DRAG_ENTER:
        this.listenForDragEnterEvent();
        break;
      case ControlEventType.DRAG_LEAVE:
        this.listenForDargLeaveEvent();
        break;
      case ControlEventType.DROP:
        this.listenForDropEvent();
        break;
      case ControlEventType.UN_CLICK:
        this.subscribeForControlUnSelectEvent();
        break;
      case ControlEventType.MOUSE_ENTER:
        this.listenForMouseEnterEvent();
        break;
      case ControlEventType.MOUSE_LEAVE:
        this.listenForMouseLeaveEvent();
        break;
      case ControlEventType.ESCAPE:
        this.subscribeForEscapeKeyPressedEvent();
        break;
    }
  }

  private listenForMouseLeaveEvent() {
    this.viewContainerRef.element.nativeElement.addEventListener("mouseleave", (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.eventsSubject.next(ControlEventType.MOUSE_LEAVE);
    });
  }

  private listenForMouseEnterEvent() {
    this.viewContainerRef.element.nativeElement.addEventListener("mouseenter", (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.eventsSubject.next(ControlEventType.MOUSE_ENTER);
    });
  }

  private listenForDropEvent() {
    this.viewContainerRef.element.nativeElement.addEventListener("drop", (event: DragEvent) => {
      event.preventDefault();
      this.eventsSubject.next(ControlEventType.DROP);
    });
  }

  private listenForDargLeaveEvent() {
    this.viewContainerRef.element.nativeElement.addEventListener("dragleave", (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.eventsSubject.next(ControlEventType.DRAG_LEAVE);
    });
  }

  private listenForDragEnterEvent() {
    this.viewContainerRef.element.nativeElement.addEventListener("dragenter", (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.eventsSubject.next(ControlEventType.DRAG_ENTER);
    });
  }

  private listenForControlSelectEvent() {
    // 1) On control click
    const click: Observable<Event> = defer(() => this.clickEvent());
    this.subs.add(click.subscribe());

    // 2) On select of control from outline
    this.subscribeForElementSelectedFromOutline();

    this.subscribeForControlSelectEvent();
  }
  private listenForContextMenuEvent() {
    const event: Observable<Event> = defer(() => this.contextMenuEvent());
    this.subs.add(event.subscribe());
  }
  private listenForMouseOverEvent() {
    const event: Observable<Event> = defer(() => this.mouseOverEvent());
    this.subs.add(event.subscribe());
  }
  private listenForMouseOutEvent() {
    const event: Observable<Event> = defer(() => this.mouseOutEvent());
    this.subs.add(event.subscribe());
  }

  private subscribeForControlSelectEvent() {
    const controlSelectTopic = PubSubTopic.buildControlSelectTopic(this.control.id);

    this.subs.add(this.eventService.subscribe(controlSelectTopic, (pointerEvent: { event: PointerEvent }) => {
      this.publishControlUnSelectEventIfAny();

      this.eventsSubject.next(ControlEventType.CLICK);

      PageStateManager.setSelected(this.control.id, this.control.descriptor.controlName);

      //Publish CONTROL_INSTANCE_SELECTED for selecting control in outline, change right side panels etc.
      // this.eventService.publish(PubSubTopic.CONTROL_INSTANCE_SELECTED, { ctrlInstance: this.control.controlInstance, ctrlDescriptor: this.control.descriptor, controlId: this.control.id });
      const domElementAttrs: { domRect: DOMRect; pageX: number; pageY: number; } = { domRect: (pointerEvent.event.currentTarget as HTMLDivElement).children[0].getBoundingClientRect(), pageX: pointerEvent.event.pageX, pageY: pointerEvent.event.pageY };
      const selectedControlData: SelectedControlData = { controlInstanceId: this.control.controlInstance.instanceId, controlId: this.control.id, pointerEvent: domElementAttrs, controlName: this.control.descriptor.controlName };
      const topic = PubSubTopic.CONTROL_INSTANCE_SELECTED;
      this.appToEditorMessagingService.publishToEditor(topic, selectedControlData);
    }));
  }

  private clickEvent(): Observable<Event> {
    return fromEvent(this.viewContainerRef.element.nativeElement, 'click').pipe(tap(($event: MouseEvent) => {

      //For controls which are listening on click event
      const controlSelectTopic = PubSubTopic.buildControlSelectTopic(this.control.id);
      this.eventService.publish(controlSelectTopic, { ctrlInstance: this.control.controlInstance, event: $event });
      $event.stopPropagation();
    }));
  }
  private contextMenuEvent(): Observable<Event> {
    return fromEvent(this.viewContainerRef.element.nativeElement, 'contextmenu').pipe(tap(($event: MouseEvent) => {

      //For controls which are listening on contextmenu
      const contextMenuTopic = PubSubTopic.buildContextMenuTopic(this.control.id);
      this.eventService.publish(contextMenuTopic, { ctrlInstance: this.control.controlInstance, event: $event });
      const controlSelectTopic = PubSubTopic.buildControlSelectTopic(this.control.id);
      this.eventService.publish(controlSelectTopic, { ctrlInstance: this.control.controlInstance });

      $event.stopPropagation();
      $event.preventDefault();

    }));
  }
  private mouseOverEvent(): Observable<Event> {
    return fromEvent(this.viewContainerRef.element.nativeElement, 'mouseover').pipe(tap(($event: MouseEvent) => {

      //For controls which are listening on contextmenu
      const controlSelectTopic = PubSubTopic.buildMouseEnterTopic(this.control.id);
      this.eventService.publish(controlSelectTopic, { ctrlInstance: this.control.controlInstance });
      $event.stopPropagation();
      $event.preventDefault();

    }));
  }
  private mouseOutEvent(): Observable<Event> {
    return fromEvent(this.viewContainerRef.element.nativeElement, 'mouseout').pipe(tap(($event: MouseEvent) => {

      //For controls which are listening on contextmenu
      const controlSelectTopic = PubSubTopic.buildMouseLeaveTopic(this.control.id);
      this.eventService.publish(controlSelectTopic, { ctrlInstance: this.control.controlInstance });
      $event.stopPropagation();
      $event.preventDefault();

    }));
  }

  private subscribeForElementSelectedFromOutline() {
    const controlInstanceSelectedFromOutlineTopic = PubSubTopic.buildElementSelectedFromOutlineTopic(this.control.controlInstance.instanceId);
    const controlInstanceSelectedSub = this.eventService.subscribe(controlInstanceSelectedFromOutlineTopic, ($event: { controlInstanceId: string; }) => {
      if (this.isTheControlCurrentControl($event.controlInstanceId)) {
        const controlSelectTopic = PubSubTopic.buildControlSelectTopic(this.control.id);
        this.eventService.publish(controlSelectTopic, { ctrlInstance: this.control.controlInstance });
      }
    });
    this.subs.add(controlInstanceSelectedSub);
  }

  private isTheControlCurrentControl(controlInstanceId: string) {
    return this.control.controlInstance?.instanceId === controlInstanceId && this.canSelectCurrentControl();
  }

  private canSelectCurrentControl() {
    if (this.control.parentCmd.commandType === ChildCommandType.NEW_WITH_DS_RESULT) {
      return this.isFirstChild();
    } else {
      return true;
    }
  }

  private isFirstChild() {
    const parentCmd = ChildCommandResolver.as<AddChildWithDsResultCommand>(this.control.parentCmd);
    return parentCmd.firstChild.isDefined;
  }

  private publishControlUnSelectEventIfAny() {
    const previouslySelectedControl = PageStateManager.getSelected();
    if (previouslySelectedControl.isDefined) {
      const controlUnSelectTopic = PubSubTopic.buildControlUnSelectTopic(previouslySelectedControl.get);
      this.eventService.publish(controlUnSelectTopic, {});
    }
    const isParamMappingMode = PageStateManager.getParamMappingMode();
    if (isParamMappingMode) {
      const controlUnSelectTopic = PubSubTopic.buildRemoveEventMenuTopic();
      this.eventService.publish(controlUnSelectTopic, {});
      PageStateManager.clearParamMappingMode();
    }
  }

  private subscribeForControlUnSelectEvent() {
    const controlUnSelectTopic = PubSubTopic.buildControlUnSelectTopic(this.control.id);
    this.subs.add(this.eventService.subscribe(controlUnSelectTopic, () => {
      this.eventsSubject.next(ControlEventType.UN_CLICK);
    }));
  }

  private subscribeForEscapeKeyPressedEvent() {
    this.subs.add(this.eventService.subscribe(PubSubTopic.ON_ESC_KEY_PRESSED, () => {
      this.publishControlUnSelectEventIfAny();
      PageStateManager.clearSelection();
      this.eventsSubject.next(ControlEventType.ESCAPE);
    }));
  }
}
