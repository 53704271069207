import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { EntitySchemaDataResponse, SchemaControlType } from 'projects/den-core/base-models';
import { FilterAndSortResult, PageService, ReturnMethodParamsWrapper } from 'projects/den-core/page-builder';
import { SchemaFieldsWrapper, TemplateOption } from 'projects/den-core/schema-models';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { SchemaService } from '../../../../providers/schema/schema.service';
import { ControlTypeFormlyRegistry } from '../../../../utils/controlType-formly-registry';
import { UserTaskListHelper } from '../../../../utils/user-task-list-helper';
import { FieldOptions } from '../../form/utils/fieldOptions';
import { ControlDataSourceSchemaRegistry, DataSourceSchemaContainer } from '../control-datasource-schema-container';
import { FilterAndSortModalData } from '../list-view-control/list-view-models';

@Component({
    selector: 'den-task-list-filter',
    templateUrl: './task-list-filter.component.html',
    styleUrls: ['./task-list-filter.component.css']
})

export class TaskListFilterComponent implements OnInit, OnDestroy {

    form = new UntypedFormGroup({});
    model = {};
    formlyFields: FormlyFieldConfig[] = [];
    selectedSortField: ReturnMethodParamsWrapper;
    supportedSortFields: ReturnMethodParamsWrapper[] = [];
    private controlDataSourceSchemaRegistry: ControlDataSourceSchemaRegistry;
    private supportedFilterFields: SchemaFieldsWrapper[] = [];
    private subs = new SubSink();

    constructor(@Inject(MAT_DIALOG_DATA) public data: FilterAndSortModalData, private schemaService: SchemaService,
        public dialogRef: MatDialogRef<TaskListFilterComponent>, private _pageService: PageService) {
    }

    ngOnInit(): void {
        // TODO for ProcessFormAssignmentListingComponent
        this.initializeFormlyData();
    }
    private initializeFormlyData() {
        if (this.data?.returnMethodParams?.length) {
            const returnMethodParams = this.data.returnMethodParams;
            const dataSourceSchemaContainer = new DataSourceSchemaContainer(this.data.controlInstanceId, this._pageService, this.schemaService);
            ControlTypeFormlyRegistry.registerFormlyControls();
            this.controlDataSourceSchemaRegistry = dataSourceSchemaContainer.getControlDataSourceSchemaContainer();
            this.supportedFilterFields = this.getSupportedFilterFields(returnMethodParams);
            this.supportedSortFields = this.getSupportedSortFields(returnMethodParams);
            this.constructFormlyFields();
            if (this.data?.selectedFilterData) {
                this.buildModelData();
            }
            this.selectedSortField = this.data?.selectedSortField;
        }
    }

    private getSupportedSortFields(returnMethodParams: ReturnMethodParamsWrapper[]) {
        return returnMethodParams.filter(ef => UserTaskListHelper.getSupportedSortingFields().includes(
            this.controlDataSourceSchemaRegistry.getSchemaField(ef.name)?.controlType as SchemaControlType)
        );
    }
    private getSupportedFilterFields(returnMethodParams: ReturnMethodParamsWrapper[]): SchemaFieldsWrapper[] {
        return returnMethodParams.map(r => this.controlDataSourceSchemaRegistry.getSchemaField(r.name)).filter(schemaFieldsWrapper => {
            if (this.data?.isClientFilter) {
                return [SchemaControlType.TEXT_BOX].includes(schemaFieldsWrapper.controlType as SchemaControlType) && !schemaFieldsWrapper.isArray;
            } else {
                return UserTaskListHelper.getSupportedFilterTypes().includes(schemaFieldsWrapper.controlType as SchemaControlType) && !schemaFieldsWrapper.isArray;
            }
        });
        // Used in UserTasks to display cols acc to maxCount
        // TODO
        // if (this.data.maxCount) {
        //     entityFields.length = this.data.maxCount;
        // }
    }
    private constructFormlyFields() {
        this.supportedFilterFields?.forEach(element => {
            const formlyFieldConfig = this.buildFormlyFieldConfig(element);
            this.formlyFields.push(formlyFieldConfig);
        });
    }

    private buildFormlyFieldConfig(schemaFieldsWrapper: SchemaFieldsWrapper) {
        const formlyType = ControlTypeFormlyRegistry.getFormlyControlType(schemaFieldsWrapper?.controlType as SchemaControlType);
        return {
            key: schemaFieldsWrapper.fieldName,
            type: formlyType,
            className: 'col-md-6',
            templateOptions: this.buildTemplateOptions(schemaFieldsWrapper),
            hooks: {
                onInit: (field: FormlyFieldConfig): void => { this.bindOptions(field, schemaFieldsWrapper) }
            }
        };
    }

    private buildTemplateOptions(schemaFieldsWrapper: SchemaFieldsWrapper): FormlyTemplateOptions {
        const formlyType = ControlTypeFormlyRegistry.getFormlyControlType(schemaFieldsWrapper.controlType as SchemaControlType);
        return {
            label: schemaFieldsWrapper.label,
            type: formlyType,
        };
    }
    private bindOptions(field: FormlyFieldConfig, schemaFieldsWrapper: SchemaFieldsWrapper) {
        if (FieldOptions.isFkey(schemaFieldsWrapper.controlType as SchemaControlType)) {
            field.templateOptions.options = this.resolveAndSetDynamicOptions(schemaFieldsWrapper);
        } else if (FieldOptions.isStaticControl(schemaFieldsWrapper.controlType as SchemaControlType)) {
            FieldOptions.setStaticOptions(field, schemaFieldsWrapper.staticOptions);
        }
        field.templateOptions.multiple = schemaFieldsWrapper.isArray;
    }

    private resolveAndSetDynamicOptions(schemaFieldsWrapper: SchemaFieldsWrapper): Observable<TemplateOption[]> {
        return new Observable((observer) => {
            this.subs.add(this.schemaService.getDatasourceObservables(schemaFieldsWrapper.referenceSchema).subscribe(
                (serviceData: EntitySchemaDataResponse[]) => {
                    const templateOptions = FieldOptions.constructOptionsAndSort(serviceData, schemaFieldsWrapper.referenceField,
                        schemaFieldsWrapper.controlType as SchemaControlType, schemaFieldsWrapper.isArray, schemaFieldsWrapper.label, schemaFieldsWrapper.applySort);
                    templateOptions.shift();
                    observer.next(templateOptions);
                }));
        });
    }
    private buildModelData() {
        this.model = Object.assign({}, this.data.selectedFilterData);
    }

    applyFilter() {
        const formlyModelValue = this.buildFormlyModelValue();
        const filterAndSort: FilterAndSortResult = { model: formlyModelValue, sortBy: this.selectedSortField };
        this.dialogRef.close(filterAndSort);
    }

    private buildFormlyModelValue() {
        const fomlyModelValue = {};
        this.formlyFields.forEach(f => {
            fomlyModelValue[f.key as string] = f.formControl.value;
        });
        return fomlyModelValue;
    }

    resetFilter() {
        this.dialogRef.close({});
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}






