import { ViewContainerRef } from "@angular/core";
import { ToastServiceProvider } from "projects/den-core";
import { RtOption, RtSome } from "../../../../utils/option-helper";
import { CSSPropertyNameConstants } from "../../../constants/css-property-name-constants";
import { Units } from "../enums/grid-enums";
import { GridDefinitionHelper } from "../utils/grid-definition-helper";

export class GridCell {
  constructor(public rowIndex: number, public columnIndex: number) {

  }
  static parse(gridCellObj: GridCell) {
    return new GridCell(gridCellObj.rowIndex, gridCellObj.columnIndex);
  }

  public get id() { return this.rowIndex + "_" + this.columnIndex }
}

export class GridSize {
  constructor(public totalRows: number, public totalColumns: number) {

  }
}


export class GridDimensions implements CSSAttribute {
  constructor(public width: Dimension, public height: Dimension) {

  }
  static parse(gridDimensionsObj: GridDimensions) {
    const gridHeight = Dimension.parse(gridDimensionsObj.height);
    const gridWidth = Dimension.parse(gridDimensionsObj.width);
    return new GridDimensions(gridWidth, gridHeight);
  }
  toCSSAttribute(): Map<string, string> {
    const stylesMap = new Map();
    stylesMap.set(CSSPropertyNameConstants.WIDTH, this.width.toCssString());
    stylesMap.set(CSSPropertyNameConstants.HEIGHT, this.height.toCssString());
    return stylesMap;
  }
}

export class GridDefinition implements CSSAttribute {

  private fixedMenuIndex = 0;
  private editorColumn = new ColumnDefinition(this.fixedMenuIndex, new Dimension(RtSome(20), Units.PX));
  private editorRow = new RowDefinition(this.fixedMenuIndex, new Dimension(RtSome(20), Units.PX));

  constructor(public columnsContainer: ColumnsContainer,
    public rowsContainer: RowsContainer,
    public areas: GridTemplateAreasContainer,
    public gridGap: GridGap,
    public gridDimensions: GridDimensions) {
  }

  static parse(json: string): GridDefinition {
    const gridDefinitionObj: GridDefinition = JSON.parse(json);

    const columnsContainer = ColumnsContainer.parse(gridDefinitionObj.columnsContainer);
    const rowsContainer = RowsContainer.parse(gridDefinitionObj.rowsContainer);
    const gridSize = new GridSize(rowsContainer.allRows.length, columnsContainer.allColumns.length);
    const areasContainer = GridTemplateAreasContainer.parse(gridDefinitionObj.areas, gridSize);
    const gridGap = GridGap.parse(gridDefinitionObj.gridGap);
    const gridDimensions = GridDimensions.parse(gridDefinitionObj.gridDimensions);
    const gridDefinition = new GridDefinition(columnsContainer, rowsContainer, areasContainer, gridGap, gridDimensions);
    return gridDefinition;
  }

  toCSSAttribute(): Map<string, string> {
    const stylesMap = new Map();

    const gridDimensionStyles = this.gridDimensions.toCSSAttribute();
    gridDimensionStyles?.forEach((value: string, key: string) => stylesMap.set(key, value));

    const gridGapStyles = this.gridGap.toCSSAttribute();
    gridGapStyles?.forEach((value: string, key: string) => stylesMap.set(key, value));

    const gridTemplateAreasStyles = this.areas.toCSSAttribute();
    gridTemplateAreasStyles?.forEach((value: string, key: string) => stylesMap.set(key, value));

    const gridTemplateColumnsStyles = this.columnsContainer.toCSSAttribute();
    gridTemplateColumnsStyles?.forEach((value: string, key: string) => stylesMap.set(key, value));

    const gridTemplateRowsStyles = this.rowsContainer.toCSSAttribute();
    gridTemplateRowsStyles?.forEach((value: string, key: string) => stylesMap.set(key, value));

    return stylesMap;
  }

  public buildDesignerGrid() {
    const designerColumnsContainer: ColumnsContainer = new ColumnsContainer([this.editorColumn, ...this.columnsContainer.allColumns]);
    const designerRowsContainer: RowsContainer = new RowsContainer([this.editorRow, ...this.rowsContainer.allRows]);
    const gridSize: GridSize = new GridSize(this.rowsContainer.allRows.length, this.columnsContainer.allColumns.length);
    const areas: GridTemplateArea[] = [];
    for (let colIndex = 0; colIndex <= this.columnsContainer.allColumns.length; colIndex++) {
      areas.push(new GridTemplateArea([new GridCell(0, colIndex)]));
    }
    for (let rowIndex = 1; rowIndex <= this.rowsContainer.allRows.length; rowIndex++) {
      areas.push(new GridTemplateArea([new GridCell(rowIndex, 0)]));
    }
    const templateAreas = new GridTemplateAreasContainer([...areas, ...this.areas.allAreas], gridSize);
    return new GridDefinition(designerColumnsContainer, designerRowsContainer, templateAreas, this.gridGap, this.gridDimensions);
  }

  public get allGridCells(): GridCell[] {
    let gridCells = this.rowsContainer.allRows.map((row: RowDefinition) => {
      return this.columnsContainer.allColumns.map((column: ColumnDefinition) => {
        return new GridCell(row.index, column.index);
      })
    })
    return gridCells.flat();
  }

  public get gridSize(): GridSize {
    return new GridSize(this.rowsContainer.allRows.length, this.columnsContainer.allColumns.length);
  }
  addNewColumnAtTheEnd() {
    this.columnsContainer.addNewColumnAtTheEnd();
    this.addAreasForNewlyAddedColumn();
  }

  addNewRowAtTheEnd() {
    this.rowsContainer.addNewRowAtTheEnd();
    this.addAreasForNewlyAddedRow();
  }
  deleteColumn(columnDefinition: ColumnDefinition) {
    this.removeColumnAndUpdateIndexsToTheRight(columnDefinition)
    // Delete column from the columns container
    // Update column indexes of the columns which are right side of the deleted column
    // Remove the deleted column grid cells from the template area grid cells
  }
  deleteRow(rowDefinition: RowDefinition) {
    this.removeRowAndUpdateIndexsToTheBottom(rowDefinition)
    // Delete row from the rows container
    // Update row indexes of the rows which are bottom of the deleted row
    // Remove the deleted row grid cells from the template area grid cells
  }
  private removeColumnAndUpdateIndexsToTheRight(columnDefinition: ColumnDefinition) {
    // const columnsToTheRight = this.columnsContainer.columns.filter(col => col.index > columnDefinition.index);
    this.columnsContainer.removeColumn(columnDefinition);
    this.columnsContainer.columns.forEach(col => {
      if (col.index >= columnDefinition.index) {
        col.index--;
      }
    });
    this.recreateAreasIfItHasUpdatedColumns(columnDefinition);
  }
  private recreateAreasIfItHasUpdatedColumns(columnDefinition: ColumnDefinition) {
    this.areas.areas.forEach((gridTemplateArea: GridTemplateArea) => {
      // finding areas which are having updated columns
      const isAreaDrawnOnUpdatedColumns = gridTemplateArea.gridCells.some(c => c.columnIndex >= columnDefinition.index);
      if (isAreaDrawnOnUpdatedColumns) {
        // Creating new area with updated columns
        const newArea = GridTemplateArea.parse(gridTemplateArea);
        const gridCells = [];
        gridTemplateArea.gridCells.forEach(areaGridCell => {
          if (areaGridCell.columnIndex > columnDefinition.index) {
            areaGridCell.columnIndex--;
          }
          this.updateColumnIndexsForNewAreas(newArea, columnDefinition, gridCells);
          // Removing old area and adding new Area;
          this.areas.removeArea(gridTemplateArea);
          this.areas.addArea(new GridTemplateArea(gridCells));
        })
      }
    });
  }
  private updateColumnIndexsForNewAreas(newArea: GridTemplateArea, columnDefinition: ColumnDefinition, gridCells: any[]) {
    newArea.gridCells.forEach(areaGridCell => {
      if (areaGridCell.columnIndex > columnDefinition.index) {
        areaGridCell.columnIndex--;
      }
      const gridCell = gridCells.find(cell => cell.id === areaGridCell.id);
      if (!gridCell) {
        gridCells.push(areaGridCell);
      }
    });
  }

  private recreateAreasIfItHasUpdatedRows(rowDefinition: RowDefinition) {
    this.areas.areas.forEach((gridTemplateArea: GridTemplateArea) => {
      // finding areas which are having updated Rows
      const isAreaDrawnOnUpdatedColumns = gridTemplateArea.gridCells.some(c => c.rowIndex >= rowDefinition.index);
      if (isAreaDrawnOnUpdatedColumns) {
        // Creating new area with updated columns
        const newArea = GridTemplateArea.parse(gridTemplateArea);
        let gridCells: GridCell[] = []
        gridTemplateArea.gridCells.forEach(areaGridCell => {
          if (areaGridCell.rowIndex > rowDefinition.index) {
            areaGridCell.rowIndex--;
          }
          this.updateRowIndexsForNewAreas(newArea, rowDefinition, gridCells);
          this.areas.removeArea(gridTemplateArea);
          this.areas.addArea(new GridTemplateArea(gridCells));
        })
      }
    });
  }

  private updateRowIndexsForNewAreas(newArea: GridTemplateArea, rowDefinition: RowDefinition, gridCells: GridCell[]) {
    newArea.gridCells.forEach(areaGridCell => {
      if (areaGridCell.rowIndex > rowDefinition.index) {
        areaGridCell.rowIndex--;
      }
      // pushing unique cells
      const gridCell = gridCells.find(cell => cell.id === areaGridCell.id);
      if (!gridCell) {
        gridCells.push(areaGridCell);
      }
    });
  }

  private removeRowAndUpdateIndexsToTheBottom(rowDefinition: RowDefinition) {
    this.rowsContainer.removeRow(rowDefinition);
    this.rowsContainer.rows.forEach(row => {
      if (row.index >= rowDefinition.index) {
        row.index--;
      }
    });
    this.recreateAreasIfItHasUpdatedRows(rowDefinition);
  }

  private addAreasForNewlyAddedColumn() {
    const newAreas = [];
    const columnIndex = this.columnsContainer.allColumns.length;
    for (let rowIndex = 1; rowIndex <= this.rowsContainer.allRows.length; rowIndex++) {
      newAreas.push(new GridTemplateArea([new GridCell(rowIndex, columnIndex)]));
    }
    this.areas = new GridTemplateAreasContainer([...this.areas.allAreas, ...newAreas], this.gridSize);
  }

  private addAreasForNewlyAddedRow() {
    const newAreas = [];
    const rowIndex = this.rowsContainer.allRows.length;
    for (let columnIndex = 1; columnIndex <= this.columnsContainer.allColumns.length; columnIndex++) {
      newAreas.push(new GridTemplateArea([new GridCell(rowIndex, columnIndex)]));
    }
    this.areas = new GridTemplateAreasContainer([...this.areas.allAreas, ...newAreas], this.gridSize);
  }

}



export class ColumnsContainer implements CSSAttribute {
  constructor(public columns: ColumnDefinition[]) {

  }
  static parse(columnsObj: ColumnsContainer) {
    const columnDefinitions = columnsObj.columns.map(cd => ColumnDefinition.parse(cd));
    return new ColumnsContainer(columnDefinitions);
  }
  toCSSAttribute(): Map<string, string> {
    const stylesMap = new Map();
    const sortedData = this.columns.sort((a1, a2) => a1.index - a2.index);
    const templateColumns = sortedData.map(cd => cd.columnWidth.toCssString()).join(" ");
    stylesMap.set(CSSPropertyNameConstants.GRID_TEMPLATE_COLUMNS, templateColumns);
    return stylesMap;
  }
  addColumn(column: ColumnDefinition) {
    this.columns.push(column);
  }
  removeColumn(column: ColumnDefinition) {
    this.columns = this.columns.filter(r => r.name != column.name);
  }
  addNewColumnAtTheEnd() {
    const newColumnIndex = this.allColumns.length + 1;
    const defaultColumnDimension = new Dimension(RtSome(0.5), Units.FR);
    this.columns.push(new ColumnDefinition(newColumnIndex, defaultColumnDimension));
  }
  updateColumnWidth(column: ColumnDefinition) {
    const selectedColumn = this.columns.find(c => c.index === column.index);
    if (selectedColumn) {
      selectedColumn.updateWidth(column.columnWidth);
    }
  }
  get allColumns(): ColumnDefinition[] {
    return this.columns;
  }
}

export class ColumnDefinition {
  constructor(public index: number, public columnWidth: Dimension) {
  }
  static parse(columnDefinitionObj: ColumnDefinition) {
    return new ColumnDefinition(columnDefinitionObj.index, Dimension.parse(columnDefinitionObj.columnWidth));
  }
  public get name(): string {
    return `COLUMN_${this.index}`;
  }
  public updateWidth(columnWidth: Dimension) {
    this.columnWidth = columnWidth;
  }
}

export class RowsContainer implements CSSAttribute {
  constructor(public rows: RowDefinition[]) {

  }

  static parse(rowsObj: RowsContainer) {
    const columnDefinitions = rowsObj.rows.map(cd => RowDefinition.parse(cd));
    return new RowsContainer(columnDefinitions);
  }

  toCSSAttribute(): Map<string, string> {
    const stylesMap = new Map();
    const sortedData = this.rows.sort((a1, a2) => a1.index - a2.index);
    const templateRows = sortedData.map(rd => rd.rowHeight.toCssString()).join(" ");
    stylesMap.set(CSSPropertyNameConstants.GRID_TEMPLATE_ROWS, templateRows);
    return stylesMap;
  }
  addRow(row: RowDefinition) {
    this.rows.push(row);
  }
  removeRow(row: RowDefinition) {
    this.rows = this.rows.filter(r => r.name != row.name);
  }
  updateRowHeight(row: RowDefinition) {
    const selectedRow = this.rows.find(c => c.index === row.index);
    if (selectedRow) {
      selectedRow.updateHeight(row.rowHeight);
    }
  }
  addNewRowAtTheEnd() {
    const newRowIndex = this.allRows.length + 1;
    const defaultRowDimension = new Dimension(RtSome(0.5), Units.FR);
    this.rows.push(new RowDefinition(newRowIndex, defaultRowDimension));
  }
  get allRows(): RowDefinition[] {
    return this.rows;
  }
}

export class RowDefinition {
  constructor(public index: number, public rowHeight: Dimension) {

  }
  static parse(rowDefinitionObj: RowDefinition) {
    return new RowDefinition(rowDefinitionObj.index, Dimension.parse(rowDefinitionObj.rowHeight));
  }
  public updateHeight(rowHeight: Dimension) {
    this.rowHeight = rowHeight;
  }
  public get name(): string {
    return `ROW_${this.index}`;
  }
}

export class GridTemplateAreasContainer implements CSSAttribute {
  //Grid Area
  //    "header header header header"
  //    "main main . sidebar"
  //    "footer footer footer footer"
  constructor(public areas: GridTemplateArea[], public gridSize: GridSize) {

  }
  static parse(gridTemplateAreasContainerObj: GridTemplateAreasContainer, gridSize: GridSize) {
    const gridTemplateAreas = gridTemplateAreasContainerObj.areas.map(cd => GridTemplateArea.parse(cd));
    return new GridTemplateAreasContainer(gridTemplateAreas, gridSize);
  }
  toCSSAttribute(): Map<string, string> {
    let templateAreas = '';
    for (let rowIndex = 0; rowIndex <= this.gridSize.totalRows; rowIndex++) {
      let rowArea = this.getRowAreas(rowIndex);
      if (rowArea) {
        templateAreas = `${templateAreas} "${rowArea}" `;
      }
    }
    const stylesMap = new Map();
    stylesMap.set(CSSPropertyNameConstants.GRID_TEMPLATE_AREAS, templateAreas);
    return stylesMap;
  }
  private getRowAreas(rowIndex: number) {
    let rowArea = '';
    for (let colIndex = 0; colIndex <= this.gridSize.totalColumns; colIndex++) {
      const templateArea = this.areas.find(ta => ta.gridCells.some(gc => gc.rowIndex === rowIndex && gc.columnIndex === colIndex));
      if (templateArea) {
        rowArea = `${rowArea} ${templateArea.areaName}`;
      }
    }
    return rowArea;
  }

  removeEmptyAreasFromGrid() {
    this.areas = this.areas.filter(a => a.gridCells.length > 0);
  }

  getAreaNamesFromWhichCellsAreRemoved(gridCells: GridCell[], selectedTemplateArea: GridTemplateArea): string[] {
    const removedAreaNames = [];
    this.areas.forEach((gridTemplateArea: GridTemplateArea) => {
      const remainingCells = gridTemplateArea.gridCells.filter(areaGridCell => !gridCells.some(c => c.columnIndex === areaGridCell.columnIndex && c.rowIndex === areaGridCell.rowIndex))
      if (remainingCells.length == 0) {
        if (selectedTemplateArea.areaName != gridTemplateArea.areaName) {
          removedAreaNames.push(gridTemplateArea.areaName);
        }
      }
    })
    return removedAreaNames;
  }

  removeGridCellsFromOtherAreas(gridCells: GridCell[]) {
    this.areas.forEach((gridTemplateArea: GridTemplateArea) => {
      gridTemplateArea.gridCells = gridTemplateArea.gridCells.filter(areaGridCell =>
        !gridCells.some(c => c.columnIndex === areaGridCell.columnIndex && c.rowIndex === areaGridCell.rowIndex))
    })
  }


  addArea(area: GridTemplateArea) {
    this.areas.push(area);
  }
  removeArea(area: GridTemplateArea) {
    this.areas = this.areas.filter(eachArea => eachArea.areaName != area.areaName);
  }
  get allAreas(): GridTemplateArea[] {
    return this.areas;
  }
}

export class GridTemplateArea {

  constructor(public gridCells: GridCell[]) {
    if (gridCells.length <= 0) {
      // throw new Error("Can not create grid area without any cells");
    }
  }
  static parse(gridTemplateAreaObj: GridTemplateArea) {
    const gridCells = gridTemplateAreaObj.gridCells.map(gc => GridCell.parse(gc));
    return new GridTemplateArea(gridCells);
  }
  public get areaBeginningRowIndex(): number {
    const firstRow = this.gridCells.sort((a1, a2) => a1.rowIndex - a2.rowIndex)[0];
    return firstRow.rowIndex;
  }
  public get areaEndRowIndex(): number {
    const lastRow = this.gridCells.sort((a1, a2) => a2.rowIndex - a1.rowIndex)[0];
    return lastRow.rowIndex;
  }
  public get areaBeginningColumnIndex(): number {
    const firstColumn = this.gridCells.sort((a1, a2) => a1.columnIndex - a2.columnIndex)[0];
    return firstColumn.columnIndex;
  }
  public get areaEndingColumnIndex(): number {
    const lastColumn = this.gridCells.sort((a1, a2) => a2.columnIndex - a1.columnIndex)[0];
    return lastColumn.columnIndex;
  }
  public get areaName(): string {
    // return `R_${this.areaBeginningRowIndex}_C_${this.areaBeginningColumnIndex}_R_${this.areaEndRowIndex}_C_${this.areaEndingColumnIndex}`;
    return GridDefinitionHelper.constructAreaName(this.areaBeginningRowIndex, this.areaBeginningColumnIndex);
  }



  public get areaNameWithRowAndColumnIndexes(): string {
    return `${this.areaBeginningRowIndex}/${this.areaBeginningColumnIndex}/${this.areaEndRowIndex + 1}/${this.areaEndingColumnIndex + 1}`;
  }

  // public allGrips(gridSize: GridSize) {
  //   if(this.gridCells.length > 1)
  //   return; //build all and return
  //   else
  //   return GridCellPositionHelper.getAllGrips(this.gridCells[0],gridSize)
  // }

  //area: e.g.  ["header", "header", "header", "header"]
  // constructor(private index: number, private area: string[]) {

  // }
  //   public get gridAreaIndex(): number {
  //     return this.index;
  //   }
  //   public get gridAreas(): string[] {
  //     return this.area;
  //   }
  //   public get toString(): string {
  //     return this.area.join(" "); //"header header header header"
  //   }
  // }
}

export class GridGap implements CSSAttribute {
  constructor(public rowGap: Dimension, public columnGap: Dimension) {

  }
  static parse(gridGapObj: GridGap) {
    const rowGap = Dimension.parse(gridGapObj.rowGap);
    const columnGap = Dimension.parse(gridGapObj.columnGap);
    return new GridGap(rowGap, columnGap);
  }
  toCSSAttribute(): Map<string, string> {
    const stylesMap = new Map();
    stylesMap.set(CSSPropertyNameConstants.ROW_GAP, this.rowGap.toCssString());
    stylesMap.set(CSSPropertyNameConstants.COLUMN_GAP, this.columnGap.toCssString());
    return stylesMap;
  }
}

export class Dimension {
  constructor(public value: RtOption<number>, public unit: Units) {
    if (this.value.isDefined) {
      const value = this.value.get;
      if (typeof value === 'string') {
        this.value = RtSome(parseFloat(value));
      }
    }
  }
  static parse(dimensionObj: Dimension) {
    const reConstructedDimensionValue = RtOption.parse<number>(dimensionObj.value);
    return new Dimension(reConstructedDimensionValue, dimensionObj.unit);
  }
  toCssString(): string {
    if (this.value.isDefined) {
      return `${this.value.get}${this.unit}`;
    }
    return `${this.unit}`;
  }
  toDisplayString(): string {
    if (this.value.isDefined) {
      return `${this.roundToTwoDecimals(this.value.get)}${this.unit}`;
    }
    return `${this.unit}`;
  }

  private roundToTwoDecimals(n: number) {
    if (this.isFloat(n)) {
      return n.toFixed(2);
    }
    return n;
  }
  isInt(n: number) {
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n: number) {
    return Number(n) === n && n % 1 !== 0;
  }
}
export abstract class CSSPropertyModel {

}

export class Point {
  constructor(public x: number, public y: number) {

  }
}

export interface CSSAttribute {
  toCSSAttribute(): Map<string, string>;
}

export type BoundingClientRect = { left: number, top: number, right: number, bottom: number, x: number, y: number, width: number, height: number };

export type GridCompanionModel = {
  gridChildDropAreaRef: ViewContainerRef, columnsHeaderViewContainerRef: ViewContainerRef, rowsHeaderViewContainer: ViewContainerRef,
  areasViewContainerRef: ViewContainerRef, gridDefinition: GridDefinition
};

export type ColumnConfigurations = { columnDefinition: ColumnDefinition, columnHeaderArea: GridTemplateArea };
export type RowConfigurations = { rowDefinition: RowDefinition, rowHeaderArea: GridTemplateArea };
