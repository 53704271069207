import { ComponentRef, ViewContainerRef } from "@angular/core";
import { EventStoreActionWrapper } from "projects/den-core/base-models";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SubSink } from "subsink";
import { EventService } from "../../../../providers/event-service/event.service";
import { IdentityGenerator } from "../../../../utils/identity-generator";
import { RtNone, RtOption, RtSome } from "../../../../utils/option-helper";
import { AttributeInstance } from "../../../attributes/core/attribute-instance";
import { PubSubTopic } from "../../../constants/pub-sub-topic-constants";
import { WebControlRegistryConstant } from "../../../constants/web-control-registry-constants";
import { DsResultArray, IDsResult } from "../../../data-source/data-source-result-entities";
import { CompanionMixinType, ControlRegistryProvider } from "../../../ide/services/control-registry-provider";
import { ControlDropData, ControlDropTypeEnum, ControlReArrangeDrop, DropChildData, StandardControlDrop } from "../../../models/model";
import { AppToEditorMessagingService, ATTRIBUTE_PANEL_CONFIG, EditorToAppMessagingService, PropertyChanged } from "../../../services/attribute-panel-bridging/control-attribute-panel-messaging.service";
import { ControlRenderProgressIndicatorService } from "../../../services/control-render-progress-indicator/control-render-progress-indicator.service";
import { PageService } from "../../../services/page-service";
import { DraftPageWriterService } from "../../../services/page/draft-page-writer-service";
import { AttributeInstanceResolver, AttributeResolverType } from "../attribute-instance-resolver";
import { AddChildWithDsResultCommand, AddStaticChildCommand, ChildCommandResolver, ChildCommandType, ChildControlDeletedCommand } from "../child-rendered-event";
import { ControlInstanceWrapper } from "../control-instance-drafts-models";
import { IControl, RenderedControlInstance } from "../control-model";
import { AppEventTopics, DataSourceReceivedCommand } from "../event/app-event-topics";
import { EventStoreWriterService } from "../event/event-store-writer.service";
import { MobiPopupAction } from "../event/mobi-event-actions/popup/popup-action-models";
import { CompanionMixinItems, ICompanionSubMixin } from "./companion-mixin-menu";
import { ICompanionTriggerService } from "./companion-triggers/companion-trigger.service";
import { ControlCompanionHelper } from "./control-companion-helper";
import { CompanionDataChangeCommand, CompanionRequestCommand } from "./control-companion-models";
import { AttributeInstanceUpdateData, CompanionMixin, CompanionMixinChangeEvent, CompanionMixinChangeEventType, CompanionMixinHolder, ControlEventType } from "./mixins/companion-mixin";
import { SubMixinMenuManagerService } from "./services/sub-mixin-menu-manager/sub-mixin-menu-manager.service";

export type ControlMixin = { mixinHolder: CompanionMixinHolder, companionMixinType: CompanionMixinType; };
export class ControlCompanion<D extends IDsResult, C extends IControl<D>> extends CompanionMixinItems {
  instanceId: string;
  ctrlInstance: ControlInstanceWrapper;
  divContainer: ControlInstanceWrapper;

  constructMenuItems(): ICompanionSubMixin[] {
    const menuItems = Array.from(this.mixinsComponentRefs.values()).map((mixinHolder: CompanionRefWithSubscription) =>
      mixinHolder.companionRef.instance.constructMenuItems()).flat();
    return menuItems;
  }

  private control: IControl<D>;
  private controlInstance: ControlInstanceWrapper;
  private viewContainerRef: ViewContainerRef;
  private companionRequestCommand: CompanionRequestCommand<D, C>;

  private subs = new SubSink();
  private mixins: Map<string, ControlMixin> = new Map();
  private mixinsComponentRefs: Map<string, CompanionRefWithSubscription> = new Map();

  constructor(private eventService: EventService, private pageService: PageService, private draftPageWriterService: DraftPageWriterService,
    private companionTriggerService: ICompanionTriggerService,
    private subMixinMenuManagerService: SubMixinMenuManagerService, private controlRegistry: ControlRegistryProvider,
    private eventStoreWriterService: EventStoreWriterService, private appToEditorMessagingService: AppToEditorMessagingService,
    private controlRenderProgressIndicatorService: ControlRenderProgressIndicatorService,
    private editorToAppMessagingService?: EditorToAppMessagingService) {
    super();
  }

  init(companionRequestCommand: CompanionRequestCommand<D, C>, mixins: ControlMixin[]): void {

    this.initMixins(mixins);
    this.control = companionRequestCommand.control;
    this.companionRequestCommand = companionRequestCommand;
    this.viewContainerRef = companionRequestCommand.viewContainerRef;
    this.controlInstance = companionRequestCommand.control.controlInstance;
    // (this.control as unknown as ModalDialogComponent)?.mixinInitEvent();

    this.registerForTriggerEvents(mixins, companionRequestCommand);
    this.subscribeForPropertyChanges();
    this.subscribeForDataBindingChanges();
    this.subscribeForChildDeleteEvent();
    this.subscribeForReloadRepeaterEvent();
    this.subscribeForControlDataSourceUpdateEvent();
    this.subscribeForControlInstanceChanges();
    this.subscribeForControlRepositionedEvent(this.controlInstance.instanceId);
    this.subscribeToAddNewControl();
    this.manageMixins(ControlEventType.ON_LOAD);
    this.subscribeForControlRearrangeFromOutlineEvent();
    this.subscribeForControlCopyPasteFromOutlineEvent();
    this.subscribeForRerenderChildren();
    this.subscribeForPublishOrDiscard();
    this.subscribeForClearPage();
    this.saveControlInstanceSubscription();
    this.openPopupForMobileSubscription();
    this.deleteEventActionSubscription();

  }

  subscribeForControlDataSourceUpdateEvent() {
    const dataSourceUpdateTopic = PubSubTopic.buildControlDataSourceChangeTopic(this.control.id);
    this.subs.add(this.eventService.subscribe(dataSourceUpdateTopic, () => {
      this.control.reInitialiseDataSourceExecutor();
    }));
  }

  private initMixins(mixins: ControlMixin[]) {
    mixins.forEach(mixin => {
      this.mixins.set(IdentityGenerator.guid(), mixin);
    });

  }


  destroy(): void {
    this.companionTriggerService.destroy();
    this.destroyMixins();
    this.removeListeners();
    this.subs.unsubscribe();
  }

  onControlDataUpdated(_data: CompanionDataChangeCommand): void {

  }

  removeListeners() {
  }

  private registerForTriggerEvents(mixins: ControlMixin[], companionRequestCommand: CompanionRequestCommand<D, C>) {
    const mixinHolders = mixins.map(mixin => mixin.mixinHolder);
    this.subs.add(this.companionTriggerService.subscribeForEvents(mixinHolders, companionRequestCommand).subscribe((controlEvents: ControlEventType) => {
      this.manageMixins(controlEvents);
    }));
  }

  private manageMixins(controlEvent: ControlEventType) {
    const mixinsArray: MixinWithIdentifier[] = [...this.mixins].map(([id, mixin]: [string, ControlMixin]) => {
      return { id: id, controlMixin: mixin };
    });
    this.instantiateMixinIfMixinInitiationEventMatchesCurrentEvent(mixinsArray, controlEvent);
    this.destroyMixinsIfTheMixinDestroyEventsMatchesTheCurrent(mixinsArray, controlEvent);
  }

  private instantiateMixinIfMixinInitiationEventMatchesCurrentEvent(mixinsArray: MixinWithIdentifier[], controlEvent: ControlEventType) {
    const instantiatedMixinsIds: string[] = [...this.mixinsComponentRefs].map(([id, _mixin]: [string, CompanionRefWithSubscription]) => id);
    const companionMixinsToInit: MixinWithIdentifier[] = mixinsArray.filter((mixinWithIdentifier: MixinWithIdentifier) =>
      mixinWithIdentifier.controlMixin.mixinHolder.mixinInitiationEvent === controlEvent && !instantiatedMixinsIds.some(id => mixinWithIdentifier.id === id));
    if (companionMixinsToInit?.length > 0) {
      this.createMixins(companionMixinsToInit);
    } else {
      //Update submixin menu if the companion self mixin is already instantiated
      this.updateMenuOptionsIfSelfMixinIsAlreadyInstantiated(mixinsArray, controlEvent);
    }
  }

  private destroyMixinsIfTheMixinDestroyEventsMatchesTheCurrent(mixinsArray: MixinWithIdentifier[], controlEvent: ControlEventType) {
    const companionMixinsToDestroy = mixinsArray.filter((mixinWithIdentifier: MixinWithIdentifier) =>
      mixinWithIdentifier.controlMixin.mixinHolder.mixinDestroyEvents.some((event) => event === controlEvent));
    if (companionMixinsToDestroy?.length > 0) {
      this.subMixinMenuManagerService.createSubMenuComponent(this.viewContainerRef, []);
      this.destroyCompanionMixin(companionMixinsToDestroy);
    }
  }

  private updateMenuOptionsIfSelfMixinIsAlreadyInstantiated(mixinsArray: MixinWithIdentifier[], controlEvent: ControlEventType) {
    const selfMixin = mixinsArray.find((mixinWithIdentifier: MixinWithIdentifier) => mixinWithIdentifier.controlMixin.companionMixinType === CompanionMixinType.SELF_MIXIN &&
      (mixinWithIdentifier.controlMixin.mixinHolder.mixinInitiationEvent === controlEvent));
    //It is required to check if the current event is CLICK as some of the mixins initiation events will not be always CLICK
    if (selfMixin || controlEvent === ControlEventType.CLICK) {
      this.constructSubMixinMenu();
    }
  }

  private destroyCompanionMixin(companionMixinsToDestroy: MixinWithIdentifier[]) {
    companionMixinsToDestroy.forEach(companionMixinHolder => {
      const mixinComponentRef = this.mixinsComponentRefs.get(companionMixinHolder.id);
      if (mixinComponentRef) {
        mixinComponentRef.companionRef.destroy();
        mixinComponentRef.subscription.unsubscribe();
        this.mixinsComponentRefs.delete(companionMixinHolder.id);
      }
    });
  }

  private publishAttributesChanges(attributeInstanceUpdateData: AttributeInstanceUpdateData) {
    this.controlInstance.allAttributeValues = attributeInstanceUpdateData.allAttributeInstances;
    const propertyChanged: PropertyChanged = {
      controlInstance: this.controlInstance, pageId: this.controlInstance.pageName,
      panelConfig: ATTRIBUTE_PANEL_CONFIG.CONTROL, updatedAttributeInstance: attributeInstanceUpdateData.updatedAttributeInstance
    };
    this.eventService.publish(PubSubTopic.buildCssPropertiesTopicId(this.controlInstance.instanceId), propertyChanged);
  }

  private renderOnControlDrop(controlDropData: ControlDropData<DropChildData>) {
    if (controlDropData.type === ControlDropTypeEnum.CONTROL_RE_ARRANGE) {
      this.saveReArrangedControl(controlDropData as ControlReArrangeDrop);
    } else if (controlDropData.type === ControlDropTypeEnum.STANDARD) {
      this.saveControlInstance(controlDropData as StandardControlDrop);
    } else {
      this.eventService.publish(PubSubTopic.ON_FORM_FIELD_DROPPED, controlDropData);
    }
  }

  private saveReArrangedControl(controlReArrangeData: ControlReArrangeDrop) {
    const controlInstance = controlReArrangeData.childControlInstanceWrapper.controlInstance;
    const parentInstanceId = controlReArrangeData.parentData.parentInstanceId;
    controlInstance.parentInstanceId = parentInstanceId;
    this.subs.add(this.draftPageWriterService.saveOrUpdateControlInstance(new ControlInstanceWrapper(controlInstance), controlReArrangeData.parentData.pageName)
      .subscribe((instance: ControlInstanceWrapper) => {
        this.onSaveOfControlInstance(parentInstanceId, instance);
        this.publishControlRepositionedEvent(controlInstance.instanceId);
      }));
  }

  private subscribeForControlRepositionedEvent(controlInstanceId: string) {
    //CONTROL_MOVED_CONTROL_INSTANCE
    //Destroy this.
    const controlReArrangedTopic = PubSubTopic.buildControlReArrangedTopicId(controlInstanceId);
    this.subs.add(this.eventService.subscribe(controlReArrangedTopic, () => {
      this.deleteControl();
    }));
  }

  private publishControlRepositionedEvent(controlInstanceId: string) {
    //CONTROL_MOVED_CONTROL_INSTANCE
    const controlReArrangedTopic = PubSubTopic.buildControlReArrangedTopicId(controlInstanceId);
    this.eventService.publish(controlReArrangedTopic, {});
  }


  private subscribeForPropertyChanges() {
    this.subs.add(this.eventService.subscribe(PubSubTopic.buildCssPropertiesTopicId(this.controlInstance.instanceId),
      async (event: PropertyChanged) => {
        if (event.controlInstance.instanceId === this.controlInstance.instanceId) {
          if (this.control.parentCmd.repeaterControlId.isDefined && event.panelConfig == ATTRIBUTE_PANEL_CONFIG.CONTROL_CONDITIONAL_BEHAVIOR) {
            this.publishReloadChildrenCommand(this.control.parentCmd.repeaterControlId);
          } else {
            this.saveControlProperties(event);
            const updatedAttributes = this.getUpdatedAttributes(event);
            const attributes = await this.companionRequestCommand.control.descriptor.getAttributes();
            const allAttributeInstanceHolder = AttributeInstanceResolver.convertAttributeInstanceToAttributeInstanceHolder(this.controlInstance.controlName,
              updatedAttributes, attributes);
            this.companionRequestCommand.control.applyProperties(allAttributeInstanceHolder, AttributeResolverType.ON_CONFIGURATION_CHANGE);
          }
        }
      }));
  }

  private getUpdatedAttributes(event: PropertyChanged): AttributeInstance[] {
    if (event.panelConfig == ATTRIBUTE_PANEL_CONFIG.CONTROL_QUICK_STYLES) {
      return event.controlInstance.allAttributeValues;
    } else if (RtSome(event.updatedAttributeInstance).isDefined) {
      return [event.updatedAttributeInstance];
    }
    return [];
  }

  //save proerties
  private controlInstanceAttributeChanges = new Subject<{ controlInstance: ControlInstanceWrapper, pageId: string; }>();
  private saveControlProperties(event: PropertyChanged) {
    if (event.panelConfig == ATTRIBUTE_PANEL_CONFIG.CONTROL) {
      this.controlInstanceAttributeChanges.next({ controlInstance: event.controlInstance, pageId: event.pageId });
    } else if (event.panelConfig == ATTRIBUTE_PANEL_CONFIG.CONTROL_QUICK_STYLES) {
      const completedAPIExecutionTopic = PubSubTopic.buildCompletedAPIExecutionTopicId(event.controlInstance.id);
      this.eventService.publish(completedAPIExecutionTopic, true);
      this.publishControlInstanceWrapperToEditor(event.controlInstance);
    }
  }

  private subscribeForControlInstanceChanges() {
    this.subs.add(this.controlInstanceAttributeChanges.pipe(debounceTime(3000)).subscribe((data: { controlInstance: ControlInstanceWrapper, pageId: string; }) => {
      this.subs.add(this.draftPageWriterService.saveOrUpdateControlInstance(data.controlInstance, data.pageId).subscribe(() => {
        const completedAPIExecutionTopic = PubSubTopic.buildCompletedAPIExecutionTopicId(data.controlInstance.id);
        this.eventService.publish(completedAPIExecutionTopic, true);
        this.publishControlInstanceWrapperToEditor(data.controlInstance);
      }));
    }));
  }

  private subscribeForDataBindingChanges(): void {
    this.subs.add(this.eventService.subscribe(PubSubTopic.buildDataReceivedTopicId(this.control.id),
      (data: DataSourceReceivedCommand) => {
        if (data.controlInstance.instanceId === this.controlInstance.instanceId) {
          this.subs.add(this.draftPageWriterService.saveOrUpdateControlInstance(data.controlInstance, data.pageId).subscribe(() => {
            this.companionRequestCommand.control.applyPropertyDefinitions(data.controlInstance.propertyDefinitions);
            this.updatePropertyDefinition(data);
            if (this.control.parentCmd.repeaterControlId.isDefined) {
              this.publishReloadChildrenCommand(this.control.parentCmd.repeaterControlId);
            } else {
              this.control.reInitialiseDataSourceExecutor();
            }
          }));
        }
      }));
  }

  private updatePropertyDefinition(data: DataSourceReceivedCommand) {
    this.controlInstance.propertyDefinitions = data.controlInstance.propertyDefinitions;
  }

  private saveControlInstance(controlDropData: StandardControlDrop) {

    const controlInstance = ControlCompanionHelper.constructControlInstance(controlDropData);
    const parentInstanceId = controlDropData.parentData.parentInstanceId;

    this.subs.add(this.draftPageWriterService.saveOrUpdateControlInstance(new ControlInstanceWrapper(controlInstance), controlDropData.parentData.pageName)
      .subscribe((instance: ControlInstanceWrapper) => {
        this.onSaveOfControlInstance(parentInstanceId, instance);
        this.addEventProducerAndDefaultEventToEventStore(instance);
      }));
  }

  private addEventProducerAndDefaultEventToEventStore(instance: ControlInstanceWrapper) {
    const controlDescriptor = RtSome(this.controlRegistry.getControlDescriptor(instance.controlName));
    if (controlDescriptor.isDefined) {
      const eventsToAttach = controlDescriptor.get.eventsToAttachOnInstanceCreation;
      if (eventsToAttach.length > 0) {
        this.eventStoreWriterService.addProducerAndDefaultEventIfApplicable(instance.instanceId, instance.name, eventsToAttach);
      }
    }
  }

  private subscribeToAddNewControl() {
    const topic = PubSubTopic.buildNewControlAddedTopicId(this.controlInstance.instanceId);
    this.subs.add(this.eventService.subscribe(topic, (ctrlInstanceWrapper: ControlInstanceWrapper) => {
      this.onSaveOfControlInstance(ctrlInstanceWrapper.parentInstanceId, ctrlInstanceWrapper);
      this.addEventProducerAndDefaultEventToEventStore(ctrlInstanceWrapper);
    }));
  }
  private onSaveOfControlInstance(parentInstanceId: string, instance: ControlInstanceWrapper) {

    if (parentInstanceId) {
      const repeaterControlId = this.getRepeaterControlId();
      const cmd = new AddStaticChildCommand(this.controlInstance, repeaterControlId, this.control.id, new Map());
      this.control.renderChild(instance, cmd)
        .subscribe({
          next: renderedChildControl => {
            this.notifyToSelfMixin(renderedChildControl);
            this.publishReloadChildrenCommand(repeaterControlId);
          },
          error: error => {
            console.error(error);
          }
        });
    } else {
      // const controlInfo = new RootAngularControlInfo(this.viewContainerRef);
      // this.ctrlRenderService.createControlInstanceAndRender(new ControlInstanceWrapper(controlInstance), RtNone(), controlInfo);
    }
    // TODO:
    this.draftPageWriterService.elementStatus.next(instance.controlInstance);
    this.appToEditorMessagingService.publishToEditor(AppEventTopics.buildOnControlAddedTopic(), instance.controlInstance)
    console.error(`TODO: Refactor draftPageService.elementStatus usages to subscribe on AppEventTopics.buildOnControlAddedTopic() topic`);
  }

  private notifyToSelfMixin(renderedChildControl: RenderedControlInstance) {
    const selfMixin: [string, ControlMixin] = [...this.mixins].find(([id, mixin]: [string, ControlMixin]) => mixin.companionMixinType === CompanionMixinType.SELF_MIXIN);
    if (selfMixin) {
      const selfMixinInstance = this.mixinsComponentRefs.get(selfMixin[0]);
      if (selfMixinInstance) {
        selfMixinInstance.companionRef.instance.onChildControlAdded(renderedChildControl);
      }
    }
  }
  private getRepeaterControlId(): RtOption<string> {
    let repeaterId = this.control.parentCmd.repeaterControlId;
    if (this.control.resultClassName === DsResultArray.name) {
      repeaterId = RtSome(this.control.id);
    }
    return repeaterId;
  }

  private createMixins(mixins: MixinWithIdentifier[]) {
    mixins.map(mixin => this.createMixin(mixin));
  }

  private async createMixin(mixinWithIdentifier: MixinWithIdentifier) {

    const controlMixin = await mixinWithIdentifier.controlMixin.mixinHolder.mixin().then(r => r);
    const componentRef: ComponentRef<CompanionMixin> = this.viewContainerRef.createComponent<CompanionMixin>(controlMixin);

    componentRef.instance.initMixin(this.companionRequestCommand);
    const changesSubscription = this.subscribeForMixinChanges(componentRef);
    this.mixinsComponentRefs.set(mixinWithIdentifier.id, { companionRef: componentRef, subscription: changesSubscription });
    if (mixinWithIdentifier.controlMixin.companionMixinType == CompanionMixinType.SELF_MIXIN) {
      this.constructSubMixinMenu();
    }
  }
  private constructSubMixinMenu() {
    //Construct sub-menu
    const subMenuItems = this.constructMenuItems();
    this.subMixinMenuManagerService.createSubMenuComponent(this.viewContainerRef, subMenuItems);
  }

  private subscribeForMixinChanges(componentRef: ComponentRef<CompanionMixin>): Subscription {
    const observable = componentRef.instance.getMixinChangesNotifier();
    return observable.subscribe((companionMixinChangeEvent: CompanionMixinChangeEvent) => {
      switch (companionMixinChangeEvent.eventType) {
        case CompanionMixinChangeEventType.CONTROL_DROPPED:
          this.controlRenderProgressIndicatorService.showProgressIndicator();
          this.renderOnControlDrop(companionMixinChangeEvent.data as ControlDropData<DropChildData>);
          break;
        case CompanionMixinChangeEventType.ATTRIBUTES_CHANGE:
          this.publishAttributesChanges(companionMixinChangeEvent.data as AttributeInstanceUpdateData);
          break;
        case CompanionMixinChangeEventType.CONTROL_DELETE:
          this.deleteControl();
          break;
        default:
          break;
      }
    });
  }

  private subscribeForChildDeleteEvent() {
    const topic = PubSubTopic.buildOnChildControlDeleteTopicId(this.control.id);
    this.subs.add(this.eventService.subscribe(topic, (childControlDeletedCommand: ChildControlDeletedCommand) => {
      this.control.deleteChildControl(childControlDeletedCommand);
    }));
  }
  // to remove control when drag and drop from outlne
  private subscribeForControlRearrangeFromOutlineEvent() {
    const topic = PubSubTopic.buildControlRearrangeFromOutlineTopicId(this.controlInstance.instanceId)
    this.subs.add(this.eventService.subscribe(topic, (_controlInstance: ControlInstanceWrapper) => {
      this.deleteControl();

    }));
  }
  // SUbscribing to rerender children when control is copy paste or drag and drop from outline. 
  private subscribeForControlCopyPasteFromOutlineEvent() {
    const topic = PubSubTopic.buildControlCopyPasteFromOutlineTopicId(this.controlInstance.instanceId)
    this.subs.add(this.eventService.subscribe(topic, (controlInstance: ControlInstanceWrapper) => {
      this.rerenderChildren(controlInstance);

    }));
  }
  private subscribeForRerenderChildren() {
    const topic = PubSubTopic.buildControlRerenderTopicId(this.controlInstance.instanceId)
    this.subs.add(this.eventService.subscribe(topic, () => {
      this.control.reloadChildren();
    }));
  }

  private subscribeForPublishOrDiscard() {
    const topic = PubSubTopic.RELOAD_PAGE_ON_PUBLISH_OR_DISCARD;
    this.subs.add(this.eventService.subscribe(topic, () => {
      // Reload/rerender only layout control
      if (this.isLayoutControl()) {
        this.rerenderChildren(this.controlInstance);
      }
    }));
  }
  private isLayoutControl() {
    return this.controlInstance.parentInstanceId == null &&
      (this.controlInstance.controlName === WebControlRegistryConstant.LAYOUT || this.controlInstance.controlName === WebControlRegistryConstant.REPORT_LAYOUT);
  }

  private subscribeForClearPage() {
    const topic = PubSubTopic.RELOAD_PAGE_ON_CLEARING_PAGE;
    this.subs.add(this.eventService.subscribe(topic, (_controlInstance: ControlInstanceWrapper) => {
      if (this.isLayoutControl()) {
        this.rerenderChildren(this.controlInstance);
      }
    }));
  }

  private rerenderChildren(controlInstance: ControlInstanceWrapper) {
    // When datasource is binded then just reloading its children
    if (this.control.parentCmd.repeaterControlId.isDefined) {
      this.publishReloadChildrenCommand(this.control.parentCmd.repeaterControlId);
    } else {
      // When there is no datasource reload all children.
      this.control.reloadChildren();
      this.publishControlRepositionedEvent(controlInstance.instanceId);
    }

  }

  private subscribeForReloadRepeaterEvent() {
    const topic = PubSubTopic.buildReloadRepeaterTopic(this.control.id);
    this.subs.add(this.eventService.subscribe(topic, () => {
      //Reload child controls only if the data source is configured for the repeater control
      const hasDataSourceConfigured = this.pageService.hasDataSourcesConfigured(this.control.controlInstance.instanceId);
      if (hasDataSourceConfigured) {
        this.control.reloadChildren();
      }
    }));
  }

  private deleteControl() {
    this.publishChildControlDeletedCommand();
    this.publishReloadChildrenCommand(this.control.parentCmd.repeaterControlId);
  }

  private publishChildControlDeletedCommand() {
    const parentId = this.getParentId();
    if (parentId.isDefined) {
      const topic = PubSubTopic.buildOnChildControlDeleteTopicId(parentId.get);
      const childControlDeletedCommand: ChildControlDeletedCommand = { childControlId: this.control.id };
      this.eventService.publish(topic, childControlDeletedCommand);
    }
  }
  private publishReloadChildrenCommand(repeaterControlId: RtOption<string>) {
    if (repeaterControlId.isDefined) {
      const topic = PubSubTopic.buildReloadRepeaterTopic(repeaterControlId.get);
      this.eventService.publish(topic, {});
    }
  }

  private getParentId(): RtOption<string> {
    const commandType = this.control.parentCmd.commandType;
    if (commandType === ChildCommandType.STATIC) {
      const addStaticChildCommand = ChildCommandResolver.as<AddStaticChildCommand>(this.control.parentCmd);
      return RtSome(addStaticChildCommand.parentId);
    } else if (commandType === ChildCommandType.NEW_WITH_DS_RESULT) {
      const addChildCommand = ChildCommandResolver.as<AddChildWithDsResultCommand>(this.control.parentCmd);
      return RtSome(addChildCommand.parentId);
    }
    return RtNone();
  }

  //Publish the updated control instance with respect to property panel changes.
  private publishControlInstanceWrapperToEditor(controlInstance: ControlInstanceWrapper) {
    const controlInstanceSelected = PubSubTopic.buildControlInstanceWrapperTopicId(controlInstance.instanceId);
    this.appToEditorMessagingService.publishToEditor(controlInstanceSelected, controlInstance);
  }


  private destroyMixins() {
    this.mixinsComponentRefs.forEach(mixinRef => {
      mixinRef.companionRef.destroy();
      mixinRef.subscription.unsubscribe();
    });
    this.mixinsComponentRefs.clear();
    this.mixins.clear();
  }

  private saveControlInstanceSubscription() {
    this.subs.add(this.eventService.subscribe(PubSubTopic.SAVE_CONTROL_INSTANCE_SUCCESS, (controlInstanceWrapper: ControlInstanceWrapper) => {
      this.control.reloadChildren();
    }));
  }

  private deleteEventActionSubscription() {
    this.subs.add(this.eventService.subscribe(PubSubTopic.DELETE_EVENT_ACTION, (eventStoreCommunication: EventStoreActionWrapper) => {
      this.editorToAppMessagingService.publishToApp(PubSubTopic.EVENT_STORE_ACTION_MESSENGER, eventStoreCommunication);
    }));
  }

  private openPopupForMobileSubscription() {
    this.subs.add(this.eventService.subscribe(AppEventTopics.MOBILE_POP_UP, (clonedPopup: MobiPopupAction) => {
      this.editorToAppMessagingService.publishToApp(AppEventTopics.OPEN_MOBILE_POPUP_UP, clonedPopup);
    }));
  }


}

type MixinWithIdentifier = { id: string, controlMixin: ControlMixin; };
type CompanionRefWithSubscription = { companionRef: ComponentRef<CompanionMixin>, subscription: Subscription; };
