import { Injectable } from "@angular/core";
import { EventService } from "../../../../../providers/event-service/event.service";
import { AppToEditorMessagingService } from "../../../../services/attribute-panel-bridging/control-attribute-panel-messaging.service";
import { ControlType } from "../../control-model";
import { AngularCompanionTriggerService } from "./angular-companion-trigger.service";
import { ICompanionTriggerService } from "./companion-trigger.service";
import { LeafletCompanionTriggerService } from "./leaflet-companion-trigger.service";
import { ThreeJsCompanionTriggerService } from "./threejs-companion-trigger.service";


@Injectable()
export class CompanionTriggerResolverService {

  constructor(private eventService: EventService, private appToEditorMessagingService: AppToEditorMessagingService) {

  }

  resolveCompanionTriggerService(controlType: ControlType): ICompanionTriggerService {
    switch (controlType) {
      case ControlType.ANGULAR:
        return new AngularCompanionTriggerService(this.eventService, this.appToEditorMessagingService);
      case ControlType.THREEJS:
        return new ThreeJsCompanionTriggerService(this.eventService, this.appToEditorMessagingService);
      case ControlType.LEAFLET:
        return new LeafletCompanionTriggerService(this.eventService, this.appToEditorMessagingService);
        case ControlType.REPORT:
        return new AngularCompanionTriggerService(this.eventService, this.appToEditorMessagingService);
      default:
        throw new Error(`No CompanionTriggerService found for controlType: ${controlType}`);
    }
  }
}
