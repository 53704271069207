export class RtOption<T> {

    private readonly value: T;

    constructor(value: T | undefined) {
        if (value !== undefined && value != null) {
            this.value = value;
        } else {
            this.value = undefined;
        }
    }

    public get isDefined(): boolean {
        return this.value !== undefined && this.value != null;
    }

    public get isEmpty(): boolean {
        return this.value === undefined || this.value == null;
    }

    public get get(): T {
        if (this.value !== undefined) {
            return this.value;
        } else {
            throw new Error('None.get error');
        }
    }

    // scala: @inline final def getOrElse[B >: A](default: => B): B =
    // if (isEmpty) default else this.get

    public getOrElse(fn: () => T): T {
        if (this.isEmpty)
            return fn();
        else return this.get;
    }

    public getOrElseThrow(errorMsg: string): T {
        if (this.isEmpty)
            throw new Error(errorMsg);
        else return this.get;
    }

    public getOrElseV2(alternativeValue: T): T {
        if (this.isEmpty)
            return alternativeValue;
        else return this.get;
    }

    public get getOrElseUndefined(): T {
        return this.getOrElse(() => undefined);
    }

    public get getOrElseNull(): T {
        return this.getOrElse(() => null);
    }

    //scala: def map[B, That](f: A => B): That
    //map[B](f: A => B): Option[B]
    public map<B>(fn: (t: T) => B): RtOption<B> {

        if (this.isDefined) {
            const value: B = fn(this.get);
            return RtSome(value);
        } else {
            return RtNone();
        }
    }

    public get toArray(): T[] {
        if (this.isDefined) {
            return [this.get];
        } else {
            return [];
        }
    }

    static parse<T>(val: RtOption<T>): RtOption<T> {
        const valObj = val as any as { value?: any; };
        if (valObj.value || valObj.value == 0) {
            return RtSome(valObj.value);
        }
        return RtNone();
    }
}

export function RtSome<T>(value: T) {
    return new RtOption<T>(value);
}

export function RtNone<T>() {
    return new RtOption<T>(null);
}

//RT Map

class RtMap<K, V> {
    private undelying: Map<K, V> = new Map()

    get(key: K) {
        return RtSome(this.undelying.get(key))
    }
}


//export const RtNoneTest :  { new () : RtNone<unknown> } ;
//type NC<RtNone> = { new (): RtNone };



//add this abstract property on base ThreedModel, and implement on each of the model
//access the same on box helper to display the labels
// export class ScaleLabels  {
//     constructor(public xLabel: string,      //Cone: radius  3DModel:Length
//         public yLabel: string,              //Cone: Height  3DModel: height
//         public zLabel: RtOption<string>     //Cone: None    3DModel: depth
//         ) { }
// }
// export abstract ThreeJsObjectControl {
//   scalelabels:ScaleLabels
// }
// //extend on base ThreedModel andadapt x,y,z data on respective controls (e.g. Cone, Box, etc) independently
// interface IScaleHelper {
//     applyScaleValue(vector:Vector3)
// }
