import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IWSMessage, SessionStorageProvider } from 'projects/den-core';
import { ApplicationStorageProvider } from 'projects/den-core/src/lib/providers/abstract/application-storage-provider';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    // TODO Members should be private and should be accessible through getter & setters

    containerSubject: Subject<any> = new Subject();
    reloadNotificationSubject: Subject<IWSMessage<Object | string>> = new Subject();
    navigateToNotificationSubject: Subject<boolean> = new Subject();
    reloadUnReadNotificationCount: Subject<boolean> = new Subject();
    loginSubject: Subject<boolean> = new Subject();
    constructedTreeList: any;
    moduleId: number;
    showRecoverPasswordPage: boolean;
    userId: number;
    progressButtonLabel: string;
    private isEmbedddedMode: boolean;

    constructor(private router: Router, private sessionStorageService: SessionStorageProvider,
        private applicationSessionStorageService: ApplicationStorageProvider) { }

    getIsEmbeddedModeStatus() {
        return this.isEmbedddedMode;
    }
    setIsEmbeddedModeStatus(isEmbeddedMode: boolean) {
        this.isEmbedddedMode = isEmbeddedMode;
    }
    getLoginAsObservable(): Observable<boolean> {
        return this.loginSubject.asObservable();
    }
    getDynamicViewRefAsObservable() {
        this.containerSubject.asObservable();
    }
    getReloadNotificationAsObservable(): Observable<IWSMessage<Object | string>> {
        return this.reloadNotificationSubject.asObservable();
    }
    navigateToNotificationSubjectAsObservable(): Observable<boolean> {
        return this.navigateToNotificationSubject.asObservable();
    }
    reloadCountSubjectAsObservable(): Observable<boolean> {
        return this.reloadUnReadNotificationCount.asObservable();
    }
    addFlexContent() {
        const tableContainer = document.getElementById('table-container');
        if (document.body.className.indexOf('flex-content') === -1) {
            if (tableContainer && tableContainer.style.height !== '') {
                tableContainer.style.height = parseInt(tableContainer.style.height) + 0 + 'px';
            }
        }
        document.body.classList.add('flex-content');
        document.body.classList.add('minified');
    }
    getRouterState() {
        let path = '';
        const hash = location.hash;
        if (hash && hash.length) {
            path = location.hash.replace('#/', '');
        }
        return path;
    }
    setRouterState(currentPage: string) {
        this.applicationSessionStorageService.setCurrentPage(currentPage);
        // localStorage.setItem(WebStorageEnum.CURRENT_PAGE, currentPage);
    }

    setCurrentPageAfterClearCacheOnReload() {
        let path = '';
        if (!location.hash.endsWith('#/') && localStorage && localStorage.length === 0) {
            path = location.hash.replace('#/', '');
            if (path.includes('/')) {
                path = '';
            }
            this.setRouterState(path);
        }
    }
    loadUpdatePasswordPage(url: string) {
        let isOnUpdatePassword = false;
        const updatePasswordUrl = 'updatePassword';
        const recoveryTypeURL = 'recoveryType';
        url = url.toLowerCase();
        if (url.includes(updatePasswordUrl.toLowerCase()) && url.includes(recoveryTypeURL.toLowerCase())) {
            isOnUpdatePassword = true;
            const array = url.split(updatePasswordUrl.toLowerCase());
            const params = array[1].split('/');
            const userId = params[1];
            this.router.navigate([updatePasswordUrl + '/' + userId]);
        }
        return isOnUpdatePassword;
    }

    loadPage() {
        const currentPage = this.applicationSessionStorageService.getCurrentPage();
        let route = '';
        if (currentPage && currentPage.length) {
            route = this.constructRoute(currentPage);
        } else {
            route = this.getDefaultPageIfAny(route);
        }
        if (route.includes('?')) {
            this.router.navigateByUrl(route);
        } else {
            this.router.navigate([route]);
        }
    }

    private getDefaultPageIfAny(route: string) {
        let defaultPage = this.applicationSessionStorageService.getDefaultPage();
        if (defaultPage) {
            defaultPage = defaultPage.replace('#/', '');
            route = this.constructRoute(defaultPage);
        }
        return route;
    }


    private constructRoute(path: string) {
        let route = '';
        if (this.isSameMine() && path.includes('/')) {
            route = path;
        } else if (!path.includes('/')) {
            route = path;
        }
        return route;
    }

    private isSameMine(): boolean {
        const currentSiteId = this.sessionStorageService.getSiteCode();
        const previousSiteId = this.applicationSessionStorageService.getPreviousSiteCode();
        if (!previousSiteId) {
            return true;
        }
        else {
            return currentSiteId === previousSiteId;
        }
    }
}
