import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { ICompanionSubMixin } from "../../companion-mixin-menu";
import { PbCompanionMenuItemComponent } from "../../components/pb-companion-menu-item/pb-companion-menu-item.component";

@Injectable()
export class SubMixinMenuManagerService {

  private componentRef: ComponentRef<PbCompanionMenuItemComponent>;

  public createSubMenuComponent(viewContainerRef: ViewContainerRef, menu: ICompanionSubMixin[]) {
    if (!this.componentRef && menu.length > 0) {
      this.componentRef = this.createMenu(viewContainerRef);
    }
    this.componentRef?.instance.groupMenuItemsAndRender(menu);
  }
  private createMenu(viewContainerRef: ViewContainerRef): ComponentRef<PbCompanionMenuItemComponent> {
    const componentRef = viewContainerRef.createComponent(PbCompanionMenuItemComponent);
    return componentRef
  }
}
