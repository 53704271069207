import { Injectable } from '@angular/core';
import { RtOption } from '../../utils/option-helper';
import { CtrlEvent, CtrlEventSubscription } from '../controls/core/event/control-event';


@Injectable()
export class ParameterTopicService {

    private topics: CtrlEvent[] = [];

    insertParameterTopic(parameterTopic: CtrlEvent) {
        // if (!parameterTopic.fieldName.isDefined) { return; }

        const topic = this.topics.find(
            paramTopic =>
                paramTopic.fieldName.isDefined &&
                parameterTopic.fieldName.isDefined &&
                paramTopic.fieldName.get === parameterTopic.fieldName.get &&
                paramTopic.ctrlInstanceId === parameterTopic.ctrlInstanceId
        );
        // add to cache
        if (!topic) {
            this.topics.push(parameterTopic);
        }
    }

    getDefaultParameterTopics(instanceId: string): CtrlEvent[] {
        return this.topics.filter(topic => topic.ctrlInstanceId === instanceId && topic.isDefault === true);
    }

    isDefaultParameterRegistered(instanceId: string): boolean {
        return this.topics.some(topic => topic.ctrlInstanceId === instanceId && topic.isDefault === true);
    }


    /**
     *
     *
     * @returns {CtrlEvent[]}
     * @memberof ParameterTopicService
     */
    getParams(): CtrlEvent[] {
        return Array.from(new Set(this.topics));
    }
    removeParams(topicName: RtOption<string>, ctrlInstanceId: string): void {
        if (topicName.isDefined && ctrlInstanceId) {
            this.topics = this.topics.filter(eachTopic =>
                !(eachTopic.fieldName.isDefined &&
                    eachTopic.fieldName.get === topicName.get &&
                    eachTopic.ctrlInstanceId === ctrlInstanceId));
        }
    }
    removeAllParams(): void {
        this.topics = [];
    }

    // Data sources
    // for DS invocation OR to use param as direct datasource
    getDatasourceBindableEvents(): CtrlEvent[] {
        // collect all eventRegisterToPublish of all CtrlInstances
        return this.topics.filter(t => t.fieldName.isDefined);
    }
    getDatasourceBindableEventsByInstanceId(instanceId: string): CtrlEvent[] {
        // collect all eventRegisterToPublish of all CtrlInstances
        return this.topics.filter(t => t.fieldName.isDefined && t.ctrlInstanceId === instanceId);
    }

    // To use events directly
    // e.g. for pop-up
    getBindableEvents(): CtrlEvent[] {
        // collect all CtrlEventTYpes from ControlDescriptor Metadata
        return this.topics.filter(t => t.fieldName.isDefined === false);
    }

    // when configured, both of the above scenarios require to saveSubscription for the configured instanceId
    saveSubscription(_controlInstanceId: string, _subscription: CtrlEventSubscription) {
        throw new Error('Method not implemented');
    }

}
