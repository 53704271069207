import { Inject, Injectable, Injector } from "@angular/core";
import { DynamicFormsReaderApi } from "projects/den-core";
import { DynamicDependencyProperty, EntitySchemaDataResponse } from "projects/den-core/base-models";
import { Observable } from "rxjs";

@Injectable()
export class ExtendedDynamicFormsReaderApi extends DynamicFormsReaderApi {

    constructor(@Inject(Injector) injector: Injector) {
        super(injector);
    }
    getCollectionById(collectionName: string, id: string, dependencies?: Array<DynamicDependencyProperty>): Observable<EntitySchemaDataResponse> {
        return this.getWithUrl<EntitySchemaDataResponse>(collectionName + '/' + id, dependencies);
    }

}



