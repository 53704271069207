import { Observable, Subject } from "rxjs";
export interface OnDestroyLike {
    ngOnDestroy(): void;
    [key: string]: any;
}

export function componentDestroyed(component: OnDestroyLike): Observable<true> {
    if (component.__componentDestroyed$) {
        return component.__componentDestroyed$;
    }
    const oldNgOnDestroy = component.ngOnDestroy;
    const stop$ = new Subject<true>();
    component.ngOnDestroy = function () {
        oldNgOnDestroy && oldNgOnDestroy.apply(component);
        stop$.next(true);
        stop$.complete();
    };
    return component.__componentDestroyed$ = stop$.asObservable();
}