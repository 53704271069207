import { BehaviourData } from "../../../condition-builder/models/control-conditions"
import { EventAction, EventActionType } from "../../event-store"
import { CommonEventType } from "../../event-types";


export class ControlBindingsAction implements EventAction {

  id: string;

  actionType: EventActionType;

  eventType: CommonEventType;

  containers: ControlBindingsContainer[];

  constructor() { }

}




export class ControlBindingsContainer {

  //control instance to apply

  controlInstanceId: string;

  //behavior to apply

  behaviors: BehaviourData[];

  //When shouldIdentifyTargetControlById is true, publish data on BEHAVIOR_TOPIC_WITH_${ID}

  // shouldIdentifyTargetControlById: boolean,

  //When shouldIdentifyTargetControlById is true, idPropertyName from source control data source params should be selected

  idPropertyName: string;

  canApplyEventDataAsDataSource: boolean;

  controlInstanceName?: String;

  constructor(controlInstanceId: string, idPropertyName: string, behaviors: BehaviourData[], canApplyEventDataAsDataSource: boolean, controlInstanceName?: String) {
    this.controlInstanceId = controlInstanceId;
    this.idPropertyName = idPropertyName;
    this.behaviors = behaviors;
    this.canApplyEventDataAsDataSource = canApplyEventDataAsDataSource;
    this.controlInstanceName = controlInstanceName;
  }
}
