import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';


export interface CompanionMenuItem {
  displayName: string;
  iconName: string;
  enabled: boolean;
  children?: CompanionMenuItem[];
}

@Component({
  selector: 'lib-pb-companion-submenu-item',
  templateUrl: './pb-companion-submenu-item.component.html',
  styleUrls: ['./pb-companion-submenu-item.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PbCompanionSubMenuItemComponent implements OnInit {

 @Input() subItems: CompanionMenuItem[] = [];
 @ViewChild('childMenu', { static: true }) public childMenu: any;

  constructor() { }

  ngOnInit(): void {}

}
