import { AttributeInstance } from '../../../../attributes/core/attribute-instance';
import { CommonEventType } from '../../event/event-types';
import { ConvertibleDefinition } from '../converters/convertibles';

export enum ControlConditionType {
  ALL = "All",
  ANY = "Any"
}

export enum Operators {
  LESS_THAN = "Less Than",
  GREATER_THAN = "Greater Than",
  EQUALS_TO = "Equal To",
  NOT_EQUALS = "Not Equal To",
  IS_SAME = "Is Same",
  IS_BEFORE = "IS Before",
  IS_AFTER = "IS After"
}

export enum ControlConditionCategoryEnum {
  DEFAULT = 'Default',
  VIEW = 'View',
  UPDATE = 'update',
  DELETE = 'Delete'
}

export class ControlConditionGroup {
  constructor(
    public id: string,
    public categoryName: string,    // Default, Edit, Delete, etc.,
    public isShow: boolean,
    public isEventType: boolean,
    public conditionType: ControlConditionType,
    public cssClass: string,
    public customCssStyle: string,
    public controlConditions: ControlCondition[],
    public isEnable: boolean,
    public behaviours: BehaviourData[],
    public devumNotificationTemplateIds: string[],
    public converters: ConvertibleDefinition[],
  ) { }

  evaluate(): boolean {
    // On success, return cssStyle and visibiltiy flag;
    throw Error('method not implemented');
  }
}
export type BehaviourData = {
  name: string, attributeValues: AttributeInstance[]
}
export class ControlCondition {
  constructor(
    public datasourceName: string,
    public ctrlInstanceName: string,
    public ctrlInstanceId: string,
    public eventType: CommonEventType,
    public boundPropertyName: string,
    public condition: string,
    public comparingValue: any,
    public isExpression: boolean,
    public expressionValue: string,
    public isGlobalEvent:boolean,
    public dsPropertyName?: string,
    public producerParamName?: string,
    public producerName?: string,
    public uniqueId?: string,
  ) { }

  evaluate(): boolean {
    throw Error('method not implemented');
  }
}



export type ControlConditionGroupEvaluationResult = { controlConditionGroup: ControlConditionGroup, canExecuteBehaviour: boolean };

// export type ControlConditionEvaluationResults = { conditionGroupsEvaluationResults: ControlConditionGroupEvaluationResultsContainer[] };
export class ControlConditionEvaluationResultsContainer {
  constructor(public isShow: boolean, public cssClass: string, public customCssStyle: string) { }
}







