import { Injector } from "@angular/core";
import { Observable, Observer, Subscriber } from "rxjs";
import { ConfigService } from "../config/config-service";
import { DynamicDependencyProperty, EntitySchemaDataResponse, StatusContainer } from "../models/base.models";
import { DialogProvider } from "../providers/abstract/dialog-provider";
import { ForeignKeyEntityResolverProvider } from "../providers/abstract/foreign-key-entity-Provider";
import { HttpClientProvider } from "../providers/abstract/http-client-provider";
import { LoaderServiceProvider } from "../providers/abstract/loader-service-provider";
import { LocalStorageProvider } from "../providers/abstract/local-storage-provider";
import { ToastServiceProvider } from "../providers/abstract/toast-service-provider";

export class DynamicWriterApi {
    toastService: ToastServiceProvider;

    #indexedDbService: LocalStorageProvider;
    #baseUrl: string;
    #http: HttpClientProvider;
    #loaderService: LoaderServiceProvider;
    #dialog: DialogProvider;
    #dependencyService: ForeignKeyEntityResolverProvider;


    constructor(injector: Injector, entityName: string) {
        const url = this.constructBaseUrl(injector, entityName);
        this.initProviders(injector, url);
    }

    private constructBaseUrl(injector: Injector, entityName: string) {
        const sharedService = injector.get<ConfigService>(ConfigService);
        const url = sharedService.getConfig().serverURL;
        this.#baseUrl = entityName === '' ? this.validateBaseUrl(url) : url + entityName;
        return url;
    }

    private initProviders(injector: Injector, url: string) {
        this.#http = injector.get<HttpClientProvider>(HttpClientProvider);
        this.#indexedDbService = injector.get<LocalStorageProvider>(LocalStorageProvider);
        this.toastService = injector.get<ToastServiceProvider>(ToastServiceProvider);
        this.#loaderService = injector.get<LoaderServiceProvider>(LoaderServiceProvider);
        this.#dialog = injector.get<DialogProvider>(DialogProvider);
        this.#dependencyService = injector.get<ForeignKeyEntityResolverProvider>(ForeignKeyEntityResolverProvider);
        this.#dependencyService.baseUrl = url;
    }

    private validateBaseUrl(url: string) {
        if (!url) { return ''; }
        if (url.endsWith('/')) { return url.slice(0, -1); } else { return url; }
    }

    private constructUrl(url: string) {
        if (!url) {
            return '';
        }
        if (!url.startsWith('/')) {
            return '/'.concat(url);
        } else {
            return url;
        }
    }

    post<T>(url: string, entity: T | T[]): Observable<any> {
        const constructedUrl = this.#baseUrl + this.constructUrl(url);

        return new Observable((observer) => {
            this.#http.post<T>(constructedUrl, entity).subscribe(res => {
                observer.next(res);
            }, (error: ErrorEvent) => observer.error(error));
        });
    }

    private put<T>(url: string, entity: T): Observable<any> {
        const constructedUrl = this.#baseUrl + this.constructUrl(url);
        return new Observable((observer: Observer<T>) => {
            this.#http.put<T>(constructedUrl, entity).subscribe(res => {
                observer.next(res);
            }, (error: ErrorEvent) => { observer.error(error); });
        });
    }

    update<T>(entity: any, url: string): Observable<any> {
        if (entity && entity['name']) {
            entity['name'] = entity['name'].trim();
        }
        return new Observable((observer) => {
            this.put<T>('/' + url, entity)
                .subscribe(
                    (res) => {
                        observer.next(res);
                    }, (error: ErrorEvent) => observer.error(error)
                );
        });
    }


    addInstanceDetails(collectionName: string, obj: EntitySchemaDataResponse): Observable<EntitySchemaDataResponse> {
        return new Observable(observer => {
            this.post<EntitySchemaDataResponse>(collectionName, obj).subscribe({
                next: (data: EntitySchemaDataResponse | StatusContainer) => { this.removeEntityFromIndexDB(collectionName, observer, data); },
                error: (error: ErrorEvent) => { observer.error(error) }
            })
        });
    }
    updateInstanceDetails(obj: EntitySchemaDataResponse, collectionName: string, id: string): Observable<EntitySchemaDataResponse> {
        return new Observable(observer => {
            this.update<EntitySchemaDataResponse>(obj, `${collectionName}/${id}`).subscribe((data: EntitySchemaDataResponse | StatusContainer) => {
                this.removeEntityFromIndexDB(collectionName, observer, data);
            }, (error: any) => observer.error(error));
        });
    }

    updateInstanceListDetails(obj: EntitySchemaDataResponse, collectionName: string, id: string): Observable<EntitySchemaDataResponse> {
        return new Observable(observer => {
            console.log(obj)
            console.log(collectionName)
            observer.next();
            // this.updateList<EntitySchemaDataResponse>(obj, `${collectionName}/${id}`).subscribe((data: EntitySchemaDataResponse | StatusContainer) => {
            //     this.removeEntityFromIndexDB(collectionName, observer, data);
            // }, (error: any) => observer.error(error));
        });
    }

    private deleteDialog(name: string, entityName: string, observer: Observer<boolean>, entityUrl?: string, successCallback?: Function): void {
        const data = { deleteEntityMessage: entityName ? `Are you sure want to delete this ${entityName} ?` : '' };
        this.#dialog.showDeleteDialog(data, this.handleDeleteCloseDialog(name, entityName, observer, entityUrl, successCallback));
    }

    private delete<T>(url: string): Observable<any> {
        const constructedUrl = this.#baseUrl + this.constructUrl(url);
        return this.#http.delete<T>(constructedUrl).pipe(res => {
            return res;
        });
    }

    onDeleteConfirmation(name: string, entityName: string, observer: Observer<boolean>, entityUrl?: string, successCallback?: Function) {
        this.#loaderService.incrementLoaderCount();
        const url = entityUrl ? entityUrl + '/' : '/';
        this.delete(url + name).subscribe((isConfirmToDelete: boolean) => {
            if (isConfirmToDelete) {
                this.#loaderService.decrementLoaderCount();
                //this.entity.next(isConfirmToDelete);
                observer.next(true);
                this.toastService.showSuccess(entityName + ' deleted');
                if (successCallback) {
                    successCallback();
                }
            }
        }, (error: any) => {
            this.#loaderService.decrementLoaderCount();
            observer.error(error);
        });
    }

    private handleDeleteCloseDialog(name: string, entityName: string, observer: Observer<boolean>, entityUrl: string, successCallback: Function): any {
        return (isDelete: boolean) => {
            if (isDelete) {
                this.onDeleteConfirmation(name, entityName, observer, entityUrl, successCallback);
            }
        };
    }

    deleteEntityByUrl(entityUrl: string, name: string, entityName: string, successCallback?: Function): Observable<boolean> {
        if (!entityUrl || !name) { this.toastService.showError('Invalid Input'); }
        return new Observable((observer) => {
            this.deleteDialog(name, entityName, observer, entityUrl, successCallback);
        });
    }

    deleteInstanceDetails(collectionName: string, id: string, successCallback: { (): void; (): void; }) {
        return new Observable(observer => {
            this.deleteEntityByUrl('/' + collectionName, id, collectionName, successCallback).subscribe(() => {
                this.removeEntityFromIndexDB(collectionName, observer);
            }, (error: any) => observer.error(error));
        });
    }
    deleteInstanceDetailsForDeleteControl(collectionName: string, id: string) {
        return new Observable(observer => {
            this.deleteEntityByUrl('/' + collectionName, id, ' ').subscribe(() => {
                this.removeEntityFromIndexDB(collectionName, observer);
            }, (error: any) => observer.error(error));
        });
    }

    private removeEntityFromIndexDB(collectionName: string, observer: Subscriber<EntitySchemaDataResponse>, data?: EntitySchemaDataResponse | StatusContainer) {
        this.#indexedDbService.get(`${this.#baseUrl}/${collectionName}`).subscribe((cashedCollection) => {
            if (cashedCollection) {
                this.#indexedDbService.remove(`${this.#baseUrl}/${collectionName}`).subscribe(() => {
                    if (data && data.hasOwnProperty('id')) {
                        observer.next(data as EntitySchemaDataResponse);
                    } else {
                        observer.next({ status: true });
                    }

                }, () => observer.next({ status: true }));
            } else {
                if (data && data.hasOwnProperty('id')) {
                    observer.next(data as EntitySchemaDataResponse);
                } else {
                    observer.next({ status: true });
                }

            }
        }, () => observer.next({ status: true }));
    }

    getByCustomFilterWithUrl<T>(data: any, url: string, dependencies?: Array<DynamicDependencyProperty>): Observable<T> {
        return this.getByAnyFilterType(data, url, dependencies);
    }

    getByAnyFilterType<T>(payload: any, url: string, dependencies?: Array<DynamicDependencyProperty>): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            this.#loaderService.incrementLoaderCount();
            return this.post(url, payload).subscribe(
                (data: T) => {
                    this.#loaderService.decrementLoaderCount();
                    this.#loaderService.incrementLoaderCount();
                    this.#dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
                        (dependencyData: T) => {
                            observer.next(dependencyData);
                            this.#loaderService.decrementLoaderCount();
                        });
                }, (error: ErrorEvent) => {
                    observer.error(error);
                    this.#loaderService.decrementLoaderCount();
                });
        });
    }
}
