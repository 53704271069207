<div class="modal-dailog-generic-wrapper">

  <div class="row rt-my-10 modal-dialog-header">
    <div class="rt-flex-items rt-pl-15 rt-pr-0">
      <ng-content select=".modal-header"></ng-content>
    </div>
    <div class="rt-flex-items-auto row rt-pr-10 rt-pl-5 rt-justify-end">
      <button mat-button class="rt-px-5" *ngIf="modalMaxMinButtons">
        <span class="rt-dimension-20 rt-overflow-hdn rt-font-18 svg-icons-maximize" (click)="modalMaximize($event)"></span>
      </button>
      <button mat-button class="rt-dailog-close rt-px-5" [mat-dialog-close]="false">
        <mat-icon class="rt-mr-0">clear </mat-icon>
      </button>
    </div>
  </div>


  <div mat-dialog-content>
    <ng-content select=".modal-content"></ng-content>
  </div>

  <div mat-dialog-actions class="rt-mb-0 rt-p-0 rt-justify-end">
    <ng-content select=".modal-actions"></ng-content>
  </div>

</div>