import { Injectable } from "@angular/core";
import { Tween } from "@tweenjs/tween.js";
import { AnimationsContainer, ComposedAnimation } from "../../page-builder/attributes/animation/animation-attribute";
import { AnimationMode, AnimationProperty, AnimationPropertyAttributeregistry } from "../../page-builder/attributes/animation/animation-models";
import { ModelAndOneUsableValueContainer } from "../../page-builder/attributes/animation/model-and-usable-value-container";
import { ModelAndUsableValueContainer } from "../../page-builder/attributes/core/model-and-usable-value-container";
import { IControl } from "../../page-builder/controls/core/control-model";
import { RtNone, RtOption, RtSome } from "../../utils/option-helper";
import { TweenBuilder } from "./tween-builder";

export type TweenProperty = { tween: Tween<any>, property: AnimationProperty }

@Injectable()
export class AnimationService {

    onGoingAnimations: Map<string, TweenBuilder[]> = new Map()

    // key(attributeName:string, controlId:string) {
    //   `${controlId}_${attributeName}`
    // }

    applyAnimation(container: AnimationsContainer,
        control: IControl<unknown>,
        hydratedInstances: ModelAndUsableValueContainer<unknown, unknown>[]) {

        const existingTweens = this.ongoingTweensByControlId(control.id)

        if (existingTweens.isDefined) {
            this.modifyTweens(container, existingTweens.get)
        } else {
            const allTweens = this.buildTweens(container, control, hydratedInstances)
            this.onGoingAnimations.set(control.id, allTweens)
        }
        //  const tween = new Tween(element).to()
    }

    stopAnimation(controlId: string) {
        const existingTweens = this.ongoingTweensByControlId(controlId)
        if (existingTweens.isDefined) {
            existingTweens.get.map(t => t.stop())
            this.removeTweensByControlId(controlId)
        }
    }

    // stopAnimation(controlIds: string[]) {
    //     this.ongoingAnimations.forEach((tween, id) => {
    //         if (controlIds.find(stopRequestId => stopRequestId == id)) {
    //             try {
    //                 tween.stop()
    //             } catch {
    //                 console.error(`animation is failed to stop for the id${id}. possible is reason is that it is already elapsed.`)
    //             } finally {
    //                 this.ongoingAnimations.delete(id)
    //             }
    //         } else {
    //             //do nothing
    //         }
    //     })
    // }

    private buildTweens(container: AnimationsContainer,
        control: IControl<unknown>,
        hydratedInstances: ModelAndUsableValueContainer<unknown, unknown>[]) {

        const animations = container.composedAnimations
        if (container.animationMode == AnimationMode.SEQUENTIAL) {
            const tweensSequential = this.chainTweens(animations, RtNone(), [], control, hydratedInstances)
            return tweensSequential;
        } else {
            //build and start all as they need be running independently in parallel
            const tweensParallel = animations.map(animation => this.buildTween(animation, control, hydratedInstances)).map(t => t.start())
            return tweensParallel;
        }
    }

    //recursion
    private chainTweens(composedAnimations: ComposedAnimation[],
        previousTweenOpt: RtOption<TweenBuilder>,
        allTweens: TweenBuilder[], control: IControl<unknown>,
        hydratedInstances: ModelAndUsableValueContainer<unknown, unknown>[]) {

        if (composedAnimations.length > 0) {
            //todo: required sorting?
            const currentAnimation = composedAnimations[0]

            const currentTween = this.buildTween(currentAnimation, control, hydratedInstances)
            if (previousTweenOpt.isDefined) {
                const previousTween = previousTweenOpt.get
                //chain to the pervious
                previousTween.chain(currentTween.tween)
            } else {
                //nothing to chain as it is the first animation
                //so start the first tween, so rest should be started automatically once the first one complete in sequential order
                currentTween.start()
            }
            const remainingAnimations = composedAnimations.filter(c => c != currentAnimation)
            allTweens.push(currentTween)
            return this.chainTweens(remainingAnimations, RtSome(currentTween), allTweens, control, hydratedInstances)
        } else {
            //all ani
            return allTweens;
        }
    }

    private removeTweensByControlId(controlId: string) {
        this.onGoingAnimations.delete(controlId)
    }

    private ongoingTweensByControlId(controlId: string) {
        const existingTweens = this.onGoingAnimations.get(controlId)
        return RtSome(existingTweens)
    }

    private modifyTweens(container: AnimationsContainer, onGoingTweens: TweenBuilder[]) {
      console.error("modifyTweens: Method not implemented");
        //throw new Error("Method not implemented");
    }

    buildTween(composedAnimation: ComposedAnimation, control: IControl<unknown>,
        usableValues: ModelAndUsableValueContainer<unknown, unknown>[]): TweenBuilder {

        const attributeName = AnimationPropertyAttributeregistry.resolve(composedAnimation.animationProperty)
        const currentModelWithUsableValues = usableValues.find(m => m.hasAttribute(attributeName))//.find(h => h.name == attributeName)

        if(RtSome(currentModelWithUsableValues).isDefined) {
            const oneUsableValue = currentModelWithUsableValues.usableValues.find(u => u.name == attributeName)
            const container = new ModelAndOneUsableValueContainer(currentModelWithUsableValues.model,oneUsableValue)
            const builder = new TweenBuilder(composedAnimation, control, container).onComplete(() => {
                if (this.onGoingAnimations.has(control.id))
                    this.onGoingAnimations.delete(control.id)
            });
            return builder
        } else {
            throw new Error("No animatable property found")
        }

        //builder.
    }

}
