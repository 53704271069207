import { ViewContainerRef } from "@angular/core";
import { IDsResult, IControl } from "projects/den-core/page-builder";
import { ControlInstanceWrapper } from "../control-instance-drafts-models";

export class CompanionRequestCommand<D extends IDsResult, C extends IControl<D>> {
  constructor(public control: C, public viewContainerRef: ViewContainerRef, public data: any) {

  }
}

export class CompanionDataChangeCommand {
  constructor(public controlInstance: ControlInstanceWrapper, public data: any) {

  }
}

export class DestroyCompanionCommand<D extends IDsResult, C extends IControl<D>> {
  constructor(public control: C) {

  }
}
