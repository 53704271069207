
import { DsResult } from '../../../../data-source/data-source-result-entities';
import { DSBindTypeEnum } from '../../../../models/enums';
import { AddChildBaseCommand, AddChildWithDsResultCommand, DataStateType } from '../../child-rendered-event';
import { FilterConfig } from '../../list-view-control/shared/models/list-view.model';
import { RtOption } from "../../../../../utils/option-helper";
import { AbstractDSStateManager } from '../abstract-ds-state-manager';
import { IControl } from '../../control-model';


export class DsResultStateManager extends AbstractDSStateManager<DsResult> {
  resetDataForAllDataSources() {
    this.data = null;
  }


  dsBindType: DSBindTypeEnum = DSBindTypeEnum.SINGLE;

  applyData(parentCmd: AddChildBaseCommand, newData: DsResult, control: IControl<DsResult>, _filterConfig: RtOption<FilterConfig>,): void {
    this.parentCmd = parentCmd;
    // In case of dsResult State manager once the data is recieved all furthur data will be update mode only. Hence Changing the data state type to Updated.
    if (this.data) {
      newData.dataStateType = DataStateType.UPDATED;
    }
    this.data = newData;

    const cmd = this.buildCommand(this.data, control);
    this.enQueueAllCmds([cmd]);
  }

  rebuildCommands(control: IControl<DsResult>): AddChildWithDsResultCommand[] {
    if (this.data) {
      const command = this.buildAddNewCommand(this.data, control);
      return [command];
    }
    return [];
  }

  onDestroy() {

  }
}

