import {Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable()
export class ModalService {

    private readonly panelClass = 'modal-dialog-wrapper';
    private readonly width = '500px';
    private dialogRef: MatDialogRef<any>;
    constructor(private dialog: MatDialog) { }

    public invoke<T>(modalComponent: any, modalData?: T, width?: number, panelClass?: string, disableClose?: boolean, autoFocus?: boolean, height?: number,): Observable<any> {
        this.dialogRef = this.dialog.open(modalComponent, {
            data: modalData ? modalData : {},
            width: width ? `${width}px` : this.width,
            panelClass: panelClass ? panelClass : this.panelClass,
            disableClose: disableClose == false ? false : true,
            autoFocus: autoFocus ? autoFocus : false,
            height: height ? `${height}px` : 'auto',
        });

        return this.dialogRef.afterClosed();
    }
    public invokeWithViewContainerRef<T>(modalComponent: any, viewContainerRef: ViewContainerRef, modalData?: T, width?: number, panelClass?: string, disableClose?: boolean, autoFocus?: boolean, height?: number,): Observable<any> {
        const dialogData = {
            data: modalData ? modalData : {},
            viewContainerRef: viewContainerRef,
            width: width ? `${width}px` : this.width,
            panelClass: panelClass ? panelClass : this.panelClass,
            disableClose: disableClose == false ? false : true,
            autoFocus: autoFocus ? autoFocus : false,
            height: height ? `${height}px` : 'auto',
        };

        this.dialogRef = this.dialog.open(modalComponent, dialogData);

        return this.dialogRef.afterClosed();
    }

    public getDialogRef<T>(): MatDialogRef<T> {
        return this.dialogRef;
    }
}
