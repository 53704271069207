export class CtrlEventAttribute {
    constructor(public attributeType: EventAttributeType, public value: any) {

    }
}

export enum EventAttributeType {
    TIME_STAMP = 1,
    SELECTED_STATUS = 2,
    // E.G. add cesium (or threeJS) specific event
}
