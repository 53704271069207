import { Subscription } from "rxjs/internal/Subscription";


export class DataSourceSubscription {
    constructor(public dsName: string, public subscription: TopicSubscription[]) {}
}

export class TopicSubscription {
    constructor(public topicName: string, public subscription: Subscription) {}
}
