import { Directive, Injector } from "@angular/core";
import { DsResultArray } from "../../data-source/data-source-result-entities";
import { AngularDefaultControlRenderService } from "../../services/render/strategy/angular-default-control-render-service";
import { AngularListControl } from "./angular-list-control";

@Directive()
export abstract class SimpleAngularListControl extends AngularListControl<DsResultArray, AngularDefaultControlRenderService> {

    controlRenderService: AngularDefaultControlRenderService;
    constructor(injector: Injector) {
        super(injector, DsResultArray);
        this.controlRenderService = injector.get(AngularDefaultControlRenderService);
    }
}