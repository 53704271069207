import { AllMeasuresUnits } from "convert-units/definitions/all"
import { RtOption, RtSome } from "../utils/option-helper"
import { UOMOptions } from "./uom-reference-service"

export class UomBinding {

  constructor(private _direct: RtOption<AllMeasuresUnits>,
    private _uomOptions: RtOption<UOMOptions>,
    private _rounding: RtOption<number>,) {
  }

  get direct(): AllMeasuresUnits { return this._direct.getOrElseUndefined }


  set direct(value: AllMeasuresUnits) { this._direct = RtSome(value) }

  get uomOptions(): UOMOptions { return this._uomOptions.getOrElseUndefined }

  set uomOptions(value: UOMOptions) { this._uomOptions = RtSome(value) }

  get rounding(): number { return this._rounding.getOrElseUndefined }

  set rounding(value: number) {
    this._rounding = RtSome(value)
  }

  static parse(uomBinding: UomBinding): UomBinding {
    const directValue = RtOption.parse(uomBinding._direct);
    const uomOptions = RtOption.parse(uomBinding._uomOptions);
    const rounding = RtOption.parse(uomBinding._rounding);
    const parsedUomBinding = new UomBinding(directValue, uomOptions, rounding);
    return parsedUomBinding;
  }
}
