export abstract class ToastServiceProvider {
    abstract showSuccess(successMessage: string, action?: { name: string, cssClass: string, callback: () => void; }[], config?: {}): void;

    abstract showError(errorMessage: string, action?: { name: string, cssClass: string, callback: () => void; }[], config?: {}): void;

    abstract showInfo(infoMessage: string, action?: { name: string, cssClass: string, callback: () => void; }[], config?: {}): void;

    abstract showWarn(warnMessage: string, action?: { name: string, cssClass: string, callback: () => void; }[], config?: {}): void;
}

export enum LogType {
    LOG = 'log',
    WARN = 'warn',
    ERROR = 'error',
    INFO = 'info',
}